import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import {
    IAccountAddress,
    IAccountCard,
    IAccountChangePassword,
    IAccountChangeProfilePicture,
    IAccountDeleteProfile,
    IAccountForgetPassword,
    IAccountInfo,
    IAccountLoginValues,
    IAccountOnboarding,
    IAccountRegisterRequest,
    IAccountSosialLoginValues,
    IAccountUploadAvatar,
    IAchievement,
    IActiveAccount,
    ILoginInfo,
    ISponsor,
} from '../models/account';
import { INotification } from '../models/notifications';
import { store } from './store';
import { IEditUserProfile, IProfileDetail, IUserProfile, IUserProfilePayload } from '../models/profile';
import { useLocation } from 'react-router-dom';
import { removeCookie } from '../config/utils';


export default class AccountStore {
    account: ILoginInfo | null = null;
    user: IAccountInfo | null = null;
    userAddress: IAccountAddress | null = null;
    profile: IUserProfile | null = null;
    notification: INotification[] = [];
    request_card: IAccountUploadAvatar[] = [];
    reset_password_email: string | null | undefined;
    update_avatar: IAccountUploadAvatar[] = [];
    main_card: IProfileDetail | null = null;
    card: IAccountCard[] = [];
    achievement: IAchievement[] = [];
    activeAccount  = new Array<IActiveAccount>();
    sponsor: ISponsor[] = [];
    
    loadingAddressForm = false;
    loadingLogin = false;
    loadingRegister = false;
    loadingChangePassword = false;
    loadingUpdateAvatar = false;
    


    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    login = async (creds: IAccountLoginValues) => {
        try {
            this.loadingLogin = true;
            await agent.Account.login(creds).then((res) => {
                store.commonStore.setToken(res.access_token);
                this.getUser();
            })
            return true
        } catch (error) {
            return false;
        } finally {
            runInAction(() => {
                this.loadingLogin = false;
            });
        }
    };

    loginSocial = async (creds: any) => {
        try {
            window.localStorage.removeItem('jwt');
            if (creds) {
                const user = await agent.Account.loginSocial(creds);
                store.commonStore.setToken(user.access_token);
                await this.getUser()
                window.location.replace('/')
            }
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.loadingLogin = false;
            });
        }
    };

    private setUser = (user: ILoginInfo | null) => {
        this.account = user;
    };

    private refreshToken = async () => {
        const refresh = await agent.Account.refresh();
        store.commonStore.setToken(refresh.access_token);
    };

    logout = async () => {
        store.commonStore.setToken(null);
        this.setUser(null);
        this.setUserInfo(null);
        removeCookie('onboarding')
        window.location.replace('/login');
    };

    getUser = async () => {
        try {
            const user = await agent.Account.me();
            this.setUserInfo(user.data);
        } catch (error) {
            console.log(error);
        }
    };

    private setUserInfo = (user: IAccountInfo | null) => {
        this.user = user;
    };

    register = async (creds: IAccountRegisterRequest) => {
        try {
            this.loadingRegister = true;
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.access_token);
            this.setUser(user);
            return true;
        } catch (error) {
            return false;
        } finally {
            runInAction(() => {
                this.loadingRegister = false;
            });
        }
    };

    //Sponsor 

    getSponsor = async () => {
        try {
            const sponsor = await agent.Account.sponsor();
            this.setSponsor(sponsor.data);
            return sponsor;
        } catch (error) {
            console.log(error);
        }
    };

    setSponsor = (data : ISponsor[]) => {
        this.sponsor = data;
    }

    //User Adress
    getUserAddress = async () => {
        try {
            const address = await agent.Account.address();
            this.setUserAddress(address.data);
            return address;
        } catch (error) {
            console.log(error);
        }
    };

    setUserAddress = (address: IAccountAddress | null) => {
        this.userAddress = address;
    };

    createUserAddress = async (data: IAccountAddress) => {
        try {
            this.loadingAddressForm = true;
            await agent.Account.createAddress(data);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.loadingAddressForm = false;
            });
        }
    };

    //Change Password
    changePassword = async (pass: IAccountChangePassword) => {
        try {
            this.loadingChangePassword = true;
            await agent.Account.changePassword(pass).then((res) => {
                store.commonStore.setToken(res.access_token);
            })
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.loadingChangePassword = false;
            });
        }
    };

    onBoarding = async (details: IAccountOnboarding) => {
        try {
            const result = await agent.Account.onBoarding(details)
            return result.data.slug
        } catch (error) {
            return false;
        }
    };

    closeOnBoarding = async () => {
        try {
            await agent.Account.closeOnBoarding()
        } catch (error) {

        }
    }

    setAvatar = async (data: IAccountUploadAvatar) => {
        try {
            const result = await agent.Account.avatar(data.data);

            return result;
        } catch (error) {
            throw error;
        }
    };

    getNotifications = async () => {
        try {
            const notifications = await agent.Notification.listNotification();
            this.setNotification(notifications);
        } catch (error) {
            console.log(error);
        }
    };

    setNotification = (data: INotification[]) => {
        this.notification = data;
    };

    readNotifications = async () => {
        try {
            await agent.Notification.readNotification();
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.getNotifications();
            });
        }
    };

    validateToken = async (key: string) => {
        try {
            await agent.Account.validateEmail(key);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    sendVerifiedEmail = async () => {
        await agent.Account.sendValidateEmail()
    }

    getProfileUser = async (slug: string) => {
        try {
            const profile = await agent.Account.userProfile(slug);
            this.setProfile(profile.data)
        } catch (error) {
            throw (error)
        }
    }

    setProfile = (data: IUserProfile) => {
        this.profile = data
    }

    editProfile = async (data: IEditUserProfile, slug: string) => {
        await agent.Account.editUserProfile(data, slug);
    }

    forgetPassword = async (data: IAccountForgetPassword) => {
        const forgot = await agent.Account.forgotPassword(data);
        window.location.href = '/verification';
    };

    validateResetPassword = async (data: IAccountForgetPassword) => {
        const check = await agent.Account.validateForgotPassword(data);
        window.localStorage.setItem('reset_token', data.token ? data.token : '');
        this.reset_password_email = data.email;
        return check.success;
    };

    resetPassword = async (data: IAccountForgetPassword) => {
        try {
            this.loadingChangePassword = true;
            await agent.Account.resetPassword(data).then((res) => {
                window.localStorage.removeItem('reset_token');
            });
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.loadingChangePassword = false;
            });
        }
    };

    updateAvatar = async (data: IAccountUploadAvatar) => {
        // await agent.Account.updateAvatar(data);
        try {
            const result = await agent.Account.avatar(data.data)
            return result;
        } catch (error) {
            throw error;
        }
    };

    getCard = async (slug: string) => {
        await agent.Account.cardList(slug).then((res) => {
            this.setCard(res.data)
        })
    }

    setCard = (data: IAccountCard[]) => {
        this.card = data
    }

    getMainCard = async () => {
        await agent.Account.mainCard().then((res) => {
            this.main_card = res.data
        })
    }

    updateProfilePicture = async (data: IAccountChangeProfilePicture) => {
        await agent.Account.editProfilePicture(data).then((res) => {
            window.location.reload()
        })
    }

    deleteProfile = async (data: IAccountDeleteProfile) => {
        await agent.Account.deleteProfile(data);
    }

    getAchievementList = async (slug: string) => {
        await agent.Account.achievementList(slug).then((res) => {
            this.setAchievementList(res.data)
        })
    }

    setAchievementList = (data: IAchievement[]) => {
        this.achievement = data
    }


    getActiveAccount = async () => {
        // const result = await agent.Subscription.listSubcriptions()
        //     this.loading = false;
        //     this.setSubscriptionData(result.data);
        const result = await agent.Account.activeAccount();
        this.setActiveAccount(result.data)
    }

    setActiveAccount = (data: IActiveAccount[]) => {
        this.activeAccount = data
    }
}
