import React, { useEffect, useState } from 'react'
import NewsCard from '../../app/component/card/NewsCard'
import { Pagination } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../app/stores/store';

function NewsGrid() {

  const { postStore } = useStore();

  const [ page, setPage] = useState(1);

  useEffect(() => {
    postStore.getListPost(page);
  }, [page, postStore]);
  
  return (
    <>
        <h3 className='text-white font-bold text-lg pb-2'>Latest News</h3>
        <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4 py-6'>
            {postStore.listPost?.map((post, idx) => (
              <NewsCard post={post} key={idx}/>
            ))}
            <div className='text-center col-span-1 lg:col-span-2 lg:text-left'>
              <Pagination defaultCurrent={1} current={page} total={postStore.totalPage} pageSize={postStore.pageSize} showSizeChanger={false} showLessItems onChange={(value) => setPage(value)}/>
            </div>
        </div>
    </>
  )
}

export default observer(NewsGrid)