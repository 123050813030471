import { action, makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent";
import { IRules, ITournament, ITournamentGame, ITournamentLeaderBoard, ITournamentParticipant, ITournamentPlayerStat, ITournamentResultInput, ITournamentReward } from "../models/tournament";
import { ITeam } from "../models/team";
import { registrationTypeString } from "../config/enum";

export default class TournamentStore {

    data = new Array<ITournament>();
    leaderboardData = new Array<ITournamentLeaderBoard>();
    playerRankData : ITournamentLeaderBoard | null = null;
    loadingForm = false;
    loadingList = false;
    detail: ITournament | null = null;
    rules = new Array<IRules>();
    banner: ITournament | null = null;
    player_stat: ITournamentPlayerStat | null = null;
    teamLists: ITeam[] = [];
    participantsLists: ITournamentParticipant[] = [];
    rewardList: ITournamentReward[] = [];
    gameList: ITournamentGame[] = [];
    myMatchList: any[] = [];
    myTournamentList: any[] = [];
    myOrder: any[] = [];
    check_payment: any = false;
    in_tournament: boolean = false;
    in_tournament_message : string = "Anda sedang mengikuti Tournament. Hapus kembali saat periode Tournament sudah selesai."
    LeaderboardLastUpdated : any = '';

    constructor() {
        makeAutoObservable(this);
    }

    updateGridCallback = action(() => {
        this.getTournamentList();
        //this.getLeaderBoard();
    })

    getLeaderBoard = async (platform : string , limit : number , filters : any) => {
        try {
            const result = await agent.Tournament.tournamentLeaderboard(platform , limit , filters);
            console.log(result)
            this.setLeaderboardData(result.data);
        } catch (error) {
            throw error;
        }
    }

    getPlayerRank = async (slug : string ) => {
        try {
            const result = await agent.Tournament.tournamentplayerRank(slug);
            this.setPlayerRankData(result.data);
        } catch (error) {
            throw error;
        }
    }

    setLeaderboardData = (res: Array<ITournamentLeaderBoard>) => {
        this.leaderboardData = res;
    }

    setPlayerRankData = (res : ITournamentLeaderBoard) => {
        this.playerRankData = res;
    }

    getLeaderboardLastUpdated = async () => {
        console.log('a')
        try {
            const result = await agent.Tournament.getLeaderboardLastUpdated();
            console.log({result : result})
            this.setLeaderboardLastUpdated(result);
        } catch (error) {
            throw error;
        }
    }

    setLeaderboardLastUpdated = (res: any) => {
        this.LeaderboardLastUpdated = res
    }
    

    getTournamentList = async () => {
        try {
            const result = await agent.Tournament.listTurnament();
            this.setTournamentData(result.data);
        } catch (error) {
            throw error;
        }
    }

    setTournamentData = (res: Array<ITournament>) => {
        this.data = res;
    }

    getRulesLists = async (tournamentId: number) => {
        try {
            const result = await agent.Tournament.rulesById(tournamentId);

            this.setRules(result.data);
        } catch (error) {
            throw (error)
        }
    }

    setRules = (data: Array<IRules>) => {
        this.rules = data;
    }

    getDetailTournaments = async (tournamentSlug: string) => {
        try {
            const result = await agent.Tournament.detailTurnament(tournamentSlug);
            this.setDetailTournament(result.data);
        } catch (error) {
            throw (error)
        }
    }

    setDetailTournament = (data: ITournament) => {
        this.detail = data;
    }

    getPlayerStat = async (slug: string) => {
        await agent.Tournament.playerStat(slug).then((res) => {
            this.setPlayerStat(res.data)
        })
    }

    setPlayerStat = (data: ITournamentPlayerStat) => {
        this.player_stat = data;
    }

    getParticipantTournament = async (tournamentId: number) => {
        await agent.Tournament.listParticipants(tournamentId).then((res) => {
            this.setParticipantTournament(res.data);
        })
    }

    setParticipantTournament = (data: ITournamentParticipant[]) => {
        this.participantsLists = data;
    }

    getRewardTournament = async (tournamentId: number) => {
        await agent.Tournament.listRewards(tournamentId).then((res) => {
            this.setRewardTournament(res.data);
        })
    }

    setRewardTournament = (data: ITournamentReward[]) => {
        this.rewardList = data;
    }

    getGameList = async () => {
        await agent.Tournament.listGames().then((res) => {
            this.setGameList(res.data);
        })
    }

    setGameList = (data: ITournamentGame[]) => {
        this.gameList = data;
    }

    getTournamentByGame = async (id: number, filters: any) => {
        await agent.Tournament.tournamentByGame(id, filters.status, filters.level, filters.pendaftaran).then((res) => {
            this.setTournamentData(res.data)
        })
    }

    getMyOngoingMatch = async (slug: string) => {
        await agent.Tournament.myOngoingMatch(slug).then((res) => {
            this.setMyMatch(res.data)
        })
    }

    setMyMatch = (data: any) => {
        this.myMatchList = data;
    }

    submitResult = async (data: ITournamentResultInput) => {
        await agent.Tournament.submitMatchResult(data)
    }

    getMyTournament = async (slug: string) => {
        const data = agent.Tournament.myTournament(slug)
        this.setMyTournament((await data).data.data)
    }

    setMyTournament = async (data: any[]) => {
        this.myTournamentList = data;
    }

    getMyOrder = async (slug: string) => {
        const data = agent.Tournament.myOrder(slug)
        this.setMyOrder((await data).data)
    }

    setMyOrder = async (data: any[]) => {
        this.myOrder = data;
    }

    checkPayment = async (slug: string) => {
        const data = agent.Tournament.checkPay(slug)
        this.setCheckPayment((await data).data)
    }

    setCheckPayment = async (data: any) => {
        this.check_payment = data
    }

    checkOnoingTournament = async (slug: string) => {
        const data = await agent.Tournament.checkOngoingTournament(slug)
       
        this.setInTournament(data.data , data.message)
    }

    setInTournament= async (data: any , message : string) => {
        this.in_tournament = data
        this.in_tournament_message = message
    }
}