import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../stores/store';
import { IAccountDeleteProfile } from '../../models/account';

interface ModalProps {
    onOpenModalerrorModal: boolean;
    redirect?: () => void;
    onClose: () => void;
    description: string;
    title: string | null;
}

const ErrorModal: React.FC<ModalProps> = ({ onOpenModalerrorModal, redirect, onClose, title, description }) => {

    const [active, setActive] = useState('');

    useEffect(() => {
        if (onOpenModalerrorModal) {
            setActive('active');
        }
    })

    return (
        <div>
            {onOpenModalerrorModal && (
                <div>
                    <div className="modal-overlay" onClick={onClose}></div>
                    <div className={`modal overflow-y-scroll w-full h-fit mt-[64px] xl:w-[438px] lg:m-auto lg:h-full lg:max-h-[90vh] ${active}`}>
                        <div className="close cursor-pointer" onClick={onClose}>
                            <img
                                onClick={onClose}
                                className="w-6 h-6"
                                src="/assets/images/close.svg"
                                alt="close"
                            ></img>
                        </div>
                        <div className="modal-content p-4 lg:p-5">
                            <div className='flex flex-row text-center justify-center items-center mb-[14px]' onClick={onClose}>
                                <h3 className="text-white text-center font-extrabold text-[32px] leading-normal">{title} </h3>
                            </div>
                            <figure>
                                <img
                                    src="/assets/images/comment-alert.svg"
                                    alt="club_img"
                                    className="w-[120px] mx-auto mb-6"
                                />
                            </figure>
                            <p className='text-white text-center text-sm font-semibold leading-5 mb-6'>{description}</p>
                            <div className='flex flex-row items-center'>
                                <button className='w-full h-[50px] rounded-md bg-secondary text-white font-bold leading-normal text-xs border border-darkgray' onClick={redirect}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ErrorModal;
