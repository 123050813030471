import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { observer } from "mobx-react-lite";
import "./detail.css"
import { useStore } from "../../../app/stores/store";
import { ITournament } from "../../../app/models/tournament";
import SkeletonComponent from "../../../app/component/skeleton/SkeletonComponent";
import { formatDateOnly, tournamentMode } from "../../../app/config/enum";

interface detailProps {
    data: ITournament | null;
}

const DetailTournamentBanner: React.FC<detailProps> = ({ data }) => {

    return (
        <div className="w-full lg:h-full font-sans">
            {data ? (
                <div className="relative h-[250px] lg:h-[460px] lg:w-full">
                    <div className="absolute inset-0 bg-gradient-to-t lg:bg-gradient-to-r from-black to-transparent"></div>
                    <img className="object-cover w-full h-full" src={`${data.flag}`} alt="gambar" />
                    <div className="absolute w-full h-full p-4 inset-0 items-start justify-center text-white flex flex-col lg:w-3/4 lg:mt-0 lg:text-left lg:px-20 xl:px-40">
                        <a href="/tournament">
                            <img src="/assets/images/ic-arrow-back.svg" alt="back" className="md:hidden bg-nav1 rounded-md mb-[9px] lg:bg-transparent lg:rounded-none" />
                        </a>
                        <h1 className="font-black text-[18px] w-[70%] mb-3 leading-normal lg:w-full lg:text-5xl">{data?.name}</h1>
                        <div className="flex items-center space-x-1 mb-1">
                            <div className="badge badge-card-blue rounded text-xs font-medium leading-normal">{data.tournament_type}</div>
                            <div className="badge badge-card-blue rounded text-xs font-medium leading-normal">{data.team_type}</div>
                        </div>
                        <div className="badge badge-card-green rounded text-xs font-medium leading-normal mb-2">{new Date() > new Date(data.close_date) ? "Registration Close" : "Open Registration"}</div>
                        <p className="text-xs leading-normal font-normal mb-2">{data.range_date}</p>

                        {data.sponsor.length > 0 &&
                            // data.sponsor.
                            (
                                <div className="flex flex-row">
                                    <img
                                        src={`${process.env.REACT_APP_STORAGE_URL}${data.sponsor[0].logo}`}
                                        alt="logo"
                                        className="hidden lg:block w-10 h-auto object-contain mr-4"
                                    />
                                    <div className="flex-col flex w-full h-full">
                                        <span className="text-xs font-normal leading-normal">
                                            Hosted By:
                                        </span>
                                        <span className="text-xs font-normal leading-normal">
                                            {data.sponsor[0].name}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            ) : (
                <SkeletonComponent
                    count={1}
                    width='w-full'
                    height='h-[200px]'
                    borderRadius='rounded-sm'
                />
            )}
        </div>
    );
}

export default observer(DetailTournamentBanner);
