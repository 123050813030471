import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../app/stores/store'
import { useNavigate } from 'react-router-dom';

function LeagueClubProfile() {

    const navigate = useNavigate();

    const { leagueStore } = useStore();

  return (
    <React.Fragment>
        <div className='w-full h-full py-4 font-sans'>
            <div className='block w-full h-full divide-y-reverse divide-y-2 divide-gray-700'>
                <h3 className='text-white font-bold text-lg mb-2'>Team Participants</h3>
                <hr/>
            </div>
            <div className='w-full h-full lg:px-6 py-4 text-white grid grid-cols-2 lg:grid-cols-4 gap-4'>
                {leagueStore.standings?.map((data, idx) => (
                    <div className='w-full h-full bg-nav1 rounded-xl cursor-pointer' onClick={() => navigate(`club/${data.team.id}`)}>
                        <div className='w-full h-[100px] lg:h-[250px] p-2 lg:p-12'>
                            <img src={data.team.flag} alt='club' className='w-full h-full object-contain'/>
                        </div>
                        <div className='bg-[#1D2335] w-full py-3 text-[2.5vw] lg:text-[1vw] text-center font-semibold rounded-b-xl'>
                            <span>{data.team.name}</span>
                        </div>
                    </div>
                ))} 
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(LeagueClubProfile)