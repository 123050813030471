import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MediaQuery from 'react-responsive';
import { useParams } from "react-router-dom";
import { IUserProfile } from "../../../app/models/profile";
import { useStore } from "../../../app/stores/store";
import SkeletonComponent from "../../../app/component/skeleton/SkeletonComponent";
import AmateurCard from "../AmateurCard";
import DummyPlayerCard from "../../../app/component/card/dummy/DummyPlayerCard";
import EmptyPlayerCard from "../../../app/component/card/empty/EmptyPlayerCard";
import EmptyAchievement from "../../../app/component/card/empty/EmptyAchievement";
import AchievementCard from "../../../app/component/card/AchievementCard";
import ClubCard from "../../../app/component/club/ClubCard";
import PlayerBoard from "../PlayerBoard";
import PlayerClubList from "./PlayerClubList";
import ViewPlayerBoard from "./ViewPlayerBoard";

const playerData = {
    level: "amateur"
};

interface playerProps {
    data: IUserProfile | null;
}

const PlayerTournament: React.FC<playerProps> = ({ data }) => {
    const { clubStore, PlayerStore, accountStore, TournamentStore } = useStore()
    const [isModalEditOpen, setIsModalEditOpen] = useState(false)

    const { slug } = useParams<{ slug: string }>();

    useEffect(() => {
        clubStore.listHistory(String(slug))
        PlayerStore.getProfileUser(String(slug))
        PlayerStore.getAchievementList(String(slug))
        TournamentStore.getPlayerStat(String(slug))
    }, [clubStore, TournamentStore])

    const toggleModalEdit = () => {
        setIsModalEditOpen(!isModalEditOpen)
    }

    return (
        <div className='w-full h-full'>
            <React.Fragment>
                <div className='w-full h-full flex flex-col lg:flex-row lg:space-x-10 text-white'>
                    {/* Sidebar Left */}
                    <div className='w-full lg:w-[328px] flex flex-col mb-[14px]'>
                        {/* Standings */}
                        <div className='w-full flex flex-col'>
                            <div className="w-full bg-nav1 rounded-xl px-12 py-6 relative">
                                {!PlayerStore.profile?.detail
                                    ?
                                    <SkeletonComponent
                                        count={1}
                                        width='w-[230px]'
                                        height='h-[370px]'
                                        borderRadius='rounded-xl'
                                    /> :
                                    (!PlayerStore.profile?.detail.card
                                        ?
                                        <div>
                                            {PlayerStore.profile.detail.level === 'amateur' ?
                                                <AmateurCard
                                                    player={PlayerStore.profile.detail.level ? PlayerStore.profile.detail.level : 'amateur'}
                                                    data={PlayerStore.profile.detail}></AmateurCard>
                                                :
                                                (PlayerStore.profile.detail.card_approved ?
                                                    <DummyPlayerCard
                                                        player={PlayerStore.profile.detail.level ? PlayerStore.profile.detail.level : 'amateur'}
                                                        data={PlayerStore.profile.detail}
                                                    /> : <EmptyPlayerCard />
                                                )}
                                        </div>
                                        :
                                        <div>
                                            {PlayerStore.profile.detail.card_approved ?
                                                <DummyPlayerCard
                                                    player={PlayerStore.profile.detail.level ? PlayerStore.profile.detail.level : 'amateur'}
                                                    data={PlayerStore.profile.detail}
                                                /> : <EmptyPlayerCard />
                                            }
                                        </div>
                                    )
                                }

                            </div>
                            <MediaQuery minWidth={768}>
                                {!PlayerStore.achievement ?
                                    <EmptyAchievement />
                                    : <AchievementCard data={PlayerStore.achievement} />
                                }
                            </MediaQuery>
                        </div>
                    </div>
                    <div className='w-full lg:w-[70%] flex flex-col'>
                        <ClubCard data={TournamentStore.player_stat} />
                        <PlayerClubList data={clubStore.historyList} />
                        <MediaQuery maxWidth={768}>
                            {!PlayerStore.achievement ?
                                <EmptyAchievement />
                                : <AchievementCard data={PlayerStore.achievement} />
                            }
                        </MediaQuery>
                        <ViewPlayerBoard />
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
}

export default observer(PlayerTournament);
