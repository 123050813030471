import { useStore } from "../stores/store";

export function toPercentByCategory(nominal_jenis: number | undefined, total: number | undefined) {
    const res = Math.round((nominal_jenis || 0) / (total || 0) * 100);
    return res;
}

export const formatRupiah = (amount: number): string => {
    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
    });

    return formatter.format(amount);
};

export const shortenText = (maxLength: number, originalText: string): string => {
    if (originalText.length > maxLength) {
        return `${originalText.slice(0, maxLength)}...`;
    }
    return originalText;
};

export const formatDate = (dateString: string): string => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    });
    return formattedDate;
}

export const formatDateOnly = (dateString: string): string => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    return formattedDate;
}

export function formatDateSchedule(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
}

export function qualification_stage(data: string | undefined) {
    switch (data) {
        case "0": return 'Group Stage';
        case "1": return 'Grand Final';
        case "2": return 'Semi Final';
        case "3": return 'Quarter Final';
        case "4": return 'Round Of Sixteen';
        case "5": return 'Group Stage';
        case "6": return 'Knockout Stage';
        case "7": return 'Final Upper Bracket';
        case "8": return 'Final Lower Bracket';
    }
}

export function calculatePercentage(num1: number, num2: number) {
    if (num1 === 0 && num2 === 0) {
        return 0;
    }
    const total = num1 + num2;
    const numPercentage = (num1 / total) * 100;

    return numPercentage.toFixed(2);
}

export enum sorting {
    LOWEST_PRICE = 'lowest',
    HIGHEST_PRICE = 'highest',
    NEWEST = 'newest',
    OLDEST = 'oldest',
}

export function getPlayerCard(data: string | undefined) {
    switch (data) {
        case "amateur": return '/assets/images/amateur_card.svg';
        case "semi": return '/assets/images/semipro_card.svg';
        case "pro": return '/assets/images/gold-card.png';
    }
}

export function getPlayerSilhouette(data: string | undefined) {
    switch (data) {
        case "amateur": return '/assets/images/siluet-amateur.png';
        case "semi": return '/assets/images/siluet-semi.png';
        case "pro": return '/assets/images/siluet-pro.png';
    }
}

export function contractDate(month: string | null, year: string | null) {
    let m = "Jan"

    // if (!date || !month || year) {
    //     return `Jan 1970`;

    // }
    if (month) {
        m = month.substring(0, 3);
    }
    return `${m} ${year}`;
}

export function calculateAge(year: string) {
    const currentYear = new Date().getFullYear();
    return currentYear - parseInt(year);
}

export function tournamentMode(mode: string) {
    switch (mode) {
        case "all":
            return "All Level";
        case "semi":
            return "Semi Pro";
        case "pro":
            return "Pro"
        case "amateur":
            return "Amateur"
        default:
            break;
    }
}

export function registrationType(type: number) {
    switch (type) {
        case 1:
            return "Free Registration";
        case 2:
            return "Subcription";
        case 3:
            return "Pay to Enter"
        default:
            break;
    }
}

export function registrationTypeString(type: string) {
    switch (type) {
        case "Free Registration":
            return "1";
        case "Subcription":
            return "2";
        case "Pay to Enter":
            return "3"
        default:
            break;
    }
}

export function teamType(type: number) {
    switch (type) {
        case 1:
            return "1v1";
        case 2:
            return "2v2";
        case 3:
            return "3v3"
        default:
            break;
    }
}
interface DateRangeProps {
    startDate: Date;
    endDate: Date;
  }  

export function formatDateRange( startDate : Date, endDate : Date ) : string {
    // Check if the input is valid dates
    if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
      return 'Invalid Date';
    }
  
    // Get day, month, and year for both start and end dates
    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleString('default', { month: 'short' });
    const startYear = startDate.getFullYear();
  
    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleString('default', { month: 'short' });
    const endYear = endDate.getFullYear();
  
    // Format the date range string
    if (startMonth === endMonth && startYear === endYear) {
      return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
    } else if (startYear === endYear) {
      return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
    } else {
      return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
    }
  }