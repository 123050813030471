import { useEffect, useRef, useState } from "react";
import './customSelect.css'

type Props = {
  color: string;
  fontFamily: string;
  fontSize: number;
  fontWeight: number;
  lineHeight: string | number;
  marginRight: number;
  marginBottom: number;
  type: string | null;
  count?: number;
  toggleOptions: () => void;
  isOpen: boolean;
  onValueChange: (data: string) => void;
}

const CustomSelect = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState<string[]>([]);

  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    toggleOptions();
    props.onValueChange(option)
  };

  useEffect(() => {
    if (props.type === 'tanggal') {
      setOptions(Array.from({ length: 31 }, (_, i) => (i + 1).toString()));
    } else if (props.type === 'bulan') {
      setOptions([
        'Januari', 'Februari', 'Maret', 'April',
        'Mei', 'Juni', 'Juli', 'Agustus', 'September',
        'Oktober', 'November', 'Desember'
      ]);
    } else if (props.type === 'tahun') {
      const currentYear = new Date().getFullYear();
      setOptions(Array.from({ length: 10 }, (_, i) => (currentYear - i).toString()));
    } else if (props.type === 'match' && props.count != undefined) {
      setOptions(Array.from({ length: props.count }, (_, i) => `Match ${i + 1}`));
    } else if (props.type === 'status') {
      setOptions(['Upcoming', 'Ongoing', 'Past'])
    } else if (props.type === 'level') {
      setOptions(['Pro', 'Semi-Pro', 'Amateur'])
    } else if (props.type === 'pendaftaran') {
      setOptions(['Subscription', 'Pay to Enter', 'Free'])
    } else {
      setOptions(['option 1', 'option 2', 'option 2'])
    }
  }, [props.type, props.count]);

  return (
    <div ref={selectRef} className="custom-dropdown" onClick={toggleOptions}>
      <div className="dropdown-header">
        {selectedOption || `${props.type}`}
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option, index) => (
            <div
              key={option}
              // value={option}
              className={`option ${selectedOption === option ? 'active' : ''} `}
              onClick={() => selectOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;