import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { IPaymentSubscription } from "../../app/models/transactions";
import SubcriptionStore from "../../app/stores/SubscriptionStore";
import SkeletonComponent from "../../app/component/skeleton/SkeletonComponent";
import orderStore from "../../app/stores/orderStore";
import AlertTopBlue from "../../app/component/alerts/AlertTopBlue";
import { toast } from "react-toastify";

const PaymentSubscription = () => {

    const { SubscriptionStore, orderStore, accountStore } = useStore()
    const { subsSlug } = useParams<{ subsSlug: string }>();

    const [slugCard, setSlugCard] = useState('');

    useEffect(() => {
        SubscriptionStore.getSubscriptionBySlug(String(subsSlug));
        accountStore.getActiveAccount();
    }, [SubscriptionStore, accountStore])

    const isMobile = window.matchMedia('(max-width: 640px)').matches;

    // const [valueOnBoarding, setValueOnBoarding] = useState<IAccountOnboarding>(
    //     initialOnboardingValues
    // );

    const handleCard = (e: string) => {

        setSlugCard(e);
        //setSlug: (setValueFunc: (e: string) => void) => void;
        orderStore.errorMessage = ''
        //console.log(payment)
    };

    let payment: IPaymentSubscription = {
        slug: slugCard,
        product: SubscriptionStore.subscriptionDetail ? SubscriptionStore.subscriptionDetail?.slug : '',
        amount: SubscriptionStore.subscriptionDetail?.price ? SubscriptionStore.subscriptionDetail?.price : '0',
    }

    const handlePurchase = () => {
        orderStore.paymentSubscription(payment);
    }

    const showToast = () => {
        toast("klasldkjlasd");
    }

    return (
        <div className="bg-nav1 fixed inset-0 flex flex-col items-center justify-center">
            <img
                className="w-[170px] h-auto mt-[88px] mx-auto"
                src="/assets/images/IFeL Logo_Website 1.png"
                alt=""
            />
            <div className="w-full px-4 mx-auto h-full md:w-[436px] lg:px-0">
                <h1 className="text-white mb-3 font-extrabold text-[32px] text-center xl:mb-0 leading-normal">
                    Payment

                </h1>
                <div className="flex flex-row items-center border-b-[.5px] border-darkgray pb-[14px] mb-[26px]">
                    <img
                        className="w-[52px] h-[52px] border border-solid border-darkgray rounded-md mr-4 py-[5px] px-[10px] object-cover"
                        src="/assets/images/IFeL Logo_White 1.png"
                        alt="logo"
                    />
                    {
                        SubscriptionStore.loading == false ?
                            (
                                <>
                                    <span>
                                        <h4 className="text-white text-xs font-semibold mb-1 mt-2 xl:mb-0">
                                            {SubscriptionStore.subscriptionDetail?.title}
                                        </h4>
                                        <p>
                                            <h3 className="text-white text-xs text-center mb-3 xl:mb-0">
                                                {SubscriptionStore.subscriptionDetail?.start_period} - {SubscriptionStore.subscriptionDetail?.end_period}
                                            </h3>
                                        </p>
                                    </span>

                                </>

                            ) : (
                                <SkeletonComponent
                                    count={1}
                                    width='w-full'
                                    height='h-[10px]'
                                    borderRadius='rounded-sm'
                                />
                            )
                    }

                </div>
                <div className="w-full mt-[38px]">
                    <h4 className="text-[#EB5757] text-xs font-semibold mb-1 mt-2 xl:mb-0">{orderStore.errorMessage}</h4>
                </div>
                <select
                    name="date"
                    id="select_date"
                    style={{ width: '100%' }}
                    onChange={(e) => handleCard(e.target.value)}
                    defaultValue=""
                    className="block w-auto h-[50px] bg-transparent mb-5 px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mr-2"
                >
                    <option value="" selected>Silahkan pilih Card/Profile untuk Subscribe</option>
                    {
                        accountStore.activeAccount.map((item, index) => (
                            <>
                                <option key={index} value={item.slug}>{item.username} / {item.platform}</option>
                            </>
                        ))
                    }
                </select>
                {/* <div className="flex flex-row items-center justify-between mb-2">
                    <h4 className="text-white text-xs font-semibold text-center mb-3 xl:mb-0">Pilih Kartu</h4>
                    <select
                        name="card"
                        id="player_card"
                        className="block w-1/3 h-[32px] bg-transparent text-white text-xs font-semibold border border-solid border-darkgray-600 rounded sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none"
                    >
                        <option value="amateur">Amateur</option>
                        <option value="semi">Semi</option>
                        <option value="pro">Pro</option>
                    </select>
                </div> */}
                <div className="flex flex-row items-center justify-between text-white font-medium text-[11px] leading-normal">
                    {
                        SubscriptionStore.loading == false ?
                            (
                                <>
                                    <p>{SubscriptionStore.subscriptionDetail?.title}</p>
                                    <p>{SubscriptionStore.subscriptionDetail?.price}</p>
                                </>

                            ) : (
                                <SkeletonComponent
                                    count={1}
                                    width='w-full'
                                    height='h-[10px]'
                                    borderRadius='rounded-sm'
                                />
                            )
                    }

                </div>
                {/* <div className="flex flex-row items-center justify-between text-white font-medium text-[11px] leading-normal">
                    <p>Voucher</p>
                    <p>-Rp 0</p>
                </div> */}
                <div className="flex flex-row items-center border-b-[.5px] border-darkgray pb-[23px] mt-6 mb-[18px] justify-between text-secondary text-sm leading-normal font-bold">
                    <p>Total Pembayaranmu</p>
                    {
                        SubscriptionStore.loading == false ?
                            (
                                <>
                                    <p>Rp {SubscriptionStore.subscriptionDetail?.price}</p>
                                </>

                            ) : (
                                <SkeletonComponent
                                    count={1}
                                    width='w-full'
                                    height='h-[10px]'
                                    borderRadius='rounded-sm'
                                />
                            )
                    }
                </div>
                {/* <div className="flex flex-row items-center mb-4">
                    <input className="border-[.5px] bg-transparent border-[#475569] text-[#475569] text-xs font-normal leading-6 w-full rounded-md  h-[28px]" type="text" placeholder="Masukkan kode promo"/>
                    <button className="w-[77px] rounded-md bg-darkgray text-xs text-[#7A8697] h-[28px] ml-[6px]">Submit</button>
                </div>
                <div className="relative">
                    <img src="/assets/images/promo-banner.svg" className="w-full object-cover rounded-xl" alt="" />
                    <img src="/assets/image/arrow-white.svg" className="absolute top-1/2 right-3 transform -translate-y-1/2 z-10 w-6" alt="" />
                </div> */}
                <div className="w-full mt-[38px]">
                    <button
                        onClick={handlePurchase}
                        disabled={orderStore.btnProcessStatus}
                        className="w-full px-10 py-3 h-[50px] text-xs disabled:bg-gray-600 disabled:cursor-not-allowed text-white font-bold bg-secondary border-none rounded-md hover:bg-nav2 hover:text-secondary transition duration-300 xl:font-semibold"
                    >
                        {orderStore.btnProcess}
                    </button>
                </div>
            </div>

        </div>
    )
}

export default observer(PaymentSubscription);