import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    IResolveParams,
} from 'reactjs-social-login';
import {
    FacebookLoginButton,
    GoogleLoginButton,
} from 'react-social-login-buttons';

import {
    IAccountLoginValues,
    IAccountRegisterRequest,
} from '../../models/account';
import InputPassword from '../input/InputPassword';
import { useStore } from '../../stores/store';
import { Drawer, Spin, Typography } from 'antd';
import MediaQuery from 'react-responsive';

const initialValuesLogin: IAccountLoginValues = {
    email: '-',
    password: '-',
};

interface ModalProps {
    onOpenRegister: boolean;
    closeModal: () => void;
    switchModal: () => void;
}

const initialValuesRegister: IAccountRegisterRequest = {
    name: '-',
    email: '-',
    phone: '-',
    password: '-',
    password_confirmation: '-',
};

const RegisterModal: React.FC<ModalProps> = ({
    onOpenRegister,
    closeModal,
    switchModal
}) => {

    const { accountStore } = useStore();
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState<any>();
    const [setPassword] = useState<IAccountLoginValues>(initialValuesLogin);
    const [error, setError] = useState('');
    const [errorRegister, seterrorRegister] = useState('');
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState('')
    const [loadAuth, setLoadAuth] = useState(false);

    const { Title } = Typography;

    const [valueRegister, setValueRegister] = useState<IAccountRegisterRequest>(
        initialValuesRegister
    );

    useEffect(() => {
        if (onOpenRegister) {
            setActive('active');
        }
    })

    const [validateEmail, setValidateEmail] = useState(false);

    const handleInputEmail = (value: string) => {
        const emailRegex = /\S+@\S+\.\S+/;
        if (emailRegex.test(value)) {
            setValidateEmail(true);
            setError('');
            seterrorRegister('');
        } else {
            setError('Email tidak sesuai format');
            setValidateEmail(false);
        }
        setValueRegister((prevState) => ({ ...prevState, email: value }));
    };

    const handleInputName = (value: string) => {
        setValueRegister((prevState) => ({ ...prevState, name: value }));
    };

    const handleInputPhone = (value: string) => {
        setValueRegister((prevState) => ({ ...prevState, phone: value }));
        seterrorRegister('');
    };

    const handleInputPassword = (value: string) => {
        setValueRegister((prevState) => ({ ...prevState, password: value }));
    };

    const handleInputConfirmationPassword = (value: string) => {
        setValueRegister((prevState) => ({
            ...prevState,
            password_confirmation: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true)
        await accountStore.register(valueRegister).then((res) => {
            if (!res) {
                setLoading(false)
                return seterrorRegister('Email atau nomor telepon sudah digunakan')
            }
            setLoading(false)
            window.location.replace('/');
        })
    };

    const onLoginStart = useCallback(() => {
        console.log('auth start');
    }, []);

    const loginSocial = (provider: string, data: any) => {
        setLoadAuth(true)
        accountStore.loginSocial(data);
    };

    const REDIRECT_URI = 'https://ifel.id/login';
    const styles = {
        button: {
            borderRadius: '6px',
            border: '1px solid rgba(71,85,105, .6) ',
            color: 'white',
            padding: '13px 0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '24px',
            height: '50px',
        },
    };

    if (!onOpenRegister) return null;

    return (
        <div>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className={`modal w-full h-full mt-[64px] xl:w-[438px] lg:m-auto lg:max-h-[90vh] ${active}`}>
                {!loadAuth ?
                    <div className="modal-content overflow-y-scroll p-4 lg:p-5">
                        <button className="close" onClick={closeModal}>
                            <img src="/assets/images/close.svg" alt="close"></img>
                        </button>
                        <h2 className="font-extrabold text-white text-center text-[32px] leading-normal mb-3">
                            Register
                        </h2>
                        <div className="text-darkgray font-semibold text-xs text-center leading-normal mb-4">
                            Sudah memiliki akun?{' '}
                            <button className="text-secondary underline" onClick={switchModal}>
                                Login
                            </button>
                        </div>
                        <LoginSocialGoogle
                            client_id={process.env.REACT_APP_GG_APP_ID || ''}
                            onLoginStart={onLoginStart}
                            redirect_uri={REDIRECT_URI}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }: IResolveParams) => {
                                setProvider(provider);
                                setProfile(data);
                                loginSocial(provider, data);
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <GoogleLoginButton
                                className="custom-button-social"
                                text="Continue with Google"
                                style={styles.button}
                            />
                        </LoginSocialGoogle>
                        {/* <LoginSocialFacebook
                        isOnlyGetToken
                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }: IResolveParams) => {
                            setProvider(provider);
                            setProfile(data);
                            loginSocial(provider, data);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <FacebookLoginButton
                            className="custom-button-social"
                            text="Continue with Facebook"
                            style={styles.button}
                        />
                    </LoginSocialFacebook> */}
                        <div className="flex flex-row items-center justify-between my-6">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="149"
                                height="2"
                                viewBox="0 0 149 2"
                                fill="none"
                            >
                                <path d="M0 1H149" stroke="#383D42" strokeWidth="0.5" />
                            </svg>
                            <span className="text-white text-sm font-medium mx-12">Or</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="149"
                                height="2"
                                viewBox="0 0 149 2"
                                fill="none"
                            >
                                <path d="M0 1H149" stroke="#383D42" strokeWidth="0.5" />
                            </svg>
                        </div>
                        {/* <div className='relative w-full container mx-auto font-sans'>
            <label
                htmlFor="username"
                className="text-white text-xs font-medium"
            >
                Username
            </label>
            <input
                type="text"
                id="username"
                className="w-full text-xs border-darkgray px-[19px] pb-[13px] pt-[13px] mt-2 mb-3 leading-normal text-white bg-transparent rounded-md appearance-none focus:outline-none"
                name="username"
                placeholder="Username"
            />
            </div> */}
                        <div className="relative w-full container mx-auto font-sans">
                            <label
                                htmlFor="fullname"
                                className="text-white text-xs font-medium"
                            >
                                Nama Lengkap
                            </label>
                            <input
                                type="text"
                                id="fullname"
                                className="w-full text-xs border-darkgray px-[19px] pb-[13px] pt-[13px] mt-2 mb-3 leading-normal text-white bg-transparent rounded-md appearance-none focus:outline-none"
                                name="fullname"
                                placeholder="Nama Lengkap"
                                onChange={(e) => handleInputName(e.target.value)}
                            />
                        </div>
                        <div className="relative w-full container mx-auto font-sans">
                            <label htmlFor="email" className="text-white text-xs font-medium">
                                Email Address
                            </label>
                            <div className="w-full h-full relative">
                                <input
                                    type="email"
                                    id="email"
                                    // value={email}
                                    onChange={(e) => handleInputEmail(e.target.value)}
                                    className={`w-full text-xs px-[19px] pb-[13px] pt-[13px] mt-2 mb-3 leading-normal text-white bg-transparent rounded-md appearance-none focus:outline-none ${error ? 'border-red error-icon' : 'border-darkgray'
                                        }`}
                                    name="email"
                                    placeholder="Email address"
                                />
                                {error && (
                                    <div className="flex flex-row items-center absolute top-1/2 right-4 transform translate-y-[-50%]">
                                        <p className="text-red text-[8px] text-left font-semibold">
                                            {error}
                                        </p>
                                        <div className="w-4 h-4 relative ml-2">
                                            <img
                                                src="/assets/images/ic-error.svg"
                                                alt=""
                                                className="w-4 h-4"
                                            />
                                            <svg
                                                className="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="5"
                                                height="5"
                                                viewBox="0 0 5 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M4.64543 0.354779C4.57183 0.280577 4.48427 0.221682 4.3878 0.18149C4.29133 0.141298 4.18786 0.120605 4.08335 0.120605C3.97884 0.120605 3.87536 0.141298 3.77889 0.18149C3.68242 0.221682 3.59486 0.280577 3.52126 0.354779L2.50001 1.38395L1.47876 0.354779C1.32969 0.205705 1.1275 0.121957 0.916679 0.121957C0.705857 0.121957 0.50367 0.205705 0.354596 0.354779C0.205522 0.503853 0.121774 0.70604 0.121774 0.916862C0.121774 1.12768 0.205522 1.32987 0.354596 1.47895L1.38376 2.5002L0.354596 3.52145C0.280394 3.59504 0.221499 3.6826 0.181307 3.77907C0.141115 3.87554 0.120422 3.97902 0.120422 4.08353C0.120422 4.18804 0.141115 4.29151 0.181307 4.38799C0.221499 4.48446 0.280394 4.57202 0.354596 4.64561C0.428192 4.71981 0.515751 4.77871 0.612223 4.8189C0.708695 4.85909 0.81217 4.87979 0.916679 4.87979C1.02119 4.87979 1.12466 4.85909 1.22114 4.8189C1.31761 4.77871 1.40517 4.71981 1.47876 4.64561L2.50001 3.61645L3.52126 4.64561C3.59486 4.71981 3.68242 4.77871 3.77889 4.8189C3.87536 4.85909 3.97884 4.87979 4.08335 4.87979C4.18786 4.87979 4.29133 4.85909 4.3878 4.8189C4.48427 4.77871 4.57183 4.71981 4.64543 4.64561C4.71963 4.57202 4.77853 4.48446 4.81872 4.38799C4.85891 4.29151 4.8796 4.18804 4.8796 4.08353C4.8796 3.97902 4.85891 3.87554 4.81872 3.77907C4.77853 3.6826 4.71963 3.59504 4.64543 3.52145L3.61626 2.5002L4.64543 1.47895C4.71963 1.40535 4.77853 1.31779 4.81872 1.22132C4.85891 1.12485 4.8796 1.02137 4.8796 0.916862C4.8796 0.812353 4.85891 0.708878 4.81872 0.612406C4.77853 0.515934 4.71963 0.428375 4.64543 0.354779Z"
                                                    fill="#EB5757"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="relative w-full container mx-auto font-sans">
                            <label
                                htmlFor="phone_number"
                                className="text-white text-xs font-medium"
                            >
                                Nomor Handphone
                            </label>
                            <input
                                type="text"
                                id="phone_number"
                                className="w-full text-xs border-darkgray px-[19px] pb-[13px] pt-[13px] mt-2 mb-3 leading-normal text-white bg-transparent rounded-md appearance-none focus:outline-none"
                                name="phone_number"
                                placeholder="Nomor Handphone"
                                onChange={(e) => handleInputPhone(e.target.value)}
                            />
                        </div>
                        <label htmlFor="password" className="text-white text-xs font-medium">
                            Password
                        </label>
                        <InputPassword
                            label=""
                            bgColor="bg-nav1"
                            labelColor=""
                            textColor="text-white"
                            border="border-darkgray"
                            px="px-[19px]"
                            py="py-[13px]"
                            mt="mt-2"
                            mb="mb-3"
                            leading="leading-normal"
                            placeholder="Password"
                            onChange={(value) => handleInputPassword(value)}
                        />
                        <label htmlFor="password" className="text-white text-xs font-medium">
                            Password Confirmation
                        </label>
                        <InputPassword
                            label=""
                            bgColor="bg-nav1"
                            labelColor=""
                            textColor="text-white"
                            border="border-darkgray"
                            px="px-[19px]"
                            py="py-[13px]"
                            mt="mt-2"
                            mb="mb-0"
                            leading="leading-normal"
                            placeholder="Password Confirmation"
                            onChange={(value) => handleInputConfirmationPassword(value)}
                        />
                        <p className="text-red text-[8px] text-left font-semibold mt-4">
                            {errorRegister}
                        </p>
                        <div className="w-full mt-10">
                            {!loading ? (
                                <button
                                    onClick={() => handleSubmit()}
                                    disabled={
                                        !valueRegister.email ||
                                        validateEmail === false ||
                                        !valueRegister.name ||
                                        !valueRegister.phone ||
                                        !valueRegister.password_confirmation ||
                                        valueRegister.password === ''
                                    }
                                    className={`w-full px-6 lg:px-20 py-3 disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-gray-700 text-white font-semibold bg-secondary border-none rounded-md text-[12px] hover:bg-nav2 hover:text-secondary transition duration-300`}
                                >
                                    Register
                                </button>
                            ) : (
                                <button
                                    disabled={true}
                                    className={`w-full loading px-6 lg:px-20 py-3 disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-white text-white font-semibold bg-secondary border-none rounded-md text-xs hover:bg-nav2 hover:text-secondary transition duration-300`}
                                >
                                    Please Wait
                                </button>
                            )}
                            {/* <button
                type="submit"
                className="w-full px-10 py-3 text-xs disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-darkgray text-white font-semibold bg-secondary border-none rounded-md hover:bg-nav2 hover:text-secondary transition duration-300"
                onClick={() => handleSubmit()}
            >
                Register
            </button> */}
                        </div>
                        <div className="text-white text-[8px] text-center font-medium leading-normal mt-3 px-[50px] mb-12 lg:text-[12px]">
                            Dengan mengklik “Register”, Anda setuju dengan{' '}
                            <a href="#" className="text-secondary underline">
                                Syarat Penggunaan
                            </a>{' '}
                            dan{' '}
                            <a href="#" className="text-secondary underline">
                                Kebijakan Privasi
                            </a>{' '}
                            Indonesian Football e-League
                        </div>
                    </div>
                    :
                    <Spin tip="Please wait" size="large"></Spin>
                }
            </div>
        </div>
    );
};

export default RegisterModal;
