import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useStore } from '../../stores/store'

function StandingCard() {

    const { leagueStore } = useStore();

    const { leagueId, categoryLeague } = useParams();

    useEffect(() => {
        leagueStore.getStandings(Number(leagueId));
    }, [leagueId, leagueStore]);

    useEffect(() => {
        leagueStore.getLatestStandings();
    }, [leagueStore]);


if( leagueId ) {
    return (
    <div className="overflow-x-auto w-full mt-4">
        <table className="w-full table-auto text-white">
            {/* head */}
            <thead className=''>
            <tr className='bg-[#1D2335] text-[2.8vw] lg:text-[0.9vw]'>
                <th className='w-full h-full text-start flex flex-row items-center px-3 py-2'><img src={`${process.env.REACT_APP_API_URL?.replace('/api', '')}${leagueStore.statistics?.competition.flag}`}  alt='logo' className='w-6 lg:w-3 xl:w-3 mr-3'/><span>{leagueStore.statistics?.competition.name} {leagueStore.statistics?.competition.year}</span></th>
                <th className='text-end pr-3'>Points</th>
            </tr>
            </thead>
            <tbody>
            {leagueStore.standings?.map((standing, idx) => (
            <tr className='odd:bg-nav1 even:bg-transparent' key={idx}>
                 <td className='flex flex-row items-center py-2'>
                     <span className='lg:text-[1vw] font-medium px-3 lg:pl-4 lg:pr-0'>{ idx + 1 }</span>
                     <img src={standing.team.flag} alt='logo' className='lg:py-3 lg:px-2 w-[12vw] lg:w-[4vw]' />
                     <span className='pl-1 lg:pl-0 lg:text-[1vw] font-semibold'>{ standing.team.name }</span>
                 </td>
                 <td className='text-end lg:text-[1vw] font-semibold pr-5'>{standing.point}</td>
             </tr>
            ))}
            </tbody>
        </table>
        <div className='w-full flex flex-row justify-end items-center space-x-1 mt-2'>
            <a href={`/league/${categoryLeague}/${leagueId}`} className='font-semibold text-[3.8vw] lg:text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
            <span>View all tables</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
            <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z"/>
            </svg>
            </a>
        </div>
    </div>
    )
} else {
  return (
    <div className="overflow-x-auto w-full mt-4">
        <table className="w-full table-auto text-white">
            {/* head */}
            <thead className=''>
            <tr className='bg-[#1D2335] text-[2.8vw] lg:text-[0.9vw]'>
                <th className='w-full h-full text-start flex flex-row items-center px-3 py-2'><img src={leagueStore.latestleague?.flag} alt='logo' className='w-6 lg:w-3 xl:w-3 mr-3'/><span>{leagueStore.latestleague?.name}</span></th>
                <th className='text-end pr-3'>Points</th>
            </tr>
            </thead>
            <tbody>
            {leagueStore.latestStandings.map((standing, idx) => (
            <tr className='odd:bg-nav1 even:bg-transparent' key={idx}>
                 <td className='flex flex-row items-center py-2'>
                     <span className='lg:text-[1vw] font-medium px-3 lg:pl-4 lg:pr-0'>{ idx + 1 }</span>
                     <img src={`${process.env.REACT_APP_API_URL?.replace('/api', '')}${standing.team.flag}`} alt='logo' className='lg:py-3 lg:px-4 w-[12vw] lg:w-[4vw]' />
                     <span className='pl-4 lg:pl-0 lg:text-[1vw] font-semibold'>{ standing.team.name }</span>
                 </td>
                 <td className='text-end lg:text-[1vw] font-semibold pr-5'>{standing.point}</td>
             </tr>
            ))}
            </tbody>
        </table>
        <div className='w-full flex flex-row justify-end items-center space-x-1 mt-2'>
            <a href={`/league/${leagueStore.listCategory[leagueStore.listCategory.length - 1]?.id}/${leagueStore.latestStandings[0]?.competition_id}`} className='font-semibold text-[3.8vw] lg:text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
            <span>View all tables</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
            <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z"/>
            </svg>
            </a>
        </div>
    </div>
  )
}
}

export default observer(StandingCard)