import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { IHistory } from "../../../app/models/team";
import SkeletonComponent from "../../../app/component/skeleton/SkeletonComponent";

interface historyProps {
    data: IHistory[] | null;
}

interface selectedTeam {
    data: IHistory | null;
}


const PlayerClubList: React.FC<historyProps> = ({ data }) => {
    const { slug } = useParams<{ slug: string }>();

    return (
        <div className="w-full">
            {data ? (
                <div>
                    {data?.map((item, idx) => (
                        <div className="w-full flex flex-row items-center h-[57px] px-[14px] py-[10px] mt-2 bg-nav1 rounded-md  cursor-pointer"
                            key={idx}
                            data-team={JSON.stringify(item)}
                        >
                            <div className="clan-info lg:w-[60%] flex flex-row items-center">
                                <div className="w-[37px] h-[37px] flex flex-row justify-center items-center">
                                    <img
                                        // src="/assets/images/persita-esport.png"
                                        src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                                        alt="logo"
                                        className="w-full h-full object-cover object-center"
                                    />
                                </div>
                                <div className="lg:px-2 lg:w-[90%] flex flex-col justify-start items-start text-start">
                                    <h4 className="text-white font-bold text-sm leading-normal">
                                        {item.team}
                                    </h4>
                                </div>
                            </div>
                            <div className="clan-stat lg:w-[40%] flex flex-row justify-end">
                                <div className="flex flex-row items-center px-2">
                                    <figure>
                                        <img
                                            src="/assets/images/uil_sign-in-alt.svg"
                                            alt="logo"
                                            className="w-full"
                                        />
                                    </figure>
                                    <h4 className="text-white font-semibold lg:text-[0.625rem] px-2">
                                        {item.start_contract}
                                    </h4>
                                </div>
                                <div className="flex flex-row items-center px-2">
                                    <figure>
                                        <img
                                            src="/assets/images/uil_sign-in-alt-red.svg"
                                            alt="logo"
                                            className="w-full"
                                        />
                                    </figure>
                                    <h4 className="text-white font-semibold lg:text-[0.625rem] px-2">
                                        {item.end_contract}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex flex-col items-center gap-2">
                    <SkeletonComponent
                        count={5}
                        width='w-full'
                        height='h-14'
                        borderRadius='rounded-md'
                    />
                </div>
            )}
        </div>
    );
}

export default observer(PlayerClubList);
