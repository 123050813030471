import { useEffect, useState } from "react";
import { IAchievement } from "../../models/account";
import Achievement from "../modal/Achievement";
import { useStore } from "../../stores/store";
import { useParams } from "react-router-dom";
import "../../../index.css"
import { calculateAge } from "../../config/enum";
import SkeletonComponent from "../skeleton/SkeletonComponent";
import { items } from "../../config/menu";

interface Props {
    data: IAchievement[] | null;
}

const AchievementCard: React.FC<Props> = ({ data }) => {

    const { accountStore } = useStore();
    const [isModalAchievementOpen, setisModalAchievementOpen] = useState(false);
    const [dataAchievement, setDataAchievement] = useState<IAchievement>();
    const { slug } = useParams<{ slug: string }>();

    const openModalAchievement = (event: React.MouseEvent<HTMLDivElement>) => {
        const rawData = event.currentTarget.getAttribute('data-achievement');
        if (rawData) {
            const parsedData: IAchievement = JSON.parse(rawData);
            setDataAchievement(parsedData);
            setisModalAchievementOpen(true);
        }
    }

    useEffect(() => {
        accountStore.getUser();
        accountStore.getProfileUser(String(slug));
    }, [accountStore]);

    const closeModalAchievement = () => {
        setisModalAchievementOpen(false)
    }

    if (isModalAchievementOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'visible';
    }

    if (!data) {
        return null
    }

    return (
        <div className="w-full py-[14px] cursor-pointer">
            <h4 className="text-white font-bold text-[18px]">
                Achievement
            </h4>
            <div className="divider-custom-dark mt-[14px] mb-[8px]"></div>
            {(!data || data.length === 0) ? (
                <div className="bg-nav1 rounded-[10px] text-darkgray text-xs font-medium leading-normal p-[18px] h-[94px]">
                    Achievement tidak ditemukan
                </div>
                // (
                //     items.map((item, index) => (
                //         <div key={index}
                //             className='bg-nav1 flex flex-row items-center mb-2'>
                //             <SkeletonComponent
                //                 count={1}
                //                 width='w-[58px]'
                //                 height='h-[94px]'
                //                 borderRadius='rounded'
                //             />
                //             <div className='flex flex-col items-start ml-2'>
                //                 <SkeletonComponent
                //                     count={1}
                //                     width='w-[90px]'
                //                     height='h-[15px]'
                //                     borderRadius='rounded-none'
                //                     marginBottom="mb-2"
                //                 />
                //                 <SkeletonComponent
                //                     count={1}
                //                     width='w-[200px]'
                //                     height='h-[15px]'
                //                     borderRadius='rounded-none'
                //                 />
                //             </div>
                //         </div>
                //     ))
                // )
            ) : (
                data?.map((item, idx) => (
                    <div className="achievement w-full flex flex-row items-center bg-nav1 rounded-md mb-2 pr-2"
                        key={idx}
                        data-achievement={JSON.stringify(item)}
                        onClick={openModalAchievement}>
                        {item.background ?
                            <div className="relative w-[58px] h-auto mr-1">
                                <div className="flex flex-col items-start absolute z-10 top-[2px] left-[5px]">
                                    <div className={`text-${!item.color_code ? 'white' : item.color_code} text-[3px] font-extrabold whitespace-nowrap leading-normal`}>eFOOTBALL</div>
                                    <div className={`text-${!item.color_code ? 'white' : item.color_code} text-[3px] font-extrabold whitespace-nowrap uppercase`}>{item.user.platform}</div>
                                </div>
                                <img width={12} className="absolute z-10 top-[2px] right-[4px]" src="/assets/images/efootball-logo.png" alt="" />
                                <figure className="w-full h-auto mr-[10px] relative">
                                    <img
                                        src={`${process.env.REACT_APP_STORAGE_URL}${item.background}`}
                                        alt="logo"
                                        className="w-full h-full object-cover"
                                    />
                                    <img
                                        src={!item.image ? `${process.env.REACT_APP_STORAGE_URL}${accountStore.profile?.detail?.card}` : `${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                                        alt="logo"
                                        className="absolute top-[4px] left-1/2 transform translate-x-[-50%] w-[80%]"
                                    />
                                </figure>
                                <h5 className={`absolute uppercase text-[4px] bottom-[12px] left-1/2 transform translate-x-[-50%] text-${!item.color_code ? 'white' : item.color_code} text-[18px] font-extrabold leading-normal text-center whitespace-nowrap z-10 bottom-[12px] left-1/2 transform translate-[-1/2]`}>{accountStore.profile?.detail?.username}</h5>
                                <div className="flex flex-row items-center absolute z-10 bottom-[6px] left-[5px]">
                                    <h6 className={`uppercase text-${!item.color_code ? 'white' : item.color_code} text-[4px] font-medium leading-normal whitespace-nowrap mr-[1px]`}>Age</h6>
                                    <h6 className={`uppercase text-${!item.color_code ? 'white' : item.color_code} text-[4px] font-extrabold leading-normal whitespace-nowrap`}>{calculateAge(item.user.birth_year)}</h6>
                                </div>
                                <h6 className={`text-${!item.color_code ? 'white' : item.color_code}  absolute uppercase text-[4px] font-extrabold leading-normal whitespace-nowrap z-10 bottom-[6px] right-[4px]`}>{item.user.domisili}</h6>
                            </div>
                            :
                            <></>
                        }
                        <div className="flex flex-col justify-center items-start">
                            <h4 className="text-start text-base font-bold leading-normal text-white">
                                {item.title}
                            </h4>
                            <h5 className="text-start text-sm font-bold leading-normal text-darkgray">
                                {item.description}
                            </h5>
                        </div>
                    </div>
                )))}

            {isModalAchievementOpen && (
                <Achievement
                    data={dataAchievement}
                    onOpenAchievement={isModalAchievementOpen}
                    onClose={closeModalAchievement}
                />
            )}

        </div>
    );
}

export default AchievementCard;
