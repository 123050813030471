import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import AchievementCard from '../../app/component/card/AchievementCard';
import ClubCard from '../../app/component/club/ClubCard';
import ClubList from '../../app/component/club/ClubList';
import PlayerBoard from './PlayerBoard';
import ClubHistoryAdd from '../../app/component/club/ClubHistoryAdd';
import { IUserProfile } from "../../app/models/profile";
import EditPictureProfile from "../../app/component/modal/EditPictureProfile";
import { useStore } from "../../app/stores/store";
import EmptyPlayerCard from "../../app/component/card/empty/EmptyPlayerCard";
import DummyPlayerCard from "../../app/component/card/dummy/DummyPlayerCard";
import MediaQuery from 'react-responsive';
import { useParams } from "react-router-dom";
import SkeletonComponent from "../../app/component/skeleton/SkeletonComponent";
import AmateurCard from "./AmateurCard";
import EmptyAchievement from "../../app/component/card/empty/EmptyAchievement";

const playerData = {
    level: "amateur"
};

interface playerProps {
    data: IUserProfile | null;
}

const MyTournament: React.FC<playerProps> = ({ data }) => {
    const { clubStore, accountStore, TournamentStore } = useStore()
    const [isModalEditOpen, setIsModalEditOpen] = useState(false)
    const [profilePicture, setProfilePicture] = useState<File | null>(null);

    const { slug } = useParams<{ slug: string }>();

    useEffect(() => {
        clubStore.listHistory(String(slug))
        accountStore.getUser()
        TournamentStore.getPlayerStat(String(slug))
        accountStore.getAchievementList(String(slug))

    }, [clubStore, accountStore, TournamentStore])

    const toggleModalEdit = () => {
        setIsModalEditOpen(!isModalEditOpen)
    }

    const closeModal = () => {
        setIsModalEditOpen(false)
    }

    return (
        <div className='w-full h-full flex flex-col lg:flex-row'>
            <React.Fragment>
                {/* Sidebar Left */}
                <div className='w-full mr-6 lg:w-[328px] '>
                    {/* Standings */}
                    <div className='w-full mb-[14px] mx-auto h-[418px] flex flex-col items-center justify-center bg-nav1 rounded-xl relative lg:mb-0 lg:w-[328px]'>
                        {!accountStore.profile?.detail
                            ?
                            <div className="flex flex-row items-center mx-auto justify-center">
                                <SkeletonComponent
                                    count={1}
                                    width='w-[230px]'
                                    height='h-[370px]'
                                    borderRadius='rounded-xl'
                                />
                            </div> :
                            (!accountStore.profile?.detail.card
                                ?
                                <div>
                                    <button className="absolute top-[14px] right-[14px] cursor-pointer" onClick={toggleModalEdit}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M2.75 6.5C2.97981 6.5 3.20738 6.54526 3.4197 6.63321C3.63202 6.72116 3.82493 6.85006 3.98744 7.01256C4.14994 7.17507 4.27884 7.36798 4.36679 7.5803C4.45474 7.79262 4.5 8.02019 4.5 8.25C4.5 8.47981 4.45474 8.70738 4.36679 8.9197C4.27884 9.13202 4.14994 9.32493 3.98744 9.48744C3.82493 9.64994 3.63202 9.77884 3.4197 9.86679C3.20738 9.95473 2.97981 10 2.75 10C2.28587 10 1.84075 9.81563 1.51256 9.48744C1.18437 9.15925 1 8.71413 1 8.25C1 7.78587 1.18437 7.34075 1.51256 7.01256C1.84075 6.68437 2.28587 6.5 2.75 6.5ZM8 6.5C8.22981 6.5 8.45738 6.54526 8.6697 6.63321C8.88202 6.72116 9.07493 6.85006 9.23744 7.01256C9.39994 7.17507 9.52884 7.36798 9.61679 7.5803C9.70473 7.79262 9.75 8.02019 9.75 8.25C9.75 8.47981 9.70473 8.70738 9.61679 8.9197C9.52884 9.13202 9.39994 9.32493 9.23744 9.48744C9.07493 9.64994 8.88202 9.77884 8.6697 9.86679C8.45738 9.95473 8.22981 10 8 10C7.53587 10 7.09075 9.81563 6.76256 9.48744C6.43437 9.15925 6.25 8.71413 6.25 8.25C6.25 7.78587 6.43437 7.34075 6.76256 7.01256C7.09075 6.68437 7.53587 6.5 8 6.5ZM13.25 6.5C13.4798 6.5 13.7074 6.54526 13.9197 6.63321C14.132 6.72116 14.3249 6.85006 14.4874 7.01256C14.6499 7.17507 14.7788 7.36798 14.8668 7.5803C14.9547 7.79262 15 8.02019 15 8.25C15 8.47981 14.9547 8.70738 14.8668 8.9197C14.7788 9.13202 14.6499 9.32493 14.4874 9.48744C14.3249 9.64994 14.132 9.77884 13.9197 9.86679C13.7074 9.95473 13.4798 10 13.25 10C12.7859 10 12.3408 9.81563 12.0126 9.48744C11.6844 9.15925 11.5 8.71413 11.5 8.25C11.5 7.78587 11.6844 7.34075 12.0126 7.01256C12.3408 6.68437 12.7859 6.5 13.25 6.5Z" fill="white" />
                                        </svg>
                                    </button>
                                    {accountStore.profile.detail.level === 'amateur' ?
                                        <AmateurCard
                                            player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                            data={accountStore.profile.detail}></AmateurCard>
                                        :
                                        (accountStore.profile.detail.card_approved ?
                                            // <DummyPlayerCard
                                            //     player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                            //     data={accountStore.profile.detail}
                                            // /> 
                                            <AmateurCard
                                                player={String(accountStore.profile.detail.level)}
                                                data={accountStore.profile.detail}></AmateurCard>
                                            :
                                            <div className="px-4">
                                                <EmptyPlayerCard />
                                            </div>
                                        )}
                                    <button className="absolute top-[14px] right-[14px] cursor-pointer" onClick={toggleModalEdit}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M2.75 6.5C2.97981 6.5 3.20738 6.54526 3.4197 6.63321C3.63202 6.72116 3.82493 6.85006 3.98744 7.01256C4.14994 7.17507 4.27884 7.36798 4.36679 7.5803C4.45474 7.79262 4.5 8.02019 4.5 8.25C4.5 8.47981 4.45474 8.70738 4.36679 8.9197C4.27884 9.13202 4.14994 9.32493 3.98744 9.48744C3.82493 9.64994 3.63202 9.77884 3.4197 9.86679C3.20738 9.95473 2.97981 10 2.75 10C2.28587 10 1.84075 9.81563 1.51256 9.48744C1.18437 9.15925 1 8.71413 1 8.25C1 7.78587 1.18437 7.34075 1.51256 7.01256C1.84075 6.68437 2.28587 6.5 2.75 6.5ZM8 6.5C8.22981 6.5 8.45738 6.54526 8.6697 6.63321C8.88202 6.72116 9.07493 6.85006 9.23744 7.01256C9.39994 7.17507 9.52884 7.36798 9.61679 7.5803C9.70473 7.79262 9.75 8.02019 9.75 8.25C9.75 8.47981 9.70473 8.70738 9.61679 8.9197C9.52884 9.13202 9.39994 9.32493 9.23744 9.48744C9.07493 9.64994 8.88202 9.77884 8.6697 9.86679C8.45738 9.95473 8.22981 10 8 10C7.53587 10 7.09075 9.81563 6.76256 9.48744C6.43437 9.15925 6.25 8.71413 6.25 8.25C6.25 7.78587 6.43437 7.34075 6.76256 7.01256C7.09075 6.68437 7.53587 6.5 8 6.5ZM13.25 6.5C13.4798 6.5 13.7074 6.54526 13.9197 6.63321C14.132 6.72116 14.3249 6.85006 14.4874 7.01256C14.6499 7.17507 14.7788 7.36798 14.8668 7.5803C14.9547 7.79262 15 8.02019 15 8.25C15 8.47981 14.9547 8.70738 14.8668 8.9197C14.7788 9.13202 14.6499 9.32493 14.4874 9.48744C14.3249 9.64994 14.132 9.77884 13.9197 9.86679C13.7074 9.95473 13.4798 10 13.25 10C12.7859 10 12.3408 9.81563 12.0126 9.48744C11.6844 9.15925 11.5 8.71413 11.5 8.25C11.5 7.78587 11.6844 7.34075 12.0126 7.01256C12.3408 6.68437 12.7859 6.5 13.25 6.5Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                :
                                <div>
                                    {accountStore.profile.detail.card_approved ?
                                        // <DummyPlayerCard
                                        //     player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                        //     data={accountStore.profile.detail}
                                        // /> 
                                        <AmateurCard
                                            player={String(accountStore.profile.detail.level)}
                                            data={accountStore.profile.detail}></AmateurCard>
                                        :
                                        <div className="px-4">
                                            <EmptyPlayerCard />
                                        </div>
                                    }
                                    <button className="absolute top-[14px] right-[14px] cursor-pointer" onClick={toggleModalEdit}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M2.75 6.5C2.97981 6.5 3.20738 6.54526 3.4197 6.63321C3.63202 6.72116 3.82493 6.85006 3.98744 7.01256C4.14994 7.17507 4.27884 7.36798 4.36679 7.5803C4.45474 7.79262 4.5 8.02019 4.5 8.25C4.5 8.47981 4.45474 8.70738 4.36679 8.9197C4.27884 9.13202 4.14994 9.32493 3.98744 9.48744C3.82493 9.64994 3.63202 9.77884 3.4197 9.86679C3.20738 9.95473 2.97981 10 2.75 10C2.28587 10 1.84075 9.81563 1.51256 9.48744C1.18437 9.15925 1 8.71413 1 8.25C1 7.78587 1.18437 7.34075 1.51256 7.01256C1.84075 6.68437 2.28587 6.5 2.75 6.5ZM8 6.5C8.22981 6.5 8.45738 6.54526 8.6697 6.63321C8.88202 6.72116 9.07493 6.85006 9.23744 7.01256C9.39994 7.17507 9.52884 7.36798 9.61679 7.5803C9.70473 7.79262 9.75 8.02019 9.75 8.25C9.75 8.47981 9.70473 8.70738 9.61679 8.9197C9.52884 9.13202 9.39994 9.32493 9.23744 9.48744C9.07493 9.64994 8.88202 9.77884 8.6697 9.86679C8.45738 9.95473 8.22981 10 8 10C7.53587 10 7.09075 9.81563 6.76256 9.48744C6.43437 9.15925 6.25 8.71413 6.25 8.25C6.25 7.78587 6.43437 7.34075 6.76256 7.01256C7.09075 6.68437 7.53587 6.5 8 6.5ZM13.25 6.5C13.4798 6.5 13.7074 6.54526 13.9197 6.63321C14.132 6.72116 14.3249 6.85006 14.4874 7.01256C14.6499 7.17507 14.7788 7.36798 14.8668 7.5803C14.9547 7.79262 15 8.02019 15 8.25C15 8.47981 14.9547 8.70738 14.8668 8.9197C14.7788 9.13202 14.6499 9.32493 14.4874 9.48744C14.3249 9.64994 14.132 9.77884 13.9197 9.86679C13.7074 9.95473 13.4798 10 13.25 10C12.7859 10 12.3408 9.81563 12.0126 9.48744C11.6844 9.15925 11.5 8.71413 11.5 8.25C11.5 7.78587 11.6844 7.34075 12.0126 7.01256C12.3408 6.68437 12.7859 6.5 13.25 6.5Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            )
                        }
                        {isModalEditOpen &&
                            <EditPictureProfile
                                player={playerData}
                                onOpenToggle={isModalEditOpen}
                            />
                        }
                    </div>
                    <MediaQuery minWidth={768}>
                        {!accountStore.achievement ?
                            <EmptyAchievement />
                            : <AchievementCard data={accountStore.achievement} />
                        }
                    </MediaQuery>
                </div>
                <div className='w-full flex-1 flex flex-col'>
                    <ClubCard data={TournamentStore.player_stat} />
                    <ClubList data={clubStore.historyList} />
                    <ClubHistoryAdd />
                    <MediaQuery maxWidth={768}>
                        {!accountStore.achievement ?
                            <EmptyAchievement />
                            : <AchievementCard data={accountStore.achievement} />
                        }
                    </MediaQuery>
                    <PlayerBoard />
                </div>
            </React.Fragment >
        </div >
    );
}

export default observer(MyTournament);
