import { makeAutoObservable } from "mobx"
import agent from "../api/agent";
import { IGeneralCard, IGeneralProvince } from "../models/general";

export default class generalStore {

    province: IGeneralProvince[] | null = null;
    amateur_card: IGeneralCard | null = null;
    semi_card: IGeneralCard | null = null;
    pro_card: IGeneralCard | null = null;


    constructor() {
        makeAutoObservable(this);
    }

    getProvince = async () => {
        await agent.General.province().then((res) => {
            this.setProvince(res.data)
        })
    }

    setProvince = (data: IGeneralProvince[]) => {
        this.province = data
    }

    getCard = async () => {
        const amateur = agent.General.backgroundCard('amateur')
        this.setCardAmateur((await amateur).data)
        const semi = agent.General.backgroundCard('semi-pro')
        this.setCardSemi((await semi).data)
        const pro = agent.General.backgroundCard('pro')
        this.setCardPro((await pro).data)
    }

    setCardAmateur = (data: IGeneralCard) => {
        this.amateur_card = data
    }

    setCardSemi = (data: IGeneralCard) => {
        this.amateur_card = data
    }

    setCardPro = (data: IGeneralCard) => {
        this.amateur_card = data
    }
}