import React, { useEffect, useState } from 'react'
import 'aos/dist/aos.css';
import { observer } from 'mobx-react-lite';
import SettingPassword from './SettingPassword'
import HistoryTransaction from './TransactionHistory'
import { useStore } from '../../app/stores/store';
import { useParams } from 'react-router-dom';
import EmptyCard from './EmptyCard';
import Onboarding from '../../app/component/modal/Onboarding';

const EmptyPlayer = () => {

    const [activeTab, setActiveTab] = useState(1);
    const { accountStore } = useStore();
    const { slug } = useParams<{ slug: string }>();

    const [showModalOnboarding, setshowModalOnboarding] = useState(false)

    const openModalOnBoarding = () => {
        document.body.style.overflow = 'hidden';
        setshowModalOnboarding(true)
    };
    const closeModal = () => {
        document.body.style.overflow = 'visible';
        setshowModalOnboarding(false)
    };

    const [menu, setMenu] = useState([
        {
            id: 1,
            name: "Setting Profile",
            active: true
        },
        {
            id: 2,
            name: "My Tournament",
            active: false
        },
        {
            id: 3,
            name: "Setting Password",
            active: false
        },
        {
            id: 4,
            name: "Transaction History",
            active: false
        },
    ]);

    const handleMenu = (buttonId: number) => {

        const updatedButtons = menu.map(button => {
            if (button.id === buttonId) {
                return { ...button, active: true };
            } else {
                return { ...button, active: false };
            }
        });
        setMenu(updatedButtons);
        setActiveTab(buttonId);
    }

    return (
        <div className='w-full h-full font-sans bg-primary lg:bg-bg-home bg-cover bg-no-repeat py-10 px-4 lg:py-[50px] lg:px-40'>
            <div id='tab' className='w-full flex flex-row items-center overflow-x-auto scrollbar-hidden mb-6 space-x-1'>
                {menu.map((item, idx) => (
                    <button key={idx}
                        className={`capitalize p-[10px] text-[10px] rounded font-bold leading-normal whitespace-nowrap lg:overflow-hidden lg:ml-0 lg:mr-1
                        ${item.active ? ' bg-secondary text-white' : ' bg-transparent text-darkgray'}`}
                        onClick={() => handleMenu(item.id)}> {item.name} </button>
                ))}
            </div>
            {
                {
                    1: <EmptyCard onOpen={openModalOnBoarding} />,
                    2: <EmptyCard onOpen={openModalOnBoarding} />,
                    3: <SettingPassword />,
                    4: <HistoryTransaction />,
                }[activeTab]
            }

            {showModalOnboarding && (
                <Onboarding
                    onOpenOnboarding={showModalOnboarding}
                    onClose={closeModal}
                    step={2}
                ></Onboarding>
            )}
        </div>
    )
}

export default observer(EmptyPlayer)