import 'aos/dist/aos.css';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';

const EmptyPlayerCard = () => {
const {accountStore} = useStore()
  return (
    <div className="w-full flex flex-col">
        <h3 className="text-white text-center font-bold text-[22px] leading-normal">
            Menunggu Verifikasi,
        </h3>
        <h3 className="text-secondary text-center font-bold text-[22px] leading-normal capitalize mb-[38px]">
            {accountStore.profile?.detail?.username}
        </h3>
        <figure>
            <img src="/assets/images/mdi_comment-processing.svg" alt="icon_empty" className="w-[120px] h-[120px] mb-[38px] mx-auto flex" />
        </figure>
        <p className='text-white text-justify font-semibold text-sm leading-[-19px]'>Harap menunggu verifikasi dari Admin, Profile Anda akan muncul setelah verifikasi sudah disetujui</p>
    </div>
  )
}

export default observer(EmptyPlayerCard)