import { makeAutoObservable } from "mobx"
import agent from "../api/agent";

export default class notificationStore {

    show = false;
    text = '';

    constructor() {
        makeAutoObservable(this);
    }

    setNotification = (text: string) => {
        localStorage.setItem('notification', text)
        this.getNotification()
    }

    getNotification = () => {
        const result = localStorage.getItem('notification');
        this.setAlert(true, result ? result : '')
    }

    setAlert = (show: boolean, text: string) => {
        this.show = show
        this.text = text
    }

    removeNotification = () => {
        if (localStorage.getItem('notification')) {
            localStorage.removeItem('notification')
            this.getNotification()
        }
    }
}