import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

function LeaderBoardSide() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            {/* <div className="w-full h-full py-4">
                <table className="w-full table-auto text-white border-spacing-y-2 border-separate">
                    <thead className="">
                        <tr className="text-[2.5vw] lg:text-[0.9vw]">
                            <th className="text-start">Player</th>
                            <th className="text-center">Poin</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                className=" w-full h-full flex flex-row lg:flex-row items-center py-2 cursor-pointer"
                                onClick={() => navigate(`club/`)}
                            >
                                <div className="flex flex-row lg:w-[25%] justify-center items-center">
                                    <img
                                        src="/assets/images/.png"
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                    />
                                </div>
                                <div className="flex flex-col w-full lg:w-[75%]">
                                    <h4 className="ml-4 text-gray-500 font-bold text-[0.8vw] lg:text-[0.8vw]">#1</h4>
                                    <div className="flex mb-1">
                                        <span className="pl-4 ml-4 lg:pl-0 text-[0.8vw] lg:text-[0.8vw] font-semibold">
                                            Philip Franc &nbsp;
                                        </span> <p className="text-gray-500 text-[0.8vw] lg:text-[0.8vw]"> / Zeus Gaming</p>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="ml-4 mt-1 badge badge-primary badge-outline text-[1vw] lg:text-[0.5vw]">Semi Pro</div>
                                        <div className="ml-4 mt-1 badge badge-accent badge-outline text-[1vw] lg:text-[0.5vw]">eFootball Mobile</div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center text-[0.9vw] lg:text-[0.9vw] font-semibold w-[7%]">
                                1,816
                            </td>
                        </tr>
                        <tr>
                            <td
                                className=" w-full h-full flex flex-row lg:flex-row items-center py-2 cursor-pointer"
                                onClick={() => navigate(`club/`)}
                            >
                                <div className="flex flex-row lg:w-[25%] justify-center items-center">
                                    <img
                                        src="/assets/images/player-card.png"
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                    />
                                </div>
                                <div className="flex flex-col w-full lg:w-[75%]">
                                    <h4 className="ml-4 text-gray-500 font-bold text-[0.8vw] lg:text-[0.8vw]">#1</h4>
                                    <div className="flex mb-1">
                                        <span className="pl-4 ml-4 lg:pl-0 text-[0.8vw] lg:text-[0.8vw] font-semibold">
                                            Philip Franc &nbsp;
                                        </span> <p className="text-gray-500 text-[0.8vw] lg:text-[0.8vw]"> / Zeus Gaming</p>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="ml-4 mt-1 badge badge-primary badge-outline text-[1vw] lg:text-[0.5vw]">Semi Pro</div>
                                        <div className="ml-4 mt-1 badge badge-accent badge-outline text-[1vw] lg:text-[0.5vw]">eFootball Mobile</div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center text-[0.9vw] lg:text-[0.9vw] font-semibold w-[7%]">
                                1,816
                            </td>
                        </tr>
                        <tr>
                            <td
                                className=" w-full h-full flex flex-row lg:flex-row items-center py-2 cursor-pointer"
                                onClick={() => navigate(`club/`)}
                            >
                                <div className="flex flex-row lg:w-[25%] justify-center items-center">
                                    <img
                                        src="/assets/images/player-card.png"
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                    />
                                </div>
                                <div className="flex flex-col w-full lg:w-[75%]">
                                    <h4 className="ml-4 text-gray-500 font-bold text-[0.8vw] lg:text-[0.8vw]">#1</h4>
                                    <div className="flex mb-1">
                                        <span className="pl-4 ml-4 lg:pl-0 text-[0.8vw] lg:text-[0.8vw] font-semibold">
                                            Philip Franc &nbsp;
                                        </span> <p className="text-gray-500 text-[0.8vw] lg:text-[0.8vw]"> / Zeus Gaming</p>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="ml-4 mt-1 badge badge-primary badge-outline text-[1vw] lg:text-[0.5vw]">Semi Pro</div>
                                        <div className="ml-4 mt-1 badge badge-accent badge-outline text-[1vw] lg:text-[0.5vw]">eFootball Mobile</div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center text-[0.9vw] lg:text-[0.9vw] font-semibold w-[7%]">
                                1,816
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            <div className="flex flex-row items-center justify-between border-b border-darkgray mb-[47px] mt-3">
                <div className="text-darkgray text-xs font-medium leading-normal">Player</div>
                <div className="text-darkgray text-xs font-medium leading-normal">Poin</div>
            </div>

        </React.Fragment>
    );
}

export default observer(LeaderBoardSide);
