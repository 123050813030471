import React, { useState } from 'react'
import { useStore } from '../../../app/stores/store';
import { formatDateRange, formatRupiah, teamType, tournamentMode } from '../../../app/config/enum';
import ViewMatchCard from './ViewMatchCard';
import { useNavigate } from 'react-router-dom';

const ViewPlayerMatchCard = () => {
    const { TournamentStore } = useStore();
    const [matchIndex, setMatchIndex] = useState<number>(0);
    const navigate = useNavigate()

    const toTournament = (slug: string) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling behavior
        });
        navigate(`/tournament/${slug}`)
    }
    return (
        <React.Fragment>
            <h2 className=" text-white text-[22px] font-bold leading-normal mb-1">Jadwal Pertandingan</h2>
            {TournamentStore.myTournamentList.map((item, index) => (
                <div className="cursor-pointer" onClick={() => toTournament(item.tournament.slug)}>
                    <h3 className=" text-white text-[18px] font-bold leading-normal mb-[18px]">{item.tournament.name}</h3>
                    <div className="card card-side flex flex-row items-center bg-nav1 rounded-xl">
                        <figure className="mr-6 w-[274px]">
                            <img
                                src={`${process.env.REACT_APP_STORAGE_URL}${item.tournament.flag}`}
                                alt="Movie"
                                className='w-full h-full object-contain'
                            />
                        </figure>
                        <div>
                            <h2 className="card-title text-sm text-white leading-normal font-bold">{item.tournament.name}</h2>
                            <div className="flex flex-row items-center space-x-1 mb-[6px]">
                                <div className="badge badge-card badge-card-blue rounded px-2">eFootball Mobile</div>
                                <div className="badge badge-card badge-card-blue rounded px-2">{tournamentMode(item.tournament.mode)}</div>
                                <div className="badge badge-card badge-card-blue rounded px-2">{teamType(item.tournament.team_type)}</div>
                            </div>
                            <div className="badge badge-card-green rounded px-2 mb-2">Open Registration</div>
                            <div className='flex flex-row items-end justify-between'>
                                <div>
                                    <div className="flex items-center">
                                        <div className="columns-1 mr-4">
                                            <figure>
                                                <img
                                                    src="/assets/images/Prizepool.png"
                                                    alt="Prizepool"
                                                />
                                            </figure>
                                        </div>
                                        <div className="columns-2 text-xs text-white leading-normal font-medium">
                                            {formatRupiah(item.tournament.grand_prize)}
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="columns-1 mr-4">
                                            <figure>
                                                <img
                                                    src="/assets/images/Slots.png"
                                                    alt="Slots"
                                                />
                                            </figure>
                                        </div>
                                        <div className="columns-2 text-xs text-white leading-normal font-medium">
                                            {item.player_count}/{item.tournament.open_slot}
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="columns-1 mr-4">
                                            <figure>
                                                <img
                                                    src="/assets/images/Calendar.png"
                                                    alt="Calendar"
                                                />
                                            </figure>
                                        </div>
                                        <div className="columns-2 text-xs text-white leading-normal font-medium">
                                            {formatDateRange(new Date(item.tournament.start_date), new Date(item.tournament.end_date))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {TournamentStore.myMatchList.map((item, index) => (
                <div className="w-full h-full bg-[#1D2335] flex flex-col justify-between items-center px-4 my-4 py-2 rounded-md" key={index}>
                    <ViewMatchCard item={item.matches[matchIndex ? matchIndex : 0]} matches={item.matches} tournament_name={item.tournament.name} />
                </div>
            ))}
        </React.Fragment>
    )
}

export default ViewPlayerMatchCard