import React, { useEffect, useState } from "react";
import { Tooltip, Typography } from "@material-tailwind/react";
import { observer } from "mobx-react-lite";
import AchievementCard from '../../app/component/card/AchievementCard';
import { IEditUserProfile, IUserProfile } from "../../app/models/profile";
import { useStore } from "../../app/stores/store";
import EditPictureProfile from "../../app/component/modal/EditPictureProfile";
import SkeletonComponent from "../../app/component/skeleton/SkeletonComponent";
import EmptyPlayerCard from "../../app/component/card/empty/EmptyPlayerCard";
import { useParams } from "react-router-dom";
import DummyPlayerCard from "../../app/component/card/dummy/DummyPlayerCard";
import { IAccountOnboarding } from "../../app/models/account";
import MediaQuery from 'react-responsive';
import AmateurCard from "./AmateurCard";
import EmptyAchievement from "../../app/component/card/empty/EmptyAchievement";

const playerData = {
    level: "amateur"
};

interface playerProps {
    data: IUserProfile | null;
}

const initialNewValue: IEditUserProfile = {
    name: null,
    username: null,
    birth_date: null,
    birth_month: null,
    birth_year: null,
    domisili: null,
    game: null,
    platform: null,
    card: null,
    image: null,
    card_approved: false,
    address: null,
    postal_code: null,
    phone: null
}

const initialOnboardingValues: IAccountOnboarding = {
    name: '',
    birth_date: '2',
    birth_month: 'February',
    birth_year: '2000',
    domisili: 'Jakarta',
    game: 'PES',
    platform: 'Console',
    card: 'amateur',
    image: '',
    group_id: 0,
};


const SettingProfile: React.FC<playerProps> = ({ data }) => {
    const { accountStore } = useStore()
    const [isActive, setIsActive] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState(false)
    const [errorUsername, setErrorUsername] = useState('')
    const [successText, setSuccessText] = useState('')
    const [load, btnLoad] = useState(false)

    const { slug } = useParams<{ slug: string }>();
    const [userData, setUserData] = useState(data);
    const skeletonData = [
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
    ];

    const customStyles = {
        backgroundColor: "lightblue",
        color: "black",
        borderRadius: "4px",
        padding: "8px",
    };

    const [newValues, setNewValues] = useState<IEditUserProfile>(
        initialNewValue
    )

    useEffect(() => {
        if (data) {
            setNewValues((prevState) => ({ ...prevState, username: data.detail ? data.detail?.username : null }))
            setNewValues((prevState) => ({ ...prevState, name: data.name || '' }))
            setNewValues((prevState) => ({ ...prevState, domisili: data.detail ? data.detail?.domisili : null }))
            setNewValues((prevState) => ({ ...prevState, game: data.detail ? data.detail?.game : null }))
            setNewValues((prevState) => ({ ...prevState, platform: data.detail ? data.detail?.platform : null }))
            setNewValues((prevState) => ({ ...prevState, address: data.address ? data.address?.address : null }))
            setNewValues((prevState) => ({ ...prevState, postal_code: data.address ? data.address?.postal_code : null }))
            setNewValues((prevState) => ({ ...prevState, phone: data.address ? data.address?.phone : null }))
            setUserData(data);
        }
    }, [data])

    const handleInputUsername = (value: string) => {
        setSuccessText("")
        if (value.length >= 16) {
            setErrorUsername("Username maksimal 16 karakter!")
        } else {
            setErrorUsername('')
        }
        setNewValues((prevState) => ({ ...prevState, username: value }))
    }
    const handleInputName = (value: string) => {
        setSuccessText("")
        setNewValues((prevState) => ({ ...prevState, name: value }))
    }
    const handleInputDomisili = (value: string) => {
        setSuccessText("")
        setNewValues((prevState) => ({ ...prevState, domisili: value }))
    }
    const handleInputGame = (value: string) => {
        setSuccessText("")
        setNewValues((prevState) => ({ ...prevState, game: value }))
    }
    const handleInputPlatform = (value: string) => {
        setSuccessText("")
        setNewValues((prevState) => ({ ...prevState, platform: value }))
    }
    const handleInputAddress = (value: string) => {
        setSuccessText("")
        setNewValues((prevState) => ({ ...prevState, address: value }))
    }
    const handleInputPostalCode = (value: string) => {
        setSuccessText("")
        setNewValues((prevState) => ({ ...prevState, postal_code: value }))
    }
    const handleInputPhone = (value: string) => {
        setSuccessText("")
        setNewValues((prevState) => ({ ...prevState, phone: value }))
    }

    const handleSubmitForm = async () => {
        setSuccessText("")
        btnLoad(true)
        await accountStore.editProfile(newValues, String(slug)).then(() => {
            btnLoad(false)
            setIsActive(!isActive)
            setSuccessText("Berhasil Mengubah Data")
        })
    }

    const toggleModalEdit = () => {
        setIsModalEditOpen(!isModalEditOpen)
    }

    const closeModal = () => {
        setIsModalEditOpen(false)
        console.log("close");

    }

    useEffect(() => {
        accountStore.getAchievementList(String(slug))
    }, [accountStore])

    return (
        <div className='w-full h-full flex flex-col lg:flex-row'>
            <React.Fragment>
                {/* Sidebar Left */}
                <div className='w-full mr-6 lg:w-[328px]'>
                    {/* Standings */}
                    <div className='w-full mb-[14px] mx-auto h-[418px] flex flex-col items-center justify-center bg-nav1 rounded-xl relative lg:mb-0 lg:w-[328px] '>
                        {!accountStore.profile?.detail
                            ?
                            <div className="flex flex-row items-center mx-auto justify-center">
                                <SkeletonComponent
                                    count={1}
                                    width='w-[230px]'
                                    height='h-[370px]'
                                    borderRadius='rounded-xl'
                                />
                            </div> :
                            (!accountStore.profile?.detail.card
                                ?
                                <div>
                                    <button className="absolute top-[14px] right-[14px] cursor-pointer" onClick={toggleModalEdit}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M2.75 6.5C2.97981 6.5 3.20738 6.54526 3.4197 6.63321C3.63202 6.72116 3.82493 6.85006 3.98744 7.01256C4.14994 7.17507 4.27884 7.36798 4.36679 7.5803C4.45474 7.79262 4.5 8.02019 4.5 8.25C4.5 8.47981 4.45474 8.70738 4.36679 8.9197C4.27884 9.13202 4.14994 9.32493 3.98744 9.48744C3.82493 9.64994 3.63202 9.77884 3.4197 9.86679C3.20738 9.95473 2.97981 10 2.75 10C2.28587 10 1.84075 9.81563 1.51256 9.48744C1.18437 9.15925 1 8.71413 1 8.25C1 7.78587 1.18437 7.34075 1.51256 7.01256C1.84075 6.68437 2.28587 6.5 2.75 6.5ZM8 6.5C8.22981 6.5 8.45738 6.54526 8.6697 6.63321C8.88202 6.72116 9.07493 6.85006 9.23744 7.01256C9.39994 7.17507 9.52884 7.36798 9.61679 7.5803C9.70473 7.79262 9.75 8.02019 9.75 8.25C9.75 8.47981 9.70473 8.70738 9.61679 8.9197C9.52884 9.13202 9.39994 9.32493 9.23744 9.48744C9.07493 9.64994 8.88202 9.77884 8.6697 9.86679C8.45738 9.95473 8.22981 10 8 10C7.53587 10 7.09075 9.81563 6.76256 9.48744C6.43437 9.15925 6.25 8.71413 6.25 8.25C6.25 7.78587 6.43437 7.34075 6.76256 7.01256C7.09075 6.68437 7.53587 6.5 8 6.5ZM13.25 6.5C13.4798 6.5 13.7074 6.54526 13.9197 6.63321C14.132 6.72116 14.3249 6.85006 14.4874 7.01256C14.6499 7.17507 14.7788 7.36798 14.8668 7.5803C14.9547 7.79262 15 8.02019 15 8.25C15 8.47981 14.9547 8.70738 14.8668 8.9197C14.7788 9.13202 14.6499 9.32493 14.4874 9.48744C14.3249 9.64994 14.132 9.77884 13.9197 9.86679C13.7074 9.95473 13.4798 10 13.25 10C12.7859 10 12.3408 9.81563 12.0126 9.48744C11.6844 9.15925 11.5 8.71413 11.5 8.25C11.5 7.78587 11.6844 7.34075 12.0126 7.01256C12.3408 6.68437 12.7859 6.5 13.25 6.5Z" fill="white" />
                                        </svg>
                                    </button>
                                    {accountStore.profile.detail.level === 'amateur' ?
                                        <AmateurCard
                                            player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                            data={accountStore.profile.detail}></AmateurCard>
                                        :
                                        (accountStore.profile.detail.card_approved ?
                                            <AmateurCard
                                                player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                                data={accountStore.profile.detail}></AmateurCard>
                                            // <DummyPlayerCard
                                            //     player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                            //     data={accountStore.profile.detail}
                                            // />
                                            :
                                            <div className="px-4">
                                                <EmptyPlayerCard />
                                            </div>
                                        )}
                                    <button className="absolute top-[14px] right-[14px] cursor-pointer" onClick={toggleModalEdit}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M2.75 6.5C2.97981 6.5 3.20738 6.54526 3.4197 6.63321C3.63202 6.72116 3.82493 6.85006 3.98744 7.01256C4.14994 7.17507 4.27884 7.36798 4.36679 7.5803C4.45474 7.79262 4.5 8.02019 4.5 8.25C4.5 8.47981 4.45474 8.70738 4.36679 8.9197C4.27884 9.13202 4.14994 9.32493 3.98744 9.48744C3.82493 9.64994 3.63202 9.77884 3.4197 9.86679C3.20738 9.95473 2.97981 10 2.75 10C2.28587 10 1.84075 9.81563 1.51256 9.48744C1.18437 9.15925 1 8.71413 1 8.25C1 7.78587 1.18437 7.34075 1.51256 7.01256C1.84075 6.68437 2.28587 6.5 2.75 6.5ZM8 6.5C8.22981 6.5 8.45738 6.54526 8.6697 6.63321C8.88202 6.72116 9.07493 6.85006 9.23744 7.01256C9.39994 7.17507 9.52884 7.36798 9.61679 7.5803C9.70473 7.79262 9.75 8.02019 9.75 8.25C9.75 8.47981 9.70473 8.70738 9.61679 8.9197C9.52884 9.13202 9.39994 9.32493 9.23744 9.48744C9.07493 9.64994 8.88202 9.77884 8.6697 9.86679C8.45738 9.95473 8.22981 10 8 10C7.53587 10 7.09075 9.81563 6.76256 9.48744C6.43437 9.15925 6.25 8.71413 6.25 8.25C6.25 7.78587 6.43437 7.34075 6.76256 7.01256C7.09075 6.68437 7.53587 6.5 8 6.5ZM13.25 6.5C13.4798 6.5 13.7074 6.54526 13.9197 6.63321C14.132 6.72116 14.3249 6.85006 14.4874 7.01256C14.6499 7.17507 14.7788 7.36798 14.8668 7.5803C14.9547 7.79262 15 8.02019 15 8.25C15 8.47981 14.9547 8.70738 14.8668 8.9197C14.7788 9.13202 14.6499 9.32493 14.4874 9.48744C14.3249 9.64994 14.132 9.77884 13.9197 9.86679C13.7074 9.95473 13.4798 10 13.25 10C12.7859 10 12.3408 9.81563 12.0126 9.48744C11.6844 9.15925 11.5 8.71413 11.5 8.25C11.5 7.78587 11.6844 7.34075 12.0126 7.01256C12.3408 6.68437 12.7859 6.5 13.25 6.5Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                :
                                <div>
                                    {accountStore.profile.detail.card_approved ?
                                        // <DummyPlayerCard
                                        //     player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                        //     data={accountStore.profile.detail}
                                        // /> 
                                        <AmateurCard
                                            player={accountStore.profile.detail.level ? accountStore.profile.detail.level : 'amateur'}
                                            data={accountStore.profile.detail}></AmateurCard>
                                        :
                                        <div className="px-4">
                                            <EmptyPlayerCard />
                                        </div>
                                    }
                                    <button className="absolute top-[14px] right-[14px] cursor-pointer" onClick={toggleModalEdit}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M2.75 6.5C2.97981 6.5 3.20738 6.54526 3.4197 6.63321C3.63202 6.72116 3.82493 6.85006 3.98744 7.01256C4.14994 7.17507 4.27884 7.36798 4.36679 7.5803C4.45474 7.79262 4.5 8.02019 4.5 8.25C4.5 8.47981 4.45474 8.70738 4.36679 8.9197C4.27884 9.13202 4.14994 9.32493 3.98744 9.48744C3.82493 9.64994 3.63202 9.77884 3.4197 9.86679C3.20738 9.95473 2.97981 10 2.75 10C2.28587 10 1.84075 9.81563 1.51256 9.48744C1.18437 9.15925 1 8.71413 1 8.25C1 7.78587 1.18437 7.34075 1.51256 7.01256C1.84075 6.68437 2.28587 6.5 2.75 6.5ZM8 6.5C8.22981 6.5 8.45738 6.54526 8.6697 6.63321C8.88202 6.72116 9.07493 6.85006 9.23744 7.01256C9.39994 7.17507 9.52884 7.36798 9.61679 7.5803C9.70473 7.79262 9.75 8.02019 9.75 8.25C9.75 8.47981 9.70473 8.70738 9.61679 8.9197C9.52884 9.13202 9.39994 9.32493 9.23744 9.48744C9.07493 9.64994 8.88202 9.77884 8.6697 9.86679C8.45738 9.95473 8.22981 10 8 10C7.53587 10 7.09075 9.81563 6.76256 9.48744C6.43437 9.15925 6.25 8.71413 6.25 8.25C6.25 7.78587 6.43437 7.34075 6.76256 7.01256C7.09075 6.68437 7.53587 6.5 8 6.5ZM13.25 6.5C13.4798 6.5 13.7074 6.54526 13.9197 6.63321C14.132 6.72116 14.3249 6.85006 14.4874 7.01256C14.6499 7.17507 14.7788 7.36798 14.8668 7.5803C14.9547 7.79262 15 8.02019 15 8.25C15 8.47981 14.9547 8.70738 14.8668 8.9197C14.7788 9.13202 14.6499 9.32493 14.4874 9.48744C14.3249 9.64994 14.132 9.77884 13.9197 9.86679C13.7074 9.95473 13.4798 10 13.25 10C12.7859 10 12.3408 9.81563 12.0126 9.48744C11.6844 9.15925 11.5 8.71413 11.5 8.25C11.5 7.78587 11.6844 7.34075 12.0126 7.01256C12.3408 6.68437 12.7859 6.5 13.25 6.5Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            )
                        }
                        <EditPictureProfile
                            player={playerData}
                            onOpenToggle={isModalEditOpen}
                        />
                    </div>
                    <MediaQuery minWidth={768}>
                        {!accountStore.achievement ?
                            <EmptyAchievement />
                            :
                            <AchievementCard data={accountStore.achievement} />
                        }
                    </MediaQuery>
                </div>
                <div className='w-full flex-1 flex flex-col'>
                    <h1 className="text-white text-center font-extrabold text-xl lg:text-[32px] leading-normal mb-[14px]">Setting Profile</h1>
                    {data ? (
                        <div className="bg-nav1 rounded-xl lg:rounded-3xl block px-4 py-6">
                            <div className="flex flex-row items-center mb-2">
                                <label htmlFor="fullname" className="text-white text-xs font-medium leading-6 mr-1">Nama Lengkap</label>
                                <Tooltip
                                    content={
                                        <div className="w-full">
                                            <Typography
                                                variant="small"
                                                color="white"
                                                className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                            >
                                                Isi nama lengkap Anda.
                                            </Typography>
                                        </div>
                                    }
                                    placement="right-start"
                                    style={customStyles}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <input
                                type="text"
                                id="fullname"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 capitalize"
                                placeholder="Full Name"
                                value={newValues.name ? newValues.name : ''}
                                onChange={(e) => handleInputName(e.target.value)}
                            />
                            <div className="flex flex-row items-center mb-2">
                                <label htmlFor="username" className="text-white text-xs font-medium leading-6 mr-1">Username (Nama pada Kartu)</label>
                                <Tooltip
                                    content={
                                        <div className="w-full">
                                            <Typography
                                                variant="small"
                                                color="white"
                                                className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                            >
                                                Pastikan username yang Anda cantumkan sama dengan informasi pada game.
                                            </Typography>
                                        </div>
                                    }
                                    placement="right-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <input type="text"
                                id="username"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3"
                                placeholder="Username"
                                value={newValues.username ? newValues.username : ''}
                                maxLength={16}
                                disabled
                                onChange={(e) => handleInputUsername(e.target.value)}
                            />
                            <p className="text-red text-[8px] text-left font-semibold">{errorUsername}</p>

                            <div className="flex flex-row items-center mb-2">
                                <label htmlFor="age" className="text-white text-xs font-medium leading-6 mr-1">Tanggal Lahir</label>
                                {/* <Tooltip
                                    content={
                                        <div className="w-full">
                                            <Typography
                                                variant="small"
                                                color="white"
                                                className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                            >
                                                Masukkan tanggal lahir Anda
                                            </Typography>
                                        </div>
                                    }
                                    placement="right-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip> */}
                            </div>
                            <div className="flex flex-row items-start mb-2 flex-wrap">
                                <input type="text"
                                    className="rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 mr-3 w-1/3 lg:w-[110px]"
                                    value={userData?.detail?.birth_date}
                                ></input>
                                <input type="text"
                                    className="rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 mr-3 w-1/3 lg:w-[110px]"
                                    value={userData?.detail?.birth_month}
                                ></input>
                                <input type="text"
                                    className="rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 w-1/3 lg:w-[110px]"
                                    value={userData?.detail?.birth_year}
                                ></input>
                            </div>
                            <div className="flex flex-row items-center mb-2">
                                <label htmlFor="domisili" className="text-white text-xs font-medium leading-6 mr-1">Domisili</label>
                                <Tooltip
                                    content={
                                        <div className="w-full">
                                            <Typography
                                                variant="small"
                                                color="white"
                                                className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                            >
                                                Anda akan bergabung dengan komunitas virtual football lainnya sesuai domisili yang Anda masukkan.
                                            </Typography>
                                        </div>
                                    }
                                    placement="right-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <input type="text"
                                id="domisili"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3"
                                placeholder="Domisili"
                                value={newValues.domisili ? newValues.domisili : ''}
                                onChange={(e) => handleInputDomisili(e.target.value)}
                                disabled
                            />
                            <label htmlFor="game" className="text-white text-xs font-medium leading-6">Game</label>
                            <input type="text"
                                id="game"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3"
                                placeholder="Game"
                                value={newValues.game ? newValues.game : ''}
                                onChange={(e) => handleInputGame(e.target.value)}
                                disabled
                            />
                            <label htmlFor="game" className="text-white text-xs font-medium leading-6">Platform</label>
                            <input type="text"
                                id="platform"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3"
                                placeholder="Platform"
                                value={newValues.platform ? newValues.platform : ''}
                                onChange={(e) => handleInputPlatform(e.target.value)}
                                disabled
                            />
                            <label htmlFor="address" className="text-white text-xs font-medium leading-6">Alamat</label>
                            <input type="text"
                                id="address"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3"
                                placeholder="Address"
                                value={newValues.address ? newValues.address : ''}
                                onChange={(e) => handleInputAddress(e.target.value)}
                            />
                            <label htmlFor="postal_code" className="text-white text-xs font-medium leading-6">Kode Pos</label>
                            <input type="text"
                                id="postal_code"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3"
                                placeholder="Kode Pos"
                                value={newValues.postal_code ? newValues.postal_code : ''}
                                onChange={(e) => handleInputPostalCode(e.target.value)}
                            />
                            <label htmlFor="phone_number" className="text-white text-xs font-medium leading-6">Nomor Telepon</label>
                            <input type="text"
                                id="phone_number"
                                className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3"
                                placeholder="Nomor Telepon"
                                value={newValues.phone ? newValues.phone : ''}
                                onChange={(e) => handleInputPhone(e.target.value)}
                            />
                            <label className="text-success text-xs font-medium leading-6">{successText}</label>
                            <button
                                onClick={handleSubmitForm}
                                className={!load
                                    ?
                                    `w-full h-[50px] bg-secondary text-white font-semibold mt-6 py-2 text-xs rounded-md cursor-pointer`
                                    :
                                    `w-full h-[50px] bg-nav2 text-white font-semibold mt-6 py-2 text-xs rounded-md cursor-pointer`
                                }
                            >
                                {!load ? "Submit" : "Please Wait"}
                            </button>
                            {/* <button
                        onClick={() => this.setState({isActive : !isActive})}
                        disabled={isActive}
                        className={` ${isActive ? ' bg-secondary text-white' : ' bg-darkgray text-[#7A8697]'} w-full h-[50px] rounded-md text-sm font-semibold text-center leading-normal`
                        {isActive ? 'bg-secondary text-white' : 'bg-darkgray text-[#7A8697]'} Button
                    </button> */}
                        </div>
                    ) : (
                        <div className="bg-nav1 rounded-3xl block px-4 py-6">
                            {skeletonData.map((data, index) => (
                                <SkeletonComponent
                                    key={index}
                                    count={data.count}
                                    width={data.width}
                                    height={data.height}
                                    marginBottom={data.marginBottom}
                                    borderRadius={data.borderRadius}
                                />
                            ))}
                        </div>
                    )}
                    <MediaQuery maxWidth={767}>
                        {!accountStore.achievement ?
                            <EmptyAchievement />
                            : <AchievementCard data={accountStore.achievement} />
                        }
                    </MediaQuery>
                </div>
            </React.Fragment>
        </div>
    );
}

export default observer(SettingProfile);
