import { observer } from 'mobx-react-lite'
import React, { ReactNode } from 'react'
import ProfileMenu from '../../app/component/menu/ProfileMenu'

type ProfileProps = {
    children: ReactNode;
};

const ProfileIndexPage: React.FC<ProfileProps> = ({ children }) => {
  return (
    <div className='w-full bg-bg-home bg-no-repeat bg-cover flex flex-row lg:space-x-5 justify-center items-start lg:px-20 xl:px-40 lg:py-[8vh]'>
        <div className='hidden lg:block lg:w-1/4'>
            <ProfileMenu/>
        </div>
        <div className='w-full lg:w-3/4'>
            {children}
        </div>
    </div>
  )
}

export default observer(ProfileIndexPage)