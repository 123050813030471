import { observer } from 'mobx-react-lite';
import React from 'react';

interface PopupProps {
    onClose: () => void
}

const AdsBannerPopup = (props: PopupProps) => {

  const { onClose } = props;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-gray-300 p-1 rounded-sm shadow-lg">
        <div className='w-full flex flex-row justify-end items-center space-x-1'>
            <p className='font-sans font-bold text-[10px]'>ADVERTISEMENT</p> 
            <p className='font-sans font-extrabold text-[8px] bg-gray-700 rounded-sm text-white px-1 cursor-pointer' onClick={onClose}>X</p>
        </div>
        <p className="w-full h-full text-lg mb-4 lg:w-96 lg:h-52">Ini adalah Pop-up Ads</p>
      </div>
    </div>
  );
};

export default observer(AdsBannerPopup);