import React, { useCallback, useEffect, useState } from 'react';
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    IResolveParams,
} from 'reactjs-social-login';
import {
    FacebookLoginButton,
    GoogleLoginButton,
} from 'react-social-login-buttons';
import { IAccountLoginValues } from '../../models/account';
import InputPassword from '../input/InputPassword';
import { useStore } from '../../stores/store';
import '../topNavbar/topNavbar.css';
import AppLoader from '../loading/appLoader';
import { Spin } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';


const initialValuesLogin: IAccountLoginValues = {
    email: '-',
    password: '-',
};

interface ModalProps {
    onOpenLogin: boolean;
    closeModal: () => void;
    switchModal: () => void;
}

const LoginModal: React.FC<ModalProps> = ({ onOpenLogin, closeModal, switchModal }) => {
    const { accountStore, commonStore } = useStore();
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState('');

    const [valueLogin, setValueLogin] =
        useState<IAccountLoginValues>(initialValuesLogin);

    const [validateEmail, setValidateEmail] = useState(false);
    const [error, setError] = useState(false);
    const [loadAuth, setLoadAuth] = useState(false);

    useEffect(() => {
        if (onOpenLogin) {
            setActive('active');
        }
    })

    const handleInputEmail = (value: string) => {
        const emailRegex = /\S+@\S+\.\S+/;
        if (emailRegex.test(value)) {
            setValidateEmail(true);
        } else {
            setValidateEmail(false);
        }
        setError(false)

        setValueLogin((prevState) => ({ ...prevState, email: value }));
    };

    const handleInputPassword = (value: string) => {
        setError(false)
        setValueLogin((prevState) => ({ ...prevState, password: value }));
    };

    const handleSubmit = async () => {
        setLoading(true)
        const login = await accountStore.login(valueLogin);

        if (!login) {
            setLoading(false)
            return setError(true)
        }
        setLoading(false)
        toast.success('Login berhasil!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 36000,
            progressClassName: 'custom-progress',
            icon: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'white' }} />,
            style: {
                background: '#1F98FF',
                color: 'white',
                fontSize: '18px',
            },
        });

        return window.location.href = '/'
    };

    const handleCloseModal = () => {
        closeModal();
    }

    const onLoginStart = useCallback(() => {
        console.log('login start');
    }, []);
    const loginSocial = (provider: string, data: any) => {
        console.log(data);

        setLoadAuth(true)
        accountStore.loginSocial(data)
    };
    const REDIRECT_URI = 'https://ifel.id/login';

    const styles = {
        button: {
            borderRadius: '6px',
            border: '1px solid rgba(71,85,105, .6) ',
            color: 'white',
            padding: '13px 0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '24px',
            height: '50px',
        }
    };

    if (!onOpenLogin) return null;

    return (
        <div>
            <div className="modal-overlay" onClick={handleCloseModal}></div>
            <div className={`modal h-full w-full mt-[64px] md:w-[70%] lg:w-[438px] lg:m-auto lg:h-fit lg:max-h-[90vh] ${active}`}>
                {!loadAuth ?
                    <div className="modal-content overflow-y-scroll p-4 lg:p-5">
                        <button className="close" onClick={handleCloseModal}>
                            <img className="w-6 h-6" src="/assets/images/close.svg" alt="close"></img>
                        </button>
                        <h2 className="font-extrabold text-white text-center text-[32px] leading-normal mb-3">
                            Login
                        </h2>
                        <div className="text-darkgray font-semibold text-xs text-center leading-normal mb-4">
                            Belum memiliki akun?{' '}
                            <button
                                className="text-secondary font-semibold text-xs underline"
                                onClick={switchModal}
                            >
                                Register
                            </button>
                        </div>
                        <LoginSocialGoogle
                            client_id={process.env.REACT_APP_GG_APP_ID || ''}
                            onLoginStart={onLoginStart}
                            redirect_uri={REDIRECT_URI}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }: IResolveParams) => {
                                setProvider(provider);
                                setProfile(data);
                                loginSocial(provider, data);
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <GoogleLoginButton
                                className="custom-button-social"
                                style={styles.button}
                            />
                        </LoginSocialGoogle>
                        {/* <LoginSocialFacebook
                        isOnlyGetToken
                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }: IResolveParams) => {
                            setProvider(provider);
                            setProfile(data);
                            loginSocial(provider, data);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <FacebookLoginButton
                            className="custom-button-social"
                            style={styles.button}
                        />
                    </LoginSocialFacebook> */}
                        <div className="flex flex-row items-center justify-between my-6">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="149"
                                height="2"
                                viewBox="0 0 149 2"
                                fill="none"
                            >
                                <path d="M0 1H149" stroke="#383D42" strokeWidth="0.5" />
                            </svg>
                            <span className="text-white text-sm font-medium mx-12">Or</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="149"
                                height="2"
                                viewBox="0 0 149 2"
                                fill="none"
                            >
                                <path d="M0 1H149" stroke="#383D42" strokeWidth="0.5" />
                            </svg>
                        </div>
                        <div className="relative w-full container mx-auto font-sans">
                            <label htmlFor="email" className="text-white text-xs font-medium">
                                Email Address
                            </label>
                            <input
                                type="text"
                                id="email"
                                className="w-full text-xs border-darkgray px-[19px] py-[13px] mt-2 leading-normal text-white bg-transparent rounded-md appearance-none mb-3 focus:outline-none"
                                name="email"
                                placeholder="Email address"
                                onChange={(e) => handleInputEmail(e.target.value)}
                            />
                        </div>
                        <label
                            htmlFor="password"
                            className="text-white text-xs font-medium mb-3"
                        >
                            Password
                        </label>
                        <InputPassword
                            label=""
                            bgColor="bg-nav1"
                            labelColor=""
                            textColor="text-white"
                            border="border-darkgray"
                            px="px-[19px]"
                            py="py-[13px]"
                            mt="mt-2"
                            mb="mb-0"
                            leading="leading-normal"
                            placeholder="Password"
                            onChange={(value) => handleInputPassword(value)}
                        />
                        {valueLogin.email === null || valueLogin.email === '' ? (
                            <p className="text-left text-xs text-red font-medium mt-2">
                                email tidak boleh kosong
                            </p>
                        ) : valueLogin.email !== '-' && validateEmail === false ? (
                            <p className="text-left text-xs text-red font-medium mt-2">
                                email tidak sesuai format
                            </p>
                        ) : (
                            <></>
                        )}
                        {error ?
                            <p className="text-left text-xs text-red font-medium mt-2">
                                email atau password salah
                            </p> : <></>}
                        <div className="w-full mt-12">
                            {!accountStore.loadingLogin ? (
                                <button
                                    onClick={() => handleSubmit()}
                                    disabled={
                                        !valueLogin.email ||
                                        valueLogin.password === '' ||
                                        validateEmail === false
                                    }
                                    className={`w-full h-[50px] px-10 lg:px-20 py-3 mb-5 disabled:bg-darkgray disabled:cursor-not-allowed text-white font-semibold bg-secondary border-none rounded-md text-[12px] hover:bg-nav2 hover:text-secondary transition duration-300 lg:mb-0`}
                                >
                                    Login
                                </button>
                            ) : (
                                <button
                                    onClick={() => handleSubmit()}
                                    disabled={true}
                                    className={`loading w-full h-[50px] px-10 lg:px-20 py-3 disabled:bg-secondary disabled:cursor-not-allowed disabled:text-white text-white font-semibold bg-darkgray border-none rounded-md text-[12px] hover:bg-nav2 hover:text-secondary transition duration-300`}
                                >
                                    Please Wait
                                </button>
                            )}
                        </div>
                        <a
                            href="/forgot-password"
                            className="text-secondary font-semibold text-xs leading-normal underline mt-3 pb-5 flex justify-center"
                        >
                            Lupa password?
                        </a>
                        <ToastContainer />
                    </div>
                    :
                    <Spin tip="Please wait" size="large"></Spin>
                }
            </div>
        </div>
    );
};

export default LoginModal;
