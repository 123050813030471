import { observer } from "mobx-react-lite"
import "../../../src/index.css"
import { IProfileDetail } from "../../app/models/profile";
import { calculateAge, getPlayerSilhouette } from "../../app/config/enum";

interface AmateurCardProps {
    player: string;
    data: IProfileDetail;
}

const renderLevelTag = (level: string) => {
    if (level === 'amateur') {
        return <img className="w-full h-full mx-auto" src="/assets/images/Amateur.png" alt=""></img>
    } else if (level === 'semi') {
        return <img className="w-full h-full mx-auto" src="/assets/images/Semi.png" alt=""></img>
    } else {
        return <img className="w-full h-full mx-auto" src="/assets/images/Pro.png" alt=""></img>
    }
};

const AmateurCard: React.FC<AmateurCardProps> = ({ player, data }) => {
    console.log(data);

    return (
        <div className="w-[229px] mx-auto h-[370px] card-player relative">
            <div className="card-player w-full h-full">
                <div className="flex flex-col items-start absolute team-name">
                    {/* <div className={`text-[10px] font-extrabold leading-normal whitespace-nowrap text-${!data.color_code ? 'white' : data.color_code}`}>{data.game}</div>
                    <div className={`text-[10px] font-extrabold leading-[4px] whitespace-nowrap uppercase text-${!data.color_code ? 'white' : data.color_code}`}>{data.platform}</div> */}
                    <div className={`text-[10px] font-extrabold leading-normal whitespace-nowrap text-${data.level === 'amateur' ? 'white' : '[#181818]'}`}>{data.game}</div>
                    <div className={`text-[10px] font-extrabold leading-[4px] whitespace-nowrap uppercase text-${data.level === 'amateur' ? 'white' : '[#181818]'}`}>{data.platform}</div>
                </div>
                {
                    data.level === 'amateur'
                        ?
                        <img className="absolute team-logo z-10" src="/assets/images/efootball-logo.png" alt="" />
                        :
                        <img className="absolute team-logo z-10" src="/assets/images/eFootball-black.png" alt="" />
                }
                <div className="relative">
                    {/* <img className="w-full h-full mx-auto" src="/assets/images/Amateur.png" alt="" /> */}
                    {renderLevelTag(!data.level ? 'amateur' : data.level)}
                    <img className="absolute w-[180px] top-6 left-1/2 transform -translate-x-1/2" src={data.card ?  `${process.env.REACT_APP_STORAGE_URL}${data.card}` : getPlayerSilhouette(!data.level ? 'amateur' : data.level)} alt="" />
                    {/* <img className="absolute bottom-[68px] left-1/2 transform -translate-x-1/2 w-[160px]" src={getPlayerSilhouette(!data.level ? 'amateur' : data.level)} alt="" /> */}
                </div>
            </div>
            <h2 className={`absolute uppercase text-[18px] font-extrabold leading-normal text-center whitespace-nowrap bottom-[35px] left-1/2 transform -translate-x-1/2 text-${data.level === 'amateur' ? 'white' : '[#181818]'}`}>{data.username}</h2>
            <div className="flex flex-row items-center absolute age">
                {/* <h3 className={`uppercase text-[10px] font-medium leading-normal whitespace-nowrap mr-1 text-${!data.color_code ? 'white' : data.color_code}`}>Age</h3>
                <h3 className={`uppercase text-[10px] font-extrabold leading-normal whitespace-nowrap text-${!data.color_code ? 'white' : data.color_code}`}>{calculateAge(data.birth_year)}</h3> */}
                <h3 className={`uppercase text-[10px] font-medium leading-normal whitespace-nowrap mr-1 text-${data.level === 'amateur' ? 'white' : '[#181818]'}`}>Age</h3>
                <h3 className={`uppercase text-[10px] font-extrabold leading-normal whitespace-nowrap text-${data.level === 'amateur' ? 'white' : '[#181818]'}`}>{calculateAge(data.birth_year)}</h3>
            </div>
            {/* <h3 className={`absolute uppercase text-[10px] font-extrabold leading-normal whitespace-nowrap domicili text-${!data.color_code ? 'white' : data.color_code}`}>{data.domisili}</h3> */}
            <h3 className={`absolute uppercase text-[10px] font-extrabold leading-normal whitespace-nowrap domicili text-${data.level === 'amateur' ? 'white' : '[#181818]'}`}>{data.domisili}</h3>
        </div>
    )
}

export default observer(AmateurCard)