import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify';
import InputPassword from "../../app/component/input/InputPassword";
import { IAccountChangePassword } from "../../app/models/account";
import { useStore } from "../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import AlertTopBlue from "../../app/component/alerts/AlertTopBlue";
import { Tooltip, Typography } from "@material-tailwind/react";

const initValue: IAccountChangePassword = {
    old_password: '-',
    new_password: '------',
    new_password_confirmation: '------'
}

function SettingPassword() {
    const { accountStore, notificationStore } = useStore();
    const navigate = useNavigate();
    const [password, setPassword] = useState<IAccountChangePassword>(initValue);
    const [success, setSuccess] = useState(false);
    const { slug } = useParams<{ slug: string }>();

    const handleOldPassword = (value: string) => {
        setPassword(prevState => ({ ...prevState, old_password: value }));
    }

    const handleNewPassword = (value: string) => {
        setPassword(prevState => ({ ...prevState, new_password: value }));
    }

    const handleNewPasswordConfirmation = (value: string) => {
        setPassword(prevState => ({ ...prevState, new_password_confirmation: value }));
    }

    const handleSubmit = () => {
        if (password) {
            accountStore.changePassword(password).then(() => {
                notificationStore.setNotification('Password berhasil diubah')
                showToast()
                setSuccess(true)
                navigate(`/profile/${String(slug)}`)
            })
        }
    }

    const showToast = () => {
        toast(<AlertTopBlue onOpen={true} />, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <div className='w-full h-full lg:px-20'>
            <React.Fragment>
                <div className='w-full max-w-[762px] mx-auto flex flex-col'>
                    <h1 className="text-white text-center font-extrabold text-[32px] mb-[14px] lg:text-[32px] leading-normal">Setting Password</h1>
                    <div className="bg-transparent lg:bg-nav1 lg:rounded-3xl block lg:px-4 lg:py-6">
                        <div className="bg-nav1 rounded-xl lg:bg-transparent py-6 px-4 lg:p-0 mb-6">
                            <div className="flex flex-row items-center">
                                <label htmlFor="old-password" className="text-white text-xs font-medium leading-6 mr-[6px]">Old Password</label>
                                <Tooltip
                                    content={
                                        <div className="w-full">
                                            <Typography
                                                variant="small"
                                                color="white"
                                                className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                            >
                                                Masukkan Password Lama Anda
                                            </Typography>
                                        </div>
                                    }
                                    placement="right-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <InputPassword
                                label=""
                                bgColor="bg-nav1"
                                labelColor=""
                                textColor="text-white"
                                border="border-darkgray"
                                px="px-[19px]"
                                py="py-[13px]"
                                mt="mt-2"
                                mb="mb-3"
                                leading="leading-normal"
                                placeholder="Old Password"
                                onChange={(value) => handleOldPassword(value)}></InputPassword>
                            <div className="flex flex-row items-center">
                                <label htmlFor="new-password" className="text-white text-xs font-medium leading-6 mr-[6px]">New Password</label>
                                <Tooltip
                                    content={
                                        <div className="w-full">
                                            <Typography
                                                variant="small"
                                                color="white"
                                                className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                            >
                                                Masukkan Password Lama Anda
                                            </Typography>
                                        </div>
                                    }
                                    placement="right-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <InputPassword
                                label=""
                                bgColor="bg-nav1"
                                labelColor=""
                                textColor="text-white"
                                border="border-darkgray"
                                px="px-[19px]"
                                py="py-[13px]"
                                mt="mt-2"
                                mb="mb-3"
                                leading="leading-normal"
                                placeholder="New Password"
                                onChange={(value) => handleNewPassword(value)}></InputPassword>
                            <div className="flex flex-row items-center">
                                <label htmlFor="confirm-password" className="text-white text-xs font-medium leading-6 mr-[6px]">Confirm Password</label>
                                <Tooltip
                                    content={
                                        <div className="w-full">
                                            <Typography
                                                variant="small"
                                                color="white"
                                                className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                            >
                                                Masukkan Password Lama Anda
                                            </Typography>
                                        </div>
                                    }
                                    placement="right-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <InputPassword
                                label=""
                                bgColor="bg-nav1"
                                labelColor=""
                                textColor="text-white"
                                border="border-darkgray"
                                px="px-[19px]"
                                py="py-[13px]"
                                mt="mt-2"
                                mb="mb-3"
                                leading="leading-normal"
                                placeholder="Confirm Password"
                                onChange={(value) => handleNewPasswordConfirmation(value)}></InputPassword>
                            {success ? <span className='text-[0.8vw] text-green-500 mb-4'>Password Changed</span> : <></>}
                            <button
                                onClick={handleSubmit}
                                disabled={
                                    !password.new_password ||
                                    password.new_password_confirmation === '' ||
                                    password.new_password !== password.new_password_confirmation
                                }
                                className="bg-secondary text-white w-full h-[50px] mt-3 rounded-md text-sm font-semibold text-center leading-normal disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-[#7A8697]">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
}

export default observer(SettingPassword);
