import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { useEffect, useState } from "react";

interface ModalProps {
    onOpen: boolean;
}
const BlueTopAlert: React.FC<ModalProps> = ({ onOpen }) => {
    const { notificationStore } = useStore();

    const [showAlert, setShowAlert] = useState(false);
    const [nText, setNText] = useState('');

    useEffect(() => {
        setNText(notificationStore.text)
        setShowAlert(onOpen)
        setTimeout(() => {
            closeAlert()
        }, 2000);
    }, [])

    const closeAlert = () => {
        notificationStore.removeNotification()
        setShowAlert(false)
    }
    if (!notificationStore.text) {
        return null
    }

    if (!showAlert) {
        return null;
    }

    return (
        <div className="w-full">

            <div className="w-full h-full flex lg:space-x-2 justify-between bg-secondary rounded-md mt-2 px-5 py-2">
                <div className="flex flex-row items-center">
                    <img src="/assets/images/iconoir_app-notification.svg" alt="" className="mx-1" />
                    <span className="text-start lg:pl-0 text-[0.75rem] lg:text-[0.75rem] font-semibold text-white mx-2">
                        {notificationStore.text}
                    </span>
                </div>
                <img src="/assets/images/close-white.svg" alt="" className="mx-1 cursor-pointer" onClick={closeAlert} />
            </div>
        </div>
    );
}

export default observer(BlueTopAlert);
