import React from "react";
import { observer } from "mobx-react-lite";
import { ITournamentReward } from "../../../app/models/tournament";

interface rewardProps {
    data: ITournamentReward[] | null;
}
const Reward: React.FC<rewardProps> = ({ data }) => {
    return (
        <React.Fragment>
            <div className="w-full h-full py-4">
                <h4 className='text-white font-bold text-lg py-3'>Rewards</h4>
                <div className="flex flex-row items-stretch flex-wrap">
                    {!data ? (
                        <div>Data Rewards tidak ditemukan</div>
                    )
                        :
                        (data?.map((item, idx) => (
                            !item.image ?
                                <div className="card shadow-xl bg-[#1C1F2A] text-white w-[160px] h-auto p-2 flex flex-col justify-center items-center mr-2 mb-2">
                                    <ul className="flex flex-col items-center justify-center text-center list-disc list-inside">
                                        {item.desc_1 ? <li>{item.desc_1}</li> : <></>}
                                        {item.desc_2 ? <li>{item.desc_2}</li> : <></>}
                                        {item.desc_3 ? <li>{item.desc_3}</li> : <></>}
                                    </ul>
                                </div>
                                :
                                <div className="card shadow-xl bg-[#1C1F2A] text-white w-[160px] h-auto p-2 flex flex-col justify-center items-center mr-2 mb-2" key={idx}>
                                    <img className="w-[58px] h-[94px] mx-auto mb-1" src={`${process.env.REACT_APP_STORAGE_URL}${item?.image}`} alt="Rewards" />
                                    <ul className="flex flex-col items-center justify-center text-center list-disc list-inside">
                                        {item.desc_1 ? <li>{item.desc_1}</li> : <></>}
                                        {item.desc_2 ? <li>{item.desc_2}</li> : <></>}
                                        {item.desc_3 ? <li>{item.desc_3}</li> : <></>}
                                    </ul>
                                </div>
                        )))
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default observer(Reward);
