import { observer } from "mobx-react-lite"
// import '../../../index.css'
import { calculateAge } from "../../config/enum";
import { IProfileDetail } from "../../models/profile";

interface MatchPlayerCardProps {
    player: string;
    data: IProfileDetail;
}

const renderLevelTag = (level: string) => {
    if (level === 'amateur') {
        return <img className="w-full" src="/assets/images/Amateur.png" alt=""></img>
    } else if (level === 'semi') {
        return <img className="w-full" src="/assets/images/Semi.png" alt=""></img>
    } else {
        return <img className="w-full" src="/assets/images/Pro.png" alt=""></img>
    }
};


const userLevel = (level: string) => {
    if (level === 'amateur') {
        return "/assets/images/siluet-amateur.png";
    } else if (level === 'semi') {
        return "/assets/images/siluet-semi.png";
    } else {
        return "/assets/images/siluet-pro.png";
    }
};

const MatchPlayerCard: React.FC<MatchPlayerCardProps> = ({ player, data }) => {

    return (
        <div className="w-full relative">
            {/* <div className="flex flex-col items-start absolute top-[1px] left-1">
                <h6 style={{ fontSize: '2px' }} className={`font-extrabold leading-normal whitespace-nowrap text-${!data.color_code ? 'white' : data.color_code}`}>{data.game}</h6>
                <h6 style={{ fontSize: '2px', lineHeight: '0' }} className={`uppercase font-extrabold leading-normal whitespace-nowrap text-${!data.color_code ? 'white' : data.color_code}`}>{data.platform}</h6>
            </div>
            <img className="absolute top-[1px] right-[3px] w-[6px]" src="/assets/images/efootball-logo.png" alt="" /> */}
            {renderLevelTag(player ? player : '')}
            {/* <img className="w-[18px] top-[6px] absolute left-1/2 transform -translate-x-1/2 lg:w-[27px] lg:top-[2px]" src={`${process.env.REACT_APP_STORAGE_URL}${data.card}`} alt="" /> */}
            <img className="w-[18px] top-[6px] absolute left-1/2 transform -translate-x-1/2 lg:w-[27px] lg:top-[2px]" src={data.card ? `${process.env.REACT_APP_STORAGE_URL}${data.card}` : userLevel(player ? player : '')} alt="" />

            {/* <h6 style={{ fontSize: '3px' }} className={`absolute bottom-[5px] left-1/2 transform -translate-x-1/2 uppercase font-extrabold leading-normal text-center whitespace-nowrap text-${!data.color_code ? 'white' : data.color_code}`}>{data.username}</h6>
            <div className="flex flex-row items-center absolute left-[3px] bottom-[2px]">
                <h6 style={{ fontSize: '2px' }} className={`uppercase font-medium leading-normal whitespace-nowrap mr-[1px] text-${!data.color_code ? 'white' : data.color_code}`}>Age</h6>
                <h6 style={{ fontSize: '2px' }} className={`uppercase font-extrabold leading-normal whitespace-nowrap text-${!data.color_code ? 'white' : data.color_code}`}>{calculateAge(data.birth_year)}</h6>
            </div>
            <h6 style={{ fontSize: '2px' }} className={`absolute uppercase font-extrabold leading-normal whitespace-nowrap right-[2px] bottom-[2px] text-${!data.color_code ? 'white' : data.color_code} z-10`}>{data.domisili}</h6> */}
        </div>
    )
}

export default observer(MatchPlayerCard)