import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStore } from '../../stores/store';
import Countdown from '../countdown/Countdown';
import { formatRupiah, registrationType, tournamentMode } from '../../config/enum';
import '../../../../src/index.css'
import { useNavigate } from 'react-router-dom';

const TournamentTrendingCard = () => {

    const { TournamentStore } = useStore();
    const navigate = useNavigate()
    useEffect(() => {
        TournamentStore.getTournamentList();
    }, [TournamentStore]);

    const toTournament = (val: string) => {
        navigate(`/tournament/${val}`)
    }
    return (
        <div>
            {
                TournamentStore.data.length > 0 ?
                    <div className="flex flex-row items-center justify-between mb-2 px-4 md:pl-0 lg:justify-normal">
                        <h3 className="text-white font-bold text-xl leading-normal mr-2">
                            Tournament Trending
                        </h3>
                        {/* <svg
                            className="hidden lg:block"
                            xmlns="http://www.sw3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                        >
                            <path
                                d="M4.96199 13.4667C4.78623 13.2909 4.6875 13.0525 4.6875 12.8039C4.6875 12.5553 4.78623 12.3169 4.96199 12.1411L9.60261 7.50047L4.96199 2.85985C4.79121 2.68303 4.69672 2.44622 4.69885 2.20041C4.70099 1.9546 4.79959 1.71946 4.97341 1.54564C5.14723 1.37182 5.38236 1.27323 5.62817 1.27109C5.87398 1.26896 6.1108 1.36345 6.28761 1.53422L11.5911 6.83766C11.7668 7.01347 11.8655 7.25188 11.8655 7.50047C11.8655 7.74907 11.7668 7.98748 11.5911 8.16329L6.28761 13.4667C6.1118 13.6425 5.87339 13.7412 5.6248 13.7412C5.37621 13.7412 5.13779 13.6425 4.96199 13.4667Z"
                                fill="#565D6B"
                            />
                        </svg>
                        <p className="text-secondary text-sm font-semibold leading-normal text-right lg:hidden">
                            View All
                        </p> */}
                    </div>
                : <></>
            }
            
            <div className="w-full flex flex-row pb-8 items-start overflow-x-scroll scrollbar-hidden lg:overflow-hidden tournament-list">
                {TournamentStore.data?.map((tournament, idx) => (
                    <a key={idx} className={`block w-[258px] lg:w-1/4 mr-[14px]`} href={`/tournament/${tournament.slug}`}>
                        <div
                            className="card card-compact h-full shadow-xl rounded-lg border-none w-[258px] lg:w-full lg:ml-0 rounded-tl-[10px] rounded-tr-[10px]">
                            <div className={`${new Date() > new Date(tournament.close_date) ? "overlay-closed" : " "}`}></div>
                            <figure className="max-w-lg">
                                <img
                                    src={tournament.flag}
                                    alt="banner-tournament"
                                    className="w-full h-[26.4vw] lg:h-[5vw] object-cover transform hover:scale-125 transition duration-500 ease-in-out m-auto rounded-tl-[10px] rounded-tr-[10px]"
                                />
                            </figure>
                            <div className="text-white border-none w-full bg-nav1 px-3 py-2">
                                <span className="flex items-center text-sm text-white font-bold leading-normal">
                                    {tournament.name}
                                </span>
                                <div className="flex flex-row flex-wrap mt-2">
                                    <div className="badge badge-card-blue rounded  text-white text-xs px-2 mb-1 mr-1">
                                        {tournament.game}
                                    </div>
                                    <div className="badge badge-card-blue rounded  text-white text-xs px-2 mb-1 mr-1">
                                        {tournamentMode(tournament.mode)}
                                    </div>
                                    <div className="badge badge-card-blue rounded  text-white text-xs px-2 mb-1 mr-1">
                                        {tournament.team_type}
                                    </div>
                                    <div className="badge badge-card-green rounded text-white text-xs px-2 mb-1">
                                        {registrationType(tournament.registration_type)}
                                    </div>
                                </div>
                                <div className="flex mt-3 mb-2">
                                    <div className="mr-5">
                                        <figure>
                                            <img src="/assets/images/Prizepool.png" alt="Prizepool" />
                                        </figure>
                                    </div>
                                    <div className="font-extrabold">
                                        {formatRupiah(tournament.grand_prize)}
                                    </div>
                                </div>
                                <div className="flex mb-2">
                                    <div className="mr-5">
                                        <figure>
                                            <img src="/assets/images/Slots.png" alt="slots" />
                                        </figure>
                                    </div>
                                    <div className="font-medium">
                                        {tournament.registered}/{tournament.open_slot}
                                    </div>
                                </div>
                                <div className="flex mb-2">
                                    <div className="mr-5">
                                        <figure>
                                            <img src="/assets/images/Calendar.png" alt="calendar" />
                                        </figure>
                                    </div>
                                    <div className="font-medium">{tournament.range_date}</div>
                                </div>
                            </div>
                            <div className='bg-[#1D2335] flex flex-row items-start justify-between py-2 px-3 rounded-b-xl'>
                                {new Date() > new Date(tournament.close_date) ? <></> :
                                    <div>
                                        <div className='text-darkgray text-[8px] font-medium'>Pendaftaran ditutup</div>
                                        <Countdown targetDate={new Date(tournament.close_date)} />
                                    </div>
                                }
                                {new Date() > new Date(tournament.close_date) ?
                                    <button
                                        key={tournament.id}
                                        className="text-darkgray text-[8px] font-medium"
                                        disabled
                                    >
                                        Pendaftaran Ditutup
                                    </button>
                                    :
                                    <button
                                        key={tournament.id}
                                        className="text-xs bg-secondary text-white font-semibold hover:bg-nav2 hover:text-secondary hover:ç px-4 py-[6px] rounded-md disabled:"
                                        onClick={() => toTournament(tournament.slug)}
                                    >
                                        View
                                    </button>
                                }
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div >
    );
}

export default observer(TournamentTrendingCard);
