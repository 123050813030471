import React from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../config/enum';
import { IMatchesInfo } from '../../models/matches'

interface ScheduleProps {
    data: IMatchesInfo
  }
function ScheduleCard(props: ScheduleProps) {

    const { data } = props;

    const navigate = useNavigate();

    const [show, setShow] = React.useState(data);
    
    const handleSelecteSchedule = (id: number) => {
        if(id ===  data.id) {
            setShow(data);
        } else {
            data.another_match?.map(item => {
                if(item.id === id) {
                    return setShow(item);
                } else {
                    return null;
                }
            })
        }
    };

  return (
    <React.Fragment>
        <div className='w-full h-full bg-nav1 flex flex-col justify-between items-center px-4'>
            <div className='w-full h-full flex flex-row justify-center items-center px-4'>
                <select className="py-0 lg:pl-2 lg:py-1 w-[28%] lg:w-[10%] lg:max-w-xs lg:h-[50%] text-gray-500 text-[1.8vw] lg:text-[0.8vw] bg-nav1 border-gray-700 border-1 active:border-none rounded-sm" onChange={(e) => handleSelecteSchedule(Number(e.target.value))}>
                    <option className='border-none' value={data.id}>Match 1</option>
                    {data.another_match?.map((item, idx) => (
                        <option className='border-none' key={idx} value={item.id}>Match {item.match_day}</option>
                    ))}
                </select>
                <div className='w-[80%] flex flex-col justify-center items-center'>
                    <div className='w-full h-full flex flex-row justify-center items-center'>
                        <div className='w-[46%] flex flex-row justify-center items-center py-2'> 
                            <div id='home' className='flex flex-row w-full h-full justify-center items-center'>
                                <div className='w-[75%] flex flex-col text-end font-semibold justify-end mr-5'>
                                    <span className='text-white text-[2.8vw] lg:text-[1vw]'>{show.home.name}</span>
                                    <span className='text-white text-[2.5vw] lg:text-[0.7vw] font-normal'>{show.homePlayer.name}</span>
                                </div>
                                <div className='text-end w-[15%] h-[5vw] flex flex-row justify-end items-center'>
                                    <div className='lg:px-1 w-[12vw] lg:w-[5vw] lg:h-[3.5vw]'>
                                        <img src={show.home.flag} alt='logo' className='w-full h-full object-contain' />
                                    </div>
                                </div>
                                <div className='w-[10%] text-end items-center flex flex-row justify-end'>
                                    <span className='text-white font-semibold text-[2.8vw] lg:text-[1vw]'>{show.result?.home_score ?? ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className='w-[8%] flex flex-row justify-center items-center text-gray-600 font-bold text-[1.2vw]'>
                            {show.result === null ? (<span>VS</span>) : (<span>-</span>)}
                        </div>
                        <div className='w-[46%] flex flex-row justify-center items-center py-2'>
                            <div id='home' className='flex flex-row w-full h-full justify-center items-center'>
                                <div className='w-[10%] text-end items-center flex flex-row justify-start'>
                                    <span className='text-white font-semibold text-[2.8vw] lg:text-[1vw]'>{show.result?.away_score ?? ''}</span>
                                </div>
                                <div className='text-end w-[15%] h-full flex flex-row justify-start items-center'>
                                <div className='lg:px-1 w-[12vw] lg:w-[5vw] lg:h-[3.5vw]'>
                                        <img src={show.away.flag} alt='logo' className='w-full h-full object-contain' />
                                    </div>
                                </div>
                                <div className='w-[75%] flex flex-col text-start font-semibold justify-start ml-4'>
                                    <span className='text-white text-[2.8vw] lg:text-[1vw]'>{show.away.name}</span>
                                    <span className='text-white text-[2.5vw] lg:text-[0.7vw] font-normal'>{show.awayPlayer.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-full text-gray-600 text-[0.6vw] text-center pb-2'> 
                    <span>{show.qualification} / {formatDate(show.match_date.toString())} / {show.description}</span>
                    </div>
                </div>
                <div className='w-[28%] lg:w-[10%] lg:max-w-xs lg:h-[50%] text-white flex flex-row justify-end cursor-pointer' onClick={() => {navigate(`match/${data.id}`)}}>         
                    <svg width="10" height="16" viewBox="0 0 10 16" className='fill-current' xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.933365 15.6002C1.03013 15.6972 1.14507 15.7742 1.27163 15.8267C1.39818 15.8792 1.53385 15.9062 1.67086 15.9062C1.80788 15.9062 1.94355 15.8792 2.0701 15.8267C2.19665 15.7742 2.3116 15.6972 2.40836 15.6002L9.4167 8.59189C9.49395 8.51479 9.55524 8.42322 9.59706 8.32241C9.63888 8.2216 9.6604 8.11353 9.6604 8.00439C9.6604 7.89525 9.63888 7.78718 9.59706 7.68637C9.55524 7.58556 9.49395 7.49398 9.4167 7.41689L2.40837 0.408554C2.00003 0.000220583 1.3417 0.000220525 0.933366 0.408554C0.525033 0.816887 0.525033 1.47522 0.933366 1.88355L7.05003 8.00022L0.925032 14.1252C0.525032 14.5336 0.525032 15.1919 0.933365 15.6002Z"/>
                    </svg>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default ScheduleCard