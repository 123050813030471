import './skeleton.css';

interface SkeletonProps {
  count: number;
  width: string;
  height: string;
  borderRadius: string;
  marginBottom?: string;
}

const SkeletonComponent: React.FC<SkeletonProps> = ({
  count = 1,
  width = 'w-full',
  height,
  borderRadius,
  marginBottom
}) => {

  const skeletons = Array.from({ length: count }).map((_, index) => (
    <div
      key={index}
      className={`skeleton animate-pulse ${width} ${height} ${borderRadius} ${marginBottom}`}
    />
  ));

  return <>{skeletons}</>;

};

export default SkeletonComponent;
