import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { IAccountRegisterRequest } from '../../app/models/account';
import { useStore } from '../../app/stores/store';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import InputPassword from '../../app/component/input/InputPassword';

const initialValuesRegister: IAccountRegisterRequest = {
  name: '-',
  email: '-',
  phone: '-',
  password: '-',
  password_confirmation: '-',
};

const RegisterAccount = () => {
  const { Title } = Typography;

  const { accountStore } = useStore();

  const [valueRegister, setValueRegister] = useState<IAccountRegisterRequest>(
    initialValuesRegister
  );

  const [validateEmail, setValidateEmail] = useState(false);

  const handleInputEmail = (value: string) => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
    setValueRegister((prevState) => ({ ...prevState, email: value }));
  };

  const handleInputName = (value: string) => {
    setValueRegister((prevState) => ({ ...prevState, name: value }));
  };

  const handleInputPhone = (value: string) => {
    setValueRegister((prevState) => ({ ...prevState, phone: value }));
  };

  const handleInputPassword = (value: string) => {
    setValueRegister((prevState) => ({ ...prevState, password: value }));
  };

  const handleInputConfirmationPassword = (value: string) => {
    setValueRegister((prevState) => ({
      ...prevState,
      password_confirmation: value,
    }));
  };

  const handleSubmit = () => {
    accountStore.register(valueRegister);
  };

  if (accountStore.isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="w-full h-[100wh] px-0 lg:px-40 py-0 lg:py-12 bg-bg-home bg-primary bg-no-repeat bg-cover font-sans">
      <style>
        {`
        .bg-pass input {
          background: #1C1F2A;
          color: white;
        }
        `}
      </style>
      <div className="w-full h-[620px] flex flex-row bg-primary lg:rounded-xl">
        <div className="relative hidden lg:w-6/12 bg-bg-box-login bg-secondary bg-no-repeat bg-cover rounded-l-lg px-12 pt-20 lg:flex flex-col">
          <div className="text-white">
            <h1 className="font-extrabold text-[58px]">WELCOME</h1>
            <p className="font-bold text-2xl">
              Welcome to Indonesian Football e-League! Receive timely updates,
              news, and information to keep your finger on the pulse of virtual
              football Indonesia
            </p>
          </div>
          <div className="absolute bottom-8 text-white w-3/5">
            <p className="text-sm">
              After registering, you’ll receive a confirmation email in your
              inbox with a link to activate your account!
            </p>
          </div>
        </div>
        <div className="w-full lg:w-6/12 pt-20 flex flex-col  px-6 lg:px-16">
          <div className="w-full">
            <Title
              level={3}
              style={{ color: '#ffffff', fontSize: '28px', fontWeight: 'bold' }}
            >
              Register to IFel
            </Title>
            <p style={{ color: 'gray', marginBottom: 25 }}>
              Already a member?{' '}
              <a href="/login" className="text-secondary">
                Login
              </a>
            </p>
          </div>
          <div className="w-full h-full flex flex-col mt-4 space-y-2">
            <div>
              <div className="relative w-full">
                <input
                  type="text"
                  id="small_outlined"
                  className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav1 rounded-lg appearance-none dark:text-white peer"
                  placeholder=" "
                  onChange={(e) => handleInputName(e.target.value)}
                />
                <label
                  htmlFor="small_outlined"
                  className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-600 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav1 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1"
                >
                  Full Name
                </label>
              </div>
              {(valueRegister.name === null || valueRegister.name === '') && (
                <p
                  id="outlined_error_help"
                  className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"
                >
                  <span className="font-medium">Nama</span> tidak boleh kosong!
                </p>
              )}
            </div>
            <div>
              <div className="relative w-full">
                <input
                  type="text"
                  id="small_outlined2"
                  className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav1 rounded-lg appearance-none dark:text-white peer"
                  placeholder=" "
                  onChange={(e) => handleInputEmail(e.target.value)}
                />
                <label
                  htmlFor="small_outlined"
                  className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-600 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav1 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1"
                >
                  Email Address
                </label>
              </div>
              {valueRegister.email === null || valueRegister.email === '' ? (
                <p
                  id="outlined_error_help"
                  className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"
                >
                  <span className="font-medium">Email</span> tidak boleh kosong!
                </p>
              ) : valueRegister.email !== '-' && validateEmail === false ? (
                <p
                  id="outlined_error_help"
                  className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"
                >
                  <span className="font-medium">Email</span> tidak sesuai
                  format!
                </p>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div className="relative w-full">
                <input
                  type="text"
                  id="small_outlined3"
                  className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav1 rounded-lg appearance-none dark:text-white peer"
                  placeholder=" "
                  onChange={(e) => handleInputPhone(e.target.value)}
                />
                <label
                  htmlFor="small_outlined"
                  className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-600 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav1 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1"
                >
                  Phone Number
                </label>
              </div>
              {(valueRegister.phone === null || valueRegister.phone === '') && (
                <p
                  id="outlined_error_help"
                  className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"
                >
                  <span className="font-medium">Phone Number</span> tidak boleh
                  kosong!
                </p>
              )}
            </div>
            <div>
              <InputPassword label='Password' bgColor='bg-nav1' placeholder=' ' labelColor='text-gray-600' border='border-none' px='px-2.5' py='py-3' mt='mt-0' mb='mb-0' leading='leading-normal' onChange={(value) => handleInputPassword(value)} textColor='text-white'/>
              {(valueRegister.password === null || valueRegister.password === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Password</span> tidak boleh kosong!</p> }
            </div>
            <div>
              <InputPassword label='Confirmation Password' bgColor='bg-nav1' placeholder=' ' labelColor='text-gray-600' border='border-none' px='px-2.5' py='py-3' mt='mt-0' mb='mb-0' leading='leading-normal' onChange={(value) => handleInputConfirmationPassword(value)} textColor='text-white'/>
              {(valueRegister.password !== valueRegister.password_confirmation) && <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Password</span> tidak sama!</p> }
            </div>
            <div className="w-full h-full text-left pt-2">
              {!accountStore.loadingRegister ? (
                <button
                  onClick={() => handleSubmit()}
                  disabled={
                    !valueRegister.email ||
                    validateEmail === false ||
                    !valueRegister.name ||
                    !valueRegister.phone ||
                    !valueRegister.password_confirmation ||
                    valueRegister.password === ''
                  }
                  className={`uppercase px-6 lg:px-20 py-3 disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-gray-700 text-white font-semibold bg-secondary border-none rounded-md text-[2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}
                >
                  Create an account
                </button>
              ) : (
                <button
                  onClick={() => handleSubmit()}
                  disabled={true}
                  className={`loading uppercase px-6 lg:px-20 py-3 disabled:bg-secondary disabled:cursor-not-allowed disabled:text-white text-white font-semibold bg-secondary border-none rounded-md text-[2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}
                >
                  Please Wait
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(RegisterAccount);
