import { observer } from "mobx-react-lite";
import { useState } from "react";
import Edit from "../modal/Edit"
import { ITournamentPlayerStat } from "../../models/tournament";
import OptionSetting from "../modal/OptionSetting";
import SkeletonComponent from "../skeleton/SkeletonComponent";

interface statProps {
    data: ITournamentPlayerStat | null;
}

const ClubCard: React.FC<statProps> = ({ data }) => {
    const [isEditModalOpen, setisEditModalOpen] = useState(false);
    const [isModalOptionSetting, setIsModalOptionSetting] = useState(false);

    if (isEditModalOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'visible';
    }

    const onOpenOptionSetting = () => {
        setIsModalOptionSetting(true)
    }

    const onClose = () => {
        setIsModalOptionSetting(false)
    }

    return (
        <div className="w-full flex flex-row items-center justify-between px-6 py-[33px] lg:px-8 bg-nav1 mb-2 rounded-md">
            <div className="clan-info lg:w-[50%] flex flex-row items-center">
                <div className='w-[66px] h-[66px] mr-[44px] flex flex-row justify-center items-center'>
                    <img src="/assets/images/efootball-logo.png" alt='logo' className='w-full h-full object-contain' />
                </div>
                <div className='hidden lg:px-2 lg:w-[80%] lg:flex flex-col justify-start items-start text-start'>
                    <h4 className="text-[18px] text-white font-black leading-normal">eFOOTBALL</h4>
                    <p className="text-sm text-darkgray font-medium leading-normal">Mobile</p>
                </div>
            </div>
            <div className="lg:w-[50%] flex flex-row justify-end">
                {data ? (
                    <div className="w-full max-w-[240px] flex flex-row items-center justify-between">
                        <div className="flex flex-col items-center px-2 w-[25%]">
                            <h4 className="text-[18px] text-white font-semibold leading-normal">{data?.match}</h4>
                            <p className="text-[10px] text-darkgray font-medium leading-normal">Matches</p>
                        </div>
                        <div className="flex flex-col items-center px-2 w-[25%]">
                            <h4 className="text-[18px] text-white font-semibold leading-normal">{data?.win}</h4>
                            <p className="text-[10px] text-darkgray font-medium leading-normal">Wins</p>
                        </div>
                        <div className="flex flex-col items-center px-2 w-[25%]">
                            <h4 className="text-[18px] text-white font-semibold leading-normal">{data?.lose}</h4>
                            <p className="text-[10px] text-darkgray font-medium leading-normal">Losses</p>
                        </div>
                        <div className="flex flex-col items-center px-2 w-[25%]">
                            <h4 className="text-[18px] text-white font-semibold leading-normal">{data?.point}</h4>
                            <p className="text-[10px] text-darkgray font-medium leading-normal">Points</p>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-row items-center gap-2">
                        <SkeletonComponent
                            count={4}
                            width='w-12'
                            height='h-12'
                            borderRadius='rounded-md'
                        />
                    </div>
                )
                }
            </div>


            {/* {isModalOptionSetting && (
                <OptionSetting
                    onOpenOptionSetting={isModalOptionSetting}
                    onClose={onClose}
                />
            )} */}
        </div>
    );
}

export default observer(ClubCard);
