import React, { useEffect, useState } from 'react';
import { useStore } from '../../stores/store';
import { IAccountOnboarding } from '../../models/account';
import { IHistoryInput } from '../../models/team';
import { contractDate } from '../../config/enum';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertTopBlue from '../alerts/AlertTopBlue';

interface ModalProps {
    onOpenHistoryModal: boolean;
    closeModal: () => void;
    slug: string
}

const initialHistoryInput: IHistoryInput = {
    team: null,
    image: null,
    date_start: "1",
    month_start: "January",
    year_start: "2023",
    date_end: "1",
    month_end: "January",
    year_end: "2023",
    start_contract: '-',
    end_contract: '-',
    current_team: false,
    type: false,
    slug: null,
    team_id: null
};

const initialSelectedTeam = {
    selected: false,
    flag: '',
    name: '',
    team_id: 0
};


const History: React.FC<ModalProps> = ({ onOpenHistoryModal, closeModal, slug }) => {
    const [showEndDatePicker, setShowEndDatePicker] = useState(false);
    const [active, setActive] = useState('');
    const [endError, setEndError] = useState('');
    const [teamError, setTeamError] = useState('');
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const { clubStore, notificationStore } = useStore();
    const [showSearch, setShowSearch] = useState(true);
    const [formValidation, setFormValidation] = useState(true);
    const [loading, setLoading] = useState(false);
    const [valStartDate, setValStartDate] = useState('1');
    const [valStartMonth, setValStartMonth] = useState('January');
    const [valStartYear, setValStartYear] = useState('2023');
    const [show, setShow] = useState('');

    const [valueHistory, setValueHistory] = useState<IHistoryInput>(
        initialHistoryInput
    );

    const [valueSelectedTeam, setValueSelectedTeam] =
        useState(initialSelectedTeam);


    useEffect(() => {
        setValueHistory((prevState) => ({
            ...prevState,
            slug: slug,
            start_contract: contractDate(valueHistory.month_start ? valueHistory.month_start : valStartMonth, valueHistory.year_start ? valueHistory.year_start : valStartYear)
        }));
    }, [])

    const handleEndDateClick = (value: boolean) => {
        setShowEndDatePicker(value);
        if (!value) {
            setActive('current')
            setValueHistory((prevState) => ({ ...prevState, end_contract: '-' }))
            setValueHistory((prevState) => ({ ...prevState, current_team: true }))
        } else {
            setActive('history')
            setValueHistory((prevState) => ({ ...prevState, end_contract: contractDate(valStartMonth, valStartYear) }))
            setValueHistory((prevState) => ({ ...prevState, current_team: false }))
        }
        setEndError('')
    };

    const handleChooseTeam = (event: React.MouseEvent<HTMLDivElement>) => {
        const selectedFlag = event.currentTarget.getAttribute('data-flag');
        const selectedName = event.currentTarget.getAttribute('data-name');
        const selectedId = event.currentTarget.getAttribute('data-value');
        setValueHistory((prevState) => ({ ...prevState, slug: String(slug) }))

        if (selectedFlag) {
            setValueSelectedTeam((prevState) => ({
                ...prevState,
                flag: selectedFlag,
            }));
            setValueHistory((prevState) => ({
                ...prevState,
                image: selectedFlag,
            }));
        }
        if (selectedName) {
            setValueSelectedTeam((prevState) => ({
                ...prevState,
                name: selectedName,
            }));
            setValueHistory((prevState) => ({
                ...prevState,
                team: selectedName,
            }));
        }
        if (selectedId) {
            setValueSelectedTeam((prevState) => ({
                ...prevState,
                team_id: Number(selectedId),
            }));
            setValueHistory((prevState) => ({
                ...prevState,
                team_id: Number(selectedId),
            }));
        }
        setValueSelectedTeam((prevState) => ({
            ...prevState,
            selected: true,
        }));
        console.log(valueSelectedTeam);

        setShowSearch(false)
        setSearchValue(null)
        setTeamError('')
    };

    const removeSelectedTeam = () => {
        setValueSelectedTeam((prevState) => ({
            ...prevState,
            selected: false,
        }));
        setShowSearch(true);
        setSearchValue(null)
    }

    const handleInputSearch = (value: string) => {
        setSearchValue(value)
        setTimeout(() => {
            clubStore.searchTeam(value);
        }, 300);
    };

    const handleInputStartDate = (value: string) => {
        setValueHistory((prevState) => ({
            ...prevState,
            date_start: value,
        }));
        setValueHistory((prevState) => ({ ...prevState, start_contract: contractDate(valueHistory.month_start ? valueHistory.month_start : valStartMonth, valueHistory.year_start ? valueHistory.year_start : valStartYear) }))
    }

    const handleInputStartMonth = (value: string) => {
        setValueHistory((prevState) => ({
            ...prevState,
            month_start: value,
        }));
        setValueHistory((prevState) => ({ ...prevState, start_contract: contractDate(value, valueHistory.year_start ? valueHistory.year_start : valStartYear) }))
    }

    const handleInputStartYear = (value: string) => {
        setValueHistory((prevState) => ({
            ...prevState,
            year_start: value,
        }));
        setValueHistory((prevState) => ({ ...prevState, start_contract: contractDate(valueHistory.month_start ? valueHistory.month_start : valStartMonth, value) }))

    }

    const handleInputEndDate = (value: string) => {
        setValueHistory((prevState) => ({
            ...prevState,
            date_end: value,
        }));
        setValueHistory((prevState) => ({ ...prevState, end_contract: contractDate(valueHistory.month_end ? valueHistory.month_end : valStartMonth, valueHistory.year_end ? valueHistory.year_end : valStartYear) }))
    }

    const handleInputEndMonth = (value: string) => {
        setValueHistory((prevState) => ({
            ...prevState,
            month_end: value,
        }));
        setValueHistory((prevState) => ({ ...prevState, end_contract: contractDate(value, valueHistory.year_end ? valueHistory.year_end : valStartYear) }))
    }

    const handleInputEndYear = (value: string) => {
        setValueHistory((prevState) => ({
            ...prevState,
            year_end: value,
        }));
        setValueHistory((prevState) => ({ ...prevState, end_contract: contractDate(valueHistory.month_end ? valueHistory.month_end : valStartMonth, value) }))
    }

    const handleSubmitHistory = async () => {
        setLoading(true)
        if (showEndDatePicker) {
            setValueHistory((prevState) => ({ ...prevState, end_contract: contractDate(valueHistory.month_end ? valueHistory.month_end : valStartMonth, valueHistory.year_end ? valueHistory.year_end : valStartYear) }))
        }
        setValueHistory((prevState) => ({ ...prevState, start_contract: contractDate(valueHistory.month_start, valueHistory.year_start) }))

        validation()
        console.log("submit");

        if (formValidation) {
            clubStore.submitHistory(valueHistory)
            await clubStore.listHistory(String(slug)).then(() => {
                setLoading(false)
                closeModal()
                notificationStore.setNotification('Data history Team berhasil ditambahkan!')
                window.location.reload()
            })
        }
        setLoading(false)
    }

    const showToast = () => {
        toast(<AlertTopBlue onOpen={true} />, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    const handleCloseModal = () => {
        setActive('')
        setShowEndDatePicker(false)
        setShowSearch(true)
        closeModal()
    }

    const validation = () => {
        if (!valueHistory.team) {
            setTeamError('Team harus diisi!')
            return setFormValidation(false)
        }
        if (active === '') {
            setEndError('Isi salah satu dari pilihan ini!')
            return setFormValidation(false)
        }

        return setFormValidation(true)

    }

    useEffect(() => {
        clubStore.getTeamLists();
    }, [clubStore]);

    useEffect(() => {
        if (onOpenHistoryModal) {
            setShow('show');
        }
    })

    return (
        <div>
            {onOpenHistoryModal && (
                <div>
                    <div className="modal-overlay" onClick={handleCloseModal}></div>
                    <div className={`modal overflow-y-scroll w-full h-fit mt-[64px] xl:w-[438px] lg:m-auto lg:h-fit lg:max-h-[90vh] ${show}`}>
                        <div className="modal-content p-4 lg:p-5">
                            <div className='flex flex-row items-start mb-[14px]'>
                                <h3 className="text-white flex flex-row mx-auto font-extrabold text-[32px] leading-normal">
                                    History Team
                                </h3>
                                <figure>
                                    <img
                                        src="/assets/images/close.svg"
                                        alt="back"
                                        className="w-6 h-6"
                                        onClick={handleCloseModal}
                                    />
                                </figure>
                            </div>
                            <figure>
                                <img
                                    src="/assets/images/unknown-club.png"
                                    alt="club_img"
                                    className="w-[66px] mx-auto"
                                />
                            </figure>
                            <form>
                                <div className="mb-2 relative">
                                    <label className="text-xs font-medium text-white leading-6">
                                        Team
                                    </label>
                                    {showSearch ? (
                                        <input
                                            type="search"
                                            id="name"
                                            placeholder='Cari nama team'
                                            className='w-full h-[50px] rounded-md bg-transparent border border-solid border-darkgray-600 px-[19px] py-[13px] text-white text-xs font-semibold leading-6 mt-2 mb-3'
                                            onChange={(e) => handleInputSearch(e.target.value)
                                            }
                                        />) : (
                                        <></>
                                    )}
                                    <p className="text-red text-[8px] text-left font-semibold">{teamError}</p>

                                    {searchValue ? (
                                        <div className="flex flex-col items-start w-full bg-[#1C1F2A] rounded-[5px] border border-solid border-darkgray pt-[18px] px-[11px] absolute top-[110%] left-0 z-10">
                                            {clubStore.teamLists?.map((team, idx) => (
                                                <div
                                                    key={idx}
                                                    className="flex flex-row items-center mb-[18px] h-full w-full cursor-pointer"
                                                    data-value={team.id}
                                                    data-flag={team.flag}
                                                    data-name={team.name}
                                                    onClick={handleChooseTeam}
                                                >
                                                    <img
                                                        src={`${process.env.REACT_APP_STORAGE_URL}${team.flag}`}
                                                        alt="group_logos"
                                                        className="w-[1.5rem]"
                                                    />
                                                    <p className="text-sm text-darkgray leading-normal ml-3 font-semibold">
                                                        {team.name}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {valueSelectedTeam.selected ? (
                                        <div className="flex flex-col items-start w-full bg-transparent rounded-[5px] border border-solid border-darkgray pt-[18px] px-[11px] my-3">
                                            <div className="flex flex-row items-center justify-between mb-[18px] h-full w-full">
                                                <div className='flex flex-row items-center'>
                                                    <img
                                                        src={`${process.env.REACT_APP_STORAGE_URL}${valueSelectedTeam.flag}`}
                                                        alt="group_logos"
                                                        className="w-[1.5rem]"
                                                    />
                                                    <p className="text-sm text-white leading-normal ml-3 font-semibold">
                                                        {valueSelectedTeam.name}
                                                    </p>
                                                </div>
                                                <img
                                                    src="/assets/images/remove-image.svg"
                                                    alt="Shoes"
                                                    className="w-[1.5rem] cursor-pointer text-right"
                                                    onClick={removeSelectedTeam}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className='mb-2'>
                                    <label className="text-xs font-medium text-white leading-6">
                                        Bergabung
                                    </label>
                                    <div className="flex flex-row items-center mb-3">
                                        <select
                                            name="date"
                                            id="select_date"
                                            defaultValue={valStartDate}
                                            className="block w-[100px] h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mr-2"
                                            onChange={(e) => handleInputStartDate(e.target.value)}
                                        >
                                            <option value="" disabled>Tanggal</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                        </select>
                                        <select
                                            name="date"
                                            id="select_month"
                                            defaultValue={valStartMonth}
                                            className="block w-[120px] h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mr-2"
                                            onChange={(e) => handleInputStartMonth(e.target.value)}
                                        >
                                            <option value="" disabled>Bulan</option>
                                            <option value="January">January</option>
                                            <option value="Februari">Februari</option>
                                            <option value="Maret">Maret</option>
                                            <option value="April">April</option>
                                            <option value="Mei">Mei</option>
                                            <option value="Juni">Juni</option>
                                            <option value="Juli">Juli</option>
                                            <option value="Agustus">Agustus</option>
                                            <option value="September">September</option>
                                            <option value="Oktober">Oktober</option>
                                            <option value="November">November</option>
                                            <option value="Desember">Desember</option>
                                        </select>
                                        <select
                                            name="date"
                                            id="select_year"
                                            defaultValue={valStartYear}
                                            className="block w-[100px] h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none"
                                            onChange={(e) => handleInputStartYear(e.target.value)}
                                        >
                                            <option value="" disabled>Tahun</option>
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                            <option value="1994">1994</option>
                                            <option value="1995">1995</option>
                                            <option value="1996">1996</option>
                                            <option value="1997">1997</option>
                                            <option value="1998">1998</option>
                                            <option value="1999">1999</option>
                                            <option value="2000">2000</option>
                                            <option value="2001">2001</option>
                                            <option value="2002">2002</option>
                                            <option value="2003">2003</option>
                                            <option value="2004">2004</option>
                                            <option value="2005">2005</option>
                                            <option value="2006">2006</option>
                                            <option value="2007">2007</option>
                                            <option value="2008">2008</option>
                                            <option value="2009">2009</option>
                                            <option value="2010">2010</option>
                                            <option value="2011">2011</option>
                                            <option value="2012">2012</option>
                                            <option value="2013">2013</option>
                                            <option value="2014">2014</option>
                                            <option value="2015">2015</option>
                                            <option value="2016">2016</option>
                                            <option value="2017">2017</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label className="text-xs font-medium text-white leading-6" >
                                            Berakhir
                                        </label>
                                        <div className='relative cursor-pointer'>
                                            <div
                                                className={!showEndDatePicker && active === 'current' ?
                                                    'w-full h-[50px] rounded-md bg-transparent border border-solid border-gray-500 px-[19px] py-[13px] text-secondary text-xs font-semibold leading-6 mb-3 cursor-pointer'
                                                    : 'w-full h-[50px] rounded-md bg-transparent border border-solid border-gray-500 px-[19px] py-[13px] text-gray-500 text-xs font-semibold leading-6 mb-3 cursor-pointer'
                                                }
                                                onClick={() => handleEndDateClick(false)}
                                            >
                                                <p>Masih bergabung sampai saat ini</p>
                                            </div>
                                            <div className='flex flex-row items-center absolute top-1/2 right-4 transform translate-y-[-50%]'>

                                                <p className="text-red text-[8px] text-left font-semibold">{endError}</p>
                                                <div className='w-4 h-4 relative ml-2'>
                                                    <img
                                                        src=
                                                        {!showEndDatePicker && active === 'current' ?
                                                            "/assets/images/icon-park-solid_check-one-active.svg"
                                                            : "/assets/images/icon-park-solid_check-one.svg"
                                                        }
                                                        alt="" className='w-4 h-4'
                                                    />
                                                </div>
                                            </div>
                                        </div >
                                        <div className='relative cursor-pointer'>
                                            <div
                                                className={showEndDatePicker && active === 'history' ?
                                                    'w-full h-[50px] rounded-md bg-transparent border border-solid border-gray-500 px-[19px] py-[13px] text-secondary text-xs font-semibold leading-6 mb-3 cursor-pointer'
                                                    : 'w-full h-[50px] rounded-md bg-transparent border border-solid border-gray-500 px-[19px] py-[13px] text-gray-500 text-xs font-semibold leading-6 mb-3 cursor-pointer'
                                                }
                                                onClick={() => handleEndDateClick(true)}
                                            >
                                                <p>Kontrak berakhir</p>
                                            </div>
                                            <div className='flex flex-row items-center absolute top-1/2 right-4 transform translate-y-[-50%]'>
                                                <p className="text-red text-[8px] text-left font-semibold">{endError}</p>
                                                <div className='w-4 h-4 relative ml-2'>
                                                    <img
                                                        src=
                                                        {showEndDatePicker && active === 'history' ?
                                                            "/assets/images/icon-park-solid_check-one-active.svg"
                                                            : "/assets/images/icon-park-solid_check-one.svg"
                                                        }
                                                        alt="" className='w-4 h-4'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {showEndDatePicker && (
                                            <div>
                                                <div className="mb-2">
                                                    <label className="text-xs font-medium text-white leading-6">
                                                        Berakhir
                                                    </label>
                                                    <div className='relative'>
                                                        <div className="flex flex-row items-center mb-3">
                                                            <select
                                                                name="date"
                                                                id="select_date"
                                                                defaultValue={valStartDate}
                                                                onChange={(e) => handleInputEndDate(e.target.value)}
                                                                className="block w-[100px] h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mr-2"
                                                            >
                                                                <option value="" disabled>Tanggal</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                                <option value="13">13</option>
                                                                <option value="14">14</option>
                                                                <option value="15">15</option>
                                                                <option value="16">16</option>
                                                                <option value="17">17</option>
                                                                <option value="18">18</option>
                                                                <option value="19">19</option>
                                                                <option value="20">20</option>
                                                                <option value="21">21</option>
                                                                <option value="22">22</option>
                                                                <option value="23">23</option>
                                                                <option value="24">24</option>
                                                                <option value="25">25</option>
                                                                <option value="26">26</option>
                                                                <option value="27">27</option>
                                                                <option value="28">28</option>
                                                                <option value="29">29</option>
                                                                <option value="30">30</option>
                                                                <option value="31">31</option>
                                                            </select>
                                                            <select
                                                                name="date"
                                                                id="select_month"
                                                                defaultValue={valStartMonth}
                                                                onChange={(e) => handleInputEndMonth(e.target.value)}
                                                                className="block w-[120px] h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mr-2"
                                                            >
                                                                <option value="" disabled>Bulan</option>
                                                                <option value="January">January</option>
                                                                <option value="Februari">Februari</option>
                                                                <option value="Maret">Maret</option>
                                                                <option value="April">April</option>
                                                                <option value="Mei">Mei</option>
                                                                <option value="Juni">Juni</option>
                                                                <option value="Juli">Juli</option>
                                                                <option value="Agustus">Agustus</option>
                                                                <option value="September">September</option>
                                                                <option value="Oktober">Oktober</option>
                                                                <option value="November">November</option>
                                                                <option value="Desember">Desember</option>
                                                            </select>
                                                            <select
                                                                name="date"
                                                                id="select_year"
                                                                defaultValue={valStartYear}
                                                                onChange={(e) => handleInputEndYear(e.target.value)}
                                                                className="block w-[100px] h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none"
                                                            >
                                                                <option value="" disabled>Tahun</option>
                                                                <option value="1990">1990</option>
                                                                <option value="1991">1991</option>
                                                                <option value="1992">1992</option>
                                                                <option value="1993">1993</option>
                                                                <option value="1994">1994</option>
                                                                <option value="1995">1995</option>
                                                                <option value="1996">1996</option>
                                                                <option value="1997">1997</option>
                                                                <option value="1998">1998</option>
                                                                <option value="1999">1999</option>
                                                                <option value="2000">2000</option>
                                                                <option value="2001">2001</option>
                                                                <option value="2002">2002</option>
                                                                <option value="2003">2003</option>
                                                                <option value="2004">2004</option>
                                                                <option value="2005">2005</option>
                                                                <option value="2006">2006</option>
                                                                <option value="2007">2007</option>
                                                                <option value="2008">2008</option>
                                                                <option value="2009">2009</option>
                                                                <option value="2010">2010</option>
                                                                <option value="2011">2011</option>
                                                                <option value="2012">2012</option>
                                                                <option value="2013">2013</option>
                                                                <option value="2014">2014</option>
                                                                <option value="2015">2015</option>
                                                                <option value="2016">2016</option>
                                                                <option value="2017">2017</option>
                                                                <option value="2018">2018</option>
                                                                <option value="2019">2019</option>
                                                                <option value="2020">2020</option>
                                                                <option value="2021">2021</option>
                                                                <option value="2022">2022</option>
                                                                <option value="2023">2023</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="action w-full text-center mt-10">
                                        {!loading
                                            ?
                                            <button
                                                type="button"
                                                className="w-full bg-secondary text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                                                onClick={handleSubmitHistory}>
                                                Submit
                                            </button>
                                            :
                                            <button
                                                disabled
                                                type="button"
                                                className="w-full disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-secondary text-secondary hover:bg-nav2 text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                                            >
                                                Please wait
                                            </button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default History;
