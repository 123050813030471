import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import LeaderBoardSidebar from "../sidebar/LeaderBoardSidebar";
import DetailReward from "./DetailReward";
import { ITournament, ITournamentReward } from "../../../app/models/tournament";
import SkeletonComponent from "../../../app/component/skeleton/SkeletonComponent";
import ButtonFixed from "../../../app/component/fixed/ButtonFixed";
import { useStore } from "../../../app/stores/store";

interface detailProps {
    data: ITournament | null;
}

const DetailDescription: React.FC<detailProps> = ({ data }) => {
    const { TournamentStore } = useStore()

    
    useEffect(() => {
        if (data) {
            TournamentStore.getRewardTournament(data.id)
        }
    }, [data])


    const toWhatsapp = () => {
        window.location.href = `https://wa.me/${data?.whatsapp}`
    }

    return (
        <React.Fragment>
            {data ?
                <div>
                     <ButtonFixed total_payment={data?.price} prizepool={data?.grand_prize} match_date={data?.range_date} close_date={new Date(data?.close_date)} slot={`${data?.registered}/${data?.open_slot}`} registration_type={data.registration_type} btn_status={data.registered === data.open_slot ? true : false} ></ButtonFixed>
                    <div className='w-full h-full flex flex-col mb-8 px-4 lg:px-0 lg:flex-row lg:space-x-10'>
                        {/* Latest News */}
                        <div className='w-full lg:w-[70%] flex flex-col'>
                            {data ? (
                                <div style={{ whiteSpace: 'pre-line' }} className="w-full text-sm text-white font-medium leading-normal">{data.description}</div>
                            ) : (
                                <SkeletonComponent
                                    count={5}
                                    width='w-full'
                                    height='h-5'
                                    borderRadius='rounded'
                                    marginBottom="mb-2"
                                />
                            )}
                            {data.whatsapp && (
                                <div>
                                    <h4 className='text-white font-bold text-lg py-3'>Punya pertanyaan? Hubungi kami:</h4>
                                    <div onClick={toWhatsapp} className="w-full border-b-[.5px] border-[#383D42] pb-7">
                                        <img
                                            className="w-20"
                                            src="/assets/images/contact-us-whatsapp.png"
                                            alt="gambar"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className='lg:block w-full'>
                                <DetailReward data={TournamentStore.rewardList} />
                            </div>
                        </div>
                        {/* Sidebar Left */}
                        <div className='w-full flex-col mb-12 lg:flex lg:w-[30%] lg:mb-0'>
                            <div className='w-full flex flex-col'>
                                <h3 className='text-white font-bold text-lg pb-2'>Leaderboard</h3>
                                <div className='w-full'>
                                    <LeaderBoardSidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <SkeletonComponent
                    count={5}
                    width='w-full'
                    height='h-8'
                    borderRadius='rounded'
                    marginBottom="mb-2"
                />
            }
        </React.Fragment>
    );
}

export default observer(DetailDescription);
