import React, { useState } from 'react'
import InputPassword from '../../app/component/input/InputPassword'
import { useStore } from '../../app/stores/store';
import { IAccountChangePassword } from '../../app/models/account';
import { observer } from 'mobx-react-lite';

const initValue:IAccountChangePassword = {
    old_password : '-',
    new_password : '------',
    new_password_confirmation : '------'
}

function ProfileSettingPassword() {

    const { accountStore } = useStore();
    const [password, setPassword] = useState<IAccountChangePassword>(initValue);
    const [success, setSuccess] = useState(false);

    const handleSubmit = () => {
        if(password) {
            accountStore.changePassword(password).then(() => setSuccess(true));
        }
    }

    const handleOldPassword = (value: string) => {
        setPassword(prevState => ({...prevState, old_password: value}));
    }

    const handleNewPassword = (value: string) => {
        setPassword(prevState => ({...prevState, new_password: value}));
    }

    const handleNewPasswordConfirmation = (value: string) => {
        setPassword(prevState => ({...prevState, new_password_confirmation: value}));
    }

  return (
    <div className='w-full bg-nav2 rounded-lg flex flex-col justify-center items-center py-[4vh] font-sans space-y-2'>
        <div className='w-full text-center text-white font-bold'>
            <h3 className='text-[4.5vw] lg:text-[1.5vw]'>Reset Password</h3>
        </div>
        <div className='w-full px-12 lg:px-0 lg:w-1/2 flex flex-col space-y-3 text-white'>
            <span className='text-[2.2vw] lg:text-[0.8vw] mb-2'>Please enter your password</span>
            {success? <span className='text-[0.8vw] text-green-500'>Password Changed</span> : <></>}
            <div>
                <InputPassword label='Old Password' bgColor='bg-nav1' placeholder=' ' labelColor='text-gray-600' border='border-none' px='px-2.5' py='py-3' mt='mt-2' mb='mb-0' leading ='leading-normal' onChange={(value) => handleOldPassword(value)} textColor='text-white'/>
                {(password.old_password === null || password.old_password === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Old Password</span> tidak boleh kosong!</p> }
            </div>
            <div>
                <InputPassword label='New Password' bgColor='bg-nav1' placeholder=' ' labelColor='text-gray-600' border='border-none' px='px-2.5' py='py-3' mt='mt-2' mb='mb-0' leading ='leading-normal' onChange={(value) => handleNewPassword(value)} textColor='text-white'/>
                {(password.new_password.length < 6 || password.new_password === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">New Password</span> tidak boleh kosong & minimal 6 karakter!</p> }
            </div>
            <div>
                <InputPassword label='Confirm New Password' bgColor='bg-nav1' placeholder=' ' labelColor='text-gray-600' border='border-none' px='px-2.5' py='py-3' mt='mt-2' mb='mb-0' leading ='leading-normal' onChange={(value) => handleNewPasswordConfirmation(value)} textColor='text-white'/>
                {(password.new_password !== password.new_password_confirmation) && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Password</span> tidak sama!</p> }
            </div>
            <div className='w-full h-full text-center pt-2'>
                {!accountStore.loadingChangePassword ? 
                    <button onClick={() => handleSubmit()} disabled={(!password.old_password || !password.new_password || password.new_password_confirmation === '') || password.new_password !== password.new_password_confirmation} className={`btn btn-sm disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-gray-700 text-white font-semibold bg-secondary px-3 border-none rounded-md text-[2.2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Reset Password</button> : 
                    <button onClick={() => handleSubmit()} disabled={true} className={`loading btn btn-sm disabled:bg-secondary disabled:cursor-not-allowed disabled:text-white text-white font-semibold bg-secondary px-3 border-none rounded-md text-[2.2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Please Wait</button>
                }
            </div>
        </div>
    </div>
  )
}

export default observer(ProfileSettingPassword)