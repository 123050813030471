import { observer } from "mobx-react-lite";
import { useState } from "react";
import History from "../modal/History";
import { useParams } from "react-router-dom";
import SkeletonComponent from "../skeleton/SkeletonComponent";

const ClubHistoryAdd = () => {

    const [isHistoryOpen, setisHistoryOpen] = useState(false);
    const { slug } = useParams<{ slug: string }>();

    if (isHistoryOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'visible';
    }

    const openModal = () => {
        setisHistoryOpen(true);
    };

    const closeModal = () => {
        setisHistoryOpen(false);
    };

    return (
        <div className="w-full cursor-pointer">
            <div className="w-full flex flex-row items-center h-[57px] px-[14px] py-[10px] mt-2 bg-nav1 rounded-md" onClick={openModal}>
                <div className="w-[37px] h-[37px]">
                    <img
                        src="/assets/images/fluent_add-circle-32-filled.svg"
                        alt="logo"
                        className="w-full h-full object-cover object-center"
                    />
                </div>
                <p className="text-darkgray font-medium text-sm leading-normal ml-[14px]">
                    Masukkan data History Team Anda
                </p>
            </div>
            <History onOpenHistoryModal={isHistoryOpen} closeModal={closeModal} slug={String(slug)} />
        </div>
    );
};


export default observer(ClubHistoryAdd);
