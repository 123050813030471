import { observer } from "mobx-react-lite"
import '../../../index.css'
import { items } from "../../config/menu";
import { useStore } from "../../stores/store";
import { useEffect } from "react";

const TournamentCard = () => {

  const { TournamentStore } = useStore()

  useEffect(() => {
    TournamentStore.getGameList()
  }, [TournamentStore])

  return (
    <div className="mb-8">
      <div className='flex flex-row items-center mt-8 mb-2 px-4 lg:px-0'>
        <a href={`/tournament`} className='w-full flex flex-row items-center justify-between lg:justify-normal'>
          <h3 className='text-white font-bold text-xl leading-normal mr-2'>Pilih Tournament</h3>
          <svg className="hidden lg:block" xmlns="http://www.sw3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path d="M4.96199 13.4667C4.78623 13.2909 4.6875 13.0525 4.6875 12.8039C4.6875 12.5553 4.78623 12.3169 4.96199 12.1411L9.60261 7.50047L4.96199 2.85985C4.79121 2.68303 4.69672 2.44622 4.69885 2.20041C4.70099 1.9546 4.79959 1.71946 4.97341 1.54564C5.14723 1.37182 5.38236 1.27323 5.62817 1.27109C5.87398 1.26896 6.1108 1.36345 6.28761 1.53422L11.5911 6.83766C11.7668 7.01347 11.8655 7.25188 11.8655 7.50047C11.8655 7.74907 11.7668 7.98748 11.5911 8.16329L6.28761 13.4667C6.1118 13.6425 5.87339 13.7412 5.6248 13.7412C5.37621 13.7412 5.13779 13.6425 4.96199 13.4667Z" fill="#565D6B" />
          </svg>
          {/* <button className="text-secondary text-sm font-semibold leading-normal text-right lg:hidden">View All</button> */}
        </a>
      </div>
      <div className='flex flex-row items-center overflow-x-scroll scrollbar-hidden space-x-4 mb-8 lg:mb-6 lg:overflow-hidden'>
        {TournamentStore.gameList.map((item, index) =>
          <a className='block' href={`/tournament/list/${item.id}`}>
            <div key={index}
              className={`flex flex-col items-start lg:pl-0 ${index === 0 ? 'pl-4' : index === items.length - 1 ? 'pr-4' : ''}`}>
              <figure className="w-[134px] h-[179px] mb-[14px]">
                <img className="h-auto max-w-full rounded-lg" src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`} alt='game-card' />
              </figure>
              <figcaption className="text-sm text-center text-darkgray font-bold leading-3">{item.name}</figcaption>
            </div>
          </a>
        )}
        {/* <div
            className={`flex flex-col items-start lg:pl-0`}>
            <figure className="w-[134px] h-[179px] mb-[14px]">
              <img className="h-auto max-w-full rounded-lg" src='/assets/images/game-card-efootball.png' alt='game-card'/>
            </figure>
            <figcaption className="text-sm text-center text-darkgray font-bold leading-3">eFootball</figcaption>
          </div> */}

      </div>
    </div>
  )
}

export default observer(TournamentCard);
