import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import InputPassword from '../../app/component/input/InputPassword';
import { IAccountChangePassword, IAccountForgetPassword } from '../../app/models/account';
import { useStore } from '../../app/stores/store';
import { useLocation, useNavigate } from 'react-router-dom';

const initValue: IAccountForgetPassword = {
    token: '',
    email: '',
    new_password: '',
    new_password_confirmation: ''
};

const Reset = () => {
    const { accountStore } = useStore();
    const [text, setText] = useState('Sebentar yah...')
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const keyParam = searchParams.get('key');
    const emailParam = searchParams.get('email');

    useEffect(() => {
        // Define an async function to handle the logic
        async function fetchData() {
            try {
                // Create a new value object with updated parameters
                const newValue: IAccountForgetPassword = {
                    token: keyParam || '',
                    email: emailParam || '',
                    new_password: '',
                    new_password_confirmation: ''
                };

                // Call your store function with the parameter value
                const result = await accountStore.validateResetPassword(newValue);

                if (!result) {
                    return setText('Maaf reset password gagal atau link sudah kadaluarsa')
                }
                setText('Verifikasi berhasil')
                navigate('/force/reset-password')
            } catch (error) {
                console.error('Validation error:', error);
            }
        }

        // Call the async function
        fetchData().then((res) => {
            return true;
        });

    }, [keyParam, emailParam]);

    return (
        <div className="bg-nav1 w-full h-full flex fixed">
            <div className="flex flex-col items-center justify-center m-auto w-[436px] px-4">
                <img
                    className="w-[170px] h-auto xl:w-[239px]"
                    src="/assets/images/IFeL Logo_Website.svg"
                    alt=""
                />
                <h3 className="text-white font-extrabold text-[32px] text-center mb-3 leading-normal">
                    {text}
                </h3>
            </div>
        </div>
    );
};

export default observer(Reset);
