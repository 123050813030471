import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import "./detail.css";
import PlayerMatchCard from "../../../app/component/card/PlayerMatchCard";
import BracketFrame from "../../../app/component/frame/FrameBracket";
import { ITournament } from "../../../app/models/tournament";

interface detailProps {
  data: ITournament | null;
}

const DetailBracket: React.FC<detailProps> = ({ data }) => {

  const [activeTab, setActiveTab] = useState(1);

  const [menu, setMenu] = useState([
    {
      id: 1,
      name: "Round 128",
      active: true
    },
    {
      id: 2,
      name: "Round 64",
      active: false
    },
    {
      id: 3,
      name: "Round 32",
      active: false
    },
    {
      id: 4,
      name: "Round 16",
      active: false
    },
    {
      id: 5,
      name: "Round 8",
      active: false
    },
    {
      id: 6,
      name: "Round Semi Final",
      active: false
    },
    {
      id: 7,
      name: "Round Final",
      active: false
    },
  ]);

  const handleMenu = (buttonId: number) => {
    const updatedButtons = menu.map(button => {
      if (button.id === buttonId) {
        return { ...button, active: true };
      } else {
        return { ...button, active: false };
      }
    });
    setMenu(updatedButtons);
    setActiveTab(buttonId);
  }
  return (
    <React.Fragment>
      <div className="w-full h-full flex flex-col px-4">
        <div className="flex flex-col">
          <div className="block w-full h-full divide-y-reverse divide-y-2 divide-gray-700">
            <h3 className="text-white font-bold text-[22px] leading-normal">
              Bracket
            </h3>
          </div>
          {data ? (
            <div className="w-full h-full py-4 mb-4">
              <BracketFrame src={data.bracket_link} title="bracket" />
            </div>) : (
            <div>loading</div>
          )}
          {/* <div className="hidden lg:block w-full h-full divide-y-reverse divide-y-2 divide-gray-700">
            <h3 className="text-white font-bold text-[22px] leading-normal">
              Jadwal Pertandingan
            </h3>
          </div> */}
        </div>
        {/* <React.Fragment>
          <div className='w-full h-full font-sans hidden lg:block'>
            <div className='w-full h-full py-4 lg:py-12'>
              <div id='tab' className='w-full flex flex-row lg:space-x-2 xlspace-x-4 menu-tab'>
                {menu.map((item, idx) => (
                  <button key={idx} className={`capitalize text-[3vw] lg:text-[0.9vw] px-5 py-2 rounded-md font-medium lg:px-3 lg:py-2 lg:rounded-md xl:rounded-lg ${item.active ? 'active' : ''}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                ))}
              </div>
              {
                {
                  1: <PlayerMatchCard />,
                  2: <PlayerMatchCard />,
                  3: <PlayerMatchCard />,
                  4: <PlayerMatchCard />,
                  5: <PlayerMatchCard />,
                  6: <PlayerMatchCard />,
                  7: <PlayerMatchCard />,
                }[activeTab]
              }
            </div>
          </div>
        </React.Fragment> */}
      </div>
    </React.Fragment>
  );
}

export default observer(DetailBracket);
