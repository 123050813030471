import { observer } from 'mobx-react-lite';
import { items, Menu } from '../../config/menu';
import NavbarDropdown from '../dropdown/NavbarDropdown';
import ListMenu from './ListMenu';

const MenuNav = () => {
//   const location = useLocation();
//   const isSelected = (route: string) => {
//     const parentPath = location.pathname.split('/');
//     const routePath = route.split('/');
//     return parentPath[1] === routePath[1];
//   };

  return (
    <div className='flex flex-col lg:flex-row items-start lg:items-center justify-end space-y-5 lg:space-y-0 lg:space-x-6 xl:space-x-10' aria-labelledby="nested-list-subheader">
      {items.map((item: Menu, idx) => {
          if (item.children) {
            return <NavbarDropdown
              label={item.label}
              route={item.route}
              children={item.children}
              icon={item.icon}
              key={idx}
            />
          } else {
            return <ListMenu
                label={item.label}
                route={item.route}
                icon={item.icon}
                key={idx}
                />
          }
        }
      )}
    </div>
  );
};

export default observer(MenuNav);