import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import './news.css';
import {Helmet} from "react-helmet-async";
import { FacebookShareButton, TwitterShareButton } from 'react-share';

function NewsDetail() {

    const { slug } = useParams();

    const { postStore } = useStore();

    const { postBySlug } = postStore;

    const [ content, setContent] = useState("");
    useEffect(() => {

        if(slug) {
            postStore.getPostBySlug(slug);

            if(postBySlug?.post_data.content){
                const data = postBySlug?.post_data.content!;
                const regex = /<pre class=\\"wp-block-preformatted\\">(.*?)<\/pre>/g;
                setContent(data!.replace(regex, ''));
            }
            
        };

    }, [postBySlug?.post_data.content, postStore, slug]);

  return (
    <div className='flex flex-col items-start justify-start w-full h-full font-sans'>
        <Helmet prioritizeSeoTags>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            <meta name="description" content={postBySlug?.post_data.title} />
            <title>{postBySlug?.post_data.title}</title>
            <meta property="og:title" content={postBySlug?.post_data.title} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={postBySlug?.post_data.attachment_image.img_src[0]} />
            <meta property="og:url" content={`https://ifel.id/news/${postBySlug?.post_data.slug}`} />
            <meta property="og:description" content={postBySlug?.post_data.excerpt} />
            <link rel="canonical" href={`https://ifel.id/news/${postBySlug?.post_data.slug}`} />
        </Helmet>
        <div className='flex flex-row items-center justify-center space-x-3 font-semibold text-secondary'>
            <svg width="5" height="10" viewBox="0 0 5 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className=''>
            <path d="M4.69187 1.02284C4.80904 1.14004 4.87486 1.29898 4.87486 1.46471C4.87486 1.63044 4.80904 1.78938 4.69187 1.90659L1.59812 5.00034L4.69187 8.09409C4.80572 8.21196 4.86872 8.36984 4.86729 8.53371C4.86587 8.69758 4.80014 8.85434 4.68426 8.97022C4.56838 9.0861 4.41162 9.15183 4.24775 9.15326C4.08387 9.15468 3.926 9.09168 3.80812 8.97783L0.272496 5.44221C0.155326 5.32501 0.0895041 5.16606 0.0895041 5.00034C0.0895041 4.83461 0.155326 4.67566 0.272496 4.55846L3.80812 1.02284C3.92533 0.905666 4.08427 0.839844 4.25 0.839844C4.41572 0.839844 4.57467 0.905666 4.69187 1.02284Z"/>
            </svg>
            <a href='/news'>Back to news</a>
        </div>

        <div className='flex flex-col items-start justify-start w-full h-full my-4 space-y-2 text-white lg:space-y-4'>
            <h1 className='font-bold text-[3.8vw] lg:text-[2.2vw]'>{postBySlug?.post_data.title}</h1>
            <span className='text-gray-600 text-[2vw] lg:text-[1vw]'>{postBySlug?.post_data.meta.author_name} | {postBySlug?.post_data.date}</span>
            <div className='w-full h-[50vw] lg:h-[30vw] rounded-md'>
                <img src={postBySlug?.post_data ? postBySlug?.post_data.attachment_image.img_src[0] : 'assets/loading.svg'} loading="lazy" alt="img-post" className='object-cover w-full h-full rounded-md' />
            </div>
            <div className='w-full'>
                <div className='text-[3.5vw] lg:text-[1.2vw] lg:-mt-8 font-sans whitespace-pre-wrap'>
                    
                    <div className='font-sans' dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
            </div>
            <div className='flex flex-row items-center justify-start w-full h-full space-x-3'>
                <span className='text-gray-700'>Share:</span>
                <div className='flex items-center justify-center w-10 h-10 text-white transition duration-500 rounded-sm bg-nav1 hover:text-secondary hover:bg-nav2'>
                    <div className='flex flex-row items-center justify-center'>
                    <TwitterShareButton
                        url={`https://ifel.id/news/${postBySlug?.post_data.slug}`}
                    >
                        <svg width="20" height="16" viewBox="0 0 20 16" className="fill-current" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.7025 2.11464C19.0067 2.42297 18.2592 2.6313 17.4733 2.72547C18.2842 2.2403 18.8908 1.47669 19.18 0.577138C18.4182 1.02963 17.5845 1.34813 16.715 1.5188C16.1303 0.894525 15.3559 0.480743 14.5119 0.341699C13.668 0.202656 12.8018 0.346131 12.0477 0.749848C11.2937 1.15357 10.694 1.79494 10.3418 2.57439C9.98961 3.35384 9.90462 4.22776 10.1 5.06047C8.55641 4.98297 7.04636 4.58176 5.66786 3.88289C4.28936 3.18402 3.07321 2.20311 2.09834 1.0038C1.765 1.5788 1.57334 2.24547 1.57334 2.95547C1.57297 3.59463 1.73037 4.22401 2.03157 4.78775C2.33277 5.35149 2.76847 5.83217 3.3 6.18714C2.68357 6.16752 2.08073 6.00096 1.54167 5.7013V5.7513C1.54161 6.64775 1.8517 7.51662 2.41932 8.21047C2.98695 8.90432 3.77715 9.38041 4.65584 9.55797C4.08399 9.71273 3.48445 9.73553 2.9025 9.62464C3.15042 10.396 3.63333 11.0705 4.28364 11.5537C4.93395 12.037 5.7191 12.3048 6.52917 12.3196C5.15403 13.3991 3.45574 13.9847 1.7075 13.9821C1.39782 13.9822 1.0884 13.9641 0.780838 13.928C2.5554 15.0689 4.62112 15.6745 6.73084 15.6721C13.8725 15.6721 17.7767 9.75714 17.7767 4.62714C17.7767 4.46047 17.7725 4.29214 17.765 4.12547C18.5244 3.57628 19.1799 2.89622 19.7008 2.11714L19.7025 2.11464Z"/>
                    </svg>
                    </TwitterShareButton>
                    </div>
                </div>
                <div className='flex items-center justify-center w-10 h-10 text-white transition duration-500 rounded-sm bg-nav1 hover:text-secondary hover:bg-nav2'>
                    <div className='flex flex-row items-center justify-center'>
                    <FacebookShareButton
                        url={`https://ifel.id/news/${postBySlug?.post_data.slug}`}
                        quote={''}
                        hashtag="#indonesiafootballeleague"
                    >
                        <svg width="14" height="24" viewBox="0 0 14 24" className="fill-current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5845 13.5002L13.2512 9.15698H9.08349V6.33848C9.08349 5.15048 9.66549 3.99173 11.5322 3.99173H13.4267V0.294227C13.4267 0.294227 11.7077 0.000976562 10.0637 0.000976562C6.63174 0.000976562 4.38849 2.08148 4.38849 5.84723V9.15773H0.573242V13.501H4.38849V24.001H9.08349V13.501L12.5845 13.5002Z"/>
                        </svg>
                    </FacebookShareButton>
                    </div>
                </div>
                <div className='flex items-center justify-center w-10 h-10 text-white transition duration-500 rounded-sm bg-nav1 hover:text-secondary hover:bg-nav2'>
                    <a href={`whatsapp://send?text=https://ifel.id/news/${postBySlug?.post_data.slug}`}>
                    <svg width="20" height="20" viewBox="0 0 20 20" className='fill-current' xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5125 11.985C14.2642 11.8609 13.0467 11.2625 12.82 11.1792C12.5933 11.0967 12.4283 11.0559 12.2625 11.3042C12.0975 11.5509 11.6233 12.1092 11.4792 12.2742C11.3342 12.44 11.19 12.46 10.9425 12.3367C10.695 12.2117 9.89667 11.9509 8.95083 11.1075C8.215 10.4509 7.7175 9.64003 7.57333 9.3917C7.42917 9.1442 7.5575 9.01003 7.68167 8.8867C7.79333 8.77587 7.92917 8.59753 8.05333 8.45337C8.1775 8.30837 8.21833 8.20503 8.30083 8.0392C8.38417 7.8742 8.3425 7.73003 8.28 7.60587C8.21833 7.4817 7.72333 6.26253 7.51667 5.7667C7.31583 5.2842 7.11167 5.35003 6.96 5.3417C6.815 5.33503 6.65 5.33337 6.485 5.33337C6.32 5.33337 6.05167 5.39503 5.825 5.64337C5.5975 5.89087 4.95833 6.49003 4.95833 7.7092C4.95833 8.92753 5.845 10.105 5.96917 10.2709C6.09333 10.4359 7.715 12.9375 10.1992 14.01C10.7908 14.265 11.2517 14.4175 11.6108 14.5309C12.2042 14.72 12.7442 14.6934 13.1708 14.6292C13.6458 14.5584 14.6358 14.03 14.8425 13.4517C15.0483 12.8734 15.0483 12.3775 14.9867 12.2742C14.925 12.1709 14.76 12.1092 14.5117 11.985H14.5125ZM9.99417 18.1542H9.99083C8.51536 18.1545 7.06698 17.7578 5.7975 17.0059L5.4975 16.8275L2.37917 17.6459L3.21167 14.6059L3.01583 14.2942C2.19095 12.9812 1.75441 11.4615 1.75667 9.91087C1.75833 5.3692 5.45333 1.6742 9.9975 1.6742C12.1975 1.6742 14.2658 2.53253 15.8208 4.0892C16.5878 4.85302 17.1957 5.76134 17.6094 6.76162C18.0231 7.76189 18.2343 8.83427 18.2308 9.9167C18.2292 14.4584 14.5342 18.1542 9.99417 18.1542ZM17.0042 2.9067C16.086 1.98247 14.9935 1.24965 13.7901 0.750701C12.5866 0.251754 11.2961 -0.00339687 9.99333 3.4148e-05C4.53167 3.4148e-05 0.085 4.44587 0.0833333 9.91003C0.0808025 11.649 0.536979 13.3579 1.40583 14.8642L0 20L5.25333 18.6217C6.70656 19.4135 8.33508 19.8283 9.99 19.8284H9.99417C15.4558 19.8284 19.9025 15.3825 19.9042 9.91753C19.9082 8.61526 19.654 7.32512 19.1562 6.12173C18.6584 4.91834 17.927 3.82559 17.0042 2.9067Z"/>
                    </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default observer(NewsDetail)