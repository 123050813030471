import { observer } from "mobx-react-lite"
import "../../../../index.css"
import { IProfileDetail } from "../../../models/profile";
import { calculateAge } from "../../../config/enum";

interface DummyPlayerCardProps {
    player: string;
    data: IProfileDetail;
}

const renderLevelTag = (level: string) => {
    if (level === 'amateur') {
        return <img className="w-full" src="/assets/images/Amateur.png" alt=""></img>
    } else if (level === 'semi') {
        return <img className="w-full" src="/assets/images/Semi.png" alt=""></img>
    } else {
        return <img className="w-full" src="/assets/images/Pro.png" alt=""></img>
    }
};

const DummyPlayerCard: React.FC<DummyPlayerCardProps> = ({ player, data }) => {
    return (
        <div className="w-[229px] mx-auto h-[370px] card-player relative">
            <div className="card-player w-full">
                <div className="flex flex-col items-start absolute team-name">
                    <div className={`text-[12px] font-extrabold leading-normal whitespace-nowrap text-[#181818]`}>{data.game}</div>
                    <div className={`text-[12px] font-extrabold leading-[4px] whitespace-nowrap uppercase text-[#181818]`}>{data.platform}</div>
                </div>
                <img className="absolute team-logo" src="/assets/images/eFootball-black.png" alt="" />
                {renderLevelTag(player ? player : '')}
                <img className="absolute w-[180px] top-6 left-1/2 transform -translate-x-1/2" src={`${process.env.REACT_APP_STORAGE_URL}${data.card}`} alt="" />
                <h2 className={`absolute uppercase text-[18px] font-extrabold leading-normal text-center whitespace-nowrap text-[#181818]`}>{data.username}</h2>
                <div className="flex flex-row items-center absolute age">
                    <h3 className={`uppercase text-[10px] font-medium leading-normal whitespace-nowrap mr-1 text-[#181818]`}>Age</h3>
                    <h3 className={`uppercase text-[10px] font-extrabold leading-normal whitespace-nowrap text-[#181818]`}>{calculateAge(data.birth_year)}</h3>
                </div>
                <h3 className={`absolute uppercase text-[10px] font-extrabold leading-normal whitespace-nowrap domicili text-[#181818]`}>{data.domisili}</h3>
            </div>
        </div>
    )
}

export default observer(DummyPlayerCard)