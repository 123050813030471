import React, { useEffect, useState } from 'react'
import 'aos/dist/aos.css';
import { observer } from 'mobx-react-lite';
// import './player.css'
import LeaderBoard from '../../app/component/leaderboard/LeaderBoard';
import PlayerMatchCard from '../../app/component/card/PlayerMatchCard';
import { useStore } from '../../app/stores/store';
import { useParams } from 'react-router-dom';

const PlayerBoard = () => {
    const { TournamentStore, accountStore } = useStore();
    const [activeTab, setActiveTab] = useState(1);
    const { slug } = useParams<{ slug: string }>();

    useEffect(() => {
        accountStore.getProfileUser(String(slug))
        TournamentStore.getMyOngoingMatch(String(slug))
        TournamentStore.getMyTournament(String(slug))
    }, [accountStore, TournamentStore])

    const [menu, setMenu] = useState([
        {
            id: 1,
            name: "Leaderboard",
            active: true
        },
        {
            id: 2,
            name: "Matches",
            active: false
        },
    ]);

    const handleMenu = (buttonId: number) => {
        const updatedButtons = menu.map(button => {
            if (button.id === buttonId) {
                return { ...button, active: true };
            } else {
                return { ...button, active: false };
            }
        });
        setMenu(updatedButtons);
        setActiveTab(buttonId);
    }

    return (
        <div className='lg:block w-full font-sans p-0 m-0'>
            <React.Fragment>
                <div className='w-full font-sans'>
                    <div className='w-full h-full py-4 mt-8'>
                        <div id='tab' className='w-full flex flex-row lg:space-x-2 justify-start items-start menu-tab border-b-[.25px] border-darkgray mb-2'>
                            {menu.map((item, idx) => (
                                <button key={idx}
                                    className={`capitalize text-sm px-5 py-2 font-semibold rounded-none 
                                ${item.active ? 'text-secondary border-secondary border-b-2' : 'border-darkgrey text-darkgray border-b-1'}`}
                                    onClick={() => handleMenu(item.id)}> {item.name} </button>
                            ))}
                        </div>
                        {
                            {
                                1: <LeaderBoard platform={accountStore.profile?.detail?.platform ?? ''} isProfilePage={true}/>,
                                2: <PlayerMatchCard />,
                            }[activeTab]
                        }
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}

export default observer(PlayerBoard)