import React, { useEffect, useState } from 'react';
import { IRules } from '../../models/tournament';
import SkeletonComponent from '../skeleton/SkeletonComponent';

interface dataProps {
  rule: IRules | null;
}
const AccordionTab: React.FC<dataProps> = ({ rule }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [showToggle, setShowToggle] = useState(true);
  const [htmlContent, setHtmlContent] = useState<string>(rule ? rule.description : '');

  useEffect(() => {
    if (rule?.description === '' || rule?.description === '-') {
      setShowToggle(false)
    }
  }, []);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div className="w-full py-1 flex flex-col items-start list-detail mb-3 lg:w-[343px] lg:mr-3" onClick={toggleAccordion}>
        <div className='w-full flex flex-row items-center justify-between'>
          <div className="w-full flex flex-row">
            <figure className="w-[38px] mr-[10px]">
              <img src={`${process.env.REACT_APP_STORAGE_URL}${rule?.icon}`} alt="icon" />
            </figure>
            <div className="items-start text-start lg:w-[85%]">
              <h4 className="text-white font-bold leading-normal capitalize">{rule?.title}</h4>
              <div className="text-[10px] text-darkgray font-medium leading-normal capitalize">{rule?.short_description}</div>
            </div>
          </div>
          {showToggle ? (
            isOpen ?
              <img src="/assets/images/arrow-down.svg" className='w-[14px] rotate-180' alt="" />
              :
              <img src="/assets/images/arrow-down.svg" className='w-[14px]' alt="" />
          )
            :
            <></>
          }
        </div>
        {showToggle &&
          <div className={`mt-2 text-darkgray text-[10px] font-medium transition-all duration-300 ease-in-out w-full
          ${isOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`} dangerouslySetInnerHTML={{ __html: rule?.description ? rule?.description : '' }}>
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default AccordionTab;
