import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ITransaction } from '../../app/models/transactions';
import { useStore } from '../../app/stores/store';

function StoreDetailProductPage() {

    const { productId } = useParams();
    const { storesStore, transactionStore } = useStore();

    const navigate = useNavigate();

    useEffect(() => {
        storesStore.getProductById(Number(productId));
    }, [productId, storesStore]);

    const handleBuyNow = (id: number | undefined, price: number | undefined, qty: number | undefined, total: number | undefined, status: number | undefined) => {

        let transaction: ITransaction = {
            product_id: id,
            price: price,
            qty: qty,
            total: total,
            status: status,
        }
        transactionStore.create(transaction).then((data) => {
            navigate(data.data.id + `/payment`);
        });
    }

  return (
    <React.Fragment>
        <div className='w-full h-full px-6 lg:px-40 bg-nav2 lg:bg-bg-home flex flex-col lg:flex-row lg:space-x-6 justify-center items-center py-8 font-sans'>
            <div className='w-full h-full mb-2 lg:mb-0 lg:w-1/2 lg:h-[35vw] rounded-xl'>
                <img className="object-cover w-full h-full rounded-xl" src={storesStore.product?.image} alt="product"/>
            </div>
            <div className='w-full lg:w-1/2 h-full lg:h-[35vw] bg-nav2 rounded-xl lg:p-8 flex flex-col space-y-4'>
                <h2 className='text-3xl lg:text-4xl text-white font-semibold'>{storesStore.product?.name}</h2>
                <button className='w-full py-3 bg-secondary hover:bg-blue-700 text-white font-semibold rounded-lg' onClick={() => handleBuyNow(storesStore.product?.id, storesStore.product?.price, 1, storesStore.product?.price, 1)}>Buy Now</button>
                <div className='w-full h-full flex flex-col space-y-2'>
                    <span className='text-gray-600 text-xl font-semibold'>Deskripsi Produk</span>
                    <p>{storesStore.product?.description}</p>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(StoreDetailProductPage)