import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './app/component/layouts/Dashboard';
import { useStore } from './app/stores/store';
import LoginAccount from './features/account/LoginAccount';
import PengeluaranForm from './features/pengeluaran/PengeluaranForm';
import PengeluaranIndexPage from './features/pengeluaran/PengeluaranIndexPage';
import { Spin } from 'antd';
import Home from './features/homepage/Home';
import Layouts from './app/component/layouts/Layouts';
import 'typeface-inter';
import RegisterAccount from './features/account/RegisterAccount';
import ProfileIndexPage from './features/profile/ProfileIndexPage';
import RequiredAuth from './app/utils/RequireAuth';
import ProfileDetail from './features/profile/ProfileDetail';
import ProfileSettingPassword from './features/profile/ProfileSettingPassword';
import ProfileTransactionHistory from './features/profile/ProfileTransactionHistory';
import NewsIndexPage from './features/news/NewsIndexPage';
import NewsGrid from './features/news/NewsGrid';
import NewsDetail from './features/news/NewsDetail';
import ContactPage from './features/contact/ContactPage';
import TermsPage from './features/terms/TermsPage';
import SponsorshipPage from './features/contact/SponsorshipPage';
import LeagueIndexPage from './features/league/LeagueIndexPage';
import StoreIndexPage from './features/store/StoreIndexPage';
import StoreDetailProductPage from './features/store/StoreDetailProductPage';
import StorePayment from './features/store/StorePayment';
import ProfileTournaments from './features/profile/ProfileTournaments';
import ClubProfile from './features/club/ClubProfile';
import MatchStatistics from './features/match/MatchStatistics';
import Tournament from './features/tournament/Tournament';
import TournamentList from './features/tournament/TournamentList';
import TournamentDetail from './features/tournament/detail/TournamentDetail';
import Player from './features/player/Player';
import ForgotPassword from './features/password/ForgotPassword';
import ResetPassword from './features/password/ResetPassword';
import Welcome from './features/homepage/Welcome';
import Verification from './features/account/Verification';
import Reset from './features/password/Reset';
import ForceResetPassword from './features/password/ForceResetPassword';
import EmptyPlayer from './features/player/EmptyPlayer';
import Payment from './features/payment/Payment';
import SuccessPayment from './features/payment/SuccessPayment';
import PlayerProfile from './features/player/PlayerProfile';
import ViewPlayer from './features/player/view/ViewPlayer';
import Subscription from './features/subscription/Subscription'
import PaymentSubscription from './features/payment/PaymentSubscription';

const router = createBrowserRouter([
    {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
            {
                path: 'pengeluaran',
                element: <PengeluaranIndexPage />,
            },
            {
                path: 'pengeluaran/form',
                element: <PengeluaranForm />,
            },
        ],
    },
    {
        path: 'login-admin',
        element: <LoginAccount />,
    },
    {
        path: '/verification',
        element: <Verification />,
    },
    {
        path: '/set-password',
        element: <Verification />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    //payment
    {
        path: '/payment/:transaction_number',
        element: (
            <RequiredAuth>
                <Payment />
            </RequiredAuth>
        ),
    },
    {
        path: '/payment/subscription/:subsSlug',
        element: (
            <RequiredAuth>
                <PaymentSubscription />
            </RequiredAuth>
        ),
    },
    {
        path: '/success-payment',
        element: (
            <RequiredAuth>
                <SuccessPayment />
            </RequiredAuth>
        ),
    },
    {
        path: '/reset-password',
        element: (
            <RequiredAuth>
                <ResetPassword />,
            </RequiredAuth>
        )
    },
    {
        path: '/force/reset-password',
        element: <ForceResetPassword />,
    },
    {
        path: '/pme/recovery/reset',
        element: <Reset />,
    },
    {
        path: '/',
        element: <Layouts />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/welcome',
                element: <Welcome />,
            },
            {
                path: '/tournament',
                element: <Tournament />,
            },
            {
                path: '/tournament/list/:id',
                element: <TournamentList />,
            },
            {
                path: '/tournament/:slug',
                element: <TournamentDetail />,
            },
            {
                path: '/player',
                element: (
                    <RequiredAuth>
                        <EmptyPlayer />
                    </RequiredAuth>
                )
            },
            {
                path: '/player/:slug',
                element: <ViewPlayer />,
            },
            {
                path: '/register',
                element: <RegisterAccount />,
            },
            {
                path: '/login',
                element: <LoginAccount />,
            },
            //profile
            {
                path: '/profile/:slug',
                // element: (
                //     <RequiredAuth>
                //         <ProfileIndexPage>
                //             <ProfileDetail />
                //         </ProfileIndexPage>
                //     </RequiredAuth>
                // ),
                element: (
                    <RequiredAuth>
                        <Player />
                    </RequiredAuth>
                ),
            },//player-profile
            {
                path: '/player/example',
                // element: (
                //     <RequiredAuth>
                //         <ProfileIndexPage>
                //             <ProfileDetail />
                //         </ProfileIndexPage>
                //     </RequiredAuth>
                // ),
                // element: (
                //     <RequiredAuth>
                //         <PlayerProfile />
                //     </RequiredAuth>
                // ),
            },
            {
                path: '/setting-password',
                element: (
                    <RequiredAuth>
                        <ProfileIndexPage>
                            <ProfileSettingPassword />
                        </ProfileIndexPage>
                    </RequiredAuth>
                ),
            },
            {
                path: '/transaction-history',
                element: (
                    <RequiredAuth>
                        <ProfileIndexPage>
                            <ProfileTransactionHistory />
                        </ProfileIndexPage>
                    </RequiredAuth>
                ),
            },
            {
                path: '/profile-tournament',
                element: (
                    <RequiredAuth>
                        <ProfileIndexPage>
                            <ProfileTournaments />
                        </ProfileIndexPage>
                    </RequiredAuth>
                ),
            },
            //news
            {
                path: '/news',
                element: (
                    <NewsIndexPage>
                        <NewsGrid />
                    </NewsIndexPage>
                ),
            },
            {
                path: '/news/:slug',
                element: (
                    <NewsIndexPage>
                        <NewsDetail />
                    </NewsIndexPage>
                ),
            },
            //contact
            {
                path: '/terms',
                element: <TermsPage />,
            },
            {
                path: '/contacts',
                element: <ContactPage />,
            },
            {
                path: '/sponsorship',
                element: <SponsorshipPage />,
            },
            //league
            {
                path: '/league/:categoryLeague',
                element: <LeagueIndexPage />,
            },
            {
                path: '/league/:categoryLeague/:leagueId',
                element: <LeagueIndexPage />,
            },
            //store
            {
                path: '/store',
                element: <StoreIndexPage />,
            },
            {
                path: '/store/:productId',
                element: (
                    <RequiredAuth>
                        <StoreDetailProductPage />
                    </RequiredAuth>
                ),
            },
            {
                path: '/store/:productId/:transactionId/payment',
                element: <StorePayment />,
            },
            //club
            {
                path: '/league/:categoryLeague/:leagueId/club/:clubId',
                element: <ClubProfile />,
            },
            //match
            {
                path: '/league/:categoryLeague/:leagueId/match/:matchId',
                element: <MatchStatistics />,
            },
            //subscription
            {
                path: 'subscription',
                element: <Subscription />
            },
            
        ],
    },
]);

function App() {
    const { commonStore, accountStore, leagueStore, generalStore } = useStore();

    useEffect(() => {
        leagueStore.getCategory();
        generalStore.getCard();
    }, [leagueStore, generalStore]);

    useEffect(() => {
        if (commonStore.token) {
            accountStore.getUser().finally(() => commonStore.setAppLoaded());
        } else {
            commonStore.setAppLoaded();
        }
    }, [accountStore, commonStore]);

    if (!commonStore.appLoaded)
        return (
            <div
                style={{
                    width: '100wh',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'black',
                }}
            >
                <Spin tip="Please wait" size="large"></Spin>
            </div>
        );

    return <RouterProvider router={router} />;
}

export default observer(App);
