import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import Slider from 'react-slick';
import { useStore } from '../../stores/store';

function ClientLogo() {

    const { accountStore } = useStore();

//slider settings
const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    initialSlide: 0,
    centerMode: true,
};

//slider settings
const sliderSettings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    initialSlide: 0,
    centerMode: true,
};

    useEffect(() => {
        accountStore.getSponsor();
    }, [accountStore]);

    console.log(accountStore.sponsor);

  return (
    <>
        <div className="hidden lg:block">
            <Slider {...sliderSettings}>

                {accountStore.sponsor.map((sponsor) => (
                    <div className='h-[20vw] lg:h-[7vw]'>
                        <img src={sponsor.image} alt={sponsor.name} className='h-[15vw] lg:h-[5vw] m-auto'/>
                    </div>
                ))}
                {/* <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Glance_Logo_White.png' alt='partners' className='h-[15vw] lg:h-[5vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Nostra_Logo_White.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Nusapay_Logo_White.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Vidio_Logo_White.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Garudaku.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Dunia-games.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Karcisbola.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Garuda-eleven.png' alt='partners' className='h-[16vw] lg:h-[5vw] m-auto'/>
                </div> */}
            </Slider>
        </div>
        <div className="block lg:hidden">
            <Slider {...sliderSettings2}>
                {accountStore.sponsor.map((sponsor) => (
                    <div className='h-[15vw]  lg:h-[7vw]'>
                        <img src={sponsor.image} alt={sponsor.name} className='h-[12vw] lg:h-[4vw] m-auto'/>
                    </div>
                ))}
                {/* <div className='h-[15vw]  lg:h-[7vw]'>
                    <img src='assets/images/Karcisbola.png' alt='partners' className='h-[12vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[15vw] lg:h-[7vw]'>
                    <img src='assets/images/Nostra_Logo_White.png' alt='partners' className='h-[12vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Dunia-games.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Nusapay_Logo_White.png' alt='partners' className='h-[14vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Glance_Logo_White.png' alt='partners' className='h-[17vw] lg:h-[5vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Vidio_Logo_White.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Garudaku.png' alt='partners' className='h-[15vw] lg:h-[4vw] m-auto'/>
                </div>
                <div className='h-[20vw] lg:h-[7vw]'>
                    <img src='assets/images/Garuda-eleven.png' alt='partners' className='h-[16vw] lg:h-[5vw] m-auto'/>
                </div> */}
            </Slider>
        </div>
    </>
  )
}

export default observer(ClientLogo)