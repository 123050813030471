import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import '../../../index.css';
import { items } from "../../config/menu";
import { useStore } from "../../stores/store";

interface Props {
    platform: string;
    limit: number;
    platformId : number;
    isProfilePage : boolean;
    filters : any;
}

const LeaderboardListMain: React.FC<Props> = ({ platform , limit , platformId , isProfilePage , filters }) => {

// function LeaderboardListMain() {
    const { TournamentStore } = useStore();
    
    const { accountStore } = useStore();

    const {slug} = useParams<{ slug?: string }>()
    const actualSlug = slug || '';

    const renderLevelTag = (level: string) => {
        
        if (level === 'amateur') {
          return <div className={`badge text-white text-[8px] border-white mb-1 badge-outline-amateur tag`}>Amateur</div>;
        } else if (level === 'semi') {
          return <div className={`badge text-white text-[8px] border-white mb-1 badge-outline-semi tag`}>Semi-Pro</div>;
        } else {
          return <div className={`badge text-white text-[8px] border-white mb-1 badge-outline-pro tag`}>Pro-Player</div>;
        }
      };

      const renderImageTag = (level: string ) => {
        if (level === 'amateur') {
            return  <img
                        src="/assets/images/Amateur.png"
                        alt="logo"
                        className="block w-full h-full object-cover object-center overflow-hidden"
                    />
        } else if (level === 'semi') {
            return  <img
            src="/assets/images/Semi.png"
            alt="logo"
            className="block w-full h-full object-cover object-center overflow-hidden"
        />
        } else {
            return  <img
            src="/assets/images/Pro.png"
            alt="logo"
            className="block w-full h-full object-cover object-center overflow-hidden"
        />
        }
    };

    const renderImageCard = (level : string , card: string ) => {
        if (level === 'amateur') {
            return  <img
                        src={card ? `${process.env.REACT_APP_STORAGE_URL}${card}` : '/assets/images/siluet-amateur.png'}
                        alt="logo"
                        className="absolute bottom-[18px] left-1/2 transform -translate-x-1/2 w-[42px]"
                    />
        } else if (level === 'semi') {
            return  <img
                        src={card ? `${process.env.REACT_APP_STORAGE_URL}${card}` : '/assets/images/siluet-semi.png'}
                        alt="logo"
                        className="absolute bottom-[18px] left-1/2 transform -translate-x-1/2 w-[42px]"
                    />
        } else {
            
            return  <img
                        src={card ? `${process.env.REACT_APP_STORAGE_URL}${card}` : '/assets/images/siluet-pro.png'}
                        alt="logo"
                        className="absolute bottom-[18px] left-1/2 transform -translate-x-1/2 w-[42px]"
                    />
        }
    };
    useEffect(() => {
        TournamentStore.getLeaderBoard(platform,limit , filters);
        TournamentStore.getPlayerRank(actualSlug)
    }, [TournamentStore , platform , accountStore , filters]);

    const navigate = useNavigate();
    return (
        <div>
            <div className="w-full border-b-[0.5px] border-solid border-darkgray flex flex-row items-center justify-between py-1 mt-[10px]">
                <div className="text-darkgray text-xs font-bold w-[60%] text-start">Player</div>
                <div className="w-[40%] flex flex-row justify-end">
                    <div className="text-darkgray text-xs font-bold w-[25%] text-center hidden lg:block">Matches</div>
                    <div className="text-darkgray text-xs font-bold w-[25%] text-center hidden lg:block">Wins</div>
                    <div className="text-darkgray text-xs font-bold w-[25%] text-center hidden lg:block">Losses</div>
                    <div className="text-darkgray text-xs font-bold w-[25%] text-center">Points</div>
                </div>
            </div>
            <div className="flex flex-col items-start mb-[77px]">
                {
                isProfilePage === false ? 
                TournamentStore.leaderboardData.map((item, index) => (
                    <div key={index}
                        className="flex flex-col items-start border-b-[0.5px] border-darkgray w-full">
                        <div className="flex flex-row items-center justify-between my-1 hover:bg-gray-800 w-full hover:rounded-md">
                            <div className="w-[75%] lg:w-[60%] flex flex-row items-center"
                                onClick={() => navigate(`/player/`+item.card.slug)}
                            >
                               
                                
                                <div className="w-[58px] h-[94px] relative">
                                {renderImageTag(item.card.level)}
                                    <div className="flex flex-col items-start absolute top-[2px] left-[2px]">
                                        <div className="text-[3px] font-extrabold leading-normal whitespace-nowrap text-[#181818]">eFOOTBALL</div>
                                        <div className="text-[3px] font-extrabold leading-[4px] whitespace-nowrap uppercase text-[#181818]">{item.card.platform}</div>
                                    </div>
                                    {/* {
                                        data.level === 'amateur'
                                            ?
                                    <img className="absolute team-logo z-10" src="/assets/images/efootball-logo.png" alt="" />
                                    : */}
                                    <img className="absolute team-logo z-10 w-[11px] right-[2px] top-[1px]" src="/assets/images/eFootball-black.png" alt="" />
                                    {/* } */}

                                    {renderImageCard(item.card.level ,item.card.card)}
                                    <h2 className="absolute uppercase text-[5px] font-extrabold leading-normal text-center whitespace-nowrap bottom-[10px] left-1/2 transform -translate-x-1/2 text-[#181818]">{item.card.username}</h2>
                                    <div className="flex flex-row items-center absolute bottom-[6px] left-[2px]">
                                        <h3 className="uppercase text-[3px] font-medium leading-normal whitespace-nowrap mr-[.5px] text-[#181818]">Age</h3>
                                        <h3 className="uppercase text-[3px] font-extrabold leading-normal whitespace-nowrap text-[#181818]">-</h3>
                                    </div>
                                    <h3 className="absolute uppercase text-[3px] font-extrabold leading-normal whitespace-nowrap text-[#181818] bottom-[6px] right-[2px]">-</h3>

                                </div> 

                                <div className="w-full ml-2.5 flex flex-col text-start">
                                    <h4 className="text-darkgray font-bold text-xs ">#{item.rank}</h4>
                                    <div className="flex flex-row items-center">
                                        <span className="text-white text-xs font-bold leading-normal"> {item.card.username} </span>
                                        &nbsp;
                                        {/* <p className="text-darkgray font-normal">/ Zeus Gaming</p> */}
                                    </div>
                                    {/* <div className="badge badge-outline text-white text-[8px] border-white mb-1">{item.card.level}</div> */}
                                    {renderLevelTag(item.card.level)}
                                    <div className="badge badge-outline-blue text-secondary text-[8px] rounded-xl bg-transparent">{item.card.platform == 'Console' ? "eFootball Console" : "eFootball Mobile"}</div>
                                </div>
                            </div>
                            <div className="w-[25%] lg:w-[40%] flex flex-row items-center justify-end">
                                <div className="text-white font-bold text-sm text-center w-[25%] hidden lg:flex items-center justify-center"> {item.match}</div>
                                <div className="text-white font-bold text-sm text-center w-[25%] hidden lg:flex items-center justify-center"> {item.win}</div>
                                <div className="text-white font-bold text-sm text-center w-[25%] hidden lg:flex items-center justify-center">{item.lose} </div>
                                <div className="text-white font-bold text-sm text-center w-full flex items-center justify-end lg:justify-center lg:w-[25%]"> {item.point} </div>
                            </div>
                        </div>
                    </div>
                )) :
                    <>
                        {
                            TournamentStore.playerRankData? 
                            <div 
                                className="flex flex-col items-start border-b-[0.5px] border-darkgray w-full">
                                <div className="flex flex-row items-center justify-between my-1 hover:bg-gray-800 w-full hover:rounded-md">
                                    <div className="w-[75%] lg:w-[60%] flex flex-row items-center"
                                        onClick={() => navigate(`/player/`+TournamentStore.playerRankData?.card.slug)}
                                    >
                                        <div className="w-[58px] h-[94px] relative">
                                        {renderImageTag(TournamentStore.playerRankData?.card.level)}
                                            <div className="flex flex-col items-start absolute top-[2px] left-[2px]">
                                                <div className="text-[3px] font-extrabold leading-normal whitespace-nowrap text-[#181818]">eFOOTBALL</div>
                                                <div className="text-[3px] font-extrabold leading-[4px] whitespace-nowrap uppercase text-[#181818]">{TournamentStore.playerRankData.card.platform}</div>
                                            </div>
                                            {/* {
                                                data.level === 'amateur'
                                                    ?
                                            <img className="absolute team-logo z-10" src="/assets/images/efootball-logo.png" alt="" />
                                            : */}
                                            <img className="absolute team-logo z-10 w-[11px] right-[2px] top-[1px]" src="/assets/images/eFootball-black.png" alt="" />
                                            {/* } */}
        
                                            {renderImageCard(TournamentStore.playerRankData.card.level ,TournamentStore.playerRankData.card.card)}
                                            <h2 className="absolute uppercase text-[5px] font-extrabold leading-normal text-center whitespace-nowrap bottom-[10px] left-1/2 transform -translate-x-1/2 text-[#181818]">{TournamentStore.playerRankData.card.username}</h2>
                                            <div className="flex flex-row items-center absolute bottom-[6px] left-[2px]">
                                                <h3 className="uppercase text-[3px] font-medium leading-normal whitespace-nowrap mr-[.5px] text-[#181818]">Age</h3>
                                                <h3 className="uppercase text-[3px] font-extrabold leading-normal whitespace-nowrap text-[#181818]">-</h3>
                                            </div>
                                            <h3 className="absolute uppercase text-[3px] font-extrabold leading-normal whitespace-nowrap text-[#181818] bottom-[6px] right-[2px]">-</h3>
        
                                        </div> 
        
                                        <div className="w-full ml-2.5 flex flex-col text-start">
                                            <h4 className="text-darkgray font-bold text-xs ">#{TournamentStore.playerRankData.rank}</h4>
                                            <div className="flex flex-row items-center">
                                                <span className="text-white text-xs font-bold leading-normal"> {TournamentStore.playerRankData.card.username} </span>
                                                &nbsp;
                                                {/* <p className="text-darkgray font-normal">/ Zeus Gaming</p> */}
                                            </div>
                                            {/* <div className="badge badge-outline text-white text-[8px] border-white mb-1">{item.card.level}</div> */}
                                            {renderLevelTag(TournamentStore.playerRankData.card.level)}
                                            <div className="badge badge-outline-blue text-secondary text-[8px] rounded-xl bg-transparent">{TournamentStore.playerRankData.card.platform == 'Console' ? "eFootball Console" : "eFootball Mobile"}</div>
                                        </div>
                                    </div>
                                    <div className="w-[25%] lg:w-[40%] flex flex-row items-center justify-end">
                                        <div className="text-white font-bold text-sm text-center w-[25%] hidden lg:flex items-center justify-center"> {TournamentStore.playerRankData.match}</div>
                                        <div className="text-white font-bold text-sm text-center w-[25%] hidden lg:flex items-center justify-center"> {TournamentStore.playerRankData.win}</div>
                                        <div className="text-white font-bold text-sm text-center w-[25%] hidden lg:flex items-center justify-center">{TournamentStore.playerRankData.lose} </div>
                                        <div className="text-white font-bold text-sm text-center w-full flex items-center justify-end lg:justify-center lg:w-[25%]"> {TournamentStore.playerRankData.point} </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                   
                }
                {
                    limit < 100 
                    ? <a href={`/tournament/list/${platformId}?active=leaderboard`} className="text-secondary text-sm font-semibold flex justify-end w-full mt-[10px] mb-[33px] lg:mb-10">View All</a>
                    : <></>
                }
                
            </div>
        </div>
    );
}

export default observer(LeaderboardListMain);
