import { observer } from 'mobx-react-lite'
import { shortenText } from '../../config/enum';
import { IPostV2 } from '../../models/post'

interface NewsProps {
  post: IPostV2
}

function NewsCard(props: NewsProps) {

  const { post } = props;

  return (
    <div className="card card-compact w-full h-full bg-nav1 shadow-xl rounded-lg border-none">
        <figure><img src={post.featured_img} alt="news" className='w-full lg:h-[25vw] object-cover transform hover:scale-125 transition duration-500 ease-in-out"'/></figure>
        <div className="card-body text-white rounded-b-lg border-none">
            <span className="font-semibold lg:text-[2vh]">{new Date(post.date_gmt).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</span>
            <a className="card-title text-sm lg:text-lg hover:text-secondary" href={`/news/${post.slug}`}>{post.title.rendered}</a>
            <p className='hidden lg:block text-[1.8vh]'>
            {shortenText(150, post.excerpt.rendered.replace(/<p>|<\/p>|&#8211;|\[&hellip;\]/g, ""))}
            </p>
            <p className='block lg:hidden text-[1.6vh]'>
            {shortenText(80, post.excerpt.rendered.replace(/<p>|<\/p>|&#8211;|\[&hellip;\]/g, ""))}
            </p>
            <div className="card-actions justify-end">
            </div>
        </div>
    </div>
  )
}

export default observer(NewsCard)