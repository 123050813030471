import React, { useEffect, useState } from 'react'
import { useStore } from '../../stores/store';
import { useNavigate, useParams } from 'react-router-dom';
import MatchContentCard from './MatchContentCard';
import { formatDateRange, formatRupiah, teamType, tournamentMode } from '../../config/enum';

const PlayerMatchCard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isModalInputOpen, setIsModalInputOpen] = useState(false);
    const { accountStore, TournamentStore } = useStore();
    const [matchIndex, setMatchIndex] = useState<number>(0);
    const { slug } = useParams<{ slug: string }>();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        accountStore.getProfileUser(String(slug))
        TournamentStore.getMyOngoingMatch(String(slug))
        TournamentStore.getMyTournament(String(slug))
    }, [accountStore, TournamentStore])

    const inputModalScore = () => {
        setIsModalInputOpen(true);
        document.body.style.overflow = 'hidden';
        console.log('hidden');
    };

    const closeModalInput = () => {
        setIsModalInputOpen(false);
        document.body.style.overflow = 'visible';
        console.log("visible");
    }

    const handleToggle = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    };

    const toTournament = (slug: string) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling behavior
        });
        navigate(`/tournament/${slug}`)
    }

    return (
        <React.Fragment>
            <h2 className=" text-white text-[22px] font-bold leading-normal mb-1">Jadwal Pertandingan</h2>
            <div>
                {TournamentStore.myTournamentList.length > 0
                    ?
                    TournamentStore.myTournamentList.map((item, index) => (
                        <div key={index}>
                            <h3 className=" text-white text-[18px] font-bold leading-normal mb-[18px] cursor-pointer" onClick={() => toTournament(item.tournament.slug)}>{item.tournament.name}</h3>
                            <div className="card card-side flex flex-col items-start bg-nav1 lg:items-center rounded-xl lg:flex-row mb-4">
                                <figure className="w-full lg:mr-6 lg:w-[274px] h-[154px] cursor-pointer" onClick={() => toTournament(item.tournament.slug)}>
                                    <img
                                        src={`${process.env.REACT_APP_STORAGE_URL}${item.tournament.flag}`}
                                        alt="Movie"
                                        className='w-full h-full object-cover'
                                    />
                                </figure>
                                <div className='py-2 px-3 lg:p-0'>
                                    <h2 className="card-title text-sm text-white leading-normal font-bold cursor-pointer" onClick={() => toTournament(item.tournament.slug)}>{item.tournament.name}</h2>
                                    <div className="flex flex-row items-center space-x-1 mb-[6px] cursor-pointer" onClick={() => toTournament(item.tournament.slug)}>
                                        <div className="badge badge-card badge-card-blue rounded px-2">eFootball Mobile</div>
                                        <div className="badge badge-card badge-card-blue rounded px-2">{tournamentMode(item.tournament.mode)}</div>
                                        <div className="badge badge-card badge-card-blue rounded px-2">{teamType(item.tournament.team_type)}</div>
                                    </div>
                                    <div className="badge badge-card-green rounded px-2 mb-2 cursor-pointer" onClick={() => toTournament(item.tournament.slug)}>Open Registration</div>
                                    <div className='flex flex-row items-end justify-between'>
                                        <div className="cursor-pointer" onClick={() => toTournament(item.tournament.slug)}>
                                            <div className="flex items-center">
                                                <div className="columns-1 mr-4">
                                                    <figure>
                                                        <img
                                                            src="/assets/images/Prizepool.png"
                                                            alt="Prizepool"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="columns-2 text-xs text-white leading-normal font-medium">
                                                    {formatRupiah(item.tournament.grand_prize)}
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="columns-1 mr-4">
                                                    <figure>
                                                        <img
                                                            src="/assets/images/Slots.png"
                                                            alt="Slots"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="columns-2 text-xs text-white leading-normal font-medium">
                                                    {item.player_count}/{item.tournament.open_slot}
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="columns-1 mr-4">
                                                    <figure>
                                                        <img
                                                            src="/assets/images/Calendar.png"
                                                            alt="Calendar"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="columns-2 text-xs text-white leading-normal font-medium">
                                                    {formatDateRange(new Date(item.tournament.start_date), new Date(item.tournament.end_date))}
                                                </div>
                                            </div>
                                        </div>
                                        <a href={`${item.tournament.discord}`} target='_BLANK' className='bg-secondary w-[87px] h-8 rounded-md text-white text-[10px] font-bold leading-normal absolute right-3 bottom-3 flex flex-row items-center justify-center'>Join discord</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className="bg-nav1 rounded-md p-4 text-darkgray text-xs font-medium leading-normal text-left mt-[28px]">Belum ada Turnamen</div>
                }
                {TournamentStore.myMatchList.length > 0
                    ?
                    TournamentStore.myMatchList.map((item, index) => (
                        <MatchContentCard key={index} matches={item.matches} tournament_name={item.tournament.name} item={item} />
                    ))
                    :
                    <div className="bg-nav1 rounded-md p-4 text-darkgray text-xs font-medium leading-normal text-left mt-[28px]">Belum ada Jadwal Pertandingan</div>
                }
            </div>
        </React.Fragment>
    )
}

export default PlayerMatchCard