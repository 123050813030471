import { action, makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent";
import { ISubcription } from "../models/subcription";
import { IBennefits } from "../models/bennefits";
import { IFaq } from "../models/faq";

export default class SubcriptionStore {

    data = new Array<ISubcription>();

    bennefitsData = new Array<IBennefits>();

    faqData = new Array<IFaq>

    subscriptionDetail : ISubcription | null = null;
    loading = true;
    subscriptionData : any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    updateGridCallback = action(() => {
        this.getSubcriptionData();
        this.getBennefitsData();
    })

    getSubcriptionData = async () => {
        try {
            const result = await agent.Subscription.listSubcriptions()
            this.loading = false;
            this.setSubscriptionData(result.data);
        } catch (error) {
            throw error;
        }
    }

    getBennefitsData = async () => {
        try {
            const result = await agent.Subscription.bennefits()
            this.setBennefitsData(result.data);
        } catch (error) {
            throw error;
        }
    }
    
    setSubscriptionData = (res: Array<ISubcription>) => {
        this.data = res;
    }

    setBennefitsData = (res: Array<IBennefits>) => {
        this.bennefitsData = res;
    }

    getSubscriptionBySlug = async (SubsSlug : string) => {
        try {
            const result = await agent.Subscription.subscriptinBySlug(SubsSlug)
            this.loading = false;
            this.setSubscriptionDataBySlug(result.data);
        } catch (error) {
            throw error;
        }
    }
    setSubscriptionDataBySlug = (data: ISubcription) => {
        this.subscriptionDetail = data;
    }


    getFaqData = async () => {
        try {
            const result = await agent.Subscription.faq()
            this.setFaqData(result.data);
        } catch (error) {
            throw error;
        }
    }

    setFaqData = (res: Array<IFaq>) => {
        this.faqData = res;
    }

    
}