import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { formatRupiah } from "../../app/config/enum";
import { IPayment } from "../../app/models/transactions";

const Payment = () => {
    const { orderStore, accountStore } = useStore()
    const { transaction_number } = useParams<{ transaction_number: string }>();
    useEffect(() => {
        orderStore.getPaymentById(String(transaction_number))
    }, [orderStore])

    const isMobile = window.matchMedia('(max-width: 640px)').matches;

    const handlePurchase = () => {
        let payment: IPayment = {
            order_id: transaction_number,
            amount: orderStore.orderDetail?.total_payment ? orderStore.orderDetail?.total_payment : 0,
            first_name: accountStore.user?.name,
            email: accountStore.user?.email,
            phone: accountStore.user?.phone,
        }
        orderStore.payment(payment)
    }

    return (
        <div className="bg-nav1 fixed inset-0 flex flex-col items-center justify-center">
            <img
                className="w-[170px] h-auto mt-[88px] mx-auto"
                src="/assets/images/IFeL Logo_Website 1.png"
                alt=""
            />
            <div className="w-full px-4 mx-auto h-full md:w-[436px] lg:px-0">
                <h1 className="text-white mb-3 font-extrabold text-[32px] text-center xl:mb-0 leading-normal">
                    Payment

                </h1>
                <div className="flex flex-row items-center border-b-[.5px] border-darkgray pb-[14px] mb-[26px]">
                    <img
                        className="w-[52px] h-[52px] border border-solid border-darkgray rounded-md mr-4 py-[5px] px-[10px] object-cover"
                        src="/assets/images/IFeL Logo_White 1.png"
                        alt="logo"
                    />
                    <h4 className="text-white text-xs font-semibold text-center mb-3 xl:mb-0">
                        {orderStore.orderDetail?.tournament_name}
                    </h4>
                </div>
                {/* <div className="flex flex-row items-center justify-between mb-2">
                    <h4 className="text-white text-xs font-semibold text-center mb-3 xl:mb-0">Pilih Kartu</h4>
                    <select
                        name="card"
                        id="player_card"
                        className="block w-1/3 h-[32px] bg-transparent text-white text-xs font-semibold border border-solid border-darkgray-600 rounded sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none"
                    >
                        <option value="amateur">Amateur</option>
                        <option value="semi">Semi</option>
                        <option value="pro">Pro</option>
                    </select>
                </div> */}
                <div className="flex flex-row items-center justify-between text-white font-medium text-[11px] leading-normal">
                    <p>{orderStore.orderDetail?.tournament_name}</p>
                    <p>{formatRupiah(orderStore.orderDetail?.total_payment ? orderStore.orderDetail?.total_payment : 0)}</p>
                </div>
                {/* <div className="flex flex-row items-center justify-between text-white font-medium text-[11px] leading-normal">
                    <p>Voucher</p>
                    <p>-Rp 0</p>
                </div> */}
                <div className="flex flex-row items-center border-b-[.5px] border-darkgray pb-[23px] mt-6 mb-[18px] justify-between text-secondary text-sm leading-normal font-bold">
                    <p>Total Pembayaranmu</p>
                    <p>{formatRupiah(orderStore.orderDetail?.total_payment ? orderStore.orderDetail?.total_payment : 0)}</p>
                </div>
                {/* <div className="flex flex-row items-center mb-4">
                    <input className="border-[.5px] bg-transparent border-[#475569] text-[#475569] text-xs font-normal leading-6 w-full rounded-md  h-[28px]" type="text" placeholder="Masukkan kode promo"/>
                    <button className="w-[77px] rounded-md bg-darkgray text-xs text-[#7A8697] h-[28px] ml-[6px]">Submit</button>
                </div>
                <div className="relative">
                    <img src="/assets/images/promo-banner.svg" className="w-full object-cover rounded-xl" alt="" />
                    <img src="/assets/image/arrow-white.svg" className="absolute top-1/2 right-3 transform -translate-y-1/2 z-10 w-6" alt="" />
                </div> */}
                <div className="w-full mt-[38px]">
                    <button
                        onClick={handlePurchase}
                        className="w-full px-10 py-3 h-[50px] text-xs disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-darkgray text-white font-bold bg-secondary border-none rounded-md hover:bg-nav2 hover:text-secondary transition duration-300 xl:font-semibold"
                    >
                        Bayar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default observer(Payment);