import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import CustomSelect from "../../app/component/select/CustomSelect";
import '../../index.css'
import { useStore } from "../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { formatRupiah, registrationType, tournamentMode } from "../../app/config/enum";
import Countdown from "../../app/component/countdown/Countdown";

const TournamentCardList = () => {
    const { TournamentStore } = useStore()
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [filters, setFilters] = useState({
        filterStatus: null as string | null,
        filterLevel: null as string | null,
        filterType: null as string | null,
        filterSearch: null as string | null,
    });
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>();

    const handleToggle = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    };

    const handleFilter = (type: keyof typeof filters, data: string) => {
        setFilters((prevFilters) => ({ ...prevFilters, [type]: data }));
    };

    const handleSearchInput = (val: string) => {
        setFilters((prevFilters) => ({ ...prevFilters, ['search']: val }));
    }

    const toTournament = (val: string) => {
        navigate(`/tournament/${val}`)
    }

    useEffect(() => {
        // Use a separate fetchData function inside useEffect
        const fetchData = async () => {
            const { filterStatus, filterLevel, filterType, filterSearch } = filters;
            //   console.log(filterStatus);
            await TournamentStore.getTournamentByGame(Number(id), filters);
        };
        // Call fetchData whenever filters or id change
        fetchData();
    }, [id, filters]);

    const handleSearch = (event: any) => {
        if (event.key === 'Enter') {

            //console.log(filters);

            TournamentStore.getTournamentByGame(Number(id), filters);
        }
    }


    return (
        <React.Fragment>
            <div className="w-full h-full pt-6">
                <h3 className="text-white font-bold text-[22px] mb-2">Tournaments</h3>
                <div className="flex flex-col lg:flex-row items-center justify-between">
                    <div className="flex flex-row items-center space-x-2 lg:mb-0 mb-2">
                        {["status", "level", "pendaftaran"].map((type) => (
                            <CustomSelect
                                key={type}
                                color="darkgray"
                                fontFamily="Inter"
                                fontSize={12}
                                fontWeight={500}
                                lineHeight="normal"
                                marginRight={4}
                                marginBottom={12}
                                type={type}
                                count={10}
                                isOpen={isOpen}
                                toggleOptions={handleToggle}
                                onValueChange={(data) => handleFilter(type as keyof typeof filters, data)}
                            />
                        ))}
                    </div>
                    <div className="relative h-[33px] w-full lg:w-[343px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className="absolute left-4 top-1/2 transform -translate-y-1/2">
                            <path d="M6.12586 12.1357C7.45619 12.1357 8.68295 11.7096 9.68667 10.9995L13.4626 14.7396C13.6378 14.9132 13.8688 15 14.1158 15C14.6336 15 15 14.6055 15 14.1005C15 13.8638 14.9203 13.6349 14.7451 13.4692L10.9931 9.74487C11.7817 8.7191 12.2517 7.44871 12.2517 6.06786C12.2517 2.73014 9.49549 0 6.12586 0C2.76421 0 0 2.72225 0 6.06786C0 9.40558 2.75624 12.1357 6.12586 12.1357ZM6.12586 10.8259C3.49708 10.8259 1.32236 8.67175 1.32236 6.06786C1.32236 3.46397 3.49708 1.30984 6.12586 1.30984C8.75465 1.30984 10.9294 3.46397 10.9294 6.06786C10.9294 8.67175 8.75465 10.8259 6.12586 10.8259Z" fill="#565D6B" />
                        </svg>
                        <input className="h-full w-full rounded bg-nav1 border-b-[.5px] border-[#475569] text-darkgray px-[35px] text-[14px] font-normal leading-normal" type="text" placeholder="Cari Tournament..." onKeyDown={handleSearch} onChange={(e) => handleSearchInput(e.target.value)} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35794 11.6421 0 7.5 0C3.35786 0 0 3.35794 0 7.5C0 11.6421 3.35786 15 7.5 15ZM10.8227 4.17739C11.0983 4.45291 11.0983 4.8999 10.8227 5.17564L8.49827 7.5L10.8227 9.82436C11.0983 10.1001 11.0983 10.5471 10.8227 10.8226C10.547 11.0983 10.1001 11.0983 9.82439 10.8226L7.5 8.49825L5.17561 10.8226C4.89996 11.0983 4.45302 11.0983 4.17734 10.8226C3.90168 10.5471 3.90168 10.1001 4.17734 9.82436L6.50173 7.5L4.17734 5.17564C3.90168 4.8999 3.90168 4.45291 4.17734 4.17739C4.45299 3.90165 4.89993 3.90165 5.17561 4.17739L7.5 6.50175L9.82439 4.17739C10.1001 3.90165 10.547 3.90165 10.8227 4.17739Z" fill="#475569" />
                        </svg>
                    </div>
                </div>
                <div className="w-full mt-[14px] pb-[50px]">
                    {TournamentStore.data.length > 0 &&
                        <div className="bg-secondary py-[10px] px-2 rounded text-[10px] font-bold leading-normal w-fit mb-3 text-white">{TournamentStore.data[0].game}</div>
                    }
                    {TournamentStore.data.map((item, idx) => (
                        <a className='block' href={`/tournament/${item.slug}`}>
                            <div className="card card-side flex flex-col items-center border-b-[.5px] border-solid border-darkgray rounded-none pb-2 lg:flex-row mb-4" key={idx}>
                                <div className={`${new Date() > new Date(item.close_date) ? "overlay-closed" : " "}`}></div>
                                <figure className="w-full h-[99px] lg:h-[154px] lg:w-[274px]  lg:mr-6">
                                    <img
                                        src={item.flag}
                                        alt="Tournament"
                                        className="w-full h-full object-cover banner-gradient"
                                    />
                                </figure>
                                <div className="w-full pt-2 bg-nav1 lg:bg-transparent" style={{ borderRadius: '0 0 10px 10px' }}>
                                    <div className="px-3">
                                        <h2 className="card-title text-sm text-white leading-normal font-bold mb-2">{item.name}</h2>
                                        <div className="flex flex-row items-center space-x-1 mb-[6px]">
                                            <div className="badge badge-card badge-card-blue rounded px-2">{item.game}</div>
                                            <div className="badge badge-card badge-card-blue rounded px-2">{tournamentMode(item.mode)}</div>
                                            <div className="badge badge-card badge-card-blue rounded px-2">{item.team_type}</div>
                                        </div>
                                        <div className="badge badge-card-green rounded px-2 mb-2">{registrationType(item.registration_type)}</div>
                                        <div className="flex items-center">
                                            <div className="columns-1 mr-4">
                                                <figure>
                                                    <img
                                                        src="/assets/images/Prizepool.png"
                                                        alt="Prizepool"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="columns-2 text-xs text-white leading-normal font-extrabold">
                                                {formatRupiah(item.grand_prize)}
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <div className="columns-1 mr-4">
                                                <figure>
                                                    <img
                                                        src="/assets/images/Slots.png"
                                                        alt="Slots"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="columns-2 text-xs text-white leading-normal font-extrabold">
                                                {item.registered}/{item.open_slot}
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <div className="columns-1 mr-4">
                                                <figure>
                                                    <img
                                                        src="/assets/images/Calendar.png"
                                                        alt="Calendar"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="columns-2 text-xs text-white leading-normal font-extrabold">
                                                {item.range_date}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center justify-between h-11 bg-[#1D2335] px-3 mt-2 lg:hidden">
                                        {new Date().getTime() > new Date(item.close_date).getTime() ? <></> :
                                            <div>
                                                <div className='text-darkgray text-[8px] font-medium'>Pendaftaran ditutup</div>
                                                <Countdown targetDate={new Date(item.close_date)} />
                                            </div>
                                        }
                                        {new Date() > new Date(item.close_date) ?
                                            <button
                                                key={item.id}
                                                className="text-darkgray text-[8px] font-medium"
                                                disabled
                                            >
                                                Pendaftaran Ditutup
                                            </button>
                                            :
                                            <button
                                                key={item.id}
                                                className="text-xs bg-secondary text-white font-semibold hover:bg-nav2 hover:text-secondary hover:ç px-4 py-[6px] rounded-md disabled:"
                                                onClick={() => toTournament(item.slug)}
                                            >
                                                View
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                        </a>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default observer(TournamentCardList);
