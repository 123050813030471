import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, formatDateRange, formatRupiah, teamType, tournamentMode } from "../../app/config/enum";
import { Pagination } from "antd";

function TransactionHistory() {
    const { transactionStore, accountStore, TournamentStore } = useStore();
    const { slug } = useParams<{ slug: string }>();

    const navigate = useNavigate();

    const handlePayment = (airwaybill: string | undefined) => {
        window.location.replace(`${process.env.REACT_APP_SNAP_API_MIDTRANS}${airwaybill}`);
    }

    const [page, setPage] = useState(1);

    useEffect(() => {
        transactionStore.listTransactionsByUser(page);
        TournamentStore.getMyOrder(String(slug));
    }, [page, TournamentStore]);

    return (
        <div className='w-full lg:w-[70%] mx-auto h-full text-white pb-3'>
            <h3 className='text-[20px] lg:text-[32px] text-white font-extrabold leading-normal text-center mb-[14px]'>Transaction History</h3>
            <div className='w-full lg:w-[100%] h-full text-white mb-3'>
                {TournamentStore.myOrder.length > 0 ? (
                    TournamentStore.myOrder?.map((trans, idx) => (
                        <div key={idx} className="card flex-col card-side bg-nav1 shadow-xl border-md mb-4 lg:flex-row lg:items-center">
                            {
                                trans.tournament_id !== 0 ? 
                                <>
                                <figure className="w-full h-auto rounded-tl-[10px] rounded-tr-[10px] rounded lg:rounded-md object-cover lg:w-[190px] lg:p-4">
                                    <img
                                        src={`${process.env.REACT_APP_STORAGE_URL}${trans.tournament.flag}`}
                                        alt="Movie"
                                        className='w-full h-full object-contain overflow-hidden'
                                    />
                                </figure>
                                <div className="flex-1 p-4">
                                    <h2 className="card-title text-md text-white leading-normal font-bold mb-2">{trans.tournament.name}</h2>
                                    <div className="flex flex-row items-center space-x-1 mb-[6px]">
                                        <div className="badge badge-card badge-card-blue rounded px-2 text-xs text-white font-medium">eFootball Mobile</div>
                                        <div className="badge badge-card badge-card-blue rounded px-2 text-xs text-white font-medium">{tournamentMode(trans.tournament.mode)}</div>
                                        <div className="badge badge-card badge-card-blue rounded px-2 text-xs text-white font-medium">{teamType(trans.tournament.team_type)}</div>
                                    </div>
                                    <div className='flex flex-row items-end justify-between'>
                                        <div>
                                            <div className="flex items-center">
                                                <div className="mr-4">
                                                    <figure>
                                                        <img
                                                            src="/assets/images/Prizepool.png"
                                                            alt="Prizepool"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="text-xs text-white leading-normal font-medium">
                                                    {formatRupiah(trans.tournament.grand_prize)}
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="mr-4">
                                                    <figure>
                                                        <img
                                                            src="/assets/images/Calendar.png"
                                                            alt="Calendar"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="text-xs text-white leading-normal font-medium">
                                                    {formatDateRange(new Date(trans.tournament.start_date), new Date(trans.tournament.end_date))}
                                                </div>
                                            </div>
                                            <p className="text-white text-xs font-medium mt-4 ">Total Pembayaran: {formatRupiah(trans.total_payment)}</p>
                                            <p className="text-white text-xs font-medium">Status: <span className="font-bold">{trans.status === 'active' ? 'pending' : 'success'}</span></p>
                                        </div>

                                        {trans.status === "active" && (
                                            new Date() > new Date(trans.tournament.close_date)
                                            ?
                                            <></>
                                            :
                                            <button className='text-xs font-medium bg-secondary px-4 py-1 lg:py-2 rounded-md flex justify-end' onClick={() => handlePayment(trans.detail.airwaybill)}>Pay Now</button>
                                        )}
                                    </div>
                                </div>
                                </>
                                :
                                <>
                                <div className="flex-1 p-4">
                                    <h2 className="card-title text-md text-white leading-normal font-bold mb-2">{trans.subscription ? trans.subscription.subs.name : ""}</h2>
                                    <div className='flex flex-row items-end justify-between'>
                                        <div>
                                            <p className="text-white text-xs font-medium mt-4 ">Total Pembayaran: {formatRupiah(trans.total_payment)}</p>
                                            <p className="text-white text-xs font-medium">Status: <span className="font-bold">{trans.status === 'active' ? 'pending' : 'success'}</span></p>
                                        </div>

                                        {trans.status === "active" && (
                                            new Date() > new Date(trans.subscription?.subs.periode_end)
                                            ?
                                            <></>
                                            :
                                            <button className='text-xs font-medium bg-secondary px-4 py-1 lg:py-2 rounded-md flex justify-end' onClick={() => handlePayment(trans.detail.airwaybill)}>Pay Now</button>
                                        )}
                                    </div>
                                </div>
                                </>
                            }
                            
                        </div>
                    ))
                ) : (
                    <div className="text-darkgray text-xs font-medium leading-normal text-center mt-[28px]">Data transaksi tournament tidak ditemukan</div>
                )}
            </div>
        </div>
    )
}

export default observer(TransactionHistory);
