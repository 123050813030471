import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import InputPassword from '../../app/component/input/InputPassword';
import { IAccountChangePassword, IAccountForgetPassword } from '../../app/models/account';
import { useStore } from '../../app/stores/store';
import { useNavigate } from 'react-router-dom';

const initValue: IAccountForgetPassword = {
    token: window.localStorage.getItem('reset_token') ? window.localStorage.getItem('reset_token') : '',
    email: '',
    new_password: '',
    new_password_confirmation: ''

};

const ForceResetPassword = () => {
    const { accountStore } = useStore();
    const navigate = useNavigate();
    const [password, setPassword] = useState<IAccountForgetPassword>(initValue);
    const [success, setSuccess] = useState(false);

    const handleSubmit = () => {
        if (password) {
            setPassword((prevState) => ({ ...prevState, token: window.localStorage.getItem('reset_token') }));
            accountStore.resetPassword(password).then(() => {
                setSuccess(true);
                navigate('/login');
            });
        }
    };

    const handleNewPassword = (value: string) => {
        setPassword((prevState) => ({ ...prevState, new_password: value }));
    };

    const handleNewPasswordConfirmation = (value: string) => {
        setPassword((prevState) => ({
            ...prevState,
            new_password_confirmation: value,
        }));
    };

    return (
        <div className="bg-nav1 w-full h-full flex fixed">
            <div className="flex flex-col items-center justify-center m-auto w-[436px] px-4">
                <img
                    className="w-[170px] h-auto xl:w-[239px]"
                    src="/assets/images/IFeL Logo_Website.svg"
                    alt=""
                />
                <h3 className="text-white font-extrabold text-[32px] text-center mb-3 leading-normal">
                    Reset Password Anda
                </h3>

                <div className="relative w-full container mx-auto font-sans">
                    <label htmlFor="password" className="text-white text-xs font-medium">
                        New Password
                    </label>
                    <InputPassword
                        label=""
                        bgColor="bg-nav1"
                        labelColor=""
                        textColor="text-white"
                        border="border-darkgray"
                        px="px-[19px]"
                        py="py-[13px]"
                        mt="mt-2"
                        mb="mb-3"
                        leading="leading-normal"
                        placeholder="New Password"
                        onChange={(value) => handleNewPassword(value)}
                    />
                    <label htmlFor="password" className="text-white text-xs font-medium">
                        Confirm Password
                    </label>
                    <InputPassword
                        label=""
                        bgColor="bg-nav1"
                        labelColor=""
                        textColor="text-white"
                        border="border-darkgray"
                        px="px-[19px]"
                        py="py-[13px]"
                        mt="mt-2"
                        mb="mb-[38px]"
                        leading="leading-normal"
                        placeholder="Confirm Password"
                        onChange={(value) => handleNewPasswordConfirmation(value)}
                    />
                    <div className="w-full h-full text-center pt-2">
                        <button
                            onClick={handleSubmit}
                            disabled={
                                !password.new_password ||
                                password.new_password_confirmation === '' ||
                                password.new_password !== password.new_password_confirmation
                            }
                            className={`w-full px-10 py-3 text-xs disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-darkgray text-white font-semibold bg-secondary border-none rounded-md hover:bg-nav2 hover:text-secondary transition duration-300`}
                        >
                            Reset Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(ForceResetPassword);
