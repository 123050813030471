import React, { useEffect, useState } from 'react'
import 'aos/dist/aos.css';
import { observer } from 'mobx-react-lite';
import DetailTournamentBanner from './DetailTournamentBanner';
import './detail.css'
import DetailDescription from './DetailDescription';
import DetailRules from './DetailRules';
import DetailParticipant from './DetailParticipant';
import DetailBracket from './DetailBracket';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../app/stores/store';
import '../../../index.css'
import { items } from '../../../app/config/menu';

const initiateValues = {
    tournamentId: 0,
    id: 0
}

const TournamentDetail = () => {
    const [activeTab, setActiveTab] = useState(1);
    const { TournamentStore } = useStore();
    const { slug } = useParams<{ slug: string }>();
    const [value, setValue] = useState(initiateValues);

    useEffect(() => {
        TournamentStore.getDetailTournaments(String(slug));
    }, [TournamentStore]);

    const [menu, setMenu] = useState([
        {
            id: 1,
            name: "Detail Tournament",
            active: true
        },
        {
            id: 2,
            name: "Rules",
            active: false
        },
        {
            id: 3,
            name: "Daftar Pemain",
            active: false
        },
        {
            id: 4,
            name: "Bracket & Matches",
            active: false
        },
    ]);

    const handleMenu = (buttonId: number) => {
        const updatedButtons = menu.map(button => {
            if (button.id === buttonId) {
                if (buttonId === 2) {
                    if (TournamentStore.detail) {
                        const tournamentId = TournamentStore.detail.id
                        setValue((prevState) => ({ ...prevState, tournamentId }))
                        TournamentStore.getRulesLists(Number(tournamentId));
                    }
                }
                if (buttonId === 3) {
                    if (TournamentStore.detail) {
                        const tournamentId = TournamentStore.detail.id
                        setValue((prevState) => ({ ...prevState, tournamentId }))
                        TournamentStore.getParticipantTournament(Number(tournamentId));
                    }
                }
                return { ...button, active: true };
            } else {
                return { ...button, active: false };
            }
        });
        setMenu(updatedButtons);
        setActiveTab(buttonId);
    }

    return (
        <div className='w-full h-full font-sans p-0 m-0 bg-nav1'>
            <div className='w-full h-full bg-bg-home bg-cover bg-no-repeat'>
                <DetailTournamentBanner data={TournamentStore.detail} />
                <React.Fragment>
                    <div className='w-full h-full bg-primary lg:bg-bg-home bg-top bg-no-repeat font-sans'>
                        <div className='w-full h-full lg:px-20 xl:px-40 py-4 lg:py-12'>
                            <div id='tab' className='w-full flex flex-row menu-tab overflow-x-scroll scrollbar-hidden border-b border-darkgray mb-8 px-4 lg:px-0 lg:space-x-2 xl:space-x-4'>
                                {menu.map((item, idx) => (
                                    <button key={idx} className={`text-sm leading-[14px] font-semibold px-[10px] mr-[10px] pb-4 whitespace-nowrap border-b-2 ${item.active ? 'active text-secondary font-semibold border-secondary' : 'text-darkgray font-medium border-transparent'} ${idx === 0 ? 'pl-4' : idx === items.length - 1 ? 'pr-4' : ''}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                                ))}
                            </div>
                            {
                                {
                                    1: <DetailDescription data={TournamentStore.detail} />,
                                    2: <DetailRules data={TournamentStore.rules} tournament={TournamentStore.detail} />,
                                    3: <DetailParticipant
                                        data={TournamentStore.participantsLists}
                                        slot={String(TournamentStore.detail?.open_slot)} />,
                                    4: <DetailBracket data={TournamentStore.detail} />,
                                }[activeTab]
                            }
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </div>
    )
}

export default observer(TournamentDetail)