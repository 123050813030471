import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import CommonStore from "./commonStore";
import PengeluaranStore from "./pengeluaranStore";
import LeagueStore from "./leagueStore";
import StoresStore from "./StoresStore";
import TransactionStore from "./transactionStore";
import PostStore from "./postStore";
import ClubStore from "./clubStore";
import TournamentStore from "./TournamentStore";
import notificationStore from "./notificationStore";
import generalStore from "./generalStore";
import orderStore from "./orderStore";
import PlayerStore from "./playerStore";
import SubcriptionStore from "./SubscriptionStore";
import PlayerRankStore from "./playerRankStore";

interface Store {
    // Pengeluaran
    pengeluaranStore : PengeluaranStore,
    commonStore : CommonStore,
    accountStore : AccountStore,
    leagueStore : LeagueStore,
    storesStore: StoresStore,
    transactionStore: TransactionStore,
    postStore: PostStore,
    clubStore: ClubStore,
    TournamentStore: TournamentStore,
    notificationStore: notificationStore,
    generalStore: generalStore,
    orderStore: orderStore,
    PlayerStore: PlayerStore,
    SubscriptionStore : SubcriptionStore,
    playerRank : PlayerRankStore
}

export const store: Store = {
    pengeluaranStore: new PengeluaranStore(),
    commonStore: new CommonStore(),
    accountStore: new AccountStore(),
    leagueStore: new LeagueStore(),
    storesStore: new StoresStore(),
    transactionStore: new TransactionStore(),
    postStore: new PostStore(),
    clubStore: new ClubStore(),
    TournamentStore: new TournamentStore(),
    notificationStore: new notificationStore(),
    generalStore: new generalStore(),
    orderStore: new orderStore(),
    PlayerStore: new PlayerStore(),
    SubscriptionStore : new SubcriptionStore(),
    playerRank : new PlayerRankStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}