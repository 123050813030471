import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../app/stores/store';
import { Tooltip, Typography } from "@material-tailwind/react";

const Verification = () => {
    const { accountStore } = useStore();
    const [count, setCount] = useState<number | null>(2);
    const [emailSent, setEmailSent] = useState(true);
    const [showContent, setShowContent] = useState(false)

    let countdownInterval: NodeJS.Timeout;

    const styles = {
        shadow: {
            boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.50)'
        },
    };

    const handleSendEmail = () => {
        accountStore.sendVerifiedEmail()
        setEmailSent(true);
        setCount(2);
    };

    const toggleMenu = () => {
        setShowContent(!showContent);
    }

    useEffect(() => {
        if (count !== null && count > 0) {
            countdownInterval = setInterval(() => {
                setCount((prevCount) => (prevCount !== null ? prevCount - 1 : null));
            }, 1000);
        } else if (count === 0) {
            setEmailSent(false);
            clearInterval(countdownInterval);
        }

        return () => {
            clearInterval(countdownInterval);
        };
    }, [count]);

    return (
        <div className="bg-nav1 w-full h-full flex fixed">
            <div className='flex flex-col items-center justify-center m-auto w-[436px] px-4'>
                <h1 className='text-white font-extrabold text-[32px] text-center leading-normal mb-3 xl:mb-0'>Email sudah berhasil terkirim</h1>
                <p className='text-white text-xs font-semibold text-center leading-normal mb-4 xl:mb-0'>
                    Harap periksa email Anda untuk verifikasi Akun
                </p>
                <div className='flex flex-col items-center mb-4'>
                    {emailSent ? (
                        <p className='text-gray-600 font-semibold text-xs mr-1 mb-1 leading-normal cursor-pointer'>Tunggu {count} detik untuk mengirim ulang</p>
                    ) : (
                        <p className='text-secondary font-semibold text-xs lg:mr-1 mb-1 leading-normal cursor-pointer' onClick={handleSendEmail}>Kirim ulang email</p>
                    )}
                    <div className='flex flex-row items-center mb-3 relative'>
                        <p className='text-darkgray font-semibold text-xs mr-1 leading-normal'>Belum menerima email</p>
                        <div onClick={toggleMenu}>
                            <Tooltip
                                content={
                                    <div className="w-full">
                                        <Typography
                                            variant="small"
                                            color="white"
                                            className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal"
                                        >
                                            Harap cek email anda
                                        </Typography>
                                    </div>
                                }
                                placement="right-start"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                >
                                    <path
                                        d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                        fill="#475569"
                                    />
                                </svg>
                            </Tooltip>
                        </div>
                    </div>
                    {showContent && (
                        <div className="flex flex-col w-[312px] h-auto p-2 rounded-xl" style={styles.shadow}>
                            <h2 className='text-white text-xs font-bold leading-normal flex justify-left mb-1'>Belum menerima email?</h2>
                            <ol className='flex flex-col list-decimal list-inside'>
                                <li className='text-white text-[10px] font-semibold leading-11'>Periksa Folder Spam atau Sampah
                                    <div className='flex flex-row items-start'>
                                        <div className='w-[4px] h-[4px] rounded-sm bg-white mx-1 mt-1'></div>
                                        <div className='text-darkgray text-[10px] font-medium leading-11'>Terkadang Email dapat masuk ke folder spam atau sampah. Silahkan
                                            <br />periksa folder tersebut</div>
                                    </div>
                                </li>
                                <li className='text-white text-[10px] font-semibold leading-11'>Periksa kembali Alamat Email Anda
                                    <div className='flex flex-row items-start'>
                                        <div className='w-[4px] h-[4px] rounded-sm bg-white mx-1 mt-1'></div>
                                        <div className='text-darkgray text-[10px] font-medium leading-11'>Pastikan Anda memasukkan alamat email yang benar untuk akun Anda.
                                            <br /> Kesalahan pengetikan dapat menghalangi Anda menerima tautan reset.</div>
                                    </div>
                                </li>
                                <li className='text-white text-[10px] font-semibold leading-11'>Masalah Dengan Penyedia Layanan Email
                                    <div className='flex flex-row items-start'>
                                        <div className='w-[4px] h-[4px] rounded-sm bg-white mx-1 mt-1'></div>
                                        <div className='text-darkgray text-[10px] font-medium leading-11'>Masalah sementara dengan penyedia layanan email Anda dapat
                                            <br /> menyebabkan keterlambatan dalam menerima email Anda</div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default observer(Verification);

