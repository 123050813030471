import { Typography } from 'antd';
import React, { useState } from 'react'
import { IContact } from '../../app/models/contacts';
import { useStore } from '../../app/stores/store';

function TermsPage() {

    const { Title } = Typography;

    const { accountStore } = useStore();

  return (
    <div className='w-full h-[100wh] lg:px-40 lg:py-12 bg-bg-home bg-primary bg-no-repeat bg-cover font-sans'>
      <style>{`
        .bg-pass input {
          background: #1C1F2A;
          color: white;
        }
        .scroll-able{
          overflow : scroll;
        }
        `}
      </style>
      <div className='w-full h-[620px] flex flex-row bg-primary lg:rounded-xl'>
        <div className='hidden relative w-6/12 bg-bg-contact bg-secondary bg-no-repeat bg-cover rounded-l-lg px-12 pt-16 lg:flex flex-col'>
          <div className='text-white'>
            <h1 className='font-extrabold text-[58px]'>Terms & Conditions</h1>
            <p className='font-bold text-[1.8vw]'>
                 Syarat Penggunaan dan Kebijakan Privasi Indonesian Football e-League
            </p>
          </div>
        </div>
        <div className='w-full lg:w-6/12 lg:pt-10 flex flex-col p-4 lg:px-28 scroll-able' >
            <div className='w-full'>
              <h2 className='text-3xl font-semibold mb-4'>Syarat Penggunaan</h2>
              <p className='mb-4'>
              Dengan menggunakan situs di website kami, Anda setuju untuk mengikuti syarat dan ketentuan berikut:
              </p>
              <ol className='list-decimal pl-6 mb-6'>
                <li>
                  Pendaftaran Tournament:
                  <ul className='list-disc pl-8 mb-2'>
                    <li>Anda setuju untuk memberikan informasi akurat dan lengkap saat mendaftar untuk tournament.</li>
                    <li>
                      Kami berhak untuk menolak atau menghapus akun yang diduga mengandung informasi palsu atau menyalahi ketentuan dalam mengikuti kompetisi pada website Indonesian Football e-League.
                    </li>
                  </ul>
                </li>
                {/* Add similar list items for other points */}
              </ol>
              <h2 className='text-3xl font-semibold mb-4'>Kebijakan Privasi</h2>
              <p className='mb-4'>
                Privasi terkait informasi peserta sangat penting bagi kami. Berikut adalah informasi tentang bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi pribadi Anda:
              </p>
              <ol className='list-decimal pl-6'>
                <li>
                  Informasi yang Kami Kumpulkan:
                  <ul className='list-disc pl-8 mb-2'>
                    <li>Saat Anda mendaftar untuk tournament, kami mengumpulkan informasi seperti nama lengkap, alamat email, dan nomor telepon untuk kami gunakan dalam berjalannya Tournament.</li>
                  </ul>
                </li>

                <li>
                    Penggunaan Informasi:
                  <ul className='list-disc pl-8 mb-2'>
                    <li>- Informasi yang Anda berikan akan digunakan untuk keperluan administrasi turnamen dan untuk menghubungi pemenang turnamen jika diperlukan.</li>
                    <li>- Kami tidak akan membagikan informasi pribadi Anda kepada pihak ketiga tanpa izin Anda, kecuali jika diperlukan oleh hukum.</li>
                  </ul>
                </li>

                <li>
                Keamanan Informasi
                  <ul className='list-disc pl-8 mb-2'>
                    <li>Kami mengambil langkah-langkah keamanan untuk melindungi informasi pribadi Anda dari akses yang tidak sah atau penggunaan yang tidak sah dari pihak lain.</li>
                  </ul>
                </li>

                <li>
                Penghapusan Informasi pribadi terkait akun:
                  <ul className='list-disc pl-8 mb-2'>
                    <li>Anda berhak meminta penghapusan informasi terkait pribadi Anda dari sistem kami pada website Indonesian Football e-League.</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TermsPage