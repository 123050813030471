import React, { useState, useEffect } from 'react';

interface CountdownProps {
    targetDate: Date;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime() - 7 * 60 * 60 * 1000;;
            const targetTime = new Date(targetDate).getTime() - 7 * 60 * 60 * 1000; // Tambahkan 7 jam
            const distance = targetTime - now;

            if (distance <= 0) {
                clearInterval(interval);
                setTimeLeft(0);
            } else {
                setTimeLeft(distance);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    // Format waktu dalam format "DD:HH:MM:SS"
    const formattedTime = `${String(days).padStart(2, '0')}:${String(hours).padStart(
        2,
        '0'
    )}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  
    return (
        <div>
            {timeLeft > 0 ? (
                <div className="text-white font-bold text-[11px]">
                    {formattedTime}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Countdown;
