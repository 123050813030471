import { observer } from "mobx-react-lite";
function EmptyAchievement() {
  return (
    <div className="w-full py-4">
      <h3 className="text-white font-bold text-[1.125rem] lg:text-[1.125rem] mt-2 divide-gray-700">
        Achievement
      </h3>
      {/* <hr /> */}
      <div className="achievement w-full h-full flex lg:space-x-2 bg-nav1 rounded-md mt-2 px-4 py-5">
          <span className="text-start lg:pl-0 text-[0.75rem] lg:text-[0.75rem] font-semibold text-gray-500">
            Achievement tidak ditemukan
          </span>
      </div>
    </div>
  );
}

export default observer(EmptyAchievement);
