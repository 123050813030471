import React from 'react'

function ProfileTournaments() {
  return (
    <div className='w-full bg-nav2 rounded-lg flex flex-col justify-center items-center p-4 lg:px-12 font-sans space-y-2'>
        <div className='relative w-full h-[40vw] lg:h-[20vw] bg-bg-tournament bg-no-repeat bg-cover rounded-lg lg:rounded-xl text-white text-center flex flex-col justify-end'>
            <div className="absolute inset-0 bg-gradient-to-t lg:bg-gradient-to-r from-black to-transparent rounded-l-xl"></div>
            <div className='absolute p-4 lg:px-12'>
                <h2 className='font-bold text-[4.5vw] lg:text-[2vw]'>Tournament: Coming Soon</h2>
                <p className='text-[3vw] lg:text-[1.2vw] font-normal'>Sign up for updates on our website, and be the first to know when the tournament kicks off. </p>
            </div>
        </div>
    </div>
  )
}

export default ProfileTournaments