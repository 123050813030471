import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "./detail.css";
import { useStore } from "../../../app/stores/store";
import { ITournamentParticipant } from "../../../app/models/tournament";
import { items } from "../../../app/config/menu";
import SkeletonComponent from "../../../app/component/skeleton/SkeletonComponent";
import { useNavigate } from "react-router-dom";

interface Props {
  data: ITournamentParticipant[];
  slot: string
}

interface Player {
  level: string;
}

const renderLevelTag = (level: string , is_subscribe : boolean) => {
  if (level === 'amateur') {
    return <div className={`badge badge-outline-${is_subscribe ? 'blue' : 'amateur'} tag`}>Amateur</div>;
  } else if (level === 'semi') {
    return <div className={`badge badge-outline-${is_subscribe ? 'blue' : 'semi'} tag`}>Semi-Pro</div>;
  } else {
    return <div className={`badge badge-outline-${is_subscribe ? 'blue' : 'pro'} tag`}>Pro-Player</div>;
  }
};


const DetailParticipant: React.FC<Props> = ({ data, slot }) => {

  const [isModalViewOpen, setIsModalViewOpen] = useState<boolean[]>(Array(data.length).fill(false));
  const navigate = useNavigate();

  const viewProfile = (idx: number) => {
    const newModalStates = [...isModalViewOpen];

    for (let i = 0; i < newModalStates.length; i++) {
      newModalStates[i] = false;
    }

    newModalStates[idx] = !newModalStates[idx];
    setIsModalViewOpen(newModalStates);
  };

  const toProfile = (value: string) => {
    navigate(`/player/${value}`)
  };

  return (
    <React.Fragment>
      <div className="w-full h-full flex flex-col px-4 lg:px-0 lg:mb-12">
        <div className="w-full h-full divide-y-reverse divide-y-2 divide-gray-700">
          <h3 className="text-white font-bold text-[22px] leading-normal">
            Participants
          </h3>
          <p className="hidden text-darkgray text-xs font-bold leading-[14px] pb-4 lg:block">
            Player <span className="font-medium">({data.length}/{slot})</span>
          </p>
        </div>
        {!data ? (
          items.map((item, index) => (
            <div key={index}
              className='bg-nav1 flex flex-row items-center mb-2'>
              <SkeletonComponent
                count={1}
                width='w-[58px]'
                height='h-[94px]'
                borderRadius='rounded'
              />
              <div className='flex flex-col items-start ml-2'>
                <SkeletonComponent
                  count={1}
                  width='w-[90px]'
                  height='h-[15px]'
                  borderRadius='rounded-none'
                  marginBottom="mb-2"
                />
                <SkeletonComponent
                  count={1}
                  width='w-[200px]'
                  height='h-[15px]'
                  borderRadius='rounded-none'
                />
              </div>
            </div>
          ))
        ) : (
          data?.map((item, idx) => (
            <div className="w-full h-full" key={idx}>
              <div className="w-full h-full flex flex-row justify-between items-center py-4">
                <div className="card card-side flex flex-col">
                  <div className="flex mb-1">
                    <span className={`text-${item.is_subscribe ? 'secondary' : 'white'} font-semibold text-xs leading-normal`}>
                      {item.username}
                      {/* &nbsp; */}
                    </span>
                    {/* <p className="text-darkgray font-normal text-xs leading-normal"> / {item.team}</p> */}
                  </div>
                  {renderLevelTag(item.level , item.is_subscribe)}
                  {/* <div className="badge badge-accent badge-outline text-[0.575rem] lg:text-[0.575rem] mx-1 mt-1">{item.level}</div> */}
                </div>
                <div className="relative">
                  <div className="more cursor-pointer" onClick={() => viewProfile(idx)}>
                    <img src="/assets/images/ep_more-filled.svg" alt="More" />
                  </div>
                  {isModalViewOpen[idx] && (
                    <div className={`border-[.5px] bg-nav2 border-darkgray rounded py-[14px] px-4 absolute top-[15px] right-0 w-[150px] ${isModalViewOpen ? 'block' : 'hidden'}`}>
                      <div className="relative" onClick={() => toProfile(item.slug)}>
                        <p className="text-white text-xs font-bold leading-normal hover:text-secondary cursor-pointer">Lihat Profile</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <hr className="h-0.5 bg-gray-700 border-0 dark:bg-gray-700" />
            </div>
          ))
        )
        }
      </div>
    </React.Fragment>
  );
}

export default observer(DetailParticipant);
