import React, { useEffect, useState } from 'react'
import InputScore from '../modal/InputScore';
import { useStore } from '../../stores/store';
import { useParams } from 'react-router-dom';
import { calculateAge, formatDate, formatDateSchedule } from '../../config/enum';
import Countdown from '../countdown/Countdown';
import '../../../index.css'
import DummyPlayerCard from './dummy/DummyPlayerCard';
import EmptyPlayerCard from './empty/EmptyPlayerCard';
import MatchPlayerCard from './MatchPlayerCard';
import InputSuccess from '../modal/InputSuccess';

interface ModalProps {
    matches: any[];
    item: any;
    tournament_name: string;
}

const MatchContentCard: React.FC<ModalProps> = ({ matches, tournament_name, item }) => {
    console.log(item);

    const [matchIndex, setMatchIndex] = useState<number>(0);
    const { accountStore, TournamentStore } = useStore();
    const { slug } = useParams<{ slug: string }>();
    const [showInputScore, setIsModalInputOpen] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        TournamentStore.getMyOngoingMatch(String(slug))
    })

    const inputModalScore = () => {
        document.body.style.overflow = 'hidden';
        setIsModalInputOpen(true);
    };

    const closeModalSuccess = () => {
        document.body.style.overflow = 'visible';
        setIsModalInputOpen(false);
        setShowSuccessModal(true);
        TournamentStore.getMyOngoingMatch(String(slug))
    }

    const closeModal = () => {
        document.body.style.overflow = 'visible';
        setIsModalInputOpen(false);
    }

    // const initialMatch = {
    //     home_card: item[0].home_card,
    //     away_card: item[0].away_card,
    //     title: item[0].title,
    //     match_date: item[0].match_date,
    //     closing_date: item[0].closing_date
    // };

    // useEffect(() => {
    //     accountStore.getProfileUser(String(slug));
    //     // changeMatch(matchIndex)
    // })

    // const changeMatch = (idx: number) => {
    //     console.log(item[idx]);

    //     initialMatch.home_card = item[idx].home_card;
    //     initialMatch.away_card = item[idx].away_card
    //     initialMatch.title = item[idx].title
    //     initialMatch.match_date = item[idx].match_date
    //     initialMatch.closing_date = item[idx].closing_date
    //     console.log(initialMatch);

    // }

    const handleSelectMatch = (value: string) => {
        setMatchIndex(Number(value))
    };

    return (
        <React.Fragment>
            <div className='w-full h-auto relative mt-4'>
                <div className="w-full h-[44px] bg-[#1D2335] flex flex-row justify-between items-center py-4 lg:py-1 rounded-tl-[10px] rounded-tr-[10px] lg:h-[20px]">
                    <h6 className='w-[60%] px-4 flex flex-row items-center justify-start text-[10px] leading-normal font-bold text-white text-left lg:text-center lg:justify-center lg:w-full'>{tournament_name}</h6>
                </div>
                <div className="w-full h-full flex flex-col justify-center items-start bg-nav1 lg:px-6 lg:flex-row lg:items-center lg:justify-between rounded-bl-[10px] rounded-br-[10px] lg:rounded-none">
                    <select
                        name="date"
                        id="select_date"
                        defaultValue="Match 1"
                        onChange={(e) => handleSelectMatch(e.target.value)}
                        className="select-inherit block absolute top-1 right-4 w-auto h-[33px] bg-transparent px-6 text-[#565D6B] text-xs font-semibold border-[.5px] border-solid border-[#565D6B bg-[#1C1F2A] rounded-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none lg:h-[32px] lg:px-[10px] lg:top-unset lg:right-unset lg:rounded"
                    >
                        {matches.map((item1: any, index1: number) => (
                            <option value={String(index1)} key={index1}>{item1.title}</option>
                        ))}
                    </select>
                    <div className='w-full flex flex-col justify-center items-center flex-1 px-5 py-3'>
                        <div className='w-full h-full flex flex-col justify-center items-center lg:flex-row px-2 lg:px-0'>
                            <div className='w-full flex flex-row justify-center items-center mb-3 lg:mb-0 lg:w-auto lg:mr-4'>
                                <div id='home' className='flex flex-row w-full h-full justify-center items-center'>
                                    <div className='w-full flex flex-col text-end font-semibold justify-end mr-1 order-2 lg:order-1 lg:w-auto'>
                                        <div className="flex flex-row items-center lg:justify-end">
                                            <span className='mr-1 order-2 lg:order-1 flex'>{renderLevelTag(matches[matchIndex].home_card.level)}</span>
                                            <span className='text-white text-[9px] font-semibold leading-[10px] lg:text-base mr-1 lg:mr-0 order-1 lg:order-2'>{matches[matchIndex].home_card.username}</span>
                                        </div>
                                    </div>
                                    <div className='w-[33px] h-[54px] flex flex-row justify-end items-center order-1 mr-5 lg:mr-0 lg:ml-1'>
                                        <MatchPlayerCard
                                            player={matches[matchIndex].home_card.level}
                                            data={matches[matchIndex].home_card}
                                        />
                                    </div>
                                    <div className='flex flex-row items-center ml-4 order-3 lg:order-1'>
                                        <span className='text-[10px] lg:text-[18px] text-white font-bold mr-1'>{matches[matchIndex].is_count ? matches[matchIndex].home_score : ''} </span>
                                        <span className='text-[10px] text-darkgray font-bold leading-normal'>{matches[matchIndex].is_count && matches[matchIndex].home_penalty ? `(${matches[matchIndex].home_penalty})` : ''}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='hidden flex-row justify-center items-center text-darkgray font-bold text-[18px] lg:flex'>
                                VS
                            </div>
                            <div className='w-full flex flex-row justify-center items-center lg:w-auto lg:ml-4'>
                                <div id='away' className='flex flex-row w-full h-full justify-center items-center'>
                                    <div className='flex flex-row items-center order-3 lg:order-1 lg:mr-4'>
                                        <span className='text-[10px] lg:text-[18px] text-white font-bold mr-1'>{matches[matchIndex].is_count ? matches[matchIndex].away_score : ''}</span>
                                        <span className='text-[10px] text-darkgray font-bold leading-normal'>{matches[matchIndex].is_count && matches[matchIndex].away_penalty ? `(${matches[matchIndex].away_penalty})` : ''}</span>
                                    </div>
                                    <div className='w-[33px] h-[54px] flex flex-row justify-end items-center order-1 mr-5 ml-0 lg:mr-0'>
                                        <MatchPlayerCard
                                            player={matches[matchIndex].away_card.level}
                                            data={matches[matchIndex].away_card}
                                        />
                                    </div>
                                    <div className='w-full flex flex-col text-end font-semibold justify-end lg:ml-1 order-2 lg:w-auto'>
                                        <div className="flex flex-row items-center lg:justify-start">
                                            <span className='text-white text-[9px] font-semibold leading-[10px] lg:text-base'>{matches[matchIndex].away_card.username}</span>
                                            <span className='ml-1 order-2 lg:order-1 flex'>{renderLevelTag(matches[matchIndex].away_card.level)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className='w-full h-full text-darkgray text-[8px] font-medium text-left lg:text-center mt-2'>{item.stage ? item.stage.name : ''} {item.bracket} / {formatDate(matches[matchIndex].match_date.toString())} GMT +7 / {item.tournament.platform}</span>
                    </div>
                    <div className='w-full bg-[#1D2335] rounded-bl-[10px] rounded-br-[10px] p-2 lg:bg-transparent lg:rounded lg:p-0 lg:w-auto'>
                        {new Date() < new Date(matches[matchIndex].closing_date) ?
                            <div className='w-full flex flex-row items-center justify-between lg:flex-col lg:items-center '>
                                <button className='bg-secondary w-[87px] h-8 rounded-md text-white text-[10px] font-bold leading-normal order-2 lg:order-1' onClick={inputModalScore}>Isi Score</button>
                                <div className='text-darkgray text-[8px] font-semibold order-1 lg:order-2'>Isi sebelum <span className='text-white text-[8px] font-bold'><Countdown targetDate={matches[matchIndex].closing_date} /></span></div>
                            </div>
                            :
                            <div className='w-full flex flex-row justify-right'>
                                <button className='bg-secondary w-[87px] h-8 rounded-md text-white text-[10px] font-bold leading-normal' style={{ marginLeft: 'auto' }} onClick={inputModalScore}>Lihat Score</button>
                            </div>
                        }
                    </div>
                </div>
                <div className='hidden lg:flex bg-transparent text-darkgray text-[10px] font-medium leading-normal flex-row items-center justify-center text-left rounded-none mt-2 lg:text-center lg:mt-0 lg:bg-[#1D2335] lg:rounded-bl-[10px] lg:rounded-br-[10px] lg:py-1 lg:h-4'>Hubungi Lawan Anda dan lakukan Pertandingan sebelum: <span className='text-white text-[10px] font-medium leading-normal ml-1'><Countdown targetDate={matches[matchIndex].match_date} /></span></div>
                {showInputScore &&
                    <InputScore data={accountStore.profile} onOpenInputModal={showInputScore} onClose={closeModal} onSuccess={closeModalSuccess} item={matches[matchIndex]}></InputScore>
                }
                {showSuccessModal &&
                    <InputSuccess onSuccessInput={showSuccessModal} onClose={() => setShowSuccessModal(false)}></InputSuccess>
                }
            </div>
        </React.Fragment>
    )
}

const renderLevelTag = (level: string) => {
    if (level === 'amateur') {
        return <div className="badge badge-outline-amateur tag">Amateur</div>;
    } else if (level === 'semi') {
        return <div className="badge badge-outline-semi tag">Semi-Pro</div>;
    } else {
        return <div className="badge badge-outline-pro tag">Pro</div>;
    }
};

export default MatchContentCard