import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import '../topNavbar/topNavbar.css';
import { useStore } from '../../stores/store';
import { IAccountChangeProfilePicture } from '../../models/account';
import AlertTopBlue from '../alerts/AlertTopBlue';

interface ModalProps {
    onOpenModalChangePicture: boolean;
    onClose: () => void;
    slug: string
}

const ChangeCardPicture: React.FC<ModalProps> = ({ onOpenModalChangePicture, onClose, slug }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { accountStore, notificationStore } = useStore();
    const [showModal, setShowModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState('');

    useEffect(() => {
        if (showModal) {
            setShow('show');
        }
    })

    const initialChangePictureValues: IAccountChangeProfilePicture = {
        slug: slug,
        image: ''
    };

    const [valueChangePicture, setValueChangePicture] = useState<IAccountChangeProfilePicture>(
        initialChangePictureValues
    );

    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'visible';
    }

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageName, setImageName] = useState<string | null>(null);

    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
            setImageName(selectedFile.name)
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);

            const formData = new FormData();
            formData.append('image', selectedFile);
            accountStore.setAvatar({ data: formData }).then((res) => {
                setValueChangePicture((prevState) => ({ ...prevState, image: res.data }));
            })
        }

    };

    const handleSubmit = async () => {
        setLoading(true)
        await accountStore.updateProfilePicture(valueChangePicture).then(() => {
            handleClose()
            notificationStore.setNotification('Permintaan Anda sedang diproses, menunggu Admin memverifikasi foto Anda')
            showToast()
        }).finally(() => {
            setLoading(false)
        })
    }

    const showToast = () => {
        toast(<AlertTopBlue onOpen={true} />, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    const handleClose = () => {
        setShowModal(false);
    };

    const deleteImage = () => {
        setFile(null);
        setImageSrc(null);
        setImageName(null);
    }

    return (
        <div>
            {showModal && (
                <div>
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className={`modal overflow-y-scroll w-full h-fit mt-[64px] xl:w-[438px] lg:m-auto lg:h-full lg:max-h-[90vh] ${show}`}>
                        <div className="modal-content p-4 lg:p-5">
                            <button className="close" onClick={handleClose}>
                                <img
                                    className="w-6 h-6"
                                    src="/assets/images/close.svg"
                                    alt="close"
                                ></img>
                            </button>
                            <div>
                                <div className="flex flex-row items-start mb-[14px]">
                                    <h3 className="text-white flex flex-row mx-auto font-extrabold text-[32px] leading-normal">
                                        Upload Foto Anda
                                    </h3>
                                </div>
                                <div className="w-full flex justify-center text-center items-center mx-auto mb-4">
                                    <figure>
                                        <img
                                            src='/assets/images/broze-upload.png'
                                            alt="upload_amateur"
                                            className="w-[74px] h-[120px]"
                                        />
                                    </figure>
                                </div>
                                <div className="w-full mb-4">
                                    {imageSrc
                                        ?
                                        <label className="h-full flex flex-col justify-center items-center w-full bg-transparent rounded-3xl border border-darkgray cursor-pointer hover:border-secondary mb-2">
                                            <div className="flex flex-row justify-between items-center py-5 px-2 w-full">
                                                <div className="text flex flex-row items-center">
                                                    <img
                                                        src="/assets/images/file-solid.svg"
                                                        alt="Shoes"
                                                        className="w-[1.5rem]"
                                                    />
                                                    <p className="text-sm text-secondary dark:text-secondary-400 mx-2 font-semibold text-[0.875rem] underline">
                                                        {imageName}
                                                    </p>
                                                </div>


                                                <img
                                                    src="/assets/images/remove-image.svg"
                                                    alt="Shoes"
                                                    className="w-[1.5rem] cursor-pointer"
                                                    onClick={deleteImage}
                                                />
                                            </div>
                                        </label>
                                        : <></>
                                    }
                                    <label className="h-[205px] flex flex-col justify-center items-center w-full bg-transparent rounded-3xl border border-darkgray cursor-pointer hover:border-secondary">
                                        <figure className="mb-[17px]">
                                            <img
                                                src={!imageSrc ? '/assets/images/solar_upload-square-bold.svg' : imageSrc}
                                                alt="upload"
                                                className="w-[93px] h-[93px]"
                                            />

                                        </figure>
                                        <span className="font-semibold text-sm leading-[19px] text-center text-darkgray">
                                            Upload Foto Profile
                                        </span>
                                        <input
                                            id="dropzone-file"
                                            name="avatar"
                                            type="file"
                                            className="hidden"
                                            onChange={handleFileChange}
                                        />

                                        {/* For Uploaded Image */}
                                    </label>
                                </div>
                                <h3 className="text-white text-start font-bold text-[22px] leading-normal mb-4">
                                    Syarat Foto dalam Profil:
                                </h3>
                                <ul className="text-darkgray text-sm leading-[19px] font-semibold list-inside list-disc indented-list w-[343px] mb-[56px]">
                                    <li>Persiapkan format foto tanpa background(PNG)</li>
                                    <li>Pastikan foto Anda memiliki kualitas tinggi dan pencahayaan yang baik</li>
                                    <li>Pastikan foto Anda sesuai dengan ukuran siluet seperti yang ada di contoh</li>
                                    <li>Harap penuhi persyaratan di atas agar mempercepat proses verifikasi</li>
                                </ul>
                                <div className="action w-full text-center">
                                    {!loading ?
                                        <button
                                            className="w-full bg-secondary text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                        :
                                        <button
                                            disabled
                                            className="w-full disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-secondary text-secondary hover:bg-nav2 text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                                        >
                                            Please wait
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChangeCardPicture;
