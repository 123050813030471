import { Carousel } from 'antd'
import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { shortenText } from '../../config/enum';
import { useNavigate } from 'react-router-dom';

function CarouselHome() {
    
    const { postStore } = useStore();

    const navigate = useNavigate();

    const regex = /<p>|<\/p>|&#8217;|&#8211;|\[&hellip;\]/g;

    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    const handleClick = (slug: string, categories:number[]) => {
        if(isMobile && categories.find(category => category === 1 && 4)) {
            navigate(`/news/${slug}`);
        }
    }

    useEffect(() => {
        postStore.getListBanner();
    }, [postStore]);

    useEffect(() => {
        AOS.init();
      }, []);

  return (
    <Carousel autoplay autoplaySpeed={4000} pauseOnFocus={false} pauseOnHover={false}>
        {postStore.listBanner.map((banner, idx) => (
            <div className='w-full lg:h-full font-sans' id='slider' key={idx}>
                <div className="relative h-[200px] lg:h-[500px] lg:w-full">
                    <div className="absolute inset-0 bg-gradient-to-t lg:bg-gradient-to-r from-black to-transparent"></div>
                    <img className="object-cover w-full h-full" src={banner.featured_img} alt="gambar" />
                    <div className='absolute w-full h-full lg:w-3/4 px-16 -mt-8 lg:mt-0 text-center lg:text-left inset-0 items-center justify-end lg:items-start lg:justify-center text-white flex flex-col lg:px-20 xl:px-40 space-y-5'>
                        <h1 className='font-extrabold text-xl lg:text-4xl xl:text-5xl' data-aos="fade-right" data-aos-delay="600" data-aos-easing="ease-in-sine" onClick={() => handleClick(banner.slug, banner.categories)}>{banner.title.rendered}</h1>
                        <p className='hidden lg:block w-2/3 font-semibold lg:text-sm xl:text-md' data-aos="fade-right" data-aos-delay="800" data-aos-easing="ease-in-sine">{shortenText(150, banner.excerpt.rendered.replace(regex, ''))}</p>
                        {banner.categories.find(category => category === 1) && <a href={`/news/${banner.slug}`} className='hidden lg:block bg-secondary hover:bg-nav1 text-white lg:text-xs text-sm lg:px-10 lg:py-2 xl:px-14 xl:py-3 rounded-lg capitalize font-sans font-semibold' data-aos="fade-right" data-aos-delay="1000" data-aos-easing="ease-in-sine">Go to article</a>
}                   </div> 
                </div>
            </div>
        ))}
    </Carousel>
  )
}

export default observer(CarouselHome)