import { observer } from 'mobx-react-lite';
import React from 'react'
import { Menu } from '../../config/menu'
import { useStore } from '../../stores/store';

interface Props {
    label: string,
    route?: string,
    children: Array<Menu>,
    icon?: JSX.Element,
}

const NavbarDropdown = (props : Props) => {

    const { label, children} = props;

    const { leagueStore } = useStore();

    const handleCompetitions = (route: string | undefined) => {

        if(route) {
            const x = route.replace('/', '');
            leagueStore.getLeagueByCategory(Number(x)).then( (data) => window.location.replace(`/league${route}/${data}`));
        }
       
    };

    return (
        <div className="dropdown dropdown-hover">
            <label tabIndex={0} className="font-semibold lg:font-bold hover:text-secondary cursor-pointer flex flex-row justify-center items-center">
                {label} 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4 ml-1">
                    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-nav1 rounded-sm w-52 text-white">
                {label === 'Competitions' ? leagueStore.categoryMenu.map((item: Menu, idx) => (
                    <li key={idx} className='hover:text-secondary rounded-md p-2 cursor-pointer' onClick={() => handleCompetitions(item.route)}>{item.label}</li>
                )) : children.map((item: Menu, idx) => (
                    <li key={idx} className='hover:text-secondary rounded-md p-2 cursor-pointer' onClick={() => handleCompetitions(item.route)}>{item.label}</li>
                ))}
            </ul>
        </div>
    )

}

export default observer(NavbarDropdown)