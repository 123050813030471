import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../topNavbar/topNavbar.css';
import { IAccountOnboarding } from '../../models/account';
import { useStore } from '../../stores/store';
import { getPlayerCard, getPlayerSilhouette } from '../../config/enum';
import AlertTopBlue from '../alerts/AlertTopBlue';
import { toast } from 'react-toastify';
import { Tooltip, Typography } from "@material-tailwind/react";
import { any } from 'prop-types';


interface ModalProps {
    onOpenOnboarding: boolean;
    onClose: () => void;
    step: number;
}

const initialOnboardingValues: IAccountOnboarding = {
    name: '',
    birth_date: '1',
    birth_month: 'January',
    birth_year: '2000',
    domisili: 'Jakarta',
    game: 'eFootball',
    platform: 'Console',
    card: 'amateur',
    image: '',
    group_id: 0,
};

const initialSelectedTeam = {
    selected: false,
    flag: '',
    name: '',
};

const Onboarding: React.FC<ModalProps> = ({ onOpenOnboarding, onClose, step }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { accountStore, clubStore, notificationStore, generalStore } = useStore();
    const [showModal, setShowModal] = useState(true);
    const [currentStep, setCurrentStep] = useState(step);
    const [showSearch, setShowSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [active, setActive] = useState('');
    const [textOnboard, setTextOnboard] = useState('Langkah pertama Anda dalam meraih kemenangan dimulai di sini! Dengan membuat Level Card Amateur, Anda dapat segera memulai pertandingan Anda tanpa menunggu. Tidak ada verifikasi yang dibutuhkan dari Admin, cukup mendaftar dan mulai Tournament yang tersedia.')


    const startYear = 1960;
    const currentYear = new Date().getFullYear();

    const yearOptions = [];
    for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push(
        <option key={year} value={year}>
        {year}
        </option>
    );
    }

    const styles = {
        tooltip: {
            zIndex: '999'
        }
    };

    useEffect(() => {
        if (onOpenOnboarding) {
            setActive('active');
        }
    })

    const [valueOnBoarding, setValueOnBoarding] = useState<IAccountOnboarding>(
        initialOnboardingValues
    );

    const [valueSelectedTeam, setValueSelectedTeam] =
        useState(initialSelectedTeam);

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageName, setImageName] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [haveTeam, setHaveTeam] = useState(false);
    const [errorUsername, setErrorUsername] = useState('')

    const setCookie = (name: string, value: string, days: number) => {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + date.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    };

    const handleInputName = (value: string) => {
        if (value.length >= 16) {
            setErrorUsername("Username maksimal 16 karakter!")
        } else {
            setErrorUsername('')
        }
        setValueOnBoarding((prevState) => ({ ...prevState, name: value }));
    };

    const handleInputBirthDate = (value: string) => {
        setValueOnBoarding((prevState) => ({ ...prevState, birth_date: value }));
    };

    const handleInputBirthMonth = (value: string) => {
        setValueOnBoarding((prevState) => ({ ...prevState, birth_month: value }));
    };

    const handleInputBirthYear = (value: string) => {
        setValueOnBoarding((prevState) => ({ ...prevState, birth_year: value }));
    };

    const handleInputDomisili = (value: string) => {
        setValueOnBoarding((prevState) => ({ ...prevState, domisili: value }));
    };

    const handleInputGame = (value: string) => {
        setValueOnBoarding((prevState) => ({ ...prevState, game: value }));
    };

    const handleInputPlatform = (value: string) => {
        setValueOnBoarding((prevState) => ({ ...prevState, platform: value }));
    };

    const handleChooseCard = (value: string) => {
        setValueOnBoarding((prevState) => ({ ...prevState, card: value }));
        if (value === 'amateur') {
            setTextOnboard('Langkah pertama Anda dalam meraih kemenangan dimulai di sini! Dengan membuat Level Card Amateur, Anda dapat segera memulai pertandingan Anda tanpa menunggu. Tidak ada verifikasi yang dibutuhkan dari Admin, cukup mendaftar dan mulai Tournament yang tersedia.');
        } else if (value === 'semi') {
            setTextOnboard('Bagi Anda yang telah mengikuti beberapa kompetisi Indonesian Football e-League: IFeLeague 2, IFeL Invitational Series, IFeL Ramadhan Series dan IFeL National Championship, Anda dapat mendaftarkan diri yang nantinya diperlukan verifikasi oleh Admin.')
        } else if (value === 'pro') {
            setTextOnboard('Player terbaik dari seluruh Indonesia yang telah mengikuti kompetisi Internasional dan Nasional yang diselenggarakan oleh Game Developer, Federal Nasional dan Indonesian Football e-League: IFeLeague 1 dan IFeL SEA Championship.')
        }

    };

    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
            setImageName(selectedFile.name)
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);

            const formData = new FormData();
            formData.append('image', selectedFile);
            accountStore.setAvatar({ data: formData }).then((res) => {
                setValueOnBoarding((prevState) => ({ ...prevState, image: res.data }));
                console.log(valueOnBoarding);
            })
        }

    };

    const handleSubmitOnBoarding = async () => {
        setLoading(true)
        await accountStore.onBoarding(valueOnBoarding).then((res) => {
            console.log(res);
            if (!res) {
                setLoading(false)
                return setError('Card already Exist')
            } else {
                setShowModal(false);
                notificationStore.setNotification('Permintaan Anda sedang diproses, menunggu Admin memverifikasi foto Anda')
                showToast()
                accountStore.getProfileUser(res)
                setLoading(false)
                setCookie('onboarding', 'true', 7);
                window.location.replace((`/profile/${res}`));
            }
        })
    };

    const showToast = () => {
        toast(<AlertTopBlue onOpen={true} />, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    const handleClose = () => {
        // accountStore.closeOnBoarding()
        setShowModal(false);
        setCurrentStep(1);
        setCookie('onboarding', 'true', 7);
        onClose();
    };

    const handleNextStep = () => {
        if (currentStep === 3 && valueOnBoarding.name === '') {
            setErrorUsername("Username Harus Diisi!")
        } else {
            if (currentStep < onboardingSteps.length) {
                setCurrentStep(currentStep + 1);
            }
        }
    };

    const handlePreviousStep = () => {
        setError('')
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleInputSearch = (value: string) => {
        setSearchValue(value)
        setTimeout(() => {
            console.log(value);
            clubStore.searchTeam(value);
        }, 300);
    };

    const handleChooseTeam = (event: React.MouseEvent<HTMLDivElement>) => {
        const clickedValue = event.currentTarget.getAttribute('data-value');
        const selectedFlag = event.currentTarget.getAttribute('data-flag');
        const selectedName = event.currentTarget.getAttribute('data-name');

        setValueOnBoarding((prevState) => ({
            ...prevState,
            group_id: Number(clickedValue),
        }));
        if (selectedFlag) {
            setValueSelectedTeam((prevState) => ({
                ...prevState,
                flag: selectedFlag,
            }));
        }
        if (selectedName) {
            setValueSelectedTeam((prevState) => ({
                ...prevState,
                name: selectedName,
            }));
        }
        setValueSelectedTeam((prevState) => ({
            ...prevState,
            selected: true,
        }));
        setShowSearch(false);
        setSearchValue(null)
    };

    const removeSelectedTeam = () => {
        setValueSelectedTeam((prevState) => ({
            ...prevState,
            selected: false,
        }));
        setSearchValue(null)
        setHaveTeam(true)
        setShowSearch(true)
    }

    useEffect(() => {
        clubStore.getTeamLists();
        generalStore.getProvince();
    }, [clubStore, generalStore]);

    const addTeam = (value: string) => {
        if (value === 'yes') {
            setHaveTeam(true);
            setShowSearch(true);
        } else {
            setHaveTeam(false);
            setShowSearch(false);
        }
    };

    const deleteImage = () => {
        setFile(null);
        setImageSrc(null);
        setImageName(null);
    }

    const onboardingSteps = [
        {
            step: 1,
            label: 'Welcoming',
            content: (
                <div>
                    <img
                        src="/assets/images/ifel-logo-white.png"
                        alt="logo"
                        className="w-[10.625rem] m-auto"
                    />
                    <h3 className="text-white text-center font-bold text-[22px] leading-normal">
                        Selamat datang di
                        <br />
                        Indonesian Football e-League,
                    </h3>
                    <h3 className="text-secondary text-center font-bold text-[22px] leading-normal mb-6">
                        {accountStore.user?.name}!
                    </h3>
                    <figure>
                        <img
                            src="/assets/images/onboarding-group-card.png"
                            alt="Shoes"
                            className="w-[208px] mb-6 mx-auto"
                        />
                    </figure>
                    <p className="text-white text-justify font-semibold text-sm mb-[137px]">
                        Tingkatkan pengalaman Anda dalam dunia sepakbola virtual dan raih
                        kemenangan dalam Tournament Indonesian Football e-League! Buat kartu
                        profil eksklusif Anda dan bersaing dengan para pemain terbaik dari
                        seluruh Indonesia!
                    </p>
                    <div className="flex flex-col items-center justify-between mt-4">
                        <div className="action w-full text-center">
                            <button
                                className="w-full bg-secondary text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                                onClick={handleNextStep}
                            >
                                Buat kartu sekarang!
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            step: 2,
            label: 'Choose Profile',
            content: (
                <div>
                    <h3 className="text-white text-center font-extrabold text-[32px] leading-normal mb-[50px]">
                        Profile
                    </h3>
                    <div className="w-full flex flex-row mb-[38px] justify-between mx-auto lg:px-6 space-x-2">
                        <div className="flex flex-col items-center">
                            <figure className="mb-3">
                                <img
                                    src="/assets/images/Amateur.png"
                                    alt="amateur"
                                    className={
                                        valueOnBoarding.card === 'amateur'
                                            ? 'w-[108px] h-[174px]'
                                            : 'w-[108px] h-[174px] opacity-30'
                                    }
                                />
                            </figure>
                            <label
                                className={
                                    valueOnBoarding.card === 'amateur'
                                        ? 'text-sm leading-normal font-bold text-secondary'
                                        : 'text-sm leading-normal font-bold text-darkgray'
                                }
                                htmlFor="amateurRadio"
                            >
                                Amateur
                            </label>
                            <input
                                className="relative bg-transparent mt-3 w-5 h-5 appearance-none rounded-full border border-solid border-darkgray bg-none after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:bg-nav1 checked:border-secondary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-secondary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] focus:ring-none focus:outline-none !important focus:border-none"
                                type="radio"
                                name="flexRadioDefault"
                                id="amateurRadio"
                                onClick={() => handleChooseCard('amateur')}
                                defaultChecked
                            />
                        </div>
                        <div className="flex flex-col items-center">
                            <figure className="mb-3">
                                <img
                                    src="/assets/images/Semi.png"
                                    alt="semipro"
                                    className={
                                        valueOnBoarding.card === 'semi'
                                            ? 'w-[108px] h-[174px]'
                                            : 'w-[108px] h-[174px] opacity-30'
                                    }
                                />
                            </figure>
                            <label
                                className={
                                    valueOnBoarding.card === 'semi'
                                        ? 'text-sm leading-normal font-bold text-secondary'
                                        : 'text-sm leading-normal font-bold text-darkgray'
                                }
                                htmlFor="semiRadio"
                            >
                                Semi-Pro
                            </label>
                            <input
                                className="relative bg-transparent mt-3 w-5 h-5 appearance-none rounded-full border border-solid border-darkgray bg-none after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:bg-nav1 checked:border-secondary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-secondary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] focus:ring-none focus:outline-none !important focus:border-none"
                                type="radio"
                                name="flexRadioDefault"
                                id="semiRadio"
                                onClick={() => handleChooseCard('semi')}
                            />
                            <div className='bg-secondary text-[8px] font-medium leading-normal px-2 rounded-sm mt-3 flex flex-row items-center justify-center text-center text-white'>Verifikasi dibutuhkan</div>
                        </div>
                        <div className="flex flex-col items-center">
                            <figure className="mb-3">
                                <img
                                    src="/assets/images/gold-card.png"
                                    alt="gold"
                                    className={
                                        valueOnBoarding.card === 'pro'
                                            ? 'w-[108px] h-[174px]'
                                            : 'w-[108px] h-[174px] opacity-30'
                                    }
                                />
                            </figure>
                            <label
                                className={
                                    valueOnBoarding.card === 'pro'
                                        ? 'text-sm leading-normal font-bold text-secondary'
                                        : 'text-sm leading-normal font-bold text-darkgray'
                                }
                                htmlFor="proRadio"
                            >
                                Pro Player
                            </label>
                            <input
                                className="relative bg-transparent mt-3 w-5 h-5 appearance-none rounded-full border border-solid border-darkgray bg-none after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:bg-nav1 checked:border-secondary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-secondary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] focus:ring-none focus:outline-none !important focus:border-none"
                                type="radio"
                                name="flexRadioDefault"
                                id="proRadio"
                                onClick={() => handleChooseCard('pro')}
                            />
                            <div className='bg-secondary text-[8px] font-medium leading-normal px-2 rounded-sm mt-3 flex flex-row items-center justify-center text-center text-white'>Verifikasi dibutuhkan</div>
                        </div>
                    </div>
                    <p className="text-white text-justify font-semibold leading-[19px] mb-[120px] text-sm">
                        {textOnboard}
                    </p>
                    <div className="action w-full text-center">
                        <button
                            className="w-full bg-secondary text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                            onClick={handleNextStep}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            ),
        },
        {
            step: 3,
            label: 'Fill Data',
            content: (
                <div>
                    <div className="flex flex-row items-start mb-[14px]">
                        <figure>
                            <img
                                src="/assets/images/btn_back.svg"
                                alt="back"
                                className="w-6 h-6"
                                onClick={handlePreviousStep}
                            />
                        </figure>
                        <h3 className="text-white flex flex-row mx-auto font-extrabold text-[32px] leading-normal">
                            Isi Data
                        </h3>
                    </div>
                    <div className="w-full flex justify-center text-center items-center mx-auto mb-4">
                        <figure>
                            <img
                                src={getPlayerCard(valueOnBoarding.card)}
                                alt="amateur"
                                className="w-[74px] h-[120px]"
                            />
                        </figure>
                    </div>
                    <div className="w-full">
                        <div className="mb-3">
                            <div className="flex flex-row items-center mb-2 relative">
                                <label className="text-xs font-medium text-white leading-6 mr-[6px]">
                                    Nama
                                </label>
                                <Tooltip
                                    content={
                                        <Typography
                                            variant="small"
                                            color="white"
                                            className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal absolute ml-3 top-1/2 transform translate-y-1/2 w-auto whitespace-nowrap"
                                            style={styles.tooltip}
                                        >
                                            Nama yang akan tertampil pada kartu profile
                                        </Typography>
                                    }
                                    placement="top-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <input
                                type="text"
                                id="name"
                                placeholder="Nama"
                                onChange={(e) => handleInputName(e.target.value)}
                                defaultValue={valueOnBoarding.name}
                                maxLength={16}
                                className="w-full h-[50px] rounded-md bg-transparent border border-solid border-darkgray-600 px-[19px] py-[13px] text-white text-xs font-semibold leading-6 mb-2"
                            />
                            <p className="text-red text-[8px] text-left font-semibold">{errorUsername}</p>
                        </div>
                        <div className="mb-3">
                            <label className="text-xs font-medium text-white leading-6">
                                Tanggal Lahir
                            </label>
                            <div className="flex flex-row items-center mt-2 mb-3 flex-wrap">
                                <select
                                    name="date"
                                    id="select_date"
                                    onChange={(e) => handleInputBirthDate(e.target.value)}
                                    // defaultValue="3"
                                    className="block w-auto h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mr-2"
                                >
                                    <option value="" selected>Pilih Tanggal</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </select>
                                <select
                                    name="date"
                                    id="select_month"
                                    onChange={(e) => handleInputBirthMonth(e.target.value)}
                                    // defaultValue="January"
                                    className="block w-auto h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mr-2"
                                >
                                    <option value="" selected>Pilih Bulan</option>
                                    <option value="January">January</option>
                                    <option value="Februari">Februari</option>
                                    <option value="Maret">Maret</option>
                                    <option value="April">April</option>
                                    <option value="Mei">Mei</option>
                                    <option value="Juni">Juni</option>
                                    <option value="Juli">Juli</option>
                                    <option value="Agustus">Agustus</option>
                                    <option value="September">September</option>
                                    <option value="Oktober">Oktober</option>
                                    <option value="November">November</option>
                                    <option value="Desember">Desember</option>
                                </select>
                                <select
                                    name="date"
                                    id="select_year"
                                    onChange={(e) => handleInputBirthYear(e.target.value)}
                                    // defaultValue="Pilih Tahun"
                                    className="block w-auto h-[50px] bg-transparent px-[12px] py-[13px] text-white text-xs font-semibold mt-2 lg:mt-0 border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none"
                                >
                                    <option value="" selected>Pilih Tahun</option>
                                    {yearOptions}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-row items-center mb-2 relative">
                                <label className="text-xs font-medium text-white leading-6 mr-[6px]">
                                    Domisili
                                </label>
                                <Tooltip
                                    content={
                                        <Typography
                                            variant="small"
                                            color="white"
                                            className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal absolute ml-3 top-1/2 transform translate-y-1/2 w-auto whitespace-nowrap"
                                            style={styles.tooltip}
                                        >
                                            Masukkan domisili Anda
                                        </Typography>
                                    }
                                    placement="top-start"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                            fill="#475569"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                            <select
                                name="address"
                                id="domisili"
                                onChange={(e) => handleInputDomisili(e.target.value)}
                                defaultValue="Pilih Domisili"
                                className="block w-full h-[50px] bg-transparent px-[19px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none"
                            >
                                <option value="" selected>Pilih Domisili</option>

                                {generalStore.province?.map((item, idx) => (
                                    <option value={item.province} key={idx}>{item.province}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="text-xs font-medium text-white leading-6">
                                Game
                            </label>
                            <select
                                name="game"
                                id="game"
                                onChange={(e) => handleInputGame(e.target.value)}
                                className="block w-full h-[50px] bg-transparent px-[19px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mt-2"
                            >
                                <option value="" selected>Pilih Game</option>
                                <option value="eFootball">eFootball</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="text-xs font-medium text-white leading-6 mb-2">
                                Platform
                            </label>
                            <select
                                name="platform"
                                id="plaform"
                                onChange={(e) => handleInputPlatform(e.target.value)}
                                // defaultValue="Console"
                                className="block w-full h-[50px] bg-transparent px-[19px] py-[13px] text-white text-xs font-semibold border border-solid border-darkgray-600 rounded-md sm:text-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none mt-2"
                            >
                                <option value="" selected>Pilih Platform</option>
                                <option value="Console">Console</option>
                                <option value="Mobile">Mobile</option>
                            </select>
                        </div>
                    </div >
                    <div className="action w-full text-center mt-5">
                        <button
                            className="w-full bg-secondary text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                            onClick={handleNextStep}
                        >
                            Submit
                        </button>
                    </div>
                </div >
            ),
        },
        {
            step: 4,
            label: 'Choose Picture',
            content: (
                <div>
                    <div className="flex flex-row items-start mb-[14px]">
                        <figure>
                            <img
                                src="/assets/images/btn_back.svg"
                                alt="back"
                                className="w-6 h-6"
                                onClick={handlePreviousStep}
                            />
                        </figure>
                        <h3 className="text-white flex flex-row mx-auto font-extrabold text-[32px] leading-normal">
                            Upload Foto Anda
                        </h3>
                    </div>
                    <div className="w-full flex justify-center text-center items-center mx-auto mb-4">
                        <figure className='relative'>
                            <img
                                src={getPlayerCard(valueOnBoarding.card)}
                                alt="upload_amateur"
                                className="w-[74px] h-[120px]"
                            />
                            <img
                                src={getPlayerSilhouette(valueOnBoarding.card)}
                                alt="amateur"
                                className="absolute w-[70%] h-[70%] bottom-[25px] left-1/2 transform -translate-x-1/2"
                            />

                        </figure>
                    </div>
                    <div className="w-full mb-4">
                        {imageSrc
                            ?
                            <label className="h-full flex flex-col justify-center items-center w-full bg-transparent rounded-3xl border border-darkgray cursor-pointer hover:border-secondary mb-4">
                                <div className="flex flex-row justify-between items-center p-6 w-full">
                                    <div className="text flex flex-row items-center">
                                        <img
                                            src="/assets/images/file-solid.svg"
                                            alt="Shoes"
                                            className="w-[1.5rem]"
                                        />
                                        <p className="text-sm text-secondary dark:text-secondary-400 mx-2 font-semibold text-[0.875rem] underline">
                                            {imageName}
                                        </p>
                                    </div>


                                    <img
                                        src="/assets/images/remove-image.svg"
                                        alt="Shoes"
                                        className="w-[1.5rem] cursor-pointer"
                                        onClick={deleteImage}
                                    />
                                </div>
                            </label>
                            : <></>
                        }
                        <label className="h-auto flex flex-col justify-center items-center w-full bg-transparent rounded-3xl border border-darkgray cursor-pointer hover:border-secondary p-6">
                            <figure className="mb-[17px]">
                                <img
                                    src={!imageSrc ? '/assets/images/solar_upload-square-bold.svg' : imageSrc}
                                    alt="upload"
                                    className="w-[100px] h-[100px] object-contain object-center"
                                />

                            </figure>
                            <span className="font-semibold text-sm leading-[19px] text-center text-darkgray">
                                Upload Foto Profile
                            </span>
                            <input
                                id="dropzone-file"
                                name="avatar"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                            />

                        </label>
                    </div>
                    <h3 className="text-white text-start font-bold text-[22px] leading-normal mb-4">
                        Syarat Foto dalam Profil:
                    </h3>
                    <ul className="text-darkgray text-[14px] leading-[19px] font-semibold list-inside list-disc indented-list w-[343px] mb-[56px]">
                        <li>Persiapkan format foto tanpa background(PNG)</li>
                        <li>Pastikan foto Anda memiliki kualitas tinggi dan pencahayaan yang baik</li>
                        <li>Pastikan foto Anda sesuai dengan ukuran siluet seperti yang ada di contoh</li>
                        <li>Harap penuhi persyaratan di atas agar mempercepat proses verifikasi</li>
                    </ul>
                    <div className="action w-full text-center">
                        <button
                            className="w-full bg-secondary text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                            onClick={handleNextStep}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            ),
        },
        {
            step: 5,
            label: 'Choose Team',
            content: (
                <div>
                    <div className="flex flex-row items-start mb-[14px]">
                        <figure>
                            <img
                                src="/assets/images/btn_back.svg"
                                alt="back"
                                className="w-6 h-6"
                                onClick={handlePreviousStep}
                            />
                        </figure>
                        <h3 className="text-white flex flex-row mx-auto font-extrabold text-[32px] leading-normal">
                            Pilih Team
                        </h3>
                    </div>
                    <div className="w-full flex flex-col">
                        <label
                            className={
                                !haveTeam
                                    ? 'flex flex-row items-center justify-between w-full bg-transparent rounded-md h-[80px] border border-solid border-darkgray cursor-pointer hover:border-secondary mb-3'
                                    : 'flex flex-row items-center justify-between w-full bg-transparent rounded-md h-[80px] border border-solid border-secondary cursor-pointer hover:border-secondary mb-3'
                            }
                        >
                            <div
                                className="flex flex-row justify-between items-center py-2 px-2 w-full"
                                onClick={() => addTeam('yes')}
                            >
                                <div className="text flex flex-row items-center">
                                    <img
                                        src="/assets/images/unknown-club.png"
                                        alt="club_img"
                                        className="w-[38px]"
                                    />
                                    <p
                                        className={
                                            !haveTeam
                                                ? 'text-sm text-gray-500 dark:text-secondary-400 mx-2 font-semibold text-[0.875rem]'
                                                : 'text-sm text-secondary dark:text-secondary-400 mx-2 font-semibold text-[0.875rem]'
                                        }
                                    >
                                        Pilih Team Anda
                                    </p>
                                </div>
                                <img
                                    src={
                                        !haveTeam
                                            ? '/assets/images/icon-park-solid_check-one.svg'
                                            : '/assets/images/icon-park-solid_check-one-active.svg'
                                    }
                                    alt="checklist"
                                    className="w-6"
                                />
                            </div>
                        </label>

                        {haveTeam && showSearch ? (
                            <div>
                                <form method="GET">
                                    <div className="w-full relative mb-4">
                                        <span className="absolute left-[8px] top-1">
                                            <button
                                                type="submit"
                                                className="p-1 focus:outline-none focus:shadow-outline"
                                            >
                                                <svg
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    className="w-5 h-5"
                                                >
                                                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                                </svg>
                                            </button>
                                        </span>
                                        <div className='relative'>
                                            <input
                                                type="search"
                                                name="q"
                                                className="search w-full text-base text-white leading-[14px] h-9 bg-transparent rounded-md px-9 py-[10px] focus:outline-none focus:bg-transparent focus:border-secondary"
                                                placeholder="Search for team..."
                                                onChange={(e) => handleInputSearch(e.target.value)}
                                            />
                                            {searchValue &&
                                                <div className="flex flex-col items-start w-full bg-transparent rounded-[5px] border border-solid border-darkgray pt-[18px] px-[11px] mt-2">
                                                    {clubStore.teamLists?.map((team, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="flex flex-row items-center mb-[18px] h-full w-full cursor-pointer"
                                                            data-value={team.id}
                                                            data-flag={team.flag}
                                                            data-name={team.name}
                                                            onClick={handleChooseTeam}
                                                        >
                                                            <img
                                                                src={`${process.env.REACT_APP_STORAGE_URL}${team.flag}`}
                                                                alt="group_logos"
                                                                className="w-[1.5rem]"
                                                            />
                                                            <p className="text-sm text-darkgray leading-normal ml-3 font-semibold">
                                                                {team.name}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <></>
                        )}

                        <label
                            className={
                                !haveTeam
                                    ? 'flex flex-row items-center justify-between w-full bg-transparent rounded-md h-[80px] border border-solid border-secondary cursor-pointer hover:border-secondary mb-3'
                                    : 'flex flex-row items-center justify-between w-full bg-transparent rounded-md h-[80px] border border-solid border-darkgray cursor-pointer hover:border-secondary mb-3'
                            }
                        >
                            <div
                                className="flex flex-row justify-between items-center py-2 px-2 w-full"
                                onClick={() => addTeam('no')}
                            >
                                <div className="text flex flex-row items-center">
                                    <img
                                        src="/assets/images/unknown-club.png"
                                        alt="club_img"
                                        className="w-[38px]"
                                    />
                                    <p
                                        className={
                                            !haveTeam
                                                ? 'text-sm text-secondary dark:text-secondary-400 mx-2 font-semibold text-[0.875rem]'
                                                : 'text-sm text-gray-500 dark:text-secondary-400 mx-2 font-semibold text-[0.875rem]'
                                        }
                                    >
                                        Saya belum memiliki Team
                                    </p>
                                </div>
                                <img
                                    src={
                                        !haveTeam
                                            ? '/assets/images/icon-park-solid_check-one-active.svg'
                                            : '/assets/images/icon-park-solid_check-one.svg'
                                    }
                                    alt="checklist"
                                    className="w-6"
                                />
                            </div>
                        </label>
                    </div>

                    {valueSelectedTeam.selected ? (
                        <div className="flex flex-col items-start w-full bg-transparent rounded-[5px] border border-solid border-darkgray pt-[18px] px-[11px] my-3">
                            <div className="flex flex-row items-center justify-between mb-[18px] h-full w-full">
                                <div className='flex flex-row items-center'>
                                    <img
                                        src={`${process.env.REACT_APP_STORAGE_URL}${valueSelectedTeam.flag}`}

                                        alt="group_logos"
                                        className="w-[1.5rem]"
                                    />
                                    <p className="text-sm text-white leading-normal ml-3 font-semibold">
                                        {valueSelectedTeam.name}
                                    </p>
                                </div>
                                <img
                                    src="/assets/images/remove-image.svg"
                                    alt="Shoes"
                                    className="w-[1.5rem] cursor-pointer text-right"
                                    onClick={removeSelectedTeam}
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="flex flex-row items-center justify-center cursor-pointer relative">
                        <p className="text-xs leading-normal font-semibold text-darkgray mr-[6px]">
                            Tidak menemukan team Anda? Hubungi Admin untuk mengetahui syarat yang harus dipenuhi
                        </p>
                        <Tooltip
                            content={
                                <Typography
                                    variant="small"
                                    color="white"
                                    className="font-medium text-[8px] bg-darkgray p-[6px] rounded leading-normal absolute right-0 w-auto whitespace-nowrap"
                                    style={styles.tooltip}
                                >
                                    Cari nama team anda disini
                                </Typography>
                            }
                            placement="bottom-start"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                            >
                                <path
                                    d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5ZM9.225 5.4675C9.375 5.3325 9.555 5.25 9.75 5.25C9.9525 5.25 10.125 5.3325 10.2825 5.4675C10.425 5.625 10.5 5.805 10.5 6C10.5 6.2025 10.425 6.375 10.2825 6.5325C10.125 6.675 9.9525 6.75 9.75 6.75C9.555 6.75 9.375 6.675 9.225 6.5325C9.0825 6.375 9 6.2025 9 6C9 5.805 9.0825 5.625 9.225 5.4675ZM7.35 8.9775C7.35 8.9775 8.9775 7.6875 9.57 7.635C10.125 7.59 10.0125 8.2275 9.96 8.5575L9.9525 8.6025C9.8475 9 9.72 9.48 9.5925 9.9375C9.3075 10.98 9.03 12 9.0975 12.1875C9.1725 12.4425 9.6375 12.12 9.975 11.895C10.02 11.865 10.0575 11.835 10.095 11.8125C10.095 11.8125 10.155 11.7525 10.215 11.835C10.23 11.8575 10.245 11.88 10.26 11.895C10.3275 12 10.365 12.0375 10.275 12.0975L10.245 12.1125C10.08 12.225 9.375 12.72 9.09 12.9C8.7825 13.1025 7.605 13.7775 7.785 12.465C7.9425 11.5425 8.1525 10.7475 8.3175 10.125C8.625 9 8.76 8.49 8.07 8.9325C7.7925 9.0975 7.6275 9.2025 7.53 9.27C7.4475 9.33 7.44 9.33 7.3875 9.2325L7.365 9.1875L7.3275 9.1275C7.275 9.0525 7.275 9.045 7.35 8.9775Z"
                                    fill="#475569"
                                />
                            </svg>
                        </Tooltip>
                    </div >

                    <div className="action w-full text-center mt-10">
                        <p className="text-red text-[8px] text-center font-semibold mb-2">{error}</p>
                        {!loading
                            ?
                            <button
                                className="w-full bg-secondary text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                                onClick={handleSubmitOnBoarding}
                            >
                                Submit
                            </button>
                            :
                            <button
                                disabled
                                className="w-full bg-darkgray text-white text-xs font-bold leading-normal mb-[14px] rounded-md py-[18px]"
                                onClick={handleSubmitOnBoarding}
                            >
                                Please Wait
                            </button>
                        }
                    </div>
                </div >
            ),
        },
    ];

    const currentRegistrationStep = onboardingSteps.find(
        (step) => step.step === currentStep
    );

    return (
        <div>
            {/* <button
        onClick={() => setShowModal(true)}
        className="text-white text-xs font-bold cursor-pointer bg-secondary px-3.5 py-2 rounded-md hover:bg-nav2 hover:text-secondary"
      >
        On Boarding
      </button> */}
            {showModal && (
                <div>
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className={`modal overflow-y-scroll w-full h-auto mt-[64px] xl:w-[438px] lg:m-auto lg:max-h-[90vh] ${active}`}>
                        <div className="modal-content p-4 lg:p-5">
                            <button className="close top-5 right-5" onClick={handleClose}>
                                <img
                                    className="w-6 h-6"
                                    src="/assets/images/close.svg"
                                    alt="close"
                                ></img>
                            </button>
                            {currentRegistrationStep?.content}
                            <div className="flex flex-col items-center justify-between">
                                <div className="flex flex-row items-center justify-center mb-6">
                                    <span className="text-darkgray text-xs font-semibold leading-normal">
                                        Atur nanti,
                                    </span>
                                    &nbsp;
                                    <a
                                        href="/tournament"
                                        className="text-secondary text-xs font-semibold leading-normal underline"
                                    >
                                        Lihat Tournament
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Onboarding;
