import React from 'react'

interface Props {
    label: string,
    route?: string,
    icon?: JSX.Element
}

const ListMenu = (props : Props) => {

  const { label, route} = props;

  return (
      <a className="font-semibold lg:font-bold hover:text-secondary" href={route}>{label}</a>
  )
}

export default ListMenu