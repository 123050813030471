import React, { useEffect, useState } from "react";
import '../topNavbar/topNavbar.css';
import { useStore } from "../../stores/store";
import SkeletonComponent from "../skeleton/SkeletonComponent";

interface SubscriptionProps {
    onOpenModalSubs: boolean;
    onClose: () => void;
}

const SubscriptionModal: React.FC<SubscriptionProps> = ({ onOpenModalSubs, onClose }) => {

    const [onOpenSubsModal, setOnOpenSubsModal] = useState(false)
    const [active, setActive] = useState('')

    const { SubscriptionStore } = useStore();

    useEffect(() => {
        if (onOpenModalSubs) {
            setActive('active');
        }
    }, [SubscriptionStore])


    const closeModal = () => {
        setOnOpenSubsModal(false)
    };

    const renderBennefits = (text: string) => {
        const lines = text.split('\n');

        // Wrap each line in <li></li> tags
        const listItems = lines.map((line, index) => (
          <li key={index}>{line}</li>
        ));
      
        // Return the list within a <ul> element
        return <ul className="custom-list-style">{listItems}</ul>;
    };

    // Split the text into lines
    

    return (
        <div>
            {onOpenModalSubs && (
                <div>
                    <div className="modal-overlay" onClick={onClose}></div>
                    <div className={`modal overflow-y-scroll w-full h-fit mt-[64px] rounded-3xl xl:w-[438px] lg:h-full lg:m-auto lg:max-h-[90vh] ${active}`} style={{ borderRadius: '16px' }}>
                        <div className="px-10 pb-5 h-full">
                            <h1 className="text-white text-[32px] font-extrabold leading-normal flex flex-row justify-center text-center mb-[18px]">Subscription</h1>
                            <button className="close" onClick={onClose}>
                                <img
                                    className="w-6 h-6"
                                    src="/assets/images/close.svg"
                                    alt="close"
                                ></img>
                            </button>
                            <div className="text-white text-xs font-medium leading-[18px] mb-[14px] text-center wrap">
                                <span className="text-secondary">Upgrade dan Subscribe</span>&nbsp;
                                <span>di situs Indonesian Football e-League, Mulailah Perjalanan Anda di Kancah Kompetitif Virtual Football!</span>
                            </div>
                            <a className="text-secondary text-[10px] font-medium leading-[18px] underline mb-[37px] flex flex-row items-center justify-center text-center" href="/subscription">Lihat Selengkapnya</a>
                            {
                                 SubscriptionStore.bennefitsData.map((item, index) => (
                                    <div className="flex flex-row items-center mb-6" key={index}>
                                        <img className="max-w-[100px] mr-2 lg:max-w-[130px] lg:mr-4" src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}  alt="" />
                                        <div className="text-xs leading-[18px]">
                                        <h6 className="text-secondary font-bold">{item.title}</h6>
                                            <p className="text-white font-medium">{item.description}</p>
                                        </div>
                                    </div>
                                ))
                            }
                            
                            {
                                SubscriptionStore.loading == false ?
                                    <>
                                        {SubscriptionStore.data.map((item, index) => (
                                            <div className="bg-nav1 w-full mb-4 text-white py-[34px] border border-solid border-white rounded px-4 lg:w-[276px] lg:mx-auto" key={index}>
                                                <h6 className="text-base text-extrabold mb-6 text-center leading-normal">{item.title}</h6>
                                                <h4 className="text-[34px] font-extrabold mb-[34px] text-center">IDR {item.price}</h4>
                                                <a href={'/payment/subscription/' + item.slug} className="text-xl font-bold w-[235px] h-[50px] rounded-md bg-secondary mb-2 flex flex-row items-center justify-center mx-auto">Subscribe</a>
                                                <p className="text-white text-center text-xs font-medium mb-[18px]">{item.description}</p>
                                                <ul className="custom-list-style">
                                                {renderBennefits(item.note)}
                                                </ul>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <SkeletonComponent
                                        count={3}
                                        width='w-full'
                                        height='h-[50px]'
                                        borderRadius='rounded-sm'
                                    />
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionModal;
