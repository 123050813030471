import { makeAutoObservable } from "mobx"
import agent from "../api/agent";
import { IClub } from "../models/club";
import { IHistory, IHistoryDelete, IHistoryInput, ITeam } from "../models/team";
import { ISearchRequest } from "../models/general";


export default class ClubStore {

    listClub: IClub[] = [];
    club: IClub | null = null;
    playerStats: IClub | null = null;
    teamLists: ITeam[] = [];
    historyList: IHistory[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getClubDetail = async (idComp: number, idClub: number) => {
        try {
            const result = await agent.Club.clubById(idComp, idClub);
            this.setclub(result.data);
            return result.data;
        } catch (error) {
            throw (error)
        }
    }

    getPlayerStats = async (idComp: number, idClub: number, idPlayer: number) => {
        try {
            const result = await agent.Club.playerStats(idComp, idClub, idPlayer);
            this.setPlayerStats(result.data);
            return result.data;
        } catch (error) {
            throw (error)
        }
    }

    setclub = (data: IClub) => {
        this.club = data;
    }

    setPlayerStats = (data: IClub) => {
        this.playerStats = data;
    }

    getTeamLists = async () => {
        try {
            const result = await agent.Team.list();
            this.setClubLists(result.data);
        } catch (error) {
            throw (error)
        }
    }

    setClubLists = (data: ITeam[]) => {
        this.teamLists = data;
    }

    searchTeam = async (keyword: string) => {
        try {
            const result = await agent.Team.search({ keyword })
            this.setClubLists(result.data)
        } catch (error) {
            throw (error)
        }
    }

    listHistory = async (slug: string) => {
        try {
            await agent.Team.listHistory(slug).then((res) => {
                this.setHistoryList(res.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    setHistoryList = (data: IHistory[]) => {
        this.historyList = data;
    }

    submitHistory = async (data: IHistoryInput) => {
        console.log(data);
        
        try {
            await agent.Team.history(data)
        } catch (error) {
            console.log(error)
        }
    }

    updateHistory = async (data: IHistoryInput) => {
        try {
            await agent.Team.historyUpdate(data)
            return true;
        } catch (error) {
            console.log(error)
            return false
        }
    }

    deleteHistory = async (data: IHistoryDelete) => {
        try {
            await agent.Team.historyDelete(data)
        } catch (error) {
            console.log(error)
        }
    }
}