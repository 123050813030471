import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import '../../../index.css'
import { useStore } from '../../../app/stores/store';
import MatchPlayerCard from '../../../app/component/card/MatchPlayerCard';
import { formatDate } from '../../../app/config/enum';
import Countdown from '../../../app/component/countdown/Countdown';

interface ModalProps {
    matches: any[];
    item: any;
    tournament_name: string;
}

const ViewMatchCard: React.FC<ModalProps> = ({ matches, tournament_name, item }) => {
    const [matchIndex, setMatchIndex] = useState<number>(0);
    const { TournamentStore } = useStore();
    const { slug } = useParams<{ slug: string }>();

    useEffect(() => {
        TournamentStore.getMyOngoingMatch(String(slug))
    })
    const handleSelectMatch = (value: string) => {
        setMatchIndex(Number(value))
    };

    return (
        <React.Fragment>
            <div className='w-full h-auto relative mt-4'>
                <div className="w-full h-[44px] bg-[#1D2335] flex flex-row justify-between items-center py-4 lg:py-1 rounded-tl-[10px] rounded-tr-[10px] lg:h-[20px]">
                    <h6 className='w-[60%] px-4 flex flex-row items-center justify-start text-[10px] leading-normal font-bold text-white text-left lg:text-center lg:justify-center lg:w-full'>{tournament_name}</h6>
                </div>
                <div className="w-full h-full flex flex-col justify-center items-start bg-nav1 lg:px-6 lg:flex-row lg:items-center lg:justify-between rounded-bl-[10px] rounded-br-[10px] lg:rounded-none">
                    <select
                        name="date"
                        id="select_date"
                        defaultValue="Match 1"
                        onChange={(e) => handleSelectMatch(e.target.value)}
                        className="select-inherit block absolute top-1 right-4 w-auto h-[33px] bg-transparent px-6 text-[#565D6B] text-xs font-semibold border-[.5px] border-solid border-[#565D6B bg-[#1C1F2A] rounded-md focus:ring-0 focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:ring-offset-transparent focus:outline-none lg:h-[32px] lg:px-[10px] lg:top-unset lg:right-unset lg:rounded"
                    >
                        {matches.map((item1: any, index1: number) => (
                            <option value={String(index1)} key={index1}>{item1.title}</option>
                        ))}
                    </select>
                    <div className='w-full flex flex-col justify-center items-center flex-1 px-5 py-3'>
                        <div className='w-full h-full flex flex-col justify-center items-center lg:flex-row px-2 lg:px-0'>
                            <div className='w-full flex flex-row justify-center items-center mb-3 lg:mb-0 lg:w-auto lg:mr-4'>
                                <div id='home' className='flex flex-row w-full h-full justify-center items-center'>
                                    <div className='w-full flex flex-col text-end font-semibold justify-end mr-1 order-2 lg:order-1 lg:w-auto'>
                                        <div className="flex flex-row items-center lg:justify-end">
                                            <span className='mr-1 order-2 lg:order-1 flex'>{renderLevelTag(matches[matchIndex].home_card.level)}</span>
                                            <span className='text-white text-[9px] font-semibold leading-[10px] lg:text-base mr-1 lg:mr-0 order-1 lg:order-2'>{matches[matchIndex].home_card.username}</span>
                                        </div>
                                    </div>
                                    <div className='w-[33px] h-[54px] flex flex-row justify-end items-center order-1 mr-5 lg:mr-0 lg:ml-1'>
                                        <MatchPlayerCard
                                            player={matches[matchIndex].home_card.level}
                                            data={matches[matchIndex].home_card}
                                        />
                                    </div>
                                    <div className='text-[10px] text-white font-bold ml-4 order-3 lg:order-1 lg:text-[18px]'>{matches[matchIndex].is_count ? matches[matchIndex].home_score : ''} {matches[matchIndex].is_count && matches[matchIndex].home_penalty ? `(${matches[matchIndex].home_penalty})` : ''} </div>
                                </div>
                            </div>
                            <div className='hidden flex-row justify-center items-center text-darkgray font-bold text-[18px] lg:flex'>
                                VS
                            </div>
                            <div className='w-full flex flex-row justify-center items-center lg:w-auto lg:ml-4'>
                                <div id='away' className='flex flex-row w-full h-full justify-center items-center'>
                                    <div className='text-[10px] text-white font-bold order-3 lg:order-1 lg:text-[18px] lg:mr-4'>{matches[matchIndex].is_count ? matches[matchIndex].away_score : ''} {matches[matchIndex].is_count && matches[matchIndex].away_penalty ? `(${matches[matchIndex].away_penalty})` : ''}</div>
                                    <div className='w-[33px] h-[54px] flex flex-row justify-end items-center order-1 mr-5 ml-0 lg:mr-0'>
                                        <MatchPlayerCard
                                            player={matches[matchIndex].away_card.level}
                                            data={matches[matchIndex].away_card}
                                        />
                                    </div>
                                    <div className='w-full flex flex-col text-end font-semibold justify-end lg:ml-1 order-2 lg:w-auto'>
                                        <div className="flex flex-row items-center lg:justify-start">
                                            <span className='text-white text-[9px] font-semibold leading-[10px] lg:text-base'>{matches[matchIndex].away_card.username}</span>
                                            <span className='ml-1 order-2 lg:order-1 flex'>{renderLevelTag(matches[matchIndex].away_card.level)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className='w-full h-full text-darkgray text-[8px] font-medium text-left lg:text-center mt-2'>{item.bracket} / {formatDate(matches[matchIndex].match_date)}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const renderLevelTag = (level: string) => {
    if (level === 'amateur') {
        return <div className="badge badge-outline-amateur tag">Amateur</div>;
    } else if (level === 'semi') {
        return <div className="badge badge-outline-semi tag">Semi-Pro</div>;
    } else {
        return <div className="badge badge-outline-pro tag">Pro</div>;
    }
};

export default ViewMatchCard