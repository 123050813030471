import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../stores/store";
import { IOrder } from "../../models/order";
import { formatRupiah } from "../../config/enum";
import { useEffect, useState } from "react";
import LoginModal from "../modal/Login";
import RegisterModal from "../modal/Register";
import ErrorModal from "../modal/ErrorModal"
import Tournament from "../../../features/tournament/Tournament";

interface ModalProps {
    total_payment: number,
    prizepool: number,
    match_date: string,
    close_date: Date,
    slot: string,
    registration_type: number,
    btn_status: boolean
}

const ButtonFixed: React.FC<ModalProps> = ({ total_payment, prizepool, match_date, close_date, slot, registration_type, btn_status }) => {
    const { orderStore, accountStore, TournamentStore, notificationStore } = useStore()
    const [isModalAuthOpen, setisModalAuthOpen] = useState(false)
    const [isModalRegisterOpen, setisModalRegisterOpen] = useState(false)
    const [isModalErrorOpen, setisModalErrorOpen] = useState(false)
    const [authType, setAuthType] = useState('login')

    const navigate = useNavigate()
    const { slug } = useParams<{ slug: string }>();
    const [btnLoad, setBtnLoad] = useState(true)

    const valueOrder: IOrder = {
        slug: String(slug),
        total_payment: total_payment
    };

    useEffect(() => {
        if (accountStore.isLoggedIn) {
            TournamentStore.checkPayment(String(slug)).then(() => {
                setBtnLoad(false)
            })
        }
    }, [TournamentStore, accountStore, slug, btnLoad])


    const openModalLogin = () => {
        setisModalAuthOpen(true)
        setAuthType('login');
    };

    const openModalRegister = () => {
        setisModalAuthOpen(true)
        setAuthType('register');
    }

    const switchAuthType = () => {
        if (authType === 'login') {
            setAuthType('register');
            setisModalAuthOpen(false)
            setisModalRegisterOpen(true)
            return
        }
        setisModalRegisterOpen(false)
        return openModalLogin()
    }

    const closeModal = () => {
        setisModalErrorOpen(false)
        setisModalAuthOpen(false)
        setisModalRegisterOpen(false)
        setAuthType('login');
    }

    const toProfile = () => {
        setisModalErrorOpen(false)
        if(orderStore.errorMessageOrderHeading === 'Subscribers only'){
            return
        }
        navigate(`/profile/${accountStore.main_card?.slug}`)
    }

    const handleCreateOrder = async () => {
        setBtnLoad(true)
        if (!accountStore.isLoggedIn) {
            setBtnLoad(false)
            return openModalLogin()
        }
        await orderStore.createOrder(valueOrder).then((res) => {
            if (!res) {
                return setisModalErrorOpen(true)
            }
            setBtnLoad(false)
            if (orderStore.order?.status === 'success') {
                notificationStore.setNotification('Berhasil registrasi turnamen, lihat daftar pemain!')
                return window.location.reload()
            } else {
                return navigate('/payment/' + orderStore.order?.transaction_number)
            }
        })
    }

    return (
        <div>
            <div className="bg-nav1 w-full h-[93px] lg:h-[98px] flex flex-row rounded-tl-3xl rounded-tr-3xl fixed bottom-0 left-1/2 transform -translate-x-1/2 z-10 max-w-[1114px] py-[10px] px-4 lg:items-center lg:justify-center">
                <div className="hidden lg:flex lg:flex-row lg:items-center">
                    <img className="border border-white p-3 rounded-lg mr-2" src="/assets/images/detail-prizepool.svg" alt="" />
                    <div>
                        <h5 className="text-sm text-white font-bold mb-[2px]">Prizepool</h5>
                        <h5 className="text-[10px] text-darkgray font-medium">{formatRupiah(prizepool)}</h5>
                    </div>
                </div>
                <div className="hidden lg:flex lg:flex-row lg:items-center ml-3">
                    <img className="border border-white p-3 rounded-lg mr-2" src="/assets/images/detail-slot.svg" alt="" />
                    <div>
                        <h5 className="text-sm text-white font-bold mb-[2px]">Total Slot</h5>
                        <h5 className="text-[10px] text-darkgray font-medium">{slot}</h5>
                    </div>
                </div>
                <div className="hidden lg:flex lg:flex-row lg:items-center ml-3">
                    <img className="border border-white p-3 rounded-lg mr-2" src="/assets/images/detail-calendar.svg" alt="" />
                    <div>
                        <h5 className="text-sm text-white font-bold mb-[2px]">Jadwal Pertandingan</h5>
                        <h5 className="text-[10px] text-darkgray font-medium">{match_date}</h5>
                    </div>
                </div>
                {!accountStore.isLoggedIn
                    ?
                    <div className="capitalize flex flex-row items-center justify-center text-white bg-secondary text-xs font-bold leading-normal rounded-md w-[90%] h-[45px] fixed left-1/2 transform -translate-x-1/2 lg:max-w-[405px] lg:h-[50px] lg:ml-6 lg:relative lg:left-auto lg:translate-x-0" onClick={handleCreateOrder}>daftar</div>
                    :
                    (new Date() > new Date(close_date) ?
                        <div className="capitalize flex flex-row items-center justify-center text-white bg-nav2 text-xs font-bold leading-normal rounded-md w-[90%] h-[45px] fixed left-1/2 transform -translate-x-1/2 lg:max-w-[405px] lg:h-[50px] lg:ml-6 lg:relative lg:left-auto lg:translate-x-0 disabled cursor-not-allowed">Pendaftaran Ditutup</div>
                        :
                        btn_status
                            ?
                            <div className="capitalize flex flex-row items-center justify-center text-white bg-nav2 text-xs font-bold leading-normal rounded-md w-[90%] h-[45px] fixed left-1/2 transform -translate-x-1/2 lg:max-w-[405px] lg:h-[50px] lg:ml-6 lg:relative lg:left-auto lg:translate-x-0 disabled cursor-not-allowed">Kuota Penuh</div>
                            :
                            !TournamentStore.check_payment
                                ?
                                (!btnLoad
                                    ?
                                    <div className="capitalize flex flex-row items-center justify-center text-white bg-secondary text-xs font-bold leading-normal rounded-md w-[90%] h-[45px] fixed left-1/2 transform -translate-x-1/2 lg:max-w-[405px] lg:h-[50px] lg:ml-6 lg:relative lg:left-auto lg:translate-x-0" onClick={handleCreateOrder}>daftar</div>
                                    :
                                    <div className="capitalize flex flex-row items-center justify-center text-white bg-secondary text-xs font-bold leading-normal rounded-md w-[90%] h-[45px] fixed left-1/2 transform -translate-x-1/2 lg:max-w-[405px] lg:h-[50px] lg:ml-6 lg:relative lg:left-auto lg:translate-x-0">please wait</div>)
                                :
                                <div className="capitalize flex flex-row items-center justify-center text-white bg-nav2 text-xs font-bold leading-normal rounded-md w-[90%] h-[45px] fixed left-1/2 transform -translate-x-1/2 lg:max-w-[405px] lg:h-[50px] lg:ml-6 lg:relative lg:left-auto lg:translate-x-0 disabled cursor-not-allowed">Sudah Mendaftar</div>
                    )}
            </div>
            <LoginModal onOpenLogin={isModalAuthOpen} closeModal={closeModal} switchModal={switchAuthType} />
            <RegisterModal onOpenRegister={isModalRegisterOpen} closeModal={closeModal} switchModal={switchAuthType} />
            <ErrorModal onOpenModalerrorModal={isModalErrorOpen} redirect={toProfile} onClose={closeModal} title={orderStore.errorMessageOrderHeading} description={orderStore.errorMessageOrder} />
        </div>
    )
}

export default observer(ButtonFixed)