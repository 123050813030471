import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { observer } from "mobx-react-lite";

function SingleBanner() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="w-full lg:h-full font-sans">
      <div className="relative h-[200px] lg:h-[500px] lg:w-full">
        <div className="absolute inset-0 bg-gradient-to-t lg:bg-gradient-to-r from-black to-transparent"></div>
        <img
          className="object-cover w-full h-full"
          src="https://s3.ap-southeast-1.amazonaws.com/hotdeal.cdn/upload/files/64c36a07d63bb-files1690528263.png"
          alt="gambar"
        />
        <div className="absolute left-1/2 top-1/2 transform -translate-y-1/2  -translate-x-1/2 lg:left-[163px] lg:translate-x-0">
          <img className="w-[53px] h-[53px] lg:w-[138px] lg:h-[138px] mx-auto lg:mb-6 lg:mx-0" src="/assets/images/eFootball-2023-Logo 3.png" alt="" />
          <h2 className="text-lg text-white lg:text-5xl font-black">eFootball 2024</h2>
        </div>
      </div>
    </div>
  );
}

export default observer(SingleBanner);
