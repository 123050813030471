import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from '../../app/stores/store';
import { IAccountForgetPassword } from '../../app/models/account';

const initialEmailValues: IAccountForgetPassword = {
    email: '',
    token: '',
    new_password: '',
    new_password_confirmation: ''
}
const ForgotPassword = () => {
    const { accountStore } = useStore()
    const [emailValue, setEmailValue] = useState<IAccountForgetPassword>(
        initialEmailValues
    );

    const handleInputEmail = (value: string) => {
        setEmailValue((prevState) => ({ ...prevState, email: value }));
    };

    const handleEmailSubmit = () => {
        accountStore.forgetPassword(emailValue)
    };

    return (
        <div className="bg-nav1 w-full h-full flex fixed">
            <div
                className="flex flex-col items-center justify-center m-auto w-[436px] px-4"
                onSubmit={handleEmailSubmit}
            >
                <img
                    className="w-[170px] h-auto xl:w-[239px]"
                    src="/assets/images/IFeL Logo_Website.svg"
                    alt=""
                />
                <h1 className="text-white mb-3 font-extrabold text-[32px] text-center xl:mb-0 leading-normal">
                    Lupa dengan Password Anda?
                </h1>
                <p className="text-white text-xs font-semibold text-center mb-3 xl:mb-0">
                    Isi alamat email akun Anda, dan kami akan mengirimkan tautan untuk
                    mengatur ulang password Anda
                </p>
                <div className="relative w-full container mx-auto font-sans">
                    <label htmlFor="email" className="text-white text-xs font-medium">
                        Email Address
                    </label>
                    <input
                        type="text"
                        id="email"
                        className="w-full text-xs border-darkgray px-[19px] py-[13px] h-[50px] mt-2 text-white bg-transparent rounded-md appearance-none mb-3 focus:outline-none xl:mt-4"
                        name="email"
                        placeholder="Email address"
                        onChange={(e) => handleInputEmail(e.target.value)}
                    />
                    <div className="w-full mt-[38px]">
                        <button
                            type="submit"
                            onClick={handleEmailSubmit}
                            className="w-full px-10 py-3 h-[50px] text-xs disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-darkgray text-white font-bold bg-secondary border-none rounded-md hover:bg-nav2 hover:text-secondary transition duration-300 xl:font-semibold"
                        >
                            Kirim
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(ForgotPassword);
