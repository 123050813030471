import { action, makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent";
import { IRules, ITournament, ITournamentGame, ITournamentLeaderBoard, ITournamentParticipant, ITournamentPlayerStat, ITournamentResultInput, ITournamentReward } from "../models/tournament";
import { ITeam } from "../models/team";
import { registrationTypeString } from "../config/enum";

export default class PlayerRankStore {

    data = new Array<ITournament>();
    leaderboardData = new Array<ITournamentLeaderBoard>();
    loadingForm = false;
    loadingList = false;

    filters : any = []
    
    constructor() {
        makeAutoObservable(this);
    }

    updateGridCallback = action(() => {
        this.getTournamentList();
        //this.getLeaderBoard();
    })

    getLeaderBoard = async (platform : string , limit : number) => {
        try {
            const result = await agent.Tournament.tournamentLeaderboard(platform , limit , this.filters);
            console.log(result)
            this.setLeaderboardData(result.data);
        } catch (error) {
            throw error;
        }
    }
    setLeaderboardData = (res: Array<ITournamentLeaderBoard>) => {
        this.leaderboardData = res;
    }
    

    getTournamentList = async () => {
        try {
            const result = await agent.Tournament.listTurnament();
            this.setTournamentData(result.data);
        } catch (error) {
            throw error;
        }
    }

    setTournamentData = (res: Array<ITournament>) => {
        this.data = res;
    }
}