import React, { useEffect, useState } from 'react';
import '../../../index.css'
import { IUserProfile } from '../../models/profile';
import { useStore } from '../../stores/store';
import Countdown from '../countdown/Countdown';
import { calculateAge, getPlayerCard } from '../../config/enum';
import { ITournamentResultInput } from '../../models/tournament';
import { useParams } from 'react-router-dom';

interface ModalProps {
    onOpenInputModal: boolean;
    onClose: () => void;
    onSuccess: () => void;
    data: IUserProfile | null;
    item: any;
}

const playerData = {
    level: "amateur"
};

const initialValurResult: ITournamentResultInput = {
    match_id: 0,
    slug_card: '',
    home_player_score: 0,
    home_player_penalty: 0,
    image: '',
    away_player_score: 0,
    away_player_penalty: 0,

};

const InputScore: React.FC<ModalProps> = ({ onOpenInputModal, onClose, onSuccess, data, item }) => {

    const { accountStore, generalStore, TournamentStore } = useStore()
    const [active, setActive] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [count, setCount] = useState(0);
    const [load, setLoad] = useState(false);
    const [countHomeScore, setCountHomeScore] = useState(item.card_result.home_score_by_player ? item.card_result.home_score_by_player : 0);
    const [countHomePenalty, setCountHomePenalty] = useState(item.card_result.home_penalty_score_by_user ? item.card_result.home_penalty_score_by_user : 0);
    const [countAwayScore, setCountAwayScore] = useState(item.card_result.away_score_by_player ? item.card_result.away_score_by_player : 0);
    const [countAwayPenalty, setCountAwayPenalty] = useState(item.card_result.away_penalty_score_by_user ? item.card_result.away_penalty_score_by_user : 0);
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const { slug } = useParams<{ slug: string }>();

    const [valueResult, setValueResult] = useState<ITournamentResultInput>(
        initialValurResult
    );

    const successInput = () => {
        setShowSuccessModal(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModalInput = () => {
        setShowSuccessModal(false);
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        if (onOpenInputModal) {
            setActive('active');
        }
        setValueResult((prevState) => ({
            ...prevState,
            slug_card: String(slug),
            match_id: Number(item.id),
            away_player_penalty: countAwayPenalty,
            away_player_score: countAwayScore,
            home_player_score: countHomeScore,
            home_player_penalty: countHomePenalty
        }));
    }, [valueResult])

    const incrementHomeScore = () => {
        setCountHomeScore(countHomeScore + 1)
    }

    const decrementHomeScore = () => {
        setCountHomeScore(countHomeScore - 1)
    }

    const incrementHomePenalty = () => {
        setCountHomePenalty(countHomePenalty + 1)
    }

    const decrementHomePenalty = () => {
        setCountHomePenalty(countHomePenalty - 1)
    }

    const incrementAwayScore = () => {
        setCountAwayScore(countAwayScore + 1)
    }

    const decrementAwayScore = () => {
        setCountAwayScore(countAwayScore - 1)
    }

    const incrementAwayPenalty = () => {
        setCountAwayPenalty(countAwayPenalty + 1)
    }

    const decrementAwayPenalty = () => {
        setCountAwayPenalty(countAwayPenalty - 1)
    }

    useEffect(() => {
        accountStore.getUser()
        generalStore.getCard()
    }, [accountStore])

    const [imageSrc, setImageSrc] = useState<string | null>(item.card_result.upload_by_player ? process.env.REACT_APP_STORAGE_URL + item.card_result.upload_by_player : null );
    const [imageName, setImageName] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        console.log(selectedFile);

        if (selectedFile) {
            setFile(selectedFile);
            setImageName(selectedFile.name)
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);

            const formData = new FormData();
            formData.append('image', selectedFile);
            accountStore.setAvatar({ data: formData }).then((res) => {
                setValueResult((prevState) => ({ ...prevState, image: res.data }));
                console.log(res.data);
            })
        }

    };

    const deleteImage = () => {
        setFile(null);
        setImageSrc(null);
        setImageName(null);
    }

    const handleSubmitResult = () => {
        setLoad(true)
        setValueResult((prevState) => ({
            ...prevState,
            away_player_penalty: countAwayPenalty,
            away_player_score: countAwayScore,
            home_player_score: countHomeScore,
            home_player_penalty: countHomePenalty
        }))
        TournamentStore.submitResult(valueResult).then(() => {
            setLoad(false)
            onSuccess()
        })
    }

    return (
        <div>
            {onOpenInputModal && (
                <div>
                    <div className="modal-overlay" onClick={onClose}></div>
                    <div className={`modal overflow-y-scroll w-full h-auto mt-[64px] xl:w-[438px] lg:m-auto lg:max-h-[90vh] ${active}`}>
                        <div className="modal-content p-4 lg:p-5">
                            <button className="close" onClick={onClose}>
                                <img
                                    className="w-6 h-6"
                                    src="/assets/images/close.svg"
                                    alt="close"
                                ></img>
                            </button>
                            <div className='flex flex-col items-start mb-[14px]'>
                                <h3 className="text-white flex flex-row mx-auto font-extrabold text-[32px] leading-normal">
                                    Input Data Match
                                </h3>
                                <h6 className='text-darkgray text-sm font-semibold leading-normal flex flex-row items-center justify-center w-full text-center'>Input hasil pertandingan Anda sebelum waktu habis!</h6>
                                <div className='text-white text-lg font-semibold flex flex-row justify-center text-center w-full mb-6'><Countdown targetDate={item.closing_date} /></div>
                                {/* <div className='flex flex-row items-center mx-auto mb-6'>
                                    <div className='flex flex-row items-center'>
                                        <div className="w-full lg:w-[74px] relative">
                                            <div className="flex flex-col items-start absolute team-name top-1 left-2 z-10">
                                                <div className="text-white text-[3px] font-extrabold leading-normal whitespace-nowrap">eFOOTBALL</div>
                                                <div className="uppercase text-white text-[3px] font-extrabold leading-normal whitespace-nowrap">{item.home_card.platform}</div>
                                            </div>
                                            <img className="absolute team-logo top-1 right-2 z-10 w-2" src="/assets/images/efootball-logo.png" alt="" />
                                            <div className="relative">
                                                <img className="w-full" src={`${getPlayerCard(item.home_card.level)}`} alt=""></img>
                                                <img className="absolute w-[50px] bottom-[23px] left-1/2 transform -translate-x-1/2" src={`${process.env.REACT_APP_STORAGE_URL}${item.home_card.card}`} alt="" />
                                            </div>
                                            <h2 className="absolute uppercase text-white text-[7px] font-extrabold leading-normal text-center whitespace-nowrap z-10 bottom-3 left-1/2 transform -translate-x-1/2">{item.home_card.username}</h2>
                                            <div className="flex flex-row items-center absolute age bottom-2 left-1">
                                                <h3 className="uppercase text-white text-[3px] font-medium leading-normal whitespace-nowrap mr-[2px]">Age</h3>
                                                <h3 className="uppercase text-white text-[3px] font-extrabold leading-normal whitespace-nowrap">{calculateAge(item.home_card.birth_year)}</h3>
                                            </div>
                                            <h3 className="absolute uppercase text-white text-[3px] font-extrabold leading-normal whitespace-nowrap domicili bottom-2 right-1">{item.home_card.domisili}</h3>
                                        </div>
                                    </div>
                                    <p className='text-darkgray text-[18px] font-bold leading-normal mx-6 uppercase'>vs</p>
                                    <div className='flex flex-row items-center'>
                                        <div className="w-full lg:w-[74px] relative">
                                            <div className="flex flex-col items-start absolute team-name top-1 left-2 z-10">
                                                <div className="text-white text-[3px] font-extrabold leading-normal whitespace-nowrap">eFOOTBALL</div>
                                                <div className="uppercase text-white text-[3px] font-extrabold leading-normal whitespace-nowrap">{item.away_card.platform}</div>
                                            </div>
                                            <img className="absolute team-logo top-1 right-2 z-10 w-2" src="/assets/images/efootball-logo.png" alt="" />
                                            <div className="relative">
                                                <img className="w-full" src={`${getPlayerCard(item.away_card.level)}`} alt=""></img>
                                                <img className="absolute w-[50px] bottom-[23px] left-1/2 transform -translate-x-1/2" src={`${process.env.REACT_APP_STORAGE_URL}${item.away_card.card}`} alt="" />
                                            </div>
                                            <h2 className="absolute uppercase text-white text-[7px] font-extrabold leading-normal text-center whitespace-nowrap z-10 bottom-3 left-1/2 transform -translate-x-1/2">{item.away_card.username}</h2>
                                            <div className="flex flex-row items-center absolute age bottom-2 left-1">
                                                <h3 className="uppercase text-white text-[3px] font-medium leading-normal whitespace-nowrap mr-[2px]">Age</h3>
                                                <h3 className="uppercase text-white text-[3px] font-extrabold leading-normal whitespace-nowrap">{calculateAge(item.away_card.birth_year)}</h3>
                                            </div>
                                            <h3 className="absolute uppercase text-white text-[3px] font-extrabold leading-normal whitespace-nowrap domicili bottom-2 right-1">{item.away_card.domisili}</h3>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='w-full h-full'>
                                    {
                                        {
                                            0: <div className='mt-[14px]'>
                                                <div className='flex flex-row items-center border-b-[.5px] border-[#475569] pb-5'>
                                                    <h4 className='text-white text-xs font-semibold leading-[10px] mr-2'>{item.home_card.username}</h4>
                                                    {renderLevelTag(item.home_card.level)}
                                                </div>
                                                <div className='flex flex-row items-center justify-between border-b-[.5px] border-[#475569] py-2'>
                                                    <h4 className='text-white text-xs font-medium leading-6'>Match Score</h4>
                                                    <div className="flex items-center justify-center">
                                                        <button onClick={decrementHomeScore} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 11.25C12.1985 11.25 12.3431 11.25 12.3431 11.25H12.5671H12.7496H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496H12.3812H12.1788H11.9691H11.7088H11.4268H11.2496H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496H11.3971H11.588C11.588 11.25 11.8007 11.25 11.9996 11.25Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                        <span className="text-white text-xs font-medium text-center mx-2 leading-6">{countHomeScore}</span>
                                                        <button onClick={incrementHomeScore} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 7.00697C12.1985 7.00697 12.3893 7.08599 12.5299 7.22664C12.6706 7.36729 12.7496 7.55806 12.7496 7.75697V11.25H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496V16.243C12.7496 16.4419 12.6706 16.6326 12.5299 16.7733C12.3893 16.914 12.1985 16.993 11.9996 16.993C11.8007 16.993 11.6099 16.914 11.4693 16.7733C11.3286 16.6326 11.2496 16.4419 11.2496 16.243V12.75H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496V7.75697C11.2496 7.55806 11.3286 7.36729 11.4693 7.22664C11.6099 7.08599 11.8007 7.00697 11.9996 7.00697Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className='flex flex-row items-center justify-between border-b-[.5px] border-[#475569] py-2'>
                                                    <h4 className='text-white text-xs font-medium leading-6'>Penalty</h4>
                                                    <div className="flex items-center justify-center">
                                                        <button onClick={decrementHomePenalty} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 11.25C12.1985 11.25 12.3431 11.25 12.3431 11.25H12.5671H12.7496H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496H12.3812H12.1788H11.9691H11.7088H11.4268H11.2496H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496H11.3971H11.588C11.588 11.25 11.8007 11.25 11.9996 11.25Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                        <span className="text-white text-xs font-medium text-center mx-2 leading-6">{countHomePenalty}</span>
                                                        <button onClick={incrementHomePenalty} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 7.00697C12.1985 7.00697 12.3893 7.08599 12.5299 7.22664C12.6706 7.36729 12.7496 7.55806 12.7496 7.75697V11.25H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496V16.243C12.7496 16.4419 12.6706 16.6326 12.5299 16.7733C12.3893 16.914 12.1985 16.993 11.9996 16.993C11.8007 16.993 11.6099 16.914 11.4693 16.7733C11.3286 16.6326 11.2496 16.4419 11.2496 16.243V12.75H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496V7.75697C11.2496 7.55806 11.3286 7.36729 11.4693 7.22664C11.6099 7.08599 11.8007 7.00697 11.9996 7.00697Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className='flex flex-row items-center border-b-[.5px] border-[#475569] pb-5 mt-5'>
                                                    <h4 className='text-white text-xs font-semibold leading-[10px] mr-2'>{item.away_card.username}</h4>
                                                    {renderLevelTag(item.away_card.level)}
                                                </div>
                                                <div className='flex flex-row items-center justify-between border-b-[.5px] border-[#475569] py-2'>
                                                    <h4 className='text-white text-xs font-medium leading-6'>Match Score</h4>
                                                    <div className="flex items-center justify-center">
                                                        <button onClick={decrementAwayScore} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 11.25C12.1985 11.25 12.3431 11.25 12.3431 11.25H12.5671H12.7496H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496H12.3812H12.1788H11.9691H11.7088H11.4268H11.2496H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496H11.3971H11.588C11.588 11.25 11.8007 11.25 11.9996 11.25Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                        <span className="text-white text-xs font-medium text-center mx-2 leading-6">{countAwayScore}</span>
                                                        <button onClick={incrementAwayScore} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 7.00697C12.1985 7.00697 12.3893 7.08599 12.5299 7.22664C12.6706 7.36729 12.7496 7.55806 12.7496 7.75697V11.25H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496V16.243C12.7496 16.4419 12.6706 16.6326 12.5299 16.7733C12.3893 16.914 12.1985 16.993 11.9996 16.993C11.8007 16.993 11.6099 16.914 11.4693 16.7733C11.3286 16.6326 11.2496 16.4419 11.2496 16.243V12.75H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496V7.75697C11.2496 7.55806 11.3286 7.36729 11.4693 7.22664C11.6099 7.08599 11.8007 7.00697 11.9996 7.00697Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className='flex flex-row items-center justify-between border-b-[.5px] border-[#475569] py-2'>
                                                    <h4 className='text-white text-xs font-medium leading-6'>Penalty</h4>
                                                    <div className="flex items-center justify-center">
                                                        <button onClick={decrementAwayPenalty} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 11.25C12.1985 11.25 12.3431 11.25 12.3431 11.25H12.5671H12.7496H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496H12.3812H12.1788H11.9691H11.7088H11.4268H11.2496H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496H11.3971H11.588C11.588 11.25 11.8007 11.25 11.9996 11.25Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                        <span className="text-white text-xs font-medium text-center mx-2 leading-6">{countAwayPenalty}</span>
                                                        <button onClick={incrementAwayPenalty} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34461 4.01697C10.4385 3.67403 13.5608 3.67403 16.6546 4.01697C18.3676 4.20897 19.7496 5.55797 19.9506 7.27697C20.3175 10.4146 20.3175 13.5843 19.9506 16.722C19.8489 17.5572 19.468 18.3337 18.8698 18.9254C18.2715 19.5171 17.491 19.8894 16.6546 19.982C13.5608 20.326 10.4384 20.326 7.34461 19.982C6.50827 19.8894 5.72769 19.5171 5.12944 18.9254C4.53118 18.3337 4.15033 17.5572 4.04861 16.722C3.68171 13.5847 3.68171 10.4153 4.04861 7.27797C4.15028 6.44284 4.53099 5.66651 5.12905 5.07481C5.7271 4.48311 6.50745 4.11071 7.34361 4.01797L7.34461 4.01697ZM11.9996 7.00697C12.1985 7.00697 12.3893 7.08599 12.5299 7.22664C12.6706 7.36729 12.7496 7.55806 12.7496 7.75697V11.25H16.2426C16.4415 11.25 16.6323 11.329 16.7729 11.4696C16.9136 11.6103 16.9926 11.8011 16.9926 12C16.9926 12.1989 16.9136 12.3896 16.7729 12.5303C16.6323 12.671 16.4415 12.75 16.2426 12.75H12.7496V16.243C12.7496 16.4419 12.6706 16.6326 12.5299 16.7733C12.3893 16.914 12.1985 16.993 11.9996 16.993C11.8007 16.993 11.6099 16.914 11.4693 16.7733C11.3286 16.6326 11.2496 16.4419 11.2496 16.243V12.75H7.75661C7.5577 12.75 7.36694 12.671 7.22628 12.5303C7.08563 12.3896 7.00661 12.1989 7.00661 12C7.00661 11.8011 7.08563 11.6103 7.22628 11.4696C7.36694 11.329 7.5577 11.25 7.75661 11.25H11.2496V7.75697C11.2496 7.55806 11.3286 7.36729 11.4693 7.22664C11.6099 7.08599 11.8007 7.00697 11.9996 7.00697Z" fill="#1F98FF" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                {new Date() < new Date(item.closing_date)
                                                    ?
                                                    <div className="w-full mb-4 mt-2">
                                                        {imageSrc
                                                            ?
                                                            <label className="h-full flex flex-col justify-center items-center w-full bg-transparent rounded-3xl border border-darkgray cursor-pointer hover:border-secondary mb-4">
                                                                <div className="flex flex-row justify-between items-center p-6 w-full">
                                                                    <div className="text flex flex-row items-center">
                                                                        <img
                                                                            src="/assets/images/file-solid.svg"
                                                                            alt="Shoes"
                                                                            className="w-[1.5rem]"
                                                                        />
                                                                        <p className="text-sm text-secondary dark:text-secondary-400 mx-2 font-semibold text-[0.875rem] underline">
                                                                            {imageName}
                                                                        </p>
                                                                    </div>


                                                                    <img
                                                                        src="/assets/images/remove-image.svg"
                                                                        alt="Shoes"
                                                                        className="w-[1.5rem] cursor-pointer"
                                                                        onClick={deleteImage}
                                                                    />
                                                                </div>
                                                            </label>
                                                            : <></>
                                                        }
                                                        <label className="h-auto flex flex-col justify-center items-center w-full bg-transparent rounded-3xl border border-darkgray cursor-pointer hover:border-secondary p-6 mb-6">
                                                            <figure className="mb-[17px]">
                                                                <img
                                                                    src={!imageSrc ? '/assets/images/solar_upload-square-bold.svg' : imageSrc}
                                                                    alt="upload"
                                                                    className="w-[100px] h-[100px] object-contain object-center"
                                                                />
                                                            </figure>
                                                            <span className="font-semibold text-sm leading-[19px] text-center text-darkgray">
                                                                Upload Bukti Pertandingan
                                                            </span>
                                                            <input
                                                                id="dropzone-file"
                                                                name="avatar"
                                                                type="file"
                                                                className="hidden"
                                                                onChange={handleFileChange}
                                                            />
                                                        </label>
                                                        {!load
                                                            ?
                                                            <button className='btn bg-secondary-gradient rounded-md w-full px-4 h-[45px] sticky bottom-6 left-0 text-xs font-bold lg:h-[50px] disabled:bg-darkgray capitalize'
                                                                onClick={handleSubmitResult}
                                                            >
                                                                Submit Score</button>
                                                            :
                                                            <button className='btn bg-nav2 text-secondary rounded-md w-full px-4 h-[45px] sticky bottom-6 left-0 text-xs font-bold lg:h-[50px] disabled:bg-darkgray capitalize'
                                                            >
                                                                Please Wait</button>
                                                        }
                                                    </div>
                                                    :
                                                    <label className="h-auto flex flex-col justify-center items-center w-full bg-transparent rounded-3xl border border-darkgray cursor-pointer hover:border-secondary p-6 mt-2">
                                                        <figure className="mb-[17px]">
                                                            <img
                                                                src={!item.card_result ? '/assets/images/solar_upload-square-bold.svg' : `${process.env.REACT_APP_STORAGE_URL}${item.card_result.upload_by_player}`}
                                                                alt="upload"
                                                                className="w-[100px] h-[100px] object-contain object-center"
                                                            />
                                                        </figure>
                                                    </label>
                                                }
                                            </div>
                                        }[0]
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

const renderLevelTag = (level: string) => {
    if (level === 'amateur') {
        return <div className="badge badge-outline-amateur tag">Amateur</div>;
    } else if (level === 'semi') {
        return <div className="badge badge-outline-semi tag">Semi-Pro</div>;
    } else {
        return <div className="badge badge-outline-pro tag">Pro</div>;
    }
};

export default InputScore;
