import { Pagination } from 'antd';
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDate, formatRupiah } from '../../app/config/enum';
import { useStore } from '../../app/stores/store';

function ProfileTransactionHistory() {

    const { transactionStore, accountStore } = useStore();

    const navigate = useNavigate();

    const handlePayment = (order_id: string, airwaybill: string | undefined, transaction_id: number, product_id: number) => {
        navigate(`/store/${product_id}/${transaction_id}/payment`);
    }

    const [page, setPage] = useState(1);

    useEffect(() => {
        transactionStore.listTransactionsByUser(page);
    }, [page, transactionStore]);

    return (
        <div className='w-full bg-nav2 rounded-lg flex flex-col justify-center items-center py-[4vh] font-sans space-y-2'>
            <div className='w-full text-center text-white font-bold'>
                <h3 className='text-[4.5vw] lg:text-[1.5vw]'>Transaction History</h3>
            </div>
        </div>
    )
}

export default observer(ProfileTransactionHistory)