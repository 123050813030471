import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";

const SuccessPayment = () => {
    const { orderStore } = useStore();

    const [searchParams, setSearchParams] = useSearchParams();
    let defaultStatus = searchParams.get("transaction_status")
    let orderId = searchParams.get('order_id')
    console.log(defaultStatus)

    const handlePayment = (airwaybill: string | undefined) => {
        window.location.replace(`${process.env.REACT_APP_SNAP_API_MIDTRANS}${airwaybill}`);
    }

    useEffect(() => {
        if(orderId){
            console.log(orderStore.getPaymentById(String(orderId)))
        }
        // transactionStore.paymentById(page);
        // TournamentStore.getMyOrder(String(slug));
    }, [defaultStatus]);

    return (
        <div className="bg-nav1 fixed inset-0 flex flex-col items-center justify-center">
            <img
                className="w-[148px] h-auto mt-[88px] mb-[43px] mx-auto"
                src="/assets/images/success-payment.svg"
                alt="success"
            />
            <h2 className="text-[48px] font-extrabold leading-normal text-white text-center mb-[63px]">{ defaultStatus === 'pending' ? 'Menunggu Pembayaran' : 'Pembelian Berhasil' }</h2>
            
            {/* <a className="block text-secondary underline text-sm text-center font-semibold" href="/tournament">Lanjutkan Pembayaran</a> */}
            {/* <button className='text-xs font-medium bg-secondary px-4 py-1 lg:py-2 rounded-md flex justify-end' onClick={() => handlePayment(trans.detail.airwaybill)}>Pay Now</button> */}
            <br/>
            
            <a className="block text-secondary underline text-sm text-center font-semibold" href="/tournament">Kembali ke Tournament</a>

        </div>
    )
}

export default observer(SuccessPayment);