import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { formatRupiah } from '../../app/config/enum';
import { IPayment } from '../../app/models/transactions';
import { useStore } from '../../app/stores/store'

function StorePayment() {

    const { storesStore, transactionStore } = useStore();
    const { productId, transactionId } = useParams();

    useEffect(() => {
        storesStore.getProductById(Number(productId));
        transactionStore.transactionsById(transactionId);
    }, [transactionId, productId, storesStore, transactionStore]);

    const handlePayment = () => {
        let payment : IPayment = {
            order_id: transactionStore.transactionPayment?.order_id,
            amount: transactionStore.transactionPayment?.total,
            first_name: transactionStore.transactionPayment?.user.name,
            email: transactionStore.transactionPayment?.user.email,
            phone: transactionStore.transactionPayment?.user.phone,
        }
        transactionStore.payment(payment).then((response) => {
            window.location.replace(`https://app.midtrans.com/snap/v3/redirection/${response.data}`);
        })
    }

  return (
    <React.Fragment>
        <script
        type="text/javascript"
        src="https://app.sandbox.midtrans.com/snap/snap.js"
        data-client-key="Mid-client-czd3OvuV2GLRHXp2"
        ></script>
        <div className='px-4 lg:px-0 w-full h-full bg-bg-home flex flex-col space-y-5 justify-center items-center font-sans py-6'>
            <div className='w-full lg:w-1/2 h-full bg-nav2 flex flex-row justify-between items-center py-4 px-4 rounded-lg'>
                <div className='w-2/5 lg:w-1/5'>
                    <img src={storesStore.product?.image} alt="product" className='w-32 h-32 rounded-md'/>
                </div>
                <div className='w-3/5 lg:w-4/5 h-full flex flex-col justify-between'>
                    <span className='text-white font-semibold '>{storesStore.product?.name}</span>
                    {/* <div className='w-full h-full flex flex-row justify-between items-center'>
                        <p className='text-gray-700 font-semibold'>Harga Barang :</p>
                        <p className='text-gray-700 font-semibold'>{formatRupiah(storesStore.product?.price!)}</p>
                    </div> */}
                    <div className='w-full h-full flex flex-col justify-end items-end text-secondary'>
                        <p className='font-semibold'>Order Total</p>
                        <p className='font-bold'>{formatRupiah(storesStore.product?.price!)}</p>
                    </div> 
                </div>
            </div>
            <div className='w-full lg:w-1/2 h-full flex flex-col justify-center items-center bg-secondary rounded-lg'>
                {!transactionStore.loading ? (
                    <button className='text-white text-[4vw] lg:text-[1.4vw] font-bold p-5 bg-secondary w-full rounded-lg hover:bg-blue-700' onClick={handlePayment}>Bayar Sekarang</button>
                ) : (
                    <button className='text-white text-[4vw] lg:text-[1.4vw] font-bold p-5 bg-gray-400 w-full rounded-lg' disabled onClick={handlePayment}>Please Wait</button>
                )}            
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(StorePayment)