import { ReactNode } from "react";

export interface ProfileMenu {
    id: number,
    name: string,
    icon: ReactNode,
    active: boolean,
    link: string
}

export const profileMenu: ProfileMenu[] = [
    {
        id: 1,
        name: 'My Profile',
        icon: <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" stroke='currentColor' className='w-4 h-4'>
            <path d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z"/>
            </svg>,
        active: false,
        link: 'profile'
    },
    {
        id: 2,
        name: 'Setting Password',
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" stroke='currentColor' className='w-4 h-4'>
            <path d="M13 0.25C9.29125 0.25 6.25 3.29125 6.25 7C6.25 7.39 6.31375 7.7335 6.367 8.0785L0.46 13.96L0.25 14.1955V19.75H5.5V17.5H7.75V15.25H10V13.0225C10.885 13.4575 11.9163 13.75 13 13.75C16.7087 13.75 19.75 10.7087 19.75 7C19.75 3.29125 16.7087 0.25 13 0.25ZM13 1.75C15.8912 1.75 18.25 4.10875 18.25 7C18.25 9.89125 15.8912 12.25 13 12.25C12.108 12.2406 11.2316 12.0154 10.4455 11.5938L10.258 11.5H8.5V13.75H6.25V16H4V18.25H1.75V14.8278L7.6795 8.875L7.96075 8.617L7.89175 8.2195C7.81675 7.7695 7.75075 7.36675 7.75075 7C7.75075 4.10875 10.1095 1.75 13.0007 1.75H13ZM14.5 4C14.3002 3.99548 14.1016 4.03092 13.9157 4.10423C13.7299 4.17754 13.5605 4.28724 13.4176 4.4269C13.2747 4.56656 13.1612 4.73336 13.0836 4.91751C13.0061 5.10165 12.9661 5.29944 12.9661 5.49924C12.966 5.69905 13.0059 5.89685 13.0834 6.08103C13.1608 6.26522 13.2743 6.43207 13.4171 6.57181C13.5599 6.71154 13.7292 6.82133 13.915 6.89473C14.1009 6.96813 14.2995 7.00367 14.4993 6.99925C14.8912 6.99058 15.2641 6.82883 15.5383 6.54862C15.8124 6.26841 15.966 5.89201 15.9661 5.49999C15.9662 5.10797 15.8128 4.7315 15.5388 4.45115C15.2648 4.1708 14.8919 4.00886 14.5 4Z"/>
            </svg>,
        active: false,
        link: 'setting-password'
    },
    {
        id: 3,
        name: 'Tournament',
        icon: <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" stroke='currentColor' className='w-4 h-4'>
            <path d="M2.25 0C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25V4.0395C0 4.8195 0.405 5.5425 1.0665 5.952L6.8655 9.537C5.98346 10.0122 5.28524 10.7683 4.88151 11.6853C4.47778 12.6022 4.39162 13.6277 4.63669 14.5992C4.88176 15.5707 5.44403 16.4327 6.23441 17.0484C7.0248 17.6642 7.99807 17.9986 9 17.9986C10.0019 17.9986 10.9752 17.6642 11.7656 17.0484C12.556 16.4327 13.1182 15.5707 13.3633 14.5992C13.6084 13.6277 13.5222 12.6022 13.1185 11.6853C12.7148 10.7683 12.0165 10.0122 11.1345 9.537L16.9335 5.952C17.2594 5.75042 17.5285 5.46894 17.7151 5.13424C17.9018 4.79955 17.9998 4.42272 18 4.0395V2.25C18 1.65326 17.7629 1.08097 17.341 0.65901C16.919 0.237053 16.3467 0 15.75 0H2.25ZM6 7.239V1.5H12V7.239L9.3945 8.85C9.27597 8.92331 9.13936 8.96214 9 8.96214C8.86064 8.96214 8.72403 8.92331 8.6055 8.85L6 7.239ZM9 10.5C9.79565 10.5 10.5587 10.8161 11.1213 11.3787C11.6839 11.9413 12 12.7044 12 13.5C12 14.2956 11.6839 15.0587 11.1213 15.6213C10.5587 16.1839 9.79565 16.5 9 16.5C8.20435 16.5 7.44129 16.1839 6.87868 15.6213C6.31607 15.0587 6 14.2956 6 13.5C6 12.7044 6.31607 11.9413 6.87868 11.3787C7.44129 10.8161 8.20435 10.5 9 10.5Z"/>
            </svg>,
        active: false,
        link: 'profile-tournament'
    },
    {
        id: 4,
        name: 'Transaction History',
        icon: <svg width="20" height="12" viewBox="0 0 20 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" stroke='currentColor' className='w-4 h-4'>
            <path d="M17.7999 0C18.2773 0 18.7351 0.189642 19.0727 0.527208C19.4103 0.864773 19.5999 1.32261 19.5999 1.8V3.6C19.5999 3.75913 19.5367 3.91174 19.4242 4.02426C19.3116 4.13679 19.159 4.2 18.9999 4.2C18.5225 4.2 18.0647 4.38964 17.7271 4.72721C17.3895 5.06477 17.1999 5.52261 17.1999 6C17.1999 6.47739 17.3895 6.93523 17.7271 7.27279C18.0647 7.61036 18.5225 7.8 18.9999 7.8C19.159 7.8 19.3116 7.86321 19.4242 7.97574C19.5367 8.08826 19.5999 8.24087 19.5999 8.4V10.2C19.5999 10.6774 19.4103 11.1352 19.0727 11.4728C18.7351 11.8104 18.2773 12 17.7999 12H2.1999C1.72251 12 1.26468 11.8104 0.92711 11.4728C0.589545 11.1352 0.399902 10.6774 0.399902 10.2V8.4C0.399902 8.24087 0.463117 8.08826 0.575638 7.97574C0.68816 7.86321 0.840772 7.8 0.999902 7.8C1.47729 7.8 1.93513 7.61036 2.27269 7.27279C2.61026 6.93523 2.7999 6.47739 2.7999 6C2.7999 5.52261 2.61026 5.06477 2.27269 4.72721C1.93513 4.38964 1.47729 4.2 0.999902 4.2C0.840772 4.2 0.68816 4.13679 0.575638 4.02426C0.463117 3.91174 0.399902 3.75913 0.399902 3.6V1.8C0.399902 1.32261 0.589545 0.864773 0.92711 0.527208C1.26468 0.189642 1.72251 0 2.1999 0H17.7999ZM18.3999 1.8C18.3999 1.64087 18.3367 1.48826 18.2242 1.37574C18.1116 1.26321 17.959 1.2 17.7999 1.2H2.1999C2.04077 1.2 1.88816 1.26321 1.77564 1.37574C1.66312 1.48826 1.5999 1.64087 1.5999 1.8V3.06C2.27808 3.19771 2.88779 3.56564 3.32573 4.10144C3.76367 4.63725 4.00291 5.30799 4.00291 6C4.00291 6.69201 3.76367 7.36275 3.32573 7.89856C2.88779 8.43436 2.27808 8.80229 1.5999 8.94V10.2C1.5999 10.3591 1.66312 10.5117 1.77564 10.6243C1.88816 10.7368 2.04077 10.8 2.1999 10.8H17.7999C17.959 10.8 18.1116 10.7368 18.2242 10.6243C18.3367 10.5117 18.3999 10.3591 18.3999 10.2V8.94C17.7383 8.80582 17.1412 8.45239 16.7053 7.93687C16.2694 7.42135 16.0202 6.77388 15.9978 6.09914C15.9755 5.42441 16.1814 4.76186 16.5822 4.21864C16.983 3.67541 17.5554 3.28326 18.2067 3.1056L18.3999 3.06V1.8Z"/>
            </svg>,
        active: false,
        link: 'transaction-history'
    },
]