import React, { useEffect, useState } from 'react'
import LeagueStandings from './LeagueStandings';
import LeagueSchedule from './LeagueSchedule';
import LeagueClubProfile from './LeagueClubProfile';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';

function LeagueIndexPage() {

    const { leagueStore } = useStore();

    const navigate = useNavigate();

    const { categoryLeague ,leagueId } = useParams();
    const id = Number(leagueId);
    const categoryId = Number(categoryLeague);

    const [activeTab, setActiveTab] = useState(1);

    const [menu, setMenu] = useState([
        {
            id : 1,
            name: "Standings",
            active: true
        },
        {
            id : 2,
            name: "Schedule",
            active: false
        },
        {
            id : 3,
            name: "Team Profile",
            active: false
        }
    ]);

    const handleMenu = (buttonId: number) => {
    const updatedButtons = menu.map(button => {
        if (button.id === buttonId) {
            return { ...button, active: true };
        } else {
            return { ...button, active: false };
        }
        });
        setMenu(updatedButtons);
        setActiveTab(buttonId);
    }

    useEffect(() => {
        leagueStore.getLeagueByCategory(categoryId);
        leagueStore.getLeagueById(id);
        leagueStore.getStandings(id);
    }, [categoryId, id, leagueStore]);

    useEffect(() => {
        if( leagueStore.selectedLeague?.without_standing === 1) {
            setMenu(
                [
                    {
                        id : 2,
                        name: "Schedule",
                        active: true
                    },
                    {
                        id : 3,
                        name: "Team Profile",
                        active: false
                    }
                ]
            )

            setActiveTab(2);
        }
    }, [leagueStore, leagueStore.selectedLeague]);

  return (
    <React.Fragment>
        <div className='w-full h-full font-sans bg-top bg-no-repeat bg-bg-home'>
            <div className='flex flex-row items-center justify-between w-full h-full px-6 py-4 lg:py-10 lg:px-20 xl:px-64 bg-gradient-to-r from-nav2 to-transparent'>
                <img src={leagueStore.selectedLeague?.flag} alt='ifel' className='w-[15vw] lg:w-[10vw]'/>
                <div className='flex flex-col items-start justify-center w-full h-full pl-4 space-y-2 text-white lg:pl-12'>
                    <select className="select select-xs w-1/3 lg:hidden text-[1.5vw] lg:w-1/3 text-gray-500" onChange={(e) => navigate(`/league/${categoryLeague}/${e.target.value}`)}>
                        {leagueStore.listLeague.map((data, idx) => (
                            <option value={data.id} key={idx} selected={data.id === leagueStore.selectedLeague?.id}>{data.name} - {data.year}</option>
                        ))}
                    </select>
                    <select className="hidden lg:block select w-1/3 text-[0.9vw] lg:w-1/3 text-gray-500" onChange={(e) => navigate(`/league/${categoryLeague}/${e.target.value}`)}>
                        {leagueStore.listLeague.map((data, idx) => (
                            <option value={data.id} key={idx} selected={data.id === leagueStore.selectedLeague?.id}>{data.name} - {data.year}</option>
                        ))}
                    </select>
                    <h2 className='font-extrabold text-[6vw] lg:text-[3.5vw] leading-none'>{leagueStore.selectedLeague?.name} {leagueStore.selectedLeague?.year}</h2>
                    { leagueStore.selectedLeague?.sponsor && <span className='font-semibold lg:font-bold text-[2.5vw] lg:text-[1.2vw] mb-2'>Sponsored by {leagueStore.selectedLeague?.sponsor}</span>}
                    <p className=' text-[2vw] lg:text-[1.1vw]'>{leagueStore.selectedLeague?.description}</p>
                </div>
            </div>
            <div className='w-full h-full px-6 py-4 lg:px-20 xl:px-40 lg:py-12'>
                <div id='tab' className='flex flex-row w-full lg:space-x-2 xlspace-x-4'>
                    {menu.map((item, idx) => (
                    <button key={idx} className={`capitalize text-[3vw] lg:text-[0.9vw] px-5 py-2 rounded-md font-medium lg:px-3 lg:py-2 lg:rounded-md xl:rounded-lg ${item.active ? 'bg-secondary text-white' : 'bg-none text-gray-500'}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                    ))}
                </div>
                {
                    {
                    1: <LeagueStandings/>,
                    2: <LeagueSchedule/>,
                    3: <LeagueClubProfile/>
                    }[activeTab]
                }
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(LeagueIndexPage)