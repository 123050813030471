import { makeAutoObservable } from "mobx"
import agent from "../api/agent";
import { IOrder, IOrderDetail, IOrderPaymentDetail } from "../models/order";
import { IPayment, IPaymentSubscription } from "../models/transactions";
import { toast } from "react-toastify";

export default class orderStore {

    order: IOrderDetail | null = null;
    orderDetail: IOrderPaymentDetail | null = null;
    airwaybill = ''
    errorMessage = ''
    btnProcess : string = 'Bayar'
    btnProcessStatus = false
    toastStatus = false;

    errorMessageOrder = 'Data Profile Anda tidak sesuai dengan Tournament yang akan Anda ikuti saat ini! ikuti Tournament lainnya dan periksa kembali apakah Tournament yang Anda ikuti sesuai dengan Level, Game, dan Platform yang akan dimainkan.'
    errorMessageOrderHeading = 'Data Tidak Sesuai'


    constructor() {
        makeAutoObservable(this);
    }

    setbtnProcess = (status: string) => {
        this.btnProcess = status
    }
    setToastStatus = () => {
        this.toastStatus = !!this.toastStatus
    }
    setbtnProcessStatus = (status: boolean) => {
        this.btnProcessStatus = status
    }
    

    createOrder = async (data: IOrder) => {
        try {
            await agent.Order.order(data).then((res) => {
                this.setOrder(res.data)
            })
            return true
        } catch (error : any) {
            if(error?.response?.data?.data === 'subscribe'){
                this.errorMessageOrderHeading = 'Subscribers only'
                this.errorMessageOrder = 'Tournament yang ingin Anda akses dikhususkan untuk Player Subscriber, lakukan Subscribe pada Website Indonesian Football e-League untuk mengikuti Tournament ini';
            }else{
                this.errorMessageOrderHeading = 'Data Tidak Sesuai'
                this.errorMessageOrder = 'Data Profile Anda tidak sesuai dengan Tournament yang akan Anda ikuti saat ini! ikuti Tournament lainnya dan periksa kembali apakah Tournament yang Anda ikuti sesuai dengan Level, Game, dan Platform yang akan dimainkan.';
            }
            return false
        }
    }

    setOrder = (data: IOrderDetail) => {
        this.order = data
    }


    getPaymentById = async (transaction_number: string) => {
        await agent.Order.paymentById(transaction_number).then((res) => {
            this.setDetail(res.data)
        })
    }

    setDetail = (data: IOrderPaymentDetail) => {
        this.orderDetail = data
    }

    paymentSubscription = async (data : IPaymentSubscription) => {
        try {
            this.setbtnProcess('Sedang memproses transaksi ...');
            //this.btnProcess = 'Sedang memproses transaksi ...'
            this.setbtnProcessStatus(true)
            await agent.Order.paymentSubscription(data).then((res) => {
                this.errorMessage = ''
                this.setbtnProcess('Bayar');
                this.setbtnProcessStatus(false)
                this.setAirwaybill(res.data)
                this.midtransRedirect()
                
            })
        } catch (error : any) {
            
            this.setbtnProcess('Bayar');
            this.setbtnProcessStatus(false)
            this.errorMessage = error.response.data.data;
            this.setToastStatus();
            //console.log({error:error.response.data.data});
        }
    }

    payment = async (data: IPayment) => {
        try {
            await agent.Order.payment(data).then((res) => {
               
                this.setAirwaybill(res.data)
                this.midtransRedirect()
            })
        } catch (error) {
            
            console.log("gagal")
        }
    }

    setAirwaybill = (data: string) => {
        this.airwaybill = data
    }

    midtransRedirect = () => {
        //${process.env.REACT_APP_STORAGE_URL}
        // window.location.replace(`https://app.sandbox.midtrans.com/snap/v3/redirection/${this.airwaybill}`);
        window.location.replace(`${process.env.REACT_APP_SNAP_API_MIDTRANS}${this.airwaybill}`);
    }
}