import React, { useCallback, useState } from 'react';
import { LoginSocialGoogle, LoginSocialFacebook, IResolveParams } from "reactjs-social-login";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { IAccountLoginValues } from '../../models/account';
import InputPassword from '../input/InputPassword';
import { useStore } from '../../stores/store';
import '../topNavbar/topNavbar.css';
import Login from '../modal/Login';
import Register from '../modal/Register';
import { Drawer } from 'antd';

interface ModalProps {
    onOpenModal: boolean;
    type: string;
    onClose: () => void;
    onSwitch: () => void;
}

const TopAuth: React.FC<ModalProps> = ({ onOpenModal, type, onClose, onSwitch }) => {

    if (onOpenModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'visible';
    }

    if (!onOpenModal) return null;

    if (type === 'login') {
        return <Login onOpenLogin={true} closeModal={onClose} switchModal={onSwitch}></Login>
    } else {
        return <Register onOpenRegister={true} closeModal={onClose} switchModal={onSwitch}></Register>

    }
};

export default TopAuth;