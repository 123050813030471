import { makeAutoObservable } from "mobx"
import agent from "../api/agent";
import { IProductCategory, IProducts } from "../models/store";


export default class StoresStore {

    listProducts: IProducts[] = [];
    listCategories: IProductCategory[] = [];
    product: IProducts | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    getListProducts = async (category: string, sort: string) => {
        try {
            const result = await agent.Store.listProducts(category, sort);
            this.setListProducts(result.data);
        } catch (error) {
            throw(error)
        }
    }

    setListProducts = (data: IProducts[]) => {
        this.listProducts = data;
    }

    getListCategories = async () => {
        try {
            const result = await agent.Store.listCategory();
            this.setListCategories(result.data);
        } catch (error) {
            throw(error)
        }
    }

    setListCategories = (data: IProductCategory[]) => {
        this.listCategories = data;
    }

    getProductById = async (id: number) => {
        try {
            const result = await agent.Store.product(id);
            this.setProduct(result.data);
        } catch (error) {
            throw(error)
        }
    }

    setProduct = (data: IProducts) => {
        this.product = data;
    }
}