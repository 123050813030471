import { makeAutoObservable } from "mobx"
import agent from "../api/agent";
import { IClub } from "../models/club";
import { IHistory, IHistoryDelete, IHistoryInput, ITeam } from "../models/team";
import { ISearchRequest } from "../models/general";
import { IUserProfile } from "../models/profile";
import { IAccountCard, IAchievement } from "../models/account";


export default class PlayerStore {

    profile: IUserProfile | null = null;
    card: IAccountCard[] = [];
    achievement: IAchievement[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getProfileUser = async (slug: string) => {
        try {
            const profile = await agent.Account.userProfile(slug);
            this.setProfile(profile.data)
        } catch (error) {
            throw (error)
        }
    }

    setProfile = (data: IUserProfile) => {
        this.profile = data
    }

    getCard = async (slug: string) => {
        await agent.Account.cardList(slug).then((res) => {
            this.setCard(res.data)
        })
    }

    setCard = (data: IAccountCard[]) => {
        this.card = data
    }

    getAchievementList = async (slug: string) => {
        await agent.Account.achievementList(slug).then((res) => {
            this.setAchievementList(res.data)
        })
    }

    setAchievementList = (data: IAchievement[]) => {
        this.achievement = data
    }
}