import { observer } from "mobx-react-lite";
import '../../../src/index.css'
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import SkeletonComponent from "../../app/component/skeleton/SkeletonComponent";

interface AccordionItem {
    title: string;
    content: string;
    isOpen: boolean;
}

const Subscription: React.FC = () => {


    const [accordions, setAccordions] = useState<AccordionItem[]>([
        { title: 'Frequently Asked Question 1', content: 'Content Frequently Asked Question', isOpen: false },
        { title: 'Frequently Asked Question 2', content: 'Content Frequently Asked Question', isOpen: false },
        { title: 'Frequently Asked Question 3', content: 'Content Frequently Asked Question', isOpen: false },
        { title: 'Frequently Asked Question 4', content: 'Content Frequently Asked Question', isOpen: false },
    ]);

    const toggleAccordion = (index: number) => {
        SubscriptionStore.faqData[index].isOpen =  !SubscriptionStore.faqData[index].isOpen
    };

    const renderBennefits = (text: string) => {
        const lines = text.split('\n');

        // Wrap each line in <li></li> tags
        const listItems = lines.map((line, index) => (
          <li key={index}>{line}</li>
        ));
      
        // Return the list within a <ul> element
        return <ul className="custom-list-style">{listItems}</ul>;
    };

    const { SubscriptionStore } = useStore();
    useEffect(() => {
        SubscriptionStore.getSubcriptionData()
        SubscriptionStore.getBennefitsData()
        SubscriptionStore.getFaqData()
    }, [SubscriptionStore]);
    return (
        <div>
            <div className='w-full h-full bg-primary bg-top bg-no-repeat font-sans lg:py-[50px] lg:px-[160px] lg:bg-bg-home'>
                <span className="text-[#4791FF] text-xl font-semibold flex justify-center w-full mt-6 mb-4">Tournament Subscription</span>
                <h1 className="text-white text-[22px] font-bold leading-normal flex justify-center text-center w-full mb-6 px-4 lg:text-[55px]">Mulai Petualangan Karir Virtual Football
                    Anda di Indonesian Football e-League!</h1>

                {
                    SubscriptionStore.loading == false ?
                        (
                            <div className="flex flex-col items-center justify-between max-w-[853px] px-4 mx-auto lg:flex-row lg:px-0">


                                {SubscriptionStore.data.map((item, index) => (
                                    <div key={index} className="bg-nav1 rounded-xl w-full mb-4 text-white py-[34px] px-4 lg:mb-0 lg:w-[276px]">
                                        <h6 className="text-base text-extrabold mb-6 text-center">{item.title}</h6>
                                        <h4 className="text-[34px] font-extrabold mb-[34px] text-center">IDR {item.price}</h4>
                                        <a href={'/payment/subscription/' + item.slug} className="text-xl font-bold w-[235px] h-[50px] rounded-md bg-secondary mb-2 flex flex-row items-center justify-center mx-auto">Subscribe</a>
                                        <p className="text-white text-center text-xs font-medium mb-[18px]">{item.description}</p>
                                        {renderBennefits(item.note)}
                                    </div>
                                ))}

                            </div>
                        ) : (

                            <div className="flex flex-col items-center justify-between max-w-[853px] px-4 mx-auto lg:flex-row lg:px-0">
                                <div className="bg-nav1 rounded-xl w-full mb-4 text-white py-[34px] px-4 lg:mb-0 lg:w-[276px]">
                                    <SkeletonComponent
                                        count={3}
                                        width='w-full'
                                        height='h-[50px]'
                                        borderRadius='rounded-sm'
                                    />
                                </div>
                                <div className="bg-nav1 rounded-xl w-full mb-4 text-white py-[34px] px-4 lg:mb-0 lg:w-[276px]">
                                    <SkeletonComponent
                                        count={3}
                                        width='w-full'
                                        height='h-[50px]'
                                        borderRadius='rounded-sm'
                                    />
                                </div>
                                <div className="bg-nav1 rounded-xl w-full mb-4 text-white py-[34px] px-4 lg:mb-0 lg:w-[276px]">
                                    <SkeletonComponent
                                        count={3}
                                        width='w-full'
                                        height='h-[50px]'
                                        borderRadius='rounded-sm'
                                    />
                                </div>

                            </div>
                        )
                }
            </div>
            <div className="bg-[1D2335] px-4 py-[50px] lg:px-0">
                <div className="flex flex-col items-start justify-between max-w-[853px] mx-auto lg:space-x-6 lg:flex-row">
                {
                    SubscriptionStore.bennefitsData.map((item, index) => (
                        <div className="bg-nav1 rounded-xl w-full text-white py-[28px] mb-4 px-4 lg:w-[276px] lg:mb-0">
                            <h4 className="text-[34px] font-extrabold mb-4 text-center">{item.title}</h4>
                            <img className="w-[190px] h-[106px] mx-auto mb-4 object-contain" src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}  alt="" />
                            <p className="text-white text-xs font-medium leading-4 text-center">{item.description}</p>
                        </div>
                    ))
                }
                </div>
            </div>
            <div style={{ background: 'linear-gradient(177deg, rgba(20, 22, 34, 0.43) 35.5%, #0E101C 68.21%)' }}>
                <div className="w-full max-w-[1114px] mx-auto px-4 py-[50px] flex flex-col items-start lg:px-0">
                    <h2 className="text-white text-[55px] font-extrabold leading-normal mb-6 text-center w-full flex flex-row justify-center">FAQ</h2>
                    <div className="w-full">
                        {SubscriptionStore.faqData.map((accordion, index) => (
                            <div className="border-b-[.5px] border-[#475569] pb-2 mb-[14px]" key={index}>
                                <div className="flex flex-row items-center">
                                    <div className="w-full text-white text-xl font-semibold leading-normal" onClick={() => toggleAccordion(index)}>
                                        {accordion.title}
                                    </div>
                                    <div>
                                        {accordion.isOpen ? (
                                            <img src="/assets/images/arrow-down.svg" className='w-[14px] rotate-180' alt="" />
                                        ) : (
                                            <img src="/assets/images/arrow-down.svg" className='w-[14px]' alt="" />
                                        )}
                                    </div>
                                </div>
                                {accordion.isOpen && <div className="text-white text-sm font-normal leading-normal mt-6"  style={{ whiteSpace: 'pre-wrap' }}>{accordion.description}</div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default observer(Subscription);
