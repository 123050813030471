import { observer } from 'mobx-react-lite';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import { useEffect, useState } from 'react';
import { IAccountSosialLoginValues } from '../../app/models/account';

function Welcome() {
    const { accountStore, notificationStore } = useStore();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const keyParam = searchParams.get('key');

    useEffect(() => {
        async function fetchData() {
            try {
                await accountStore.validateToken(String(keyParam)).then((res) => {
                    console.log(res);
                    if (res) {
                        notificationStore.setNotification("Berhasil verifikasi email")
                        return navigate('/');
                    } else {
                        notificationStore.setNotification("Gagal verifikasi email, kirim uang email")
                        return;
                    }
                })
            } catch (error) {
                console.error('Validation error:', error);
            }
        }
        // Call the async function
        fetchData()
    });

    //   if (accountStore.isLoggedIn) {
    //     return <Navigate to="/" />;
    //   }

    return (
        <div className="w-full h-[100wh] px-0 lg:px-40 py-0 lg:py-12 bg-bg-home bg-primary bg-no-repeat bg-cover font-sans">
            <style>
                {`
        .bg-pass input {
          background: #1C1F2A;
          color: white;
        }
        `}
            </style>
            <div className="w-full h-[620px] flex flex-row bg-primary lg:rounded-xl">
                <div className="relative hidden w-full bg-bg-box-login bg-secondary bg-no-repeat bg-cover rounded-lg px-12 pt-20 lg:flex flex-col">
                    <div className="text-white">
                        <h1 className="font-extrabold text-[58px]">HELLO AGAIN👋🏻</h1>
                        <p className="font-bold text-2xl">
                            Welcome to Indonesian Football e-League! Stay updated on the
                            latest news and updates on Virtual Football Indonesia. Start your
                            legendary journey with us.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(Welcome);
