import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../stores/store';

interface InputSuccessProps {
    onSuccessInput: boolean;
    onClose: () => void;
}

const InputSuccess: React.FC<InputSuccessProps> = ({ onSuccessInput, onClose }) => {

    const [active, setActive] = useState('');

    useEffect(() => {
        if (onSuccessInput) {
            setActive('active');
        }
    })

    return (
        <div>
            {onSuccessInput && (
                <div>
                    <div className="modal-overlay"></div>
                    <div className={`modal overflow-y-scroll w-full h-fit mt-[64px] xl:w-[438px] lg:m-auto lg:max-h-[90vh] ${active}`}>
                        <div className='modal-content p-4 lg:p-5'>
                            <button className="close" onClick={onClose}>
                                <img
                                    className="w-6 h-6"
                                    src="/assets/images/close.svg"
                                    alt="close"
                                ></img>
                            </button>
                            <div className='flex flex-col items-center justify-center'>
                                <h3 className="text-white flex flex-row mx-auto font-extrabold text-[32px] leading-normal text-center mb-[34px]">
                                    Data Anda telah tersimpan, menunggu verifikasi
                                </h3>
                                <img className='w-[100px] h-[100px] flex-shrink-0 mb-[34px]' src="/assets/images/check-blue.svg" alt="" />
                                <p className='text-sm text-white font-semibold mb-4'>History Match akan terupdate otomatis ke profile Anda ketika sudah di verifikasi oleh Admin. Silahkan menunggu beberapa saat.</p>
                                <a href='' className='text-secondary text-sm font-semibold leading-normal underline'>Kembali ke Tournament</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InputSuccess;
