import React, { useEffect, useState } from "react";
import { Tooltip, Typography } from "@material-tailwind/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import MediaQuery from 'react-responsive';
import { IEditUserProfile, IUserProfile } from "../../../app/models/profile";
import { IAccountOnboarding } from "../../../app/models/account";
import { useStore } from "../../../app/stores/store";
import SkeletonComponent from "../../../app/component/skeleton/SkeletonComponent";
import AmateurCard from "../AmateurCard";
import DummyPlayerCard from "../../../app/component/card/dummy/DummyPlayerCard";
import EmptyPlayerCard from "../../../app/component/card/empty/EmptyPlayerCard";
import EmptyAchievement from "../../../app/component/card/empty/EmptyAchievement";
import AchievementCard from "../../../app/component/card/AchievementCard";

const playerData = {
    level: "amateur"
};

interface playerProps {
    data: IUserProfile | null;
}

const initialNewValue: IEditUserProfile = {
    name: null,
    username: null,
    birth_date: null,
    birth_month: null,
    birth_year: null,
    domisili: null,
    game: null,
    platform: null,
    card: null,
    image: null,
    card_approved: false,
    address: null,
    postal_code: null,
    phone: null
}

const PlayerProfileSetting: React.FC<playerProps> = ({ data }) => {
    const { accountStore, PlayerStore } = useStore()
    const [isActive, setIsActive] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState(false)
    const [errorUsername, setErrorUsername] = useState('')
    const { slug } = useParams<{ slug: string }>();
    const [userData, setUserData] = useState(data);
    const skeletonData = [
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
        { count: 1, width: 'w-[86px]', height: 'h-6', marginBottom: 'mb-2', borderRadius: 'rounded' },
        { count: 1, width: 'w-full', height: 'h-[50px]', marginBottom: 'mb-3', borderRadius: 'rounded-md' },
    ];

    const [newValues, setNewValues] = useState<IEditUserProfile>(
        initialNewValue
    )

    useEffect(() => {
        if (data) {
            setNewValues((prevState) => ({ ...prevState, username: data.detail ? data.detail?.username : null }))
            setNewValues((prevState) => ({ ...prevState, name: data.name }))
            setNewValues((prevState) => ({ ...prevState, domisili: data.detail ? data.detail?.domisili : null }))
            setNewValues((prevState) => ({ ...prevState, game: data.detail ? data.detail?.game : null }))
            setNewValues((prevState) => ({ ...prevState, platform: data.detail ? data.detail?.platform : null }))
            setNewValues((prevState) => ({ ...prevState, address: data.address ? data.address?.address : null }))
            setNewValues((prevState) => ({ ...prevState, postal_code: data.address ? data.address?.postal_code : null }))
            setNewValues((prevState) => ({ ...prevState, phone: data.address ? data.address?.phone : null }))
            setUserData(data);
        }
    }, [data])

    useEffect(() => {
        PlayerStore.getAchievementList(String(slug))
    }, [PlayerStore])

    return (
        <div className='w-full h-full'>
            <React.Fragment>
                <div className='w-full h-full flex flex-col lg:flex-row lg:space-x-10 text-white'>
                    {/* Sidebar Left */}
                    <div className='w-full lg:w-[328px] flex flex-col mb-[14px]'>
                        {/* Standings */}
                        <div className='w-full flex flex-col'>
                            <div className="w-full bg-nav1 rounded-xl px-12 py-6 relative">
                                {!PlayerStore.profile?.detail
                                    ? <SkeletonComponent
                                        count={1}
                                        width='w-[230px]'
                                        height='h-[370px]'
                                        borderRadius='rounded-xl'
                                    /> :
                                    (!PlayerStore.profile?.detail.card
                                        ?
                                        <div>
                                            {PlayerStore.profile.detail.level === 'amateur' ?
                                                <AmateurCard
                                                    player={PlayerStore.profile.detail.level ? PlayerStore.profile.detail.level : 'amateur'}
                                                    data={PlayerStore.profile.detail}></AmateurCard>
                                                :
                                                (PlayerStore.profile.detail.card_approved ?
                                                    <DummyPlayerCard
                                                        player={PlayerStore.profile.detail.level ? PlayerStore.profile.detail.level : 'amateur'}
                                                        data={PlayerStore.profile.detail}
                                                    /> : <EmptyPlayerCard />
                                                )}
                                        </div>
                                        :
                                        <div>
                                            {PlayerStore.profile.detail.card_approved ?
                                                <DummyPlayerCard
                                                    player={PlayerStore.profile.detail.level ? PlayerStore.profile.detail.level : 'amateur'}
                                                    data={PlayerStore.profile.detail}
                                                /> : <EmptyPlayerCard />
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <MediaQuery minWidth={768}>
                                {!PlayerStore.achievement ?
                                    <EmptyAchievement />
                                    :
                                    <AchievementCard data={PlayerStore.achievement} />
                                }
                            </MediaQuery>
                        </div>
                    </div>
                    <div className='w-full lg:w-[70%] flex flex-col'>
                        <h1 className="text-white text-center font-extrabold text-xl lg:text-[32px] leading-normal mb-[14px]">Setting Profile</h1>
                        {data ? (
                            <div className="bg-nav1 rounded-xl lg:rounded-3xl block px-4 py-6">
                                <div className="flex flex-row items-center mb-2">
                                    <label htmlFor="fullname" className="text-white text-xs font-medium leading-6 mr-1">Nama Lengkap</label>
                                </div>
                                <input type="text"
                                    id="fullname"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 capitalize disabled cursor-not-allowed"
                                    placeholder="Full Name"
                                    value={newValues.name ? newValues.name : ''}
                                    disabled
                                />
                                <div className="flex flex-row items-center mb-2">
                                    <label htmlFor="username" className="text-white text-xs font-medium leading-6 mr-1">Username (Nama pada Kartu)</label>
                                </div>
                                <input type="text"
                                    id="username"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 disabled cursor-not-allowed"
                                    placeholder="Username"
                                    value={newValues.username ? newValues.username : ''}
                                    maxLength={16}
                                    disabled
                                />
                                <p className="text-red text-[8px] text-left font-semibold">{errorUsername}</p>

                                <div className="flex flex-row items-center mb-2">
                                    <label htmlFor="age" className="text-white text-xs font-medium leading-6 mr-1">Tanggal Lahir</label>
                                </div>
                                <div className="flex flex-row items-start mb-2 flex-wrap">
                                    <input type="text"
                                        className="rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 mr-3 w-1/3 lg:w-[110px] disabled cursor-not-allowed"
                                        value={userData?.detail?.birth_date}
                                        disabled
                                    ></input>
                                    <input type="text"
                                        className="rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 mr-3 w-1/3 lg:w-[110px] disabled cursor-not-allowed"
                                        value={userData?.detail?.birth_month}
                                        disabled
                                    ></input>
                                    <input type="text"
                                        className="rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 w-1/3 lg:w-[110px] disabled cursor-not-allowed"
                                        value={userData?.detail?.birth_year}
                                        disabled
                                    ></input>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                    <label htmlFor="domisili" className="text-white text-xs font-medium leading-6 mr-1">Domisili</label>
                                </div>
                                <input type="text"
                                    id="domisili"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 disabled cursor-not-allowed"
                                    placeholder="Domisili"
                                    value={newValues.domisili ? newValues.domisili : ''}
                                    disabled
                                />
                                <label htmlFor="game" className="text-white text-xs font-medium leading-6">Game</label>
                                <input type="text"
                                    id="game"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 disabled cursor-not-allowed"
                                    placeholder="Game"
                                    value={newValues.game ? newValues.game : ''}
                                    disabled
                                />
                                <label htmlFor="game" className="text-white text-xs font-medium leading-6">Platform</label>
                                <input type="text"
                                    id="platform"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 disabled cursor-not-allowed"
                                    placeholder="Platform"
                                    value={newValues.platform ? newValues.platform : ''}
                                    disabled
                                />
                                <label htmlFor="address" className="text-white text-xs font-medium leading-6">Alamat</label>
                                <input type="text"
                                    id="address"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 disabled cursor-not-allowed"
                                    placeholder="Address"
                                    value={newValues.address ? newValues.address : ''}
                                    disabled
                                />
                                <label htmlFor="postal_code" className="text-white text-xs font-medium leading-6">Kode Pos</label>
                                <input type="text"
                                    id="postal_code"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 disabled cursor-not-allowed"
                                    placeholder="Kode Pos"
                                    value={newValues.postal_code ? newValues.postal_code : ''}
                                    disabled
                                />
                                <label htmlFor="phone_number" className="text-white text-xs font-medium leading-6">Nomor Telepon</label>
                                <input type="text"
                                    id="phone_number"
                                    className="w-full rounded-md h-[50px] border border-solid border-darkgray-600 bg-transparent px-[19px] py-[13px] text-darkgray text-xs font-medium leading-6 mb-3 disabled cursor-not-allowed"
                                    placeholder="Nomor Telepon"
                                    value={newValues.phone ? newValues.phone : ''}
                                    disabled
                                />
                            </div>
                        ) : (
                            <div className="bg-nav1 rounded-3xl block px-4 py-6">
                                {skeletonData.map((data, index) => (
                                    <SkeletonComponent
                                        key={index}
                                        count={data.count}
                                        width={data.width}
                                        height={data.height}
                                        marginBottom={data.marginBottom}
                                        borderRadius={data.borderRadius}
                                    />
                                ))}
                            </div>
                        )}
                        <MediaQuery maxWidth={767}>
                            {!PlayerStore.achievement ?
                                <EmptyAchievement />
                                : <AchievementCard data={PlayerStore.achievement} />
                            }
                        </MediaQuery>
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
}

export default observer(PlayerProfileSetting);
