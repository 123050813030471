import React, { useEffect, useState } from 'react'
import { useStore } from '../../stores/store';
import { useLocation } from 'react-router-dom';
import { profileMenu } from '../../config/profileMenu';

function ProfileMenu() {

    const { accountStore } = useStore();

    let location = useLocation();
  
    const pathnames = location.pathname.split("/").filter(x => x);

    const [menu , setMenu] = useState(profileMenu);

    useEffect(() => {
        const updatedMenu = menu.map((item) => {
            if(pathnames[0] === item.link) {
                return { ...item, active: true };
            } else {
                return { ...item, active: false };
            }
        });

        setMenu(updatedMenu)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <div className='text-gray-500 font-semibold font-sans'>
        <ul className="bg-nav2 w-full rounded-md">
            {menu.map((item, idx) => (
                <li 
                    key={idx} 
                    className={`flex flex-row justify-start items-center p-4 space-x-4 text-gray-500 hover:text-secondary ${item.active ? 'border-secondary border-l-8 text-secondary' : ''} ${idx === 0 ? 'rounded-t-md ' : ''} ${idx === 3 ? 'rounded-b-md ' : ''}`}
                >
                    {item.icon}
                    <a href={item.link}>{item.name}</a>
                </li>
            ))}
        </ul>
        <li className='bg-nav2 mt-6 flex flex-row justify-start items-center p-4 space-x-4 text-gray-500 hover:text-secondary' onClick={() => accountStore.logout() }>
            <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" stroke='none' className='w-5 h-5'>
            <path d="M8 11L8 13L17 13L17 16L22 12L17 8L17 11L8 11Z"/>
            <path d="M4 21L13 21C14.103 21 15 20.103 15 19L15 15L13 15L13 19L4 19L4 5L13 5L13 9L15 9L15 5C15 3.897 14.103 3 13 3L4 3C2.897 3 2 3.897 2 5L2 19C2 20.103 2.897 21 4 21Z"/>
            </svg>
            <a href='/'>Logout</a>
        </li>
    </div>
  )
}

export default ProfileMenu