import { observer } from 'mobx-react-lite'
import React from 'react'
import { IPostV2 } from '../../models/post'

interface PopularPostProps {
  post : IPostV2
}

function PopularPost(props: PopularPostProps) {

  const { post } = props;

  return (
    <div className='w-full flex flex-row items-start space-x-2 font-sans mt-4'>
        <div className='w-28 h-16 overflow-hidden'>
            <img src={post.featured_img} alt="popular-post" className='object-cover w-full h-full rounded-md transform hover:scale-125 transition duration-500 ease-in-out' />
        </div>
        <div className='w-full h-full flex flex-col'>
            <a className='text-white font-bold text-xs hover:text-secondary' href='/'>{post.title.rendered}</a>
            <span className='text-gray-500 text-[10px] pt-2 font-medium'>{new Date(post.date_gmt).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</span>
        </div>
    </div>
  )
}

export default observer(PopularPost)