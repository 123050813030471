import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    IAccountAddress,
    IAccountAddressPayload,
    IAccountCardPayload,
    IAccountChangePassword,
    IAccountChangeProfilePicture,
    IAccountDeleteProfile,
    IAccountForgetPassword,
    IAccountGeneralPayload,
    IAccountInfoPayload,
    IAccountLoginValues,
    IAccountOnboarding,
    IAccountRegisterRequest,
    IAccountSosialLoginValues,
    IAccountSubmitOnboarding,
    IAccountUpdateAvatar,
    IAccountUploadAvatar,
    IAchievementPayload,
    IActiveAccountPayload,
    ILoginInfo,
    ISponsorPayload,
} from '../models/account';
import { IPengeluaran, IPengeluaranByDate } from '../models/pengeluaran';
import { store } from '../stores/store';
import {
    IAvailableDatePayload,
    IMatchesPayload,
    IMatchStatsPayload,
} from '../models/matches';
import {
    ILeagueByCategoryPayload,
    ILeagueCategoryPayload,
    ILeagueFixturePayload,
    ILeaguePayload,
    IStandingsPayload,
} from '../models/league';
import {
    IProductByIdPayload,
    IProductCategoryPayload,
    IProductsPayload,
} from '../models/store';
import {
    IPayment,
    IPaymentSubscription,
    ISnapTokenMidtrans,
    ITransaction,
    ITransactionByIdPayload,
    ITransactionCreatePayload,
    ITransactionListPayload,
} from '../models/transactions';
import { IClubByIdPayload, IPlayerStatsPayload } from '../models/club';
import { INotification } from '../models/notifications';
import {
    IRulesByIdPayload,
    ITOurnamentRewardPayload,
    ITournamentDetailPayload,
    ITournamentGamePayload,
    ITournamentGeneralPayload,
    ITournamentLeaderBoard,
    ITournamentLeaderboardPayload,
    ITournamentParticipant,
    ITournamentParticipantPayload,
    ITournamentPayload,
    ITournamentPlayerStatPayload,
    ITournamentResultInput,
    ITournamentPlayerRankPayload,
} from '../models/tournament';
import { IHistoryDelete, IHistoryGeneralPayload, IHistoryInput, IHistoryPayload, ITeamPayload } from '../models/team';
import { IGeneralCardPayload, IGeneralProvincePayload, ISearchRequest } from '../models/general';
import { IEditUserProfile, IUserProfilePayload, IuserDetailPayload } from '../models/profile';
import { IOrder, IOrderPayload, IOrderPayment, IOrderPaymentPayload } from '../models/order';
import { IBennefitsPayload, ISubscriptionPayload } from '../models/subcription';

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(
    async (response) => {
        if (process.env.NODE_ENV === 'development') await sleep(1500);
        response = readResponseHeader(response);
        return response;
    },
    (error: AxiosError<any>) => {
        const { data, status, config } = error.response!;
        console.log(error.response!);

        switch (status) {
            case 400:
                if (typeof data === 'string') {
                    alert('error, ' + data);
                }
                if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                }
                if (data.errors) {
                    const modalStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(' ' + data.errors[key]);
                        }
                    }
                    alert(modalStateErrors.join());
                }
                break;
            case 401:
                if (data.error) {
                    console.log(data.error);
                }
                break;
            case 403:
                console.log('Error 403 (Forbidden)');
                break;
            case 404:
                console.log('not-found');
                break;
            case 500:
                console.log(error.response?.data.message);
                break;
            case 422:
                // console.log(error.response?.data.message);
                break;
        }
        return Promise.reject(error);
    }
);

const readResponseHeader = (response: AxiosResponse): AxiosResponse => {
    return response;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const handleBlobResponse = (response: AxiosResponse<Blob>) => {
    console.log(response);
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    const filename = response.headers['content-disposition']!.split('"')[1];
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};

const requests = {
    get: <T>(url: string, body?: {}) => axios.get<T>(url, body).then(responseBody),
    getFile: (url: string, body?: AxiosRequestConfig<any>) => axios.get<Blob>(url, { ...body, responseType: 'blob' }).then((response) => handleBlobResponse(response)),
    post: <T>(url: string, body?: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body?: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string, body?: {}) => axios.delete<T>(url, { data: body }).then(responseBody),
    postFile: <T>(url: string, body?: {}) => axios.post<T>(url, body, { headers: { 'Content-Type': 'multipart/form-data' }, }).then(responseBody),
    putFile: <T>(url: string, body?: {}) => axios.put<T>(url, body, { headers: { 'Content-Type': 'multipart/form-data' } }).then(responseBody),
};

const Pengeluaran = {
    list: () => requests.get<IPengeluaran[]>('/pengeluaran'),
    create: (data: IPengeluaran) => requests.post<IPengeluaran>('/pengeluaran', data),
    listByDate: (date: string) => requests.get<IPengeluaranByDate>(`/pengeluaran/tanggal/${date}`),
};

const Account = {
    me: () => requests.get<IAccountInfoPayload>('/me'),
    refresh: () => requests.get<ILoginInfo>('/token/refresh'),
    login: (user: IAccountLoginValues) => requests.post<ILoginInfo>('/auth', user),
    loginSocial: (user: IAccountSosialLoginValues) => requests.post<ILoginInfo>('/auth/social', user),
    register: (user: IAccountRegisterRequest) => requests.post<ILoginInfo>('/register', user),
    sendValidateEmail: () => requests.post<ILoginInfo>('/send/validate/email'),
    validateEmail: (key: string) => requests.post<IAccountGeneralPayload>('/validate/email', { key }),
    address: () => requests.get<IAccountAddressPayload>('/address'),
    createAddress: (user: IAccountAddress) => requests.post<IAccountAddress>('/address', user),
    forgotPassword: (data: IAccountForgetPassword) => requests.post<IAccountForgetPassword>('/forgot-password', data),
    validateForgotPassword: (data: IAccountForgetPassword) => requests.post<IAccountGeneralPayload>('/validate/reset-password', data),
    resetPassword: (password: IAccountForgetPassword) => requests.post<ILoginInfo>('/reset-password', password),
    changePassword: (password: IAccountChangePassword) => requests.post<ILoginInfo>('/chage-password', password),
    onBoarding: (details: IAccountOnboarding) => requests.post<IAccountSubmitOnboarding>('/onboarding', details),
    avatar: (data: IAccountUploadAvatar) => requests.postFile<IAccountUploadAvatar>('/avatar', data),
    userProfile: (slug: string) => requests.get<IUserProfilePayload>(`/user/detail/${slug}`),
    editProfilePicture: (data: IAccountChangeProfilePicture) => requests.post<IAccountGeneralPayload>(`/profile/update/card`, data),
    deleteProfile: (data: IAccountDeleteProfile) => requests.del<IAccountGeneralPayload>(`/delete/card`, data),
    editUserProfile: (data: IEditUserProfile, slug: string) => requests.post<IAccountGeneralPayload>(`/onboarding/update/${slug}`, data),
    closeOnBoarding: () => requests.post<IAccountGeneralPayload>('/onboarding/close'),
    updateAvatar: (data: IAccountUploadAvatar) => requests.post<IAccountUpdateAvatar>('/profile/update/card', data),
    mainCard: () => requests.get<IuserDetailPayload>(`/main/card`),
    cardList: (slug: string) => requests.get<IAccountCardPayload>(`/profile/lists/${slug}`),
    achievementList: (slug: string) => requests.get<IAchievementPayload>(`/achievement/${slug}`),
    activeAccount : () => requests.get<IActiveAccountPayload>('/account/active/card'),
    sponsor: ()=> requests.get<ISponsorPayload>('/sponsors'),
}

const Matches = {
    listMatch: (competition_id: number) =>
        requests.get<IMatchesPayload>(`/matches?competition=${competition_id}`),
    MatchStatistics: (id: number) =>
        requests.get<IMatchStatsPayload>(`/matches/statistics/${id}`),
    listSchedule: (competition: number, match_type: string) =>
        requests.get<IMatchesPayload>(
            `/matches?competition=${competition}&match_type=${match_type}`
        ),
    availableDate: (competition: number, match_type: string) =>
        requests.get<IAvailableDatePayload>(
            `/matches/available-date?competition=${competition}&match_type=${match_type}`
        ),
};

const League = {
    listLeague: () => requests.get<ILeagueFixturePayload>('/competitions'),
    leagueById: (id: number) =>
        requests.get<ILeaguePayload>(`/competitions/${id}`),
    listLeagueByCategory: (id: number) =>
        requests.get<ILeagueByCategoryPayload>(`/competitions/category/${id}`),
    listCategory: () =>
        requests.get<ILeagueCategoryPayload>('/competitions/category'),
    listStandings: (id: number) =>
        requests.get<IStandingsPayload>(`/competitions/${id}/classement`),
    latestLeagueStandings: () =>
        requests.get<IStandingsPayload>(`/competitions/latest-standings`),
};

const Store = {
    listProducts: (category: string, sort: string) =>
        requests.get<IProductsPayload>(
            `/products?category=${category}&sort=${sort}`
        ),
    product: (id: number) => requests.get<IProductByIdPayload>(`/product/${id}`),
    listCategory: () =>
        requests.get<IProductCategoryPayload>('/category-products'),
};

const Transaction = {
    addTransaction: (data: ITransaction) =>
        requests.post<ITransactionCreatePayload>('/transaction', data),
    listTransactionsByUser: (page: number) =>
        requests.get<ITransactionListPayload>(`/transaction-list?page=${page}`),
    transactionById: (id: string | undefined) =>
        requests.get<ITransactionByIdPayload>(`/transaction/${id}`),
    payment: (data: IPayment) =>
        requests.post<ISnapTokenMidtrans>('/purchase', data),
};

const Order = {
    order: (data: IOrder) => requests.post<IOrderPayload>('/order', data),
    paymentById: (transaction_number: string) => requests.get<IOrderPaymentPayload>(`/order/${transaction_number}`),
    payment: (data: IPayment) =>
        requests.post<ISnapTokenMidtrans>('/purchase', data),
    paymentSubscription: (data: IPaymentSubscription) =>
        requests.post<ISnapTokenMidtrans>('/purchase/subscription', data)
}

const Notification = {
    listNotification: () => requests.get<INotification[]>('/notifications'),
    readNotification: () => requests.post<void>('/notifications/read'),
};

const Club = {
    clubById: (idClub: number, idCompetition: number) =>
        requests.get<IClubByIdPayload>(
            `/competitions/${idCompetition}/club/${idClub}`
        ),
    playerStats: (idClub: number, idCompetition: number, idPlayer: number) =>
        requests.get<IPlayerStatsPayload>(
            `/competitions/${idCompetition}/club/${idClub}/player/${idPlayer}`
        ),
};

const Tournament = {
    listGames: () => requests.get<ITournamentGamePayload>('/tournament/games'),
    listTurnament: () => requests.get<ITournamentPayload>('/tournaments'),
    tournamentLeaderboard : (platform : string , limit : number , filters : any) => requests.post<ITournamentLeaderboardPayload>('/leaderboard' , {platform : platform , limit : limit , filters : filters}),
    getLeaderboardLastUpdated : () => requests.get<void>(`leaderboard/lastupdate`),
    tournamentplayerRank : (slug : string) => requests.post<ITournamentPlayerRankPayload>('/player/rank' , {slug : slug}),
    tournamentByGame: (id: number, status: string | null, level: string | null, type: string | null) => requests.get<ITournamentPayload>(`/tournament/list/${id}?status=${status}&level=${level}&type=${type}`),
    rulesById: (rulesId: number) =>
        requests.get<IRulesByIdPayload>(`/tournaments/${rulesId}/rules`),
    detailTurnament: (tournamentSlug: string) =>
        requests.get<ITournamentDetailPayload>(
            `/tournaments/${tournamentSlug}/detail`
        ),
    playerStat: (slug: string) => requests.get<ITournamentPlayerStatPayload>(`tournament/stat/${slug}`),
    listParticipants: (tournamentId: number) => requests.get<ITournamentParticipantPayload>(`/tournaments/${tournamentId}/participants`),
    listRewards: (tournamentId: number) => requests.get<ITOurnamentRewardPayload>(`/tournaments/${tournamentId}/reward`),
    // playerStats: (idClub: number, idCompetition: number, idPlayer: number) => requests.get<IPlayerStatsPayload>(`/competitions/${idCompetition}/club/${idClub}/player/${idPlayer}`),
    myOngoingMatch: (slug: string) => requests.get<ITournamentGeneralPayload>(`/my/ongoing/match/${slug}`),
    submitMatchResult: (data: ITournamentResultInput) => requests.post<ITournamentGeneralPayload>(`/my/ongoing/match/upload/result`, data),
    myTournament: (slug: string) => requests.get<ITournamentGeneralPayload>(`/my/ongoing/tournament/${slug}`),
    myOrder: (slug: string) => requests.get<ITournamentGeneralPayload>(`/my/tournament/purchase/${slug}`),
    checkPay: (slug: string) => requests.get<ITournamentGeneralPayload>(`/check/pay/tournament/${slug}`),
    checkOngoingTournament: (slug: string) => requests.get<ITournamentGeneralPayload>(`/check/ongoing/tournament/${slug}`)
};

const Subscription = {
    listSubcriptions : () => requests.get<ISubscriptionPayload>(`/subscription`),
    subscriptinBySlug : (slug : string) => requests.get<ISubscriptionPayload>(`/subscription/payment/${slug}`),
    bennefits : () => requests.get<IBennefitsPayload>(`/bennefits`),
    faq : () => requests.get<IBennefitsPayload>(`/faq`),
}

const Team = {
    list: () => requests.get<ITeamPayload>('/teams'),
    search: (data: ISearchRequest) => requests.post<ITeamPayload>('/team/search', data),
    listHistory: (slug: string) => requests.get<IHistoryPayload>(`history/team/${slug}`),
    history: (data: IHistoryInput) => requests.post<IHistoryPayload>('/history/team/submit', data),
    historyUpdate: (data: IHistoryInput) => requests.post<IHistoryPayload>('/history/team/update', data),
    historyDelete: (data: IHistoryDelete) => requests.del<IHistoryGeneralPayload>('/history/team/delete', data)
}

const General = {
    province: () => requests.get<IGeneralProvincePayload>('/province'),
    backgroundCard: (level: string) => requests.get<IGeneralCardPayload>(`/master/card/${level}`),
}


const agent = {
    Pengeluaran,
    Account,
    Matches,
    League,
    Store,
    Transaction,
    Notification,
    Club,
    Tournament,
    Team,
    General,
    Order,
    Subscription,
}

export default agent;
