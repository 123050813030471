import React, { useEffect, useState } from 'react'
import 'aos/dist/aos.css';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../app/stores/store';
import PlayerTournament from './PlayerTournament';
import PlayerProfileSetting from './PlayerProfileSetting';

const ViewPlayer = () => {
    const [activeTab, setActiveTab] = useState(2);
    const { PlayerStore } = useStore();
    const { slug } = useParams<{ slug: string }>();

    useEffect(() => {
        PlayerStore.getProfileUser(String(slug))
    }, [PlayerStore])

    const [menu, setMenu] = useState([
        // {
        //     id: 1,
        //     name: "Profile",
        //     active: false
        // },
        {
            id: 2,
            name: "My Tournament",
            active: true
        },
    ]);

    const handleMenu = (buttonId: number) => {
        const updatedButtons = menu.map(button => {
            if (button.id === buttonId) {
                return { ...button, active: true };
            } else {
                return { ...button, active: false };
            }
        });
        setMenu(updatedButtons);
        setActiveTab(buttonId);
    }

    return (
        <div className='w-full h-full font-sans bg-primary lg:bg-bg-home bg-cover bg-no-repeat py-10 px-4 lg:py-[50px] lg:px-40'>
            <div id='tab' className='w-full flex flex-row items-center overflow-x-auto scrollbar-hidden mb-6 mt-10 space-x-1'>
                {menu.map((item, idx) => (
                    <button key={idx}
                        className={`capitalize p-[10px] text-xs rounded font-bold leading-normal whitespace-nowrap lg:overflow-hidden lg:ml-0 lg:mr-1
                        ${item.active ? ' bg-secondary text-white' : ' bg-transparent text-darkgray'} `}
                        // ${idx === 0 ? 'ml-4' : idx === menu.length - 1 ? 'mr-4' : ''}`}
                        onClick={() => handleMenu(item.id)}> {item.name} </button>
                ))}
            </div>
            {
                {
                    1: <PlayerProfileSetting data={PlayerStore.profile} />,
                    2: <PlayerTournament data={PlayerStore.profile} />,
                }[activeTab]
            }
        </div>
    )
}

export default observer(ViewPlayer)