import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import LeaderBoardSidebar from "../sidebar/LeaderBoardSidebar";
import DetailReward from "./DetailReward";
import "./detail.css"
import AccordionTab from "../../../app/component/accordion/AccordionTab";
import { useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { IRules, ITournament, ITournamentReward } from "../../../app/models/tournament";
import TournamentStore from "../../../app/stores/TournamentStore";
import ButtonFixed from "../../../app/component/fixed/ButtonFixed";
import SkeletonComponent from "../../../app/component/skeleton/SkeletonComponent";

interface detailProps {
    data: IRules[] | null;
    tournament: ITournament | null;
}

const DetailRules: React.FC<detailProps> = ({ data, tournament }) => {
    const { TournamentStore } = useStore()
    const toWhatsapp = () => {
        window.location.href = `https://wa.me/${tournament?.whatsapp}`
    }
    return (
        <React.Fragment>
            {data ?
                <div>
                    {tournament &&
                        <ButtonFixed total_payment={tournament?.price} prizepool={tournament?.grand_prize} match_date={tournament?.range_date} close_date={new Date(tournament?.close_date)} slot={`${tournament?.registered}/${tournament?.open_slot}`} registration_type={tournament?.registration_type} btn_status={tournament?.registered === tournament?.open_slot ? true : false} ></ButtonFixed>
                    }
                    <div className='w-full h-full flex flex-col px-4 lg:px-0 lg:flex-row lg:space-x-10 lg:mb-12'>
                        {/* Latest News */}
                        <div className='w-full lg:w-[70%] flex flex-col'>
                            <h3 className='text-white font-bold text-[22px] leading-normal'>Rules</h3>
                            <div className="w-full h-auto flex flex-col pt-2 pb-[14px] lg:flex-row lg:flex-wrap">
                                {(data.length < 0) ? (
                                    <div className="text-white text-xs font-medium leading-normal">Tidak ada Rules untuk Turnamen ini</div>
                                )
                                    : (data.map((rule, idx) => (
                                        <AccordionTab key={idx} rule={rule}></AccordionTab>
                                    )))
                                }
                            </div>
                            {tournament?.whatsapp && (
                                <div>
                                    <h4 className='text-white font-bold text-lg py-3'>Punya pertanyaan? Hubungi kami:</h4>
                                    <div onClick={toWhatsapp} className="w-full border-b-[.5px] border-[#383D42] pb-7">
                                        <img
                                            className="w-20"
                                            src="/assets/images/contact-us-whatsapp.png"
                                            alt="gambar"
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='hidden w-full lg:block'>
                                <DetailReward data={TournamentStore.rewardList} />
                            </div>
                        </div>

                        {/* Sidebar Left */}
                        <div className='hidden w-full lg:w-[30%] lg:flex flex-col mb-12 lg:mb-0'>
                            <div className='w-full flex flex-col'>
                                <h3 className='text-white font-bold text-lg pb-2'>Leaderboard</h3>
                                <div className='w-full'>
                                    <LeaderBoardSidebar />
                                </div>
                            </div>
                            <div className="text-darkgray text-xs font-medium leading-normal">Data Leaderboard belum ditemukan</div>
                        </div>
                    </div>
                </div>
                :
                <p>Loading Rules</p>
            }
        </React.Fragment>
    );
}

export default observer(DetailRules);
