import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../stores/store';
import { IAccountDeleteProfile } from '../../models/account';
import { IHistoryDelete } from '../../models/team';
import { toast } from 'react-toastify';
import AlertTopBlue from '../alerts/AlertTopBlue';

interface ModalProps {
    onOpenModalLeaveTeam: boolean;
    onClose: () => void;
    team?: string;
    history_id?: number | null;
    title: string;
    description: string;
}

const LeaveTeam: React.FC<ModalProps> = ({ onOpenModalLeaveTeam, onClose, team, title, description, history_id }) => {

    const { accountStore, clubStore, notificationStore } = useStore();
    const navigate = useNavigate()
    const { slug } = useParams<{ slug: string }>();
    const [active, setActive] = useState('')
    const [load, setLoad] = useState(false)

    useEffect(() => {
        if (onOpenModalLeaveTeam) {
            setActive('active');
        }
    })

    const modalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (onOpenModalLeaveTeam) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onOpenModalLeaveTeam]);

    const handleDelete = () => {
        setLoad(true)
        if (team) {
            deleteTeam()
        } else {
            deleteProfile()
        }
    }

    const deleteTeam = async () => {
        const deleteHistoryValue: IHistoryDelete = {
            history_id: history_id
        }

        clubStore.deleteHistory(deleteHistoryValue)
        notificationStore.setNotification('Data history Team berhasil dihapus')
        await clubStore.listHistory(String(slug)).then(() => {
            setLoad(false)
            showToast()
            onClose()
        })
    }

    const showToast = () => {
        toast(<AlertTopBlue onOpen={true} />, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    const deleteProfile = async () => {
        const deleteValue: IAccountDeleteProfile = {
            slug: String(slug)
        }

        accountStore.deleteProfile(deleteValue)
        await accountStore.getMainCard().then((res) => {
            if (!accountStore.main_card?.slug) {
                onClose()
                notificationStore.setNotification('Profile berhasil dihapus')
                showToast()
                return navigate('/player')
            } else {
                notificationStore.setNotification('Profile berhasil dihapus')
                window.location.href = (`/profile/${accountStore.main_card?.slug}`)
            }
            setLoad(false)
        })
    }

    return (
        <div>
            {onOpenModalLeaveTeam && (
                <div ref={modalRef}>
                    <div className="modal-overlay" onClick={onClose}></div>
                    <div className={`modal overflow-y-scroll w-full h-fit mt-[64px] xl:w-[438px] lg:m-auto lg:max-h-[90vh] ${'active'}`}>
                        <div className="modal-content p-4 lg:p-5">
                            <button className="close" onClick={onClose}>
                                <img
                                    className="w-6 h-6"
                                    src="/assets/images/close.svg"
                                    alt="close"
                                ></img>
                            </button>
                            <div className='flex flex-row items-start mb-[14px]'>
                                {team ? <h3 className="text-white text-center font-extrabold text-[32px] leading-normal">
                                    {title} <span className='text-secondary font-extrabold text-[32px] leading-normal'>{team}</span>?
                                </h3> : <h3 className="text-white text-center font-extrabold text-[32px] leading-normal">
                                    {title}
                                </h3>}

                            </div>
                            <figure>
                                <img
                                    src="/assets/images/comment-alert.svg"
                                    alt="club_img"
                                    className="w-[120px] mx-auto mb-6"
                                />
                            </figure>
                            <p className='text-white text-sm font-semibold leading-5 mb-6'>{description}</p>
                            <div className='flex flex-row items-center justify-between space-x-2'>
                                <button className='w-[183px] h-[50px] rounded-md text-darkgray font-bold leading-normal text-xs bg-transparent border border-darkgray' onClick={onClose}>No</button>
                                {!load
                                    ?
                                    <button className='w-[183px] h-[50px] rounded-md text-white font-bold leading-normal text-xs bg-[#F83B51]' onClick={handleDelete}>Yes</button>
                                    :
                                    <button className='w-[183px] h-[50px] rounded-md text-secondary font-bold leading-normal text-xs bg-navw'>Please Wait</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LeaveTeam;
