import AOS from 'aos';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import 'aos/dist/aos.css';

interface PopupProps {
    onClose: () => void
}

const AdsBannerBottom = (props: PopupProps) => {

    const { onClose } = props;

    useEffect(() => {
        AOS.init();
      }, []);

  return (
    <div className="sticky bottom-0 w-full h-full z-50 flex justify-center items-center">
      <div className="bg-gray-300 p-1 rounded-sm shadow-lg">
        <div className='w-full flex flex-row justify-end items-center space-x-1'>
            <p className='font-sans font-bold text-[10px]'>ADVERTISEMENT</p> 
            <p className='font-sans font-extrabold text-[8px] bg-gray-700 rounded-sm text-white px-1 cursor-pointer' onClick={onClose}>X</p>
        </div>
        <div className="text-lg mb-4 w-full h-20 lg:w-[662px] lg:h-20">
            <p>Ini adalah Pop-up Ads</p>
        </div>
      </div>
    </div>
  );
};

export default observer(AdsBannerBottom);