import { MenuOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import MenuNav from './MenuNav'
import TopNavbar from '../topNavbar/TopNavbar'
import NavDrawerMobile from './NavDrawerMobile'
import { useStore } from '../../stores/store'
import TopAuth from '../topNavbar/topAuth'
import Onboarding from '../modal/Onboarding'
import AlertTopBlue from '../alerts/AlertTopBlue'
import { showAlertNotif } from '../../config/utils'
import { ToastContainer } from 'react-toastify'

function Navbar() {

    const [open, setOpen] = useState<boolean>(false);
    const [isModalAuthOpen, setisModalAuthOpen] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [authType, setAuthType] = useState('login')
    const { accountStore, notificationStore } = useStore();

    const openModalLogin = () => {
        setisModalAuthOpen(true)
        setAuthType('login');
    };

    const openModalRegister = () => {
        setisModalAuthOpen(true)
        setAuthType('register');
    }

    const switchAuthType = () => {
        if (authType === 'login') {
            return openModalRegister()
        }
        return openModalLogin()
    }

    const closeModal = () => {
        setisModalAuthOpen(false)
        setAuthType('register');
    }

    const onBoardingModal = () => {
        setisModalAuthOpen(true)
        setAuthType('login');
    };

    // const openAlert = () => {
    //     showAlertNotif(setShowAlert)
    // }

    // const closeAlert = () => {
    //     notificationStore.removeNotification()
    //     setShowAlert(false)
    // }

    useEffect(() => {
        notificationStore.getNotification()
    }, [notificationStore])

    return (
        <>
            <div className='hidden lg:block'>
                <TopNavbar />
                <NavDrawerMobile open={open} setOpen={setOpen} />
            </div>
            {/* <div className='w-full h-11 bg-[#0E101C]'></div> */}
            <div className='w-full h-[87px] px-4 py-3 bg-nav2 flex flex-row justify-between items-center md:px-10 lg:px-[163px]'>
                <div className='w-1/2 lg:w-1/3 flex flex-row items-center justify-start'>
                    <div className="lg:hidden">
                        <MenuOutlined className='text-white' style={{ fontSize: 26 }} onClick={() => setOpen(true)} />
                    </div>
                    <a href="/" className='w-full h-full ml-3'>
                        <img src="/assets/images/ifel-logo-putih.png" alt="ifel-logo" className='w-[75px] h-8 lg:w-[25%] lg:h-[25%]' />
                    </a>
                </div>
                {accountStore.isLoggedIn ? (
                    <div className='lg:hidden'>
                        <TopNavbar />
                    </div>
                ) : (
                    <div className='lg:hidden w-1/2 justify-end items-stretch flex flex-row'>
                        {/* <a href='/login' className='text-white text-xs font-semibold border-2 border-white px-6 py-[8px] rounded-md'>Login</a>
                    <a href='/register' className='text-white text-xs bg-secondary font-semibold hover:bg-nav2 hover:text-secondary px-6 py-[10px] rounded-md'>Register</a>   */}
                        <div onClick={openModalLogin} className='text-white text-xs font-bold cursor-pointer border border-outline border-white rounded-md py-2 px-5'>Login</div>
                        <div onClick={openModalRegister} className='text-white text-xs font-bold cursor-pointer bg-secondary px-3.5 py-2 rounded-md hover:bg-nav2 hover:text-secondary ml-2'>Register</div>
                        <TopAuth onOpenModal={isModalAuthOpen} type={authType} onClose={closeModal} onSwitch={switchAuthType}></TopAuth>
                    </div>
                )}

                <div className='hidden lg:block w-2/3 text-white lg:text-[0.8vw]'>
                    <MenuNav />
                </div>
            </div>
            <ToastContainer />
            <AlertTopBlue onOpen={true} />
        </>
    )
}

export default Navbar