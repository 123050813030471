interface BracketFrameProps {
  src: string;
  title: string;
}

const BracketFrame: React.FC<BracketFrameProps> = ({ src, title }) => {
  return <iframe src={src} title={title} width="100%" height="500"></iframe>;
};

export default BracketFrame;
