import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { IClubPlayers } from '../../app/models/club';
import { useStore } from '../../app/stores/store';

function ClubProfile() {

    const { clubStore, leagueStore } = useStore();
    const { categoryLeague, leagueId, clubId} = useParams();

    const navigate = useNavigate();

    const [player, setPlayer] = useState<IClubPlayers | null>();

    useEffect(() => {
        leagueStore.getStandings(Number(leagueId));
        clubStore.getClubDetail(Number(clubId), Number(leagueId)).then((data) => {
            setPlayer(data.team.players[0]);
            clubStore.getPlayerStats(Number(clubId), Number(leagueId), Number(data.team.players[0].id));
        });
    }, [clubId, leagueId, clubStore, leagueStore]);

    useEffect(() => {

        if(player){
            clubStore.getPlayerStats(Number(clubId), Number(leagueId), Number(player?.id));
        }
    }, [clubId, clubStore, leagueId, player])

  return (
    <React.Fragment>
        <div className='w-full h-full bg-bg-home bg-cover bg-no-repeat font-sans'>
            <div className='w-full lg:h-[25vw] px-6 py-6 lg:px-20 xl:px-64 flex flex-row justify-between items-center lg:space-x-16 bg-gradient-to-r from-nav2 to-transparent'>
                <img src={clubStore.club?.team.flag} alt='ifel' className='w-[18vw] lg:w-[13vw]'/>
                <div className='w-full h-full flex flex-col items-start justify-center text-white ml-4'>
                    <select className="lg:hidden select select-xs w-1/3 text-[1.5vw] lg:w-1/3 text-gray-500" onChange={(e) => navigate(`/league/${categoryLeague}/${leagueId}/club/${e.target.value}`)}>
                        {leagueStore.standings?.map((data, idx) => (
                            <option value={data.team.id} key={idx} selected={data.team.id === clubStore.club?.team.id}>{data.team.name}</option>
                        ))}
                    </select>
                    <select className="select hidden lg:block w-1/3 text-[0.9vw] lg:w-1/3 text-gray-500" onChange={(e) => navigate(`/league/${categoryLeague}/${leagueId}/club/${e.target.value}`)}>
                        {leagueStore.standings?.map((data, idx) => (
                            <option value={data.team.id} key={idx} selected={data.team.id === clubStore.club?.team.id}>{data.team.name}</option>
                        ))}
                    </select>
                    <h2 className='font-extrabold text-[6vw] lg:text-[4.5vw]'>{clubStore.club?.team.name}</h2>
                    <p className='text-[2vw] lg:text-[1.1vw]'>{clubStore.club?.team.description}</p>
                </div>
            </div>
            <div className='w-full h-full flex flex-col py-4 px-4 lg:px-20 xl:px-64 lg:py-12'>
                <div id='tab' className='w-full flex flex-row lg:space-x-2 xl:space-x-4'>
                    {clubStore.club?.team.players.map((item, idx) => (
                        <button className={`capitalize text-[3vw] lg:text-[0.9vw] px-5 py-2 rounded-md font-medium lg:px-3 lg:py-2 lg:rounded-md xl:rounded-lg ${player?.id === item.id ? 'bg-secondary text-white' : 'bg-none text-gray-500'}`} key={idx} onClick={() => {setPlayer(item); }}> {item.name} </button>
                    ))}
                </div>
                <div className='w-full h-full lg:h-[35vw] flex flex-col lg:flex-row justify-center items-center lg:space-x-4 mt-4'>
                        <div className='relative w-full lg:w-1/2 bg-bg-player bg-cover rounded-lg h-[80vw] lg:h-full'>
                            <img src={player?.avatar} alt='ifel' className='w-full h-full object-contain'/>
                            <div className='absolute left-0 bottom-5 w-full'>
                                <span className='bg-gradient-to-r from-secondary to-transparent text-white text-[9vw] lg:text-[3.5vw] font-extrabold pl-4'>{player?.name}</span>
                            </div>
                        </div>
                        <div className='w-full mt-4 lg:mt-0 lg:w-1/2 h-full flex flex-col justify-center items-center text-[2.5vw] lg:text-[0.8vw]'>
                            <div className='w-full h-full mb-4'>
                                <span className='text-white font-bold text-[4vw] lg:text-[1.2vw]'>Statistics</span>
                                <hr className='border-gray-700 mt-2'/>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-nav1 flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Games</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.match_played}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-transparent flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Wins</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.win}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-nav1 flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Goal Scorer</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.goal_for}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-transparent flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Draws</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.draw}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-nav1 flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Lose</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.lose}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-transparent flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Fouls</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.fouls}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-nav1 flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Passes success</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.pass_success}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-transparent flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Goal concended</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.goal_against}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-nav1 flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Saves</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.saves}</span>
                            </div>
                            <div className='w-full h-full p-4 rounded-md bg-transparent flex flex-row justify-between items-center'>
                                <span className='text-white font-semibold'>Clean sheets</span>
                                <span className='text-secondary font-semibold'>{clubStore.playerStats?.clean_sheets}</span>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(ClubProfile)