import { observer } from 'mobx-react-lite';
import React from 'react';

interface PopupProps {
    onClose: () => void
}

const AdsBannerInner = (props: PopupProps) => {

    const { onClose } = props;

  return (
    <div className="w-full h-full z-50 flex justify-center items-center">
      <div className="bg-gray-300 p-1 mx-2 rounded-sm shadow-lg">
        <div className='w-full flex flex-row justify-end items-center space-x-1'>
            <p className='font-sans font-bold text-[10px]'>ADVERTISEMENT</p> 
            <p className='font-sans font-extrabold text-[8px] bg-gray-700 rounded-sm text-white px-1 cursor-pointer' onClick={onClose}>X</p>
        </div>
        <div className="lg:mx-0 text-lg mb-4 w-[350px] h-20 lg:w-[920px] lg:h-56">
            <p>Ini adalah Ads</p>
        </div>
      </div>
    </div>
  );
};

export default observer(AdsBannerInner);