import React, { useEffect, useState } from 'react';
import CarouselHome from '../../app/component/carousel/CarouselHome';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import '../../index.css';
import TournamentTrendingCard from '../../app/component/card/TournamentTrendingCard';
import TournamentCard from '../../app/component/card/TournamentCard';
import RecentWinnerCard from '../../app/component/card/RecentWinnerCard';
import RecentMatchCard from '../../app/component/card/RecentMatchCard';
import LeaderboardListMain from '../../app/component/leaderboard/LeaderboardListMain';
import TournamentStore from '../../app/stores/TournamentStore';
import { items } from '../../app/config/menu';
import CustomSelect from '../../app/component/select/CustomSelect';
import '../../index.css'
import CarouselTournament from '../../app/component/carousel/CarouselTournament';
import SubscriptionModal from '../../app/component/modal/SubscriptionModal';
import SubcriptionStore from '../../app/stores/SubscriptionStore';

const playerData = {
  level: "amateur"
};

const Tournament = () => {
  const [activeTab, setActiveTab] = useState(1);

  const [asPlatform, setasPlatform] = useState('mobile');

  const filters : any = {};

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { SubscriptionStore } = useStore();
  const { TournamentStore } = useStore();

  const handleToggle = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const openModalSubs = () => {
    document.body.style.overflow = 'hidden';
    setIsOpenModal(true)

  }
  const closeModalSubs = () => {
    document.body.style.overflow = 'scroll';
    setIsOpenModal(false)
  }


  const [menu, setMenu] = useState([
    {
      id: 1,
      name: 'eFootball Mobile',
      active: true,
      platform : 'Mobile'
    },
    {
      id: 2,
      name: 'eFootball Console',
      active: false,
      platform : 'Console'
    },
  ]);

  const handleMenu = (buttonId: number) => {
    
    const updatedButtons = menu.map((button) => {
      if (button.id === buttonId) {
        setasPlatform(button.platform);
        return { ...button, active: true };
      } else {
        return { ...button, active: false };
      }
    });
    //console.log(asPlatform)
    setMenu(updatedButtons);
    setActiveTab(buttonId);
  };
  useEffect(() => {
    
    SubscriptionStore.getSubcriptionData()
    SubscriptionStore.getBennefitsData()
    TournamentStore.getLeaderboardLastUpdated();
    AOS.init();
  }, [asPlatform]);

  return (
    <div className="w-full h-full font-sans p-0 m-0 bg-nav1">
      <div className="w-full h-full bg-bg-home bg-cover bg-no-repeat">
        <CarouselTournament />
        <div className="lg:py-6 lg:px-[164px]">
          <div className='pt-6 px-4 w-full h-full relative lg:pt-0 lg:px-0'>
            <img className='w-full h-full object-cover overflow-hidden min-h-[177px] rounded-md' src="/assets/images/tournament-banner.png" alt="" />
            <div className='text-center rounded-md absolute bottom-0 left-0 min-h-[177px] w-full md:h-full md:top-[50px] lg:pt-14 lg:top-0 text-banner'>
              <h2 className='text-white font-black text-xl mb-1 pt-4'>Upgrade untuk mendapatkan berbagai Tournament gratis!</h2>
              <p className='text-white font-medium text-xs mb-1'>Gratis akses Tournament, Leaderboard Sistem dan Exclusive member bersama Pro Player lainnya!</p>
              <button className='flex flex-row justify-center bg-secondary text-white text-sm rounded-xl px-6 py-3 capitalize font-sans font-bold aos-init aos-animate mx-auto hover:bg-nav1' onClick={openModalSubs}>Subscribe</button>
            </div>
          </div>
          <TournamentCard></TournamentCard>
          <TournamentTrendingCard></TournamentTrendingCard>
          {/* <RecentWinnerCard player={playerData}></RecentWinnerCard>
          <RecentMatchCard></RecentMatchCard> */}
          <React.Fragment>
            <h3 className='text-white font-bold text-[22px] mb-2 flex flex-row items-center px-4 md:pl-0'>Leaderboard | eFootball Top 100 Ranking</h3>
            <p className='text-darkgray text-sm font-normal leading-normal mb-2 px-4 lg:px-0'>Latest Update: {TournamentStore.LeaderboardLastUpdated}</p>
            {/* <div className='flex flex-row items-stretch justify-between px-4 lg:px-0'>
              <div className='custom-search ml-[6px]'>
                <img className='search' src="/assets/images/search.svg" alt="" />
                <input type="search" placeholder='Cari Player' className='search' />
                <img className='remove' src="/assets/images/remove.svg" alt="" />
              </div>
            </div> */}
            <div className='mt-[14px] mx-4 lg:mx-0'>
              <div id='tab' className='w-full flex flex-row'>
                {menu.map((item, idx) => (
                  <button key={idx} className={`text-[10px] font-bold py-2.5 px-2 rounded ${item.active ? 'bg-secondary text-white' : 'bg-none text-darkgray'}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                ))}
              </div>
              {
                {
                  1: <LeaderboardListMain platform={asPlatform} limit={5} platformId={activeTab} isProfilePage={false} filters={filters}/>,
                  2: <LeaderboardListMain platform={asPlatform} limit={5} platformId={activeTab} isProfilePage={false} filters={filters}/>,
                }[activeTab]
              }
            </div>
          </React.Fragment>

            {/* <div className='mt-[14px] mx-4 lg:mx-0'>
              <div id='tab' className='w-full flex flex-row'>
                {menu.map((item, idx) => (
                  <button key={idx} className={`text-[10px] font-bold py-2.5 px-2 rounded ${item.active ? 'bg-secondary text-white' : 'bg-none text-darkgray'} ${idx === 0 ? 'pl-4' : idx === items.length - 1 ? 'pr-4' : ''}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                ))}
              </div>
              {
                {
                  1: <LeaderboardListMain platform={asPlatform} limit={5}/>,
                  2: <LeaderboardListMain platform={asPlatform}  limit={5}/>,
                }[activeTab]
              }
            </div> */}
        </div>
      </div>
      {isOpenModal && (
        <SubscriptionModal
          onOpenModalSubs={isOpenModal}
          onClose={closeModalSubs}
        />
      )
      }
    </div>
  );
};
export default observer(Tournament);
