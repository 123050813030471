import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import MatchCard from '../../app/component/card/MatchCard';
import { useStore } from '../../app/stores/store';

function LatestMatch() {

  const { leagueStore } = useStore();

  const [leagueId, setLeagueId] = useState(0);

  const [selectedCategory, setSelectedCategory] = useState(0);

  useEffect(() => {
    leagueStore.getLeague().then((data) => {
      setLeagueId(data?.id!);
      setSelectedCategory(data?.category.id!)
    });
  }, [leagueStore]);

  useEffect(() => {
    leagueStore.getSchedule(leagueId, "all");
  }, [leagueId, leagueStore]);

  return (
    <div id='Match-Fixture' className='px-6 py-4 lg:py-12 font lg:px-20 xl:px-40 bg-login'>
      <div className='block w-full h-full divide-y-2 divide-y-reverse divide-gray-700 md:hidden'>
        <h3 className='mb-2 text-lg font-bold text-white'>Latest Match</h3>
        <hr />
      </div>

      <div id='tab' className='flex flex-row w-full mt-2 lg:space-x-2 xlspace-x-4'>
        {leagueStore.listLeagueFixture.slice().sort((a, b) => b.id - a.id).map((item, idx) => {
          if(item.status === 1) {
            return <button key={idx} className={`capitalize text-[3vw] lg:text-[0.9vw] px-5 py-2 rounded-md font-medium lg:px-3 lg:py-2 lg:rounded-md xl:rounded-lg ${item.id === leagueId ? 'bg-secondary text-white' : 'bg-none text-gray-500'}`} onClick={() => { setLeagueId(item.id); setSelectedCategory(item.category.id) }}> {item.name} </button>

          } else {
            return <></>
          }
        })}
      </div>
      {leagueStore.match.length !== 0 ? (
        <div className='w-full h-full'>
          <div className='flex flex-col w-full h-full mt-5 space-y-4 lg:px-0 lg:space-y-0 lg:flex-row lg:space-x-2 xl:space-x-5'>
            {leagueStore.match.slice(0, 4).map((item, idx) => (
              <MatchCard data={item} key={idx} />
            ))}
          </div>
          <div className='flex flex-row items-center justify-end w-full mt-4 space-x-1'>
            <a href={`/league/${selectedCategory}/${leagueId}`} className='font-semibold text-[3.8vw] lg:text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
              <span>View all fixtures</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
                <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z" />
              </svg>
            </a>
          </div>
        </div>
      ) : (
        <div className='w-full h-full py-6'>
          <span>No Match Found</span>
          <div className='flex flex-row items-center justify-end w-full mt-4 space-x-1'>
            <a href={`/league/${selectedCategory}/${leagueId}`} className='font-semibold text-[3.8vw] lg:text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
              <span>View all fixtures</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
                <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z" />
              </svg>
            </a>
          </div>
        </div>
      )}

    </div>
  )
}

export default observer(LatestMatch)