import React, { useState } from 'react';
import '../topNavbar/topNavbar.css';
import { IAchievement } from '../../models/account';
import { useStore } from '../../stores/store';
import { calculateAge, formatDate, formatDateOnly } from '../../config/enum';

interface AchievementProps {
    data: IAchievement | undefined | null;
    onOpenAchievement: boolean;
    onClose: () => void;
}

const Achievement: React.FC<AchievementProps> = ({ data, onOpenAchievement, onClose }) => {

    const { accountStore } = useStore()

    return (
        <div>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal w-full h-fit mt-[64px] xl:w-[438px] lg:m-auto lg:max-h-[90vh]">
                <div className="modal-content overflow-y-scroll p-4 lg:p-5">
                    <button className="close" onClick={onClose}>
                        <img
                            className="w-6 h-6"
                            src="/assets/images/close.svg"
                            alt="close"
                        ></img>
                    </button>
                    <div className="flex flex-col tems-center">
                        <h2 className='text-[32px] text-white font-extrabold leading-normal text-center'>Achievement</h2>
                        {data?.background ?
                            <div className="relative w-[80%] mx-auto h-auto">
                                <div className="flex flex-col items-start absolute z-10 top-[40px] left-[25px]">
                                    <div className={`text-${!data.color_code ? 'white' : data.color_code} text-lg font-extrabold leading-[4px] whitespace-nowrap`}>eFOOTBALL</div>
                                    <div className={`uppercase text-${!data.color_code ? 'white' : data.color_code} text-lg font-extrabold whitespace-nowrap`}>{data.user.platform}</div>
                                </div>
                                <img width={45} className="absolute z-10 top-[30px] right-[27px]" src="/assets/images/efootball-logo.png" alt="" />
                                <figure className="w-full h-auto object-cover mx-auto my-[14px]">
                                    <img
                                        src={`${process.env.REACT_APP_STORAGE_URL}${data?.background}`}
                                        alt="logo"
                                        className="w-full h-auto object-cover"
                                    />
                                    <img
                                        src={!data.image ? `${process.env.REACT_APP_STORAGE_URL}${accountStore.profile?.detail?.card}` : `${process.env.REACT_APP_STORAGE_URL}${data.image}`}
                                        alt="achievement"
                                        className="absolute top-[38px] left-1/2 transform translate-x-[-50%] w-[80%]"
                                    />
                                </figure>
                                <h5 className={`absolute uppercase text-xl bottom-[65px] left-1/2 transform translate-x-[-50%] text-${!data.color_code ? 'white' : data.color_code} text-xl font-extrabold leading-normal text-center whitespace-nowrap z-10 bottom-[12px] left-1/2 transform translate-x-[-50%]`}>{accountStore.profile?.detail?.username}</h5>
                                <div className="flex flex-row items-center absolute z-10 bottom-[45px] left-[30px]">
                                    <h6 className={`uppercase text-${!data.color_code ? 'white' : data.color_code} text-base font-medium leading-normal whitespace-nowrap mr-2`}>Age</h6>
                                    <h6 className={`uppercase text-${!data.color_code ? 'white' : data.color_code} text-base font-extrabold leading-normal whitespace-nowrap`}>{calculateAge(data.user.birth_year)}</h6>
                                </div>
                                <h6 className={`absolute uppercase text-${!data.color_code ? 'white' : data.color_code} text-base font-extrabold leading-normal whitespace-nowrap z-10 bottom-[45px] right-[20px]`}>{data.user.domisili}</h6>
                            </div>
                            :
                            <></>
                        }
                        <h4 className="text-center text-[22px] font-bold leading-normal text-white">
                            {data?.title}
                        </h4>
                        <span className="text-center text-sm font-semibold leading-5 text-white">
                            {data?.description}
                        </span>
                        <span className="text-center text-sm font-semibold leading-5 text-darkgray">
                            {formatDateOnly(String(data?.date))}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default (Achievement);
