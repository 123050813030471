import React, { useEffect, useState } from 'react';
import Onboarding from './Onboarding';
import './modal.css'
import { useStore } from '../../stores/store';
import { useNavigate, useParams } from 'react-router-dom';
import ChangeCardPicture from './ChangeCardPicture';
import LeaveTeam from './LeaveTeam';
import ErrorModal from './ErrorModal';
import { Spin } from 'antd';


interface Player {
    level: string;
}

interface EditProfilePicModalProps {
    player: Player;
    onOpenToggle: boolean;
}

const EditPictureProfile: React.FC<EditProfilePicModalProps> = ({ player, onOpenToggle }) => {
    const { accountStore, TournamentStore } = useStore()
    const navigate = useNavigate()
    const [showModalOnboarding, setshowModalOnboarding] = useState(false)
    const [showModalChangeProfilePicture, setshowModalChangeProfilePicture] = useState(false)
    const [showModalDeleteProfile, setShowModalDeleteProfile] = useState(false);
    const [load, setLoad] = useState(false);
    const [isModalErrorOpen, setisModalErrorOpen] = useState(false)
    const [onOpenEditModal, setOnOpenEditModal] = useState(false)

    const { slug } = useParams<{ slug: string }>();

    const openModalOnBoarding = () => {
        setshowModalOnboarding(true)
    };

    const onOpenModalChangePicture = () => {
        setshowModalChangeProfilePicture(true)
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        document.body.style.overflow = 'visible';
        setshowModalOnboarding(false)
        setisModalErrorOpen(false)
    };

    const toProfile = (slug: string) => {
        window.location.href = (`/profile/${slug}`)
    }

    // useEffect(() => {
    //     TournamentStore.in_tournament_message;
    //   }, []);

    const handleDeleteProfile = async () => {
        setLoad(true)
        TournamentStore.checkOnoingTournament(String(slug)).then((res) => {
            if (!TournamentStore.in_tournament) {
                
                setShowModalDeleteProfile(true)
            } else {
                console.log(TournamentStore.in_tournament_message)
                setisModalErrorOpen(true)
            }
            setLoad(false)
        })
    }

    const handleCloseDeleteProfile = () => {
        setShowModalDeleteProfile(false)
    }

    if (load) {
        return <div className='popover-edit z-20'>
            <div className='text-white text-[12px] leading-normal font-bold'>
                <div className='w-full p-4'>
                    <Spin tip="Please wait" size="large"></Spin>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            {onOpenToggle &&
                <div className='popover-edit z-20'>
                    <div className='text-white text-[12px] leading-normal font-bold'>
                        <div className='w-full p-4'>
                            <div onClick={onOpenModalChangePicture} className='mb-[10px] cursor-pointer hover:text-secondary'>Ubah Foto</div>
                            <div onClick={openModalOnBoarding} className='mb-[10px] cursor-pointer hover:text-secondary'>Tambahkan Profile</div>
                            <div onClick={handleDeleteProfile} className='text-[#F83B51] cursor-pointer'>Hapus Profile </div>
                        </div>
                        {!accountStore.card ? <p>Wait</p> : accountStore.card.map((item, idx) => (
                            <div key={idx}>
                                <div className='w-full h-[1px] bg-[#565D6B]'></div>
                                <div className='flex flex-row items-center justify-between cursor-pointer p-4' onClick={() => toProfile(item.slug)}>
                                    <p className='mr-1 text-white'>{item.username}</p>
                                    <div>{renderLevelTag(item.level)}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
            {showModalOnboarding && (
                <Onboarding
                    onOpenOnboarding={showModalOnboarding}
                    onClose={closeModal}
                    step={2}
                ></Onboarding>
            )}
            {showModalChangeProfilePicture && (
                <ChangeCardPicture
                    onOpenModalChangePicture={showModalChangeProfilePicture}
                    onClose={closeModal}
                    slug={String(slug)}
                ></ChangeCardPicture>
            )}
            {showModalDeleteProfile && (
                <LeaveTeam
                    onOpenModalLeaveTeam={showModalDeleteProfile}
                    onClose={handleCloseDeleteProfile}
                    title="Apakah Anda yakin untuk menghapus Profile Anda?"
                    description="Profile Anda akan dihapus secara permanen. Data Anda akan terhapus secara permanen dan tidak bisa dikembalikan. Klik “Yes” untuk mengkonfirmasi dan menghapus profile Anda."
                ></LeaveTeam>
            )}
            <ErrorModal onOpenModalerrorModal={isModalErrorOpen} redirect={closeModal} onClose={closeModal} title="Gagal Hapus Profile" description={TournamentStore.in_tournament_message} />
        </div>
    )
};

const renderLevelTag = (level: string) => {
    if (level === 'amateur') {
        return <div className="badge badge-outline-amateur tag">Amateur</div>;
    } else if (level === 'semi') {
        return <div className="badge badge-outline-semi tag">Semi-Pro</div>;
    } else {
        return <div className="badge badge-outline-pro tag">Pro-Player</div>;
    }
};


export default EditPictureProfile;
