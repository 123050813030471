import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ModalProps {
    onOpen: () => void;
}

const EmptyCard: React.FC<ModalProps> = ({ onOpen }) => {

    const handleCreateCard = () => {
        onOpen();
    }

    return (
        <>
            <button
                className="w-full lg:w-1/2 flex flex-row items-center mx-auto justify-center bg-secondary text-white text-xs font-semibold leading-normal mb-[14px] rounded-md h-[32px] lg:h-[50px]"
                onClick={handleCreateCard}
            >
                Buat kartu sekarang!
            </button>
        </>
    )
}

export default EmptyCard