import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom';
import { formatRupiah, shortenText } from '../../config/enum';
import { IProducts } from '../../models/store'

interface productProps {
    data: IProducts
}

function ProductsCard(props: productProps) {

    const { data } = props;

    const navigate = useNavigate();

  return (
    <div className="card card-compact max-w-sm bg-nav2 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 font-sans cursor-pointer" onClick={() => navigate(`${data.id}`)}>
           <figure><img src={data.image} alt="Shoes" className='w-full lg:h-[15vw] object-cover rounded-t-lg transform hover:scale-125 transition duration-500 ease-in-out'/></figure>
        <div className="p-3">
                <h5 className="mb-2 text-xs lg:text-xl font-bold tracking-tight text-white dark:text-white">{shortenText(23, data.name)}</h5>
            <p className="font-bold lg:text-xl text-secondary dark:text-gray-400">{formatRupiah(data.price)}</p>
        </div>
    </div>
  )
}

export default observer(ProductsCard)