import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import LeaderboardList from "../../../app/component/leaderboard/LeaderBoardSide";


function LeaderBoardSidebar() {
  const [activeTab, setActiveTab] = useState(1);

  const [menu, setMenu] = useState([
    {
      id: 1,
      name: "eFootball Mobile",
      active: true
    },
    {
      id: 2,
      name: "eFootball Console",
      active: false
    },
  ]);

  const handleMenu = (buttonId: number) => {
    const updatedButtons = menu.map(button => {
      if (button.id === buttonId) {
        return { ...button, active: true };
      } else {
        return { ...button, active: false };
      }
    });
    setMenu(updatedButtons);
    setActiveTab(buttonId);
  }

  return (
    <React.Fragment>
      <div className="w-full h-full">
        <div className="block w-full h-full">
          <React.Fragment>
            <div className='w-full h-full'>
              <div id='tab' className='w-full flex flex-row lg:space-x-2 xlspace-x-4'>
                {menu.map((item, idx) => (
                  <button key={idx} className={`text-[10px] font-bold py-2.5 px-2 rounded ${item.active ? 'bg-secondary text-white' : 'bg-none text-darkgray'}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                ))}
              </div>
              {
                {
                  1: <LeaderboardList />,
                  2: <LeaderboardList />,
                }[activeTab]
              }
            </div>
          </React.Fragment>
        </div>
        {/* <hr className="h-0.5 bg-gray-700 border-0 dark:bg-gray-700" /> */}
      </div>
    </React.Fragment>
  );
}

export default observer(LeaderBoardSidebar);
