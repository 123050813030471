import { makeAutoObservable } from "mobx"
import { ILeague, ILeagueCategory, IStandings } from "../models/league";
import agent from "../api/agent";
import { IMatchCompetition, IMatchesInfo, IMatchSchedule, IMatchStatistics } from "../models/matches";
import { qualification_stage } from "../config/enum";
import { Menu } from "../config/menu";

export default class LeagueStore {

    title = "Dashboard";
    standings: IStandings[] | null = null;
    latestStandings: IStandings[]  = [];
    latestleague: IMatchCompetition | null = null;
    availableDate: IMatchSchedule[] = [];
    match: IMatchesInfo[] = [];
    statistics : IMatchStatistics | null = null;
    listCategory : ILeagueCategory[] = [];
    categoryMenu : Menu[] = [];
    latestCompetitionsByCategory : number = 0;
    selectedLeague : ILeague | null = null;
    listLeague : IMatchCompetition[] = [];
    listLeagueFixture : ILeague[] = [];

    listMatches : IMatchesInfo[] = [];

    anotherMatch: IMatchesInfo[] = [];

    latestLeagueId = 0;

    constructor() {
        makeAutoObservable(this);
    }

    getStandings = async (id: number) => {
        try {
            const standings = await agent.League.listStandings(id);
            this.setStandings(standings.data);
        } catch (error) {
            console.log(error);
        }
    }

    setStandings = (data: IStandings[]) => {
        this.standings = data;
    }

    getCategory = async () => {
        try {
            const category = await agent.League.listCategory();
            this.setCategory(category.data);
            category.data.map(data => {
                return this.categoryMenu.push({
                    label: data.name,
                    route: `/${data.id}`, 
                });
            })
        } catch (error) {
            console.log(error);
        }
    }

    getLeague = async () => {
        try {
            const league = await agent.League.listLeague();
            this.setLeague(league.data);
            if(league.data)
                return league.data[league.data.length - 1];
        } catch (error) {
            console.log(error);
        }
    }

    setLeague = (data: ILeague[]) => {
        this.listLeagueFixture = data;
        this.latestLeagueId = data[data.length -1].id;
    }

    setCategory = (data: ILeagueCategory[]) => {
        this.listCategory = data;
    }

    getLeagueByCategory = async (id: number) => {
        try {
            const league = await agent.League.listLeagueByCategory(id);
            this.setLeagueByCategory(league.data[0].id, league.data);
            return league.data[0].id;
        } catch (error) {
            console.log(error);
        }
    }

    setLeagueByCategory = (data: number, league: IMatchCompetition[]) => {
        this.latestCompetitionsByCategory = data;
        this.listLeague = league;
    }

    getAllMatches = async (id: number) => {
        try {
            const matches = await agent.Matches.listMatch(id);
            this.setAllMatches(matches.data);
        } catch (error) {
            console.log(error);
        }
    }

    setAllMatches = (data: IMatchesInfo[]) => {
        this.listMatches = data;
    }

    getLeagueById = async (id: number) => {
        try {
            const league = await agent.League.leagueById(id);
            this.setLeagueById(league.data);
            return league.data;
        } catch (error) {
            console.log(error);
        }
    }

    setLeagueById = (data: ILeague) => {
        this.selectedLeague = data;
    }

    getLatestStandings = async () => {
        try {
            const standings = await agent.League.latestLeagueStandings();
            this.setLatestStandings(standings.data, standings.competition);
            return standings.competition;
        } catch (error) {
            console.log(error);
        }
    }

    setLatestStandings = (data: IStandings[], competition: IMatchCompetition) => {
        this.latestStandings = data;
        this.latestleague = competition;
    }

    getSchedule = async (competition: number, match_type: string) => {
        try {
            const schedule = await agent.Matches.listSchedule(competition, match_type);
            const data = schedule.data;
            const listMatch: IMatchesInfo[] = [];
            data.map((item) => {

                item.qualification = qualification_stage(item.qualification_stage?.toString());

                if(item.match_day === "1") {
                    item.another_match = [];
                    data.map((itemX) => {
                        if((itemX.qualification === item.qualification && itemX.match_day !== "1") && ((itemX.home.id === item.home.id || itemX.home.id === item.away.id) && (itemX.away.id === item.away.id || itemX.away.id === item.home.id))) {
                            return item.another_match?.push(itemX);
                        } else {
                            return null;
                        }
                    });
                    item.another_match.sort((a, b) => Number(a.match_day) - Number(b.match_day));
                    return listMatch.push(item); 
                } else {
                    return null;
                }

            });

            return this.setMatch(listMatch);
        } catch (error) {
            console.log(error);
        }
        
    }

    setMatch = (data: IMatchesInfo[]) => {
        this.match = data;
    }

    getAvailableDate = async (competition: number, match_type: string ) => {
        try {
            const availableDate = await agent.Matches.availableDate(competition, match_type);
            const data = availableDate.data;
            this.setAvailableDate(data);
        } catch (error) {
            console.log(error);
        }
    }

    setAvailableDate = (data: IMatchSchedule[]) => {
        this.availableDate = data;
    }

    getMatchStats = async (id: number) => {
        try {
            const matchStats = await agent.Matches.MatchStatistics(id);

            let data = matchStats.data;

            data.qualification = qualification_stage(data.qualification_stage?.toString());

            this.setMatchStats(data);

            return data;

        } catch (error) {
            console.log(error);
        }
    }

    setMatchStats = (data: IMatchStatistics) => {
        this.statistics = data;
    }

}