
export interface Menu{
  label: string,
  route?: string,
  icon?: JSX.Element,
  children?: Array<Menu>
}

export const items: Menu[] = [
    {
        label: 'Home',
        route: '/',
    },
    {
        label: 'Competitions',
        children: []
    },
    {
        label: 'Tournament',
        route: '/tournament',
    },
    {
        label: 'Virtual Stadium',
        route: '/',
    },
    {
        label: 'News',
        route: '/news',
    },
    {
        label: 'Store',
        route: '/store',
    },
    {
        label: 'Contact Us',
        route: '/contacts',
    }
];