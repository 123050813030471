import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import LeaderboardListMain from "./LeaderboardListMain";
import EmptyLeaderboard from "../card/empty/EmptyLeaderboard";
interface Props {
    platform : string,
    isProfilePage : boolean
}

const LeaderBoard : React.FC<Props> = ({ platform , isProfilePage }) => {
    const [activeTab, setActiveTab] = useState(1);
    const filters: any = {}
    const [menu, setMenu] = useState([
        {
            id: 1,
            name: "eFootball Mobile",
            active: true
        },
        {
            id: 2,
            name: "eFootball Console",
            active: false
        },
    ]);

    const handleMenu = (buttonId: number) => {
        const updatedButtons = menu.map(button => {
            if (button.id === buttonId) {
                return { ...button, active: true };
            } else {
                return { ...button, active: false };
            }
        });
        setMenu(updatedButtons);
        setActiveTab(buttonId);
    }

    return (
        <React.Fragment>
            <div className="w-full h-full py-4">
                <div className="block w-full h-full divide-y-reverse divide-y-2 divide-gray-700">
                    <React.Fragment>
                        <LeaderboardListMain limit={100} platformId={1} platform={platform} isProfilePage={isProfilePage} filters={filters}></LeaderboardListMain>
                    </React.Fragment>
                </div>
            </div>
        </React.Fragment>
    );
}

export default observer(LeaderBoard);
