import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import LeaderboardListMain from "../../app/component/leaderboard/LeaderboardListMain";
import { useStore } from "../../app/stores/store";
import { useParams, useSearchParams } from "react-router-dom";
import CustomSelect from "../../app/component/select/CustomSelect";
function TournamentLeaderboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  let defaultActive = searchParams.get("leaderboard")
  const [activeTab, setActiveTab] = useState(defaultActive ? 2 : 1);
  const [asPlatform, setasPlatform] = useState(searchParams.get("leaderboard") ? 'console' : 'mobile');
  const { TournamentStore } = useStore();
  const [menu, setMenu] = useState([
      {
        id : 1,
        name: "eFootball Mobile",
        active: searchParams.get("leaderboard") ? false : true,
        platform : 'Mobile'
      },
      {
        id : 2,
        name: "eFootball Console",
        active: searchParams.get("leaderboard") ? true : false,
        platform : 'Console'
      },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [filters, setFilters] = useState({
    filterLevel: null as string | null,
    filterSearch: null as string | null,
  });

  

  const handleToggle = () => {
      setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const handleSearch = (event: any) => {
   
    if (event.key === 'Enter') {
        let text = event.target.value;
        setFilters((prevFilters) => ({
          ...prevFilters,
          filterSearch: text,
        }));
         //console.log(filters)
        // TournamentStore.getTournamentByGame(Number(id), filters);
    }
  }

  useEffect(() => {
    TournamentStore.getLeaderboardLastUpdated();
  }, [asPlatform , filters]);

  const handleFilter = (data: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      filterLevel: data,
    }));
    //console.log(filters)
  };

  const handleMenu = (buttonId: number) => {
    const updatedButtons = menu.map(button => {
        if (button.id === buttonId) {
            setasPlatform(button.platform);
            return { ...button, active: true };
        } else {
            return { ...button, active: false };
        }
        });
    setMenu(updatedButtons);
    setActiveTab(buttonId);
    }
    

  return (
    <React.Fragment>
      <h3 className="text-white font-bold text-lg mb-2 pt-6">Leaderboard | eFootball Top 100 Ranking</h3>
      <p className='text-darkgray text-[14px] font-normal mb-2'>Latest Update: {TournamentStore.LeaderboardLastUpdated}</p>
      <div className="flex flex-col item-start lg:flex-row lg:items-center justify-between">
        <div className="flex flex-row items-center space-x-2 lg:mb-0 mb-2">
        <CustomSelect
            color="darkgray"
            fontFamily="Inter"
            fontSize={12}
            fontWeight={500}
            lineHeight="normal"
            marginRight={4}
            marginBottom={12}
            type="level"
            count={10}
            isOpen={isOpen}
            toggleOptions={handleToggle}
            
            onValueChange={(data) => handleFilter(data)}
        />
        </div>
        <div className="relative h-[33px] w-full lg:w-[343px]">
          <svg xmlns="http://www.w3.org/2000/svg"  width="15" height="15" viewBox="0 0 15 15" fill="none" className="absolute left-4 top-1/2 transform -translate-y-1/2">
              <path d="M6.12586 12.1357C7.45619 12.1357 8.68295 11.7096 9.68667 10.9995L13.4626 14.7396C13.6378 14.9132 13.8688 15 14.1158 15C14.6336 15 15 14.6055 15 14.1005C15 13.8638 14.9203 13.6349 14.7451 13.4692L10.9931 9.74487C11.7817 8.7191 12.2517 7.44871 12.2517 6.06786C12.2517 2.73014 9.49549 0 6.12586 0C2.76421 0 0 2.72225 0 6.06786C0 9.40558 2.75624 12.1357 6.12586 12.1357ZM6.12586 10.8259C3.49708 10.8259 1.32236 8.67175 1.32236 6.06786C1.32236 3.46397 3.49708 1.30984 6.12586 1.30984C8.75465 1.30984 10.9294 3.46397 10.9294 6.06786C10.9294 8.67175 8.75465 10.8259 6.12586 10.8259Z" fill="#565D6B" />
          </svg>
          <input className="h-full w-full rounded bg-nav1 border-b-[.5px] border-[#475569] text-darkgray px-[35px] text-[14px] font-normal leading-normal" type="text" placeholder="Cari Player..." onKeyDown={handleSearch} />
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className="absolute right-4 top-1/2 transform -translate-y-1/2">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35794 11.6421 0 7.5 0C3.35786 0 0 3.35794 0 7.5C0 11.6421 3.35786 15 7.5 15ZM10.8227 4.17739C11.0983 4.45291 11.0983 4.8999 10.8227 5.17564L8.49827 7.5L10.8227 9.82436C11.0983 10.1001 11.0983 10.5471 10.8227 10.8226C10.547 11.0983 10.1001 11.0983 9.82439 10.8226L7.5 8.49825L5.17561 10.8226C4.89996 11.0983 4.45302 11.0983 4.17734 10.8226C3.90168 10.5471 3.90168 10.1001 4.17734 9.82436L6.50173 7.5L4.17734 5.17564C3.90168 4.8999 3.90168 4.45291 4.17734 4.17739C4.45299 3.90165 4.89993 3.90165 5.17561 4.17739L7.5 6.50175L9.82439 4.17739C10.1001 3.90165 10.547 3.90165 10.8227 4.17739Z" fill="#475569" />
          </svg> */}
        </div>
      </div>
      <div className="w-full h-full py-4">
        <div className="block w-full h-full divide-y-reverse divide-y-2 divide-gray-700">
          <React.Fragment>
              <div className='w-full h-full'>
                  <div id='tab' className='w-full flex flex-row divider-darkgray lg:space-x-2 xlspace-x-4'>
                      {menu.map((item, idx) => (
                      <button key={idx} className={`text-[10px] font-bold py-2.5 px-2 rounded ${item.active ? 'bg-secondary text-white' : 'bg-none text-darkgray'}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                      ))}
                  </div>
                  {
                      {
                      1: <LeaderboardListMain platform={asPlatform} limit={100} platformId={activeTab} isProfilePage={false} filters={filters}/>,
                      2: <LeaderboardListMain platform={asPlatform} limit={100} platformId={activeTab} isProfilePage={false} filters={filters}/>,
                      }[activeTab]
                  }
              </div>
          </React.Fragment>
        </div>
        <hr className="h-0.5 bg-gray-700 border-0 dark:bg-gray-700" />
      </div>
    </React.Fragment>
  );
}

export default observer(TournamentLeaderboard);
