import React, { useState } from 'react'
import 'aos/dist/aos.css';
import { observer } from 'mobx-react-lite';
import TournamentCardList from './TournamentCardList';
import TournamentLeaderboard from './TournamentLeaderboard';
import '../../index.css'
import SingleBanner from '../../app/component/banner/SingleBanner';
import { useSearchParams } from 'react-router-dom';

const TournamentList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let defaultActive = searchParams.get("active")
    const [activeTab, setActiveTab] = useState(defaultActive ? 2 : 1);
    
    //setActiveTab(defaultActive ? 2 : 1)
    const [menu, setMenu] = useState([
        {
            id: 1,
            name: "Tournaments",
            active: searchParams.get("active") ? false : true
        },
        {
            id: 2,
            name: "Leaderboard",
            active: searchParams.get("active") ? true : false
        },
    ]);

    const handleMenu = (buttonId: number) => {
        const updatedButtons = menu.map(button => {
            if (button.id === buttonId) {
                return { ...button, active: true };
            } else {
                return { ...button, active: false };
            }
        });
        setMenu(updatedButtons);
        setActiveTab(buttonId);
    }

    return (
        <React.Fragment>
            <div className='w-full h-full bg-primary lg:bg-bg-home bg-top bg-no-repeat font-sans'>
                <SingleBanner></SingleBanner>
                <div className='w-full h-full pt-6 px-4 xl:px-40'>
                    <div id='tab' className='w-full flex flex-row divider-darkgray border-b border-1'>
                        {menu.map((item, idx) => (
                            <button key={idx} className={`text-sm font-bold px-2.5 pb-4 ${item.active ? ' text-secondary border-secondary border-b-2' : 'border-transparent text-darkgray border-b-2'}`} onClick={() => handleMenu(item.id)}> {item.name} </button>
                        ))}
                    </div>
                    {
                        {
                            1: <TournamentCardList />,
                            2: <div>
                                {/* <p className='text-darkgray text-[14px] font-normal border-b-[.5px] border-white pb-2 mt-4'>Data tidak ditemukan</p> */}
                                
                                <TournamentLeaderboard />
                            </div>,
                        }[activeTab]
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default observer(TournamentList)